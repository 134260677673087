<template>
    <div class="sign-in--container">
        <div class="sign-in--card">
            <div class="sign-in--card--background">
                <img src="/img/logo-color.svg" alt="">
                <h2>Plataforma de evaluación</h2>
                
                <template v-if="step == 'init'">
                    <Form style="width: 100%" ref="forgotPassword" :model="forg" :rules="ruleInline">
                        <FormItem prop="email">
                            <Input size="large" type="text" v-model="forg.email" placeholder="Ingresa tu correo electrónico"/>
                        </FormItem>
                        <FormItem style="margin-bottom: 0 !important">
                            <Button :loading="loading" long size="large" type="primary" @click="handleSubmit">Recupera tu contraseña</Button>
                            <Button @click="$router.push('/')" style="margin-top: 25px" size="small" type="text">O puedes iniciar sesión</Button>
                        </FormItem>
                    </Form>
                </template>

                <template v-if="step == 'success'">
                    <div style="height: 105px; display: flex; align-items: center; justify-content: center; text-align: center; flex-direction: column;">
                        <h3>Correo enviado</h3>
                        <p>Por favor, revisa tu correo para iniciar el proceso de recuperación de contraseña.</p>
                        <p>Recuerda también buscar dentro de la carpeta de <b>SPAM</b>.</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                step: 'init',
                forg: {
                    email: '',
                },
                loading: false,
                ruleInline: {
                    email: [
                        { required: true, message: 'Correo electrónico es requerido', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            handleSubmit(){
                this.$refs.forgotPassword.validate(valid => {
                    console.log(valid)
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/auth/forgot-password', { ...this.forg })
                        .then(response => {

                            if(response.data.success){
                                this.step = 'success'
                            }
                            else {
                                this.$Message.error(response.data.message);
                                this.loading = false
                            }

                        })
                        .catch(() => {
                            this.$Message.error('Whoops, algo salió mal');
                            this.loading = false
                        })

                    }
                })
            }
        }
    }
</script>