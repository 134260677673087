<template>
    <div>
        <!-- Q18 -->
        <WorkEnvReportItemHalfPie question="18. ¿En tu área de trabajo has recibido comentarios sugestivos o sexistas, bromas ofensivas, miradas obscenas, o petición de prácticas indeseables relacionadas con asuntos sexuales? "
                                  :answers="answers_data['q18']" />

        <!-- Q19 -->
        <WorkEnvReportItemHalfPie question="19. ¿En tu área de trabajo has vivido contacto físico sexual no deseado?"
                                  :answers="answers_data['q19']" />

        <!-- Q20 -->
        <WorkEnvReportItemHalfPie question="20. ¿En tu área de trabajo se logran recompensas o incentivos laborales a cambio de favores sexuales? "
                                  :answers="answers_data['q20']" />

        <!-- Q21 -->
        <WorkEnvReportItemHalfPie question="21. ¿En tu área de trabajo o en el ILSB hay comentarios sexistas, racistas, capacitistas, homolesbotranfóbicos? "
                                  :answers="answers_data['q21']" />
    </div>
</template>

<script>
    //import download from '@/mixins/download'
    import WorkEnvReportItemHalfPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-HalfPie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            WorkEnvReportItemHalfPie,
        }
    }
</script>