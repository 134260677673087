<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Escalas de evaluación</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button  v-if="$can('evaluation.scales.create')" style="margin-right: 15px" class="display-none-print" @click="createScale" type="primary">Agregar</Button>
                </i-col>
            </Row>

            <br>
            <Table style="font-size: 0.9em" border :columns="columns" :data="Scales">
                <template slot-scope="{ row }" slot="category">
                    <p>{{ row.category }}</p>
                </template>

                <template slot-scope="{ row }" slot="name">
                    <div style="padding: 10px 0">
                        <strong>{{ row.name }}</strong>
                        <p>{{ row.description }}</p>
                    </div>
                </template>

                <template slot-scope="{ row }" slot="color">
                    <div style="display: flex; align-items: center;">
                        <div :style="{ 'background': row.hex_color }" class="indicator--tag"></div>
                        <p style="font-size: 0.9em">{{ row.hex_color }}</p>
                    </div>
                </template>

                <!-- <template slot-scope="{ row }" slot="active">
                    <template v-if="row.active == 1">
                        <Icon color="#20CB74" size="16" type="md-checkmark-circle"></Icon>
                    </template>
                    <template v-else>
                        <Icon color="#EE6B5F" size="16" type="md-close-circle"></Icon>
                    </template>
                </template> -->

                <template slot-scope="{ row }" slot="options">
                    <Dropdown v-if="$can('evaluation.scales.edit') || $can('evaluation.scales.delete')" trigger="click">
                        <a href="javascript:void(0)">
                            <Icon color="grey" size="16" type="md-more"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem v-if="$can('evaluation.scales.edit')" @click.native="updateScale(row)">Editar</DropdownItem>
                            <DropdownItem v-if="$can('evaluation.scales.delete')" @click.native="hideScale(row)">Ocultar</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </template>
            </Table>

            <CreateScale />
            <UpdateScale />
        </div>
    </div>
</template>

<script>
    import CreateScale from './components/Create'
    import UpdateScale from './components/Update'

    export default {
        data(){
            return {
                scales: [],
                columns: [
                    {
                        title: 'Categoría',
                        slot: 'category',
                        width: 140,
                    },
                    {
                        title: 'Nombre',
                        slot: 'name',
                        width: 550,
                    },
                    {
                        title: 'Código',
                        key: 'short_name',
                        width: 90,
                    },
                    {
                        title: 'Cólor',
                        slot: 'color',
                        width: 130,
                    },
                    {
                        title: 'Puntos',
                        key: 'score',
                        width: 120,
                    },
                    // {
                    //     title: 'Active',
                    //     slot: 'active',
                    //     width: 120,
                    // },
                    {
                        title: '',
                        slot: 'options',
                        width: 50,
                    },
                ],
                loading: true,
            }
        },
        computed:{
            Scales(){
                return window._.filter(this.scales, s => {
                    return s.active == true
                })
            }
        },
        methods: {
            getScales(){
                window.axios.post(this.$root.API_URL + '/evaluation/get-scales')
                .then(response => {
                    if(response.data.success){
                        this.scales = response.data.scales
                        this.loading = false
                    }
                })
            },
            createScale(){
                window.EventBus.$emit('evaluation-scales--create', true)
            },
            updateScale(scale){
                window.EventBus.$emit('evaluation-scales--update-popup', scale)
            },
            hideScale(scale){
                 this.$Modal.confirm({
                    title: 'Ocultar escala',
                    content: `<p>¿Deseas ocultrar esta escala?</p><p>Al hacer clic en <strong>Ocultar</strong> esta escala ya no estará disponible para ser seleccionada en las evaluaciones.</p>`,
                    okText: 'Ocultar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/evaluation/update-scale/${scale.id}`, { ...scale, active: 0 })
                        .then(response => {
                            if(response.data.success){
                                 window.EventBus.$emit('evaluation-scales--update', response.data.scale)
                                this.$Message.success('Escala desactivada correctamente');
                            }
                        })
                    },
                });
            }
        },
        mounted(){
            if( ! this.$can('evaluation.scales.view') ) this.$router.replace('/plan')
            this.getScales()

            window.EventBus.$on('evaluation-scales--add', (scale) => {
                let s = {
                    ...scale,
                    active: true,
                } 
                this.scales.push(s)
            })

            window.EventBus.$on('evaluation-scales--update', (scale) => {
                let sii = window._.findIndex(this.scales, s => {
                    return s.id == scale.id
                })

                if( sii != -1 ){
                    this.$set(this.scales, sii, scale)
                }
            })
        },
        components: {
            CreateScale,
            UpdateScale,
        }
    }
</script>