<template>
    <div style="margin: 25px 0 40px 0; border-bottom: 1px solid #ececec; padding-bottom: 9px;">
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/bitacora/list`">Listado</router-link>
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/bitacora/recap`">Resumen general</router-link>
    </div>
</template>

<script>
    export default {
        name: 'BitacoraTabs',
        props: {
            tab: { required: true, type: String, default: 'list' },
            poa_link: { required: true, type: String, }
        },
    }
</script>
