<template>
    <div>
        <h2>{{ funder.name }}</h2>
        <br>
        <Table row-key="uid" show-summary :summary-method="handleSummary" :columns="columns" :data="FunderData" border>
            <template slot-scope="{ row }" slot="requested">
                <p :class="{ 'red-text': row.requested < 0 }" style="text-align: right;">{{ row.requested | currency }}</p>
            </template>

            <template slot-scope="{ row }" slot="available">
                <p :class="{ 'red-text': row.available < 0 }" style="text-align: right;">{{ row.available | currency }}</p>
            </template>

            <template slot-scope="{ row }" slot="deficit">
                <p :class="{ 'red-text': row.deficit < 0 }" style="text-align: right;">{{ row.deficit | currency }}</p>
            </template>

            <template slot-scope="{ row }" slot="spent">
                <p :class="{ 'red-text': row.spent < 0 }" style="text-align: right;">{{ row.spent | currency }}</p>
            </template>

            <template slot-scope="{ row }" slot="to_spent">
                <p :class="{ 'red-text': row.to_spent < 0 }" style="text-align: right;">{{ row.to_spent | currency }}</p>
            </template>
        </Table>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
    export default {
        props: {
            poa: { required: true, type: [Object, Array] },
            funder: { required: true, type: [Object, Array], },
            headings: { required: true, type: [Object, Array], },
            activities: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                columns: [
                    {
                        title: 'Rubro',
                        key: 'name',
                        tree: true,
                    },
                    {
                        title: 'Solicitado',
                        slot: 'requested',
                        key: 'requested',
                        width: 120,
                    },
                    {
                        title: 'Disponible',
                        slot: 'available',
                        key: 'available',
                        width: 120,
                    },
                    {
                        title: 'Variación',
                        slot: 'deficit',
                        key: 'deficit',
                        width: 120,
                    },
                    {
                        title: 'Ejercido',
                        slot: 'spent',
                        key: 'spent',
                        width: 120,
                    },
                    {
                        title: 'Por ejercer',
                        slot: 'to_spent',
                        key: 'to_spent',
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ActivitiesWithFunders(){
                return window._.filter(this.activities, a => a.funders != null)
            },
            FunderData(){
                let hs = [].concat(this.headings)

                window._.each(hs, (h) => {
                    let hds = []
                    h.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
                    h.requested = this.getRequestedOfHeading(h.id)
                    h.available = this.getAvailableOfHeading(h.id)
                    h.deficit = h.available - h.requested
                    h.spent = this.getSpentOfHeading(h.id)
                    h.to_spent = h.available - h.spent

                    window._.each(h.subheadings, (hd) => {
                        hd.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
                        hd.requested = this.getRequestedOfSubheading(h.id, hd.id)
                        hd.available = this.getAvailableOfSubheading(hd.id)
                        hd.deficit = hd.available - hd.requested
                        hd.spent = this.getSpentOfSubheading(h.id, hd.id)
                        hd.to_spent = hd.available - hd.spent

                        hds.push(hd)
                    })

                    h.children = hds
                })

                return hs
            },
        },
        methods: {
            getRequestedOfHeading(heading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                window._.each(sb.budgets, budget => {
                                    if( budget.funder && budget.funder.id == this.funder.id ){
                                        let ps = parseFloat(budget.final_gross_price)
                                        if( ps ) total += ps
                                    }
                                })
                            })
                        }
                    })
                })

                return total
            },
            getAvailableOfHeading(heading_id){
                let total = 0

                window._.each(this.funder.budget, (budget) => {
                    if(budget.heading.id == heading_id){
                        let ps = parseFloat(budget.final_total_price)
                        if( ps ) total += ps
                    }
                })

                return total
            },
            getSpentOfHeading(heading_id){
                let total = 0
                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                window._.each(sb.budgets, budget => {
                                    if( budget.funder && budget.funder.id == this.funder.id ){
                                        window._.each(budget.transfers, (transfer) => {
                                            let ps = parseFloat(transfer.amount)
                                            if( ps ){
                                                if( bd.heading.name == 'Honorarios' ){
                                                    let nps = ps / 0.95333333334  * 1.16
                                                    total += nps
                                                }
                                                else  total += ps
                                            }
                                        })
                                    }
                                })
                            })
                        }
                    })
                })
                
                return total
            },
            getRequestedOfSubheading(heading_id, subheading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                if( sb.subheading.id == subheading_id ){
                                    window._.each(sb.budgets, budget => {
                                        if( budget.funder && budget.funder.id == this.funder.id ){
                                            let ps = parseFloat(budget.final_gross_price)
                                            if( ps ) total += ps
                                        }
                                    })
                                }
                            })
                        }
                    })
                })
                
                return total
            },
            getAvailableOfSubheading(subheading_id){
                let total = 0

                window._.each(this.funder.budget, (budget) => {
                    if(budget.subheading && budget.subheading.id == subheading_id){
                        let ps = parseFloat(budget.final_total_price)
                        if( ps ) total += ps
                    }
                })

                return total
            },
            getSpentOfSubheading(heading_id, subheading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                if( sb.subheading.id == subheading_id ){
                                    window._.each(sb.budgets, budget => {
                                        window._.each(budget.transfers, (transfer) => {
                                            if(transfer.authorized_by != null){
                                                let ps = parseFloat(transfer.amount)
                                                if( ps ) {
                                                    if( bd.heading.name == 'Honorarios' ){
                                                        let nps = ps / 0.95333333334  * 1.16
                                                        total += nps
                                                    }
                                                    else  total += ps
                                                }
                                            }
                                        })
                                    })
                                }
                            })
                        }
                    })
                })

                return total
            },
            currencyFormatForSummary(value){
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                return formatter.format(value)
            },
            handleSummary ({ columns, data }) {
                const sums = {};
                columns.forEach((column, index) => {
                    const key = column.key;
                    if (index === 0) {
                        sums[key] = {
                            key,
                            value: 'Total'
                        };
                        return;
                    }

                    const values = data.map(item => Number(item[key]));
                    // console.log(values)
                    if (!values.every(value => isNaN(value))) {
                        const v = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[key] = {
                            key,
                            value: this.currencyFormatForSummary(v),
                        };
                    } else {
                        sums[key] = {
                            key,
                            value: 'N/A'
                        };
                    }
                });

                return sums;
            }
        }
    }
</script>