<template>
    <div class="anual-result">
        <Collapse class="invisible perists" v-model="collapse_result">
            <Panel name="1">
                <div class="result-header--collapse-panel">
                    <Tag v-if="evaluation && ResultEvaluation" type="dot" :color="ResultEvaluation.status.hex_color">{{ ResultEvaluation.status.name }}</Tag>
                    <h3 style="position: relative;" :style="{ 'margin-left': evaluation ? '0px': '15px' }">
                        <!-- <span v-if="!evaluation" style="position: absolute; top: 7px; left: -11px; margin-left: -10px; width: 11px; height: 11px; border-radius: 50%;" :style="{ 'background-color': result.unique_color }"></span> -->
                        {{ result.name }}
                    </h3>
                </div>
                <div style="margin-left: 2rem" slot="content">
                    <!-- Evaluation feedback -->
                    <p v-if="evaluation && ResultEvaluation" style="white-space: pre-line;">{{ ResultEvaluation.feedback }}</p>

                    <div v-if="showIndicators" class="anual-result--item--indicators">
                        <br>
                        <Row :gutter="24">
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicadores de éxito <Button v-if="canModify && !evaluation && SuccessIndicators.length < SuccessIndicatorMaxLength" style="margin-left: 15px" size="small" ghost type="primary" @click="addIndicator('success')">Agregar</Button></p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in SuccessIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <!-- Evaluation dropdown -->
                                        <template v-if="CanEvaluateIndicator">
                                            <Dropdown v-if="evaluation" trigger="click">
                                                <div :style="{ 'background-color': indicator.status.hex_color }" class="indicator--tag"></div>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem v-for="status in statuses" :key="'res_ev_ind_upt_succ_'+status.id" @click.native="updateIndicatorStatus(indicator, status)">
                                                        <div class="indicator--short">
                                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                                            {{ status.short_name }}
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </template>
                                        <template v-else>
                                            <div :style="{ 'background-color': indicator.status.hex_color }" class="indicator--tag"></div>
                                        </template>

                                        <p class="indicator--description">{{ indicator.name }}</p>
                                        <div v-if="!evaluation && canModify" class="indicator--action">
                                            <Dropdown trigger="click" placement="left-end">
                                                <a href="javascript:void(0)">
                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                </a>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem @click.native="updateIndicator(indicator)">Editar</DropdownItem>
                                                    <DropdownItem @click.native="deleteIndicator(indicator)">Eliminar</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </i-col>
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicador de avance del resultado <Button v-if="canModify && !evaluation && ProgressIndicators.length < ProgressIndicatorMaxLength" style="margin-left: 15px" size="small" ghost type="primary" @click="addIndicator('progress')">Agregar</Button></p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in ProgressIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <!-- Evaluation dropdown -->
                                        <template v-if="CanEvaluateIndicator">
                                            <Dropdown v-if="evaluation" trigger="click">
                                                <div :style="{ 'background-color': indicator.status.hex_color }" class="indicator--tag"></div>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem v-for="status in statuses" :key="'res_ev_ind_upt_succ_'+status.id" @click.native="updateIndicatorStatus(indicator, status)">
                                                        <div class="indicator--short">
                                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                                            {{ status.short_name }}
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </template>
                                        <template v-else>
                                            <div :style="{ 'background-color': indicator.status.hex_color }" class="indicator--tag"></div>
                                        </template>
                                        <p class="indicator--description">{{ indicator.name }}</p>
                                        <div  v-if="canModify && !evaluation" class="indicator--action">
                                            <Dropdown trigger="click" placement="left-end">
                                                <a href="javascript:void(0)">
                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                </a>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem @click.native="updateIndicator(indicator)">Editar</DropdownItem>
                                                    <DropdownItem @click.native="deleteIndicator(indicator)">Eliminar</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </i-col>
                        </Row>
                    </div>
                    <div class="anual-result--item--action">
                        <div class="left"></div>
                        <div v-if="!evaluation" class="right">
                            <Button v-if="canModify" size="small" ghost type="warning" @click="updateResult">Editar resultado</Button>
                            <Button v-if="canModify" size="small" ghost type="error" @click="deleteResult">Eliminar resultado</Button>
                        </div>
                        <div v-else class="right">
                            <Button v-if="canModify" size="small" ghost type="primary" @click="updateEvaluation">Evaluar Resultado</Button>
                        </div>
                    </div>
                </div>
            </Panel>
        </Collapse>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import ActivityItem from './../../Program/POA/components/Calendar/components/Activity/Item'

    export default {
        props: {
            result: { required: true, type: [Object, Array] },
            program: { required: false, type: [Object, Array] },
            ev_type: { required: false, type: String, default: "" },
            evaluation: { required: false, type: Boolean, default: false },
            canModify: { required: false, type: Boolean, default: false },
            showIndicators: { requireD: false, type: Boolean, default: true },
        },
        data(){
            return {
                collapse_result: '0',
                collapse_activities: '0',
                max_length: 5,
                ind_success_max_length: 3,
                ind_progress_max_length: 5,
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                statuses: 'status_tdc',
                program_member: 'program',
            }),
            Activities(){
                return window._.orderBy(this.result.activities, (a) => {
                    return a.result.id
                })
            },
            SuccessIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'success'
                    })
                , ['order'], ['asc'])
            },
            ProgressIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'progress'
                    })
                , ['order'], ['asc'])
            },
            SuccessIndicatorMaxLength(){
                if( this.user.role_name == 'Super Admin' ) return 99
                else return this.ind_success_max_length
            },
            ProgressIndicatorMaxLength(){
                if( this.user.role_name == 'Super Admin' ) return 99
                else return this.ind_progress_max_length
            },
            EvaluationKey(){
                if(this.ev_type == 'middle') return 'middle_evaluation'
                else return 'final_evaluation'
            },
            ResultEvaluation(){
                return this.result.evaluations[0] ?? null;
            },
            CanEvaluateIndicator(){
                return this.user.role_name == 'Super Admin'
            }
        },
        methods: {
            addIndicator(type){
                window.EventBus.$emit('program-tdc--create-indicator', this.result, type)
            },
            updateIndicator(ind){
                window.EventBus.$emit('program-poa--update-indicator', this.result, ind)
            },
            deleteIndicator(ind){
                this.$Modal.confirm({
                    title: 'Eliminar Indicador',
                    content: `<p>¿Deseas eliminar este indicador?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/indicators/${ind.id}/delete`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`result-item-${this.result.id}--delete-indicator`, ind)
                                this.$Message.success('RI eliminado correctamente');
                            }
                        })
                    },
                });
            },
            updateResult(){
                window.EventBus.$emit('program-update--update-result', this.result)
            },
            deleteResult(){
                this.$Modal.confirm({
                    title: 'Eliminar Resultado',
                    content: `<p>¿Deseas eliminar este resultado?</p><p>Al hacer clic en <strong>Eliminar</strong> también se borarrá toda la información relacionada a este resultado.</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/results/delete/${this.result.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`program-poa---delete-result`, this.result)
                                this.$Message.success('Resultado eliminado correctamente');
                            }
                        })
                    },
                });
            },
            collapseActivities(){
               this.collapse_activities = this.collapse_activities == '1' ? '0' : '1'
            },
            updateEvaluation(){
                // window.EventBus.$emit('program-tdc--update-evaluation', this.result, this.ResultEvaluation)
                window.EventBus.$emit('program-tdc--update-evaluation', this.result, this.ResultEvaluation)
            },
            evaluateIndicator(indicator){
                window.EventBus.$emit('program-poa--evaluate-indicator', indicator) 
            },
            updateIndicatorStatus(indicator, status){
                window.axios.post(this.$root.API_URL + `/indicators/${indicator.id}/update-status`, { statuses_id: status.id })
                .then(response => {
                    if(response.data.success){
                        let ii = window._.findIndex(this.result.indicators, (i) => {
                            return i.id == indicator.id
                        })

                        if(ii != -1){
                            window.EventBus.$emit('program-tdc--update-result', response.data.result)
                            this.$Message.success('Indicador actualizado correctamente');
                        }
                    }
                })
            },
            getColabs(activity){
                let usrs = []

                window._.each(activity.colabs, (c) => {
                    usrs.push(c.user)
                })

                return usrs
            },
            addActivity(){
                window.EventBus.$emit('poa-activities--create', this.result)
            },
            updateActivity(activity){
                window.EventBus.$emit('poa-activities--update', activity)
            },
            deleteActivity(activity){
                this.$Modal.confirm({
                    title: 'Eliminar Actividad',
                    content: `<p>¿Deseas eliminar esta actividad?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/activities/delete/${activity.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-activities-caldendar--delete-activity`, activity)
                                this.$Message.success('Actividad eliminada correctamente');
                            }
                        })
                    },
                });
            }
        },
        mounted(){

            window.EventBus.$on(`result-tdc--item-${this.result.id}--add-indicator`, (indicator) => {
                let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii == -1){
                    this.result.indicators.push(indicator)
                }
            })

            window.EventBus.$on(`result-item-${this.result.id}--update-indicator`, (indicator) => {
                let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii != -1){
                    this.$set(this.result.indicators, ii, indicator)
                    this.$updateCollapseHeight(`anual-result-${this.result.id}`)
                }
            })

             window.EventBus.$on(`result-item-${this.result.id}--delete-indicator`, (indicator) => {
                 let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii != -1){
                    this.result.indicators.splice(ii, 1)
                    this.$updateCollapseHeight(`anual-result-${this.result.id}`)
                }
             })

        },
        components: {
            // AvatarList,
            // ActivityItem
        }
    }
</script>