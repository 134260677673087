<template>
    <div>
        <Drawer/>
        <Notifications/>
        <div class="mobile-border">
            <div v-if="is_mobile" class="cc"></div>
            <StickyElement> 
                <div class="main-navbar">

                    <template v-if="is_mobile">
                        
                        <Button @click="toggleDrawer" size="large" icon="md-menu" type="text" shape="circle"/>
                        <img @click="$router.push('/')" class="main-navbar--logo" src="/img/logo-color.svg" alt="">

                    </template>
                    <div class="spacer"></div>

                    <!-- Notifications  -->
                    <Badge :count="notifications_count" @click.native="showNotifications">
                        <Button size="large" icon="md-notifications" type="text" shape="circle"/>
                    </Badge>
                    <!-- <Poptip word-wrap width="350" placement="bottom" trigger="click" padding="16px" v-model="show_notifications">
                        <template #content>
                            <List>
                                <ListItem class="notification-item">
                                    <ListItemMeta>
                                        <template #title>
                                            <p class="notification-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa corrupti aliquam est eos ullam soluta eveniet minus.</p>
                                        </template>
                                        <template #description>
                                            <p class="notification-date">12/07/2021 16:18</p>
                                        </template>
                                    </ListItemMeta> 
                                </ListItem>
                            </List>
                        </template>
                    </Poptip> -->

                    <!-- User info -->
                    <div @click="showProfilePopup" class="auth-user-info">
                        <div class="auth-user-info--text">
                            <p class="auth-user-info--text--name">{{ user.name }}</p>
                            <p class="auth-user-info--text--sub">{{ user.role_name }}</p>
                        </div>
                        <div class="auth-user-info--avatar">
                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                        </div>
                    </div>
                </div>
            </StickyElement>
        </div>
    </div>
</template>

<script>
    import Drawer from './Drawer'
    import Notifications from './Notifications'
    import { mapGetters } from 'vuex'
    import StickyElement from 'vue-sticky-element';

    export default {
        data(){
            return {
                show_notifications: false,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'drawer',
                'is_mobile',
                'notifications_count',
            ])
        },
        methods: {
            toggleDrawer(){
                this.$store.dispatch('SET_DRAWER', !this.drawer)
            },
            showNotifications(){
                window.EventBus.$emit('show-notifications-drawer', true)
            },
            showProfilePopup(){
                window.EventBus.$emit('profile--show-popup')
            }
        },
        components: {
            Drawer,
            Notifications,
            StickyElement,
        }
    }
</script>