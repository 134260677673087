<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Agregar fecha clave" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" label-position="top" :model="date" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="date.name" placeholder="Nombre"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Descripción" prop="desc">
                        <Input type="textarea" :autosize="{minRows: 4,maxRows: 4}" v-model="date.description" placeholder="Descripción"/>
                    </FormItem>
                </i-col>
                 <i-col span="24">
                    <FormItem label="Inicio" prop="start">
                        <DatePicker style="width: 100%" type="date" placeholder="Seleccionar" v-model="date.start" format="dd/MM/yyyy"></DatePicker>
                    </FormItem>
                </i-col>
                    <i-col span="24">
                    <FormItem label="Fin" prop="end">
                        <DatePicker style="width: 100%" type="date" placeholder="Seleccionar" v-model="date.end" format="dd/MM/yyyy"></DatePicker>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Crear</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                date: {
                    name: "",
                    description: "",
                    start: null,
                    end: null,
                },
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                    start: [
                        { required: true, type: 'date', message: 'Fecha es requerida', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/imp-dates/create/${this.$parent.poa.id}`, { ...this.date })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-activities-caldendar--add-imp-date', response.data.date)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.date = {
                    name: "",
                    description: "",
                    start: null,
                    end: null,
                }
                this.$refs['planForm'].resetFields();
            }
        },
        mounted(){
            window.EventBus.$on('poa-activities--create-imp-date', () => {
                this.show = true
            })
        }
    }
</script>