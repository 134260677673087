<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <h1 class="section--subtitle">Evaluación {{ Year }}</h1>

            <template v-if=" ! evaluation.secret && autoevaluation != null && AutoevaluationFinished == false ">
                <Alert type="error">{{ autoevaluation.user.name }} no ha finalizado su autoevaluación.</Alert>
            </template>

            <template v-if="ShowTabs">
                <template v-if="evaluation != null">
                    <div style="padding: 25px 0">
                        <p style="margin-bottom: 15px; font-weight: bold">Estás evaluando a: </p>
                        <div style="display: flex; align-items: center;">
                            <Avatar :src="evaluation.evaluated.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                            <div style="margin-left: 15px">
                                <p style="font-weight: bold">{{ evaluation.evaluated.name }}</p>
                                <p style="font-size: 0.9em; color: grey;">
                                    {{ evaluation.evaluated.role_name }}
                                    <span v-if="evaluation.evaluated.program"> en {{ evaluation.evaluated.program.program.name }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <Tabs :value="tab">
                        <TabPane v-if="ShowResultsTab" label="Planeación Operativa Anual (POA)" name="results">
                            <ResultsGrid :evaluation="evaluation" :autoevaluation="autoevaluation" :activities="activities" />
                        </TabPane>
                        <TabPane label="Competencias" name="competences">
                            <CompetencesGrid :evaluation="evaluation" :autoevaluation="autoevaluation" :isSpecial="IsSpecialEvaluation"/>
                            <br><br>
                            <Row>
                                <i-col offset="12" span="12">
                                    <Button @click="marAsFinished" :disabled="ButtonEvaluationDisabled" type="primary" long size="large">Terminar evaluación</Button>
                                </i-col>
                            </Row>
                        </TabPane>
                        <TabPane v-if="ShowEvaluationResultsTab" label="Resultados de evaluación" name="evaluation-results">
                            <EvaluationResults :evaluation="evaluation" :autoevaluation="autoevaluation" />
                            <br><br>
                            <Row>
                                <i-col offset="12" span="12">
                                    <Button @click="marAsFinished" :disabled="ButtonEvaluationDisabled" type="primary" long size="large">Terminar evaluación</Button>
                                </i-col>
                            </Row>
                        </TabPane>
                    </Tabs>

                    <!-- <v-tabs v-model="tab">
                        <v-tab v-if="ShowResultsTab" key="0">Planeación Operativa Anual (POA)</v-tab>
                        <v-tab key="1">Competencias</v-tab>
                        <v-tab v-if="ShowEvaluationResultsTab" key="2">Resultados de evaluación</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-if="ShowResultsTab" key="0">
                            <ResultsGrid :evaluation="evaluation" :autoevaluation="autoevaluation" />
                        </v-tab-item>
                        <v-tab-item v-if="ShowResultsTab" key="1">
                            <CompetencesGrid :evaluation="evaluation" :autoevaluation="autoevaluation" :isSpecial="IsSpecialEvaluation"/>
                        </v-tab-item>
                        <v-tab-item v-if="ShowResultsTab" key="2">
                            <EvaluationResults :evaluation="evaluation" :autoevaluation="autoevaluation" />
                        </v-tab-item>
                    </v-tabs-items> -->

                    
                </template>
                <template v-else>
                    <h2 style="text-align: center">{{ message }}</h2>
                </template>
            </template>

        </div>
    </div>
</template>

<script>
    import ResultsGrid from './components/Results'
    import CompetencesGrid from './components/Competences'
    import EvaluationResults from './components/EvResult'

    export default {
        data(){
            return {
                tab: null,
                message: '',
                loading: true,
                evaluation: null,
                activities: [],
                autoevaluation: null,
            }
        },
        computed: {
            EvaluationType(){
                return this.evaluation.type
            },
            Year(){
                // if( this.evaluation ) return this.evaluation.evaluation.name
                // else return ''
                return ''
            },
            ShowResultsTab(){
                let results = window._.filter(this.evaluation.evaluations, e => e.classable_type == 'result')
                return results.length > 0
            },
            ShowEvaluationResultsTab(){
                // return this.evaluation.user.role_name == 'Coordinación' && this.evaluation.evaluated.role_name == 'Oficial Sr.' || this.evaluation.evaluated.role_name == 'Oficial Jr.'
                if( this.evaluation.type == 'feedback' ) return true
                return false
            },
            ButtonEvaluationDisabled(){
                if( this.evaluation.status != 'finished' && this.evaluation.status != 'completed' ){
                    let a = [];
    
                    window._.each(this.evaluation.evaluations, (evaluation) => {
    
                        if( evaluation.comment != null ) a.push(true)
                        else a.push(false)
    
                        if( evaluation.status != null ) a.push(true)
                        else a.push(false)
    
                    })

                    if( this.EvResultsCommentsRequired ){
                        if( this.evaluation.strengths_feedback != '' && this.evaluation.strengths_feedback != null ) a.push(true)
                        else a.push(false)
    
                        if( this.evaluation.opportunities_feedback != '' && this.evaluation.opportunities_feedback != null ) a.push(true)
                        else a.push(false)
    
                        if( this.evaluation.compromises_feedback != '' && this.evaluation.compromises_feedback != null ) a.push(true)
                        else a.push(false)
                    }
                    console.log(a)
                    return a.includes(false)
                }
                else return true;
            },
            IsSpecialEvaluation(){
                let a = []
                window._.each(this.evaluation.evaluations, evaluation => a.push(evaluation.classable_type) )
                return a.includes('special-competence')
            },
            ShowTabs(){
                if( this.evaluation.secret ) return true
                else {
                    if( this.autoevaluation != null && this.AutoevaluationFinished == false ) return false
                    return true
                }
            },
            AutoevaluationFinished(){
                if( this.autoevaluation != null ){
                    return this.autoevaluation.status == 'auto-finished' || this.autoevaluation.status == 'boss-finished' || this.autoevaluation.status == 'accepted'
                }
                return false;
            },
            EvResultsCommentsRequired(){
                if( this.evaluation.evaluated.role_name == 'Coordinación' && this.evaluation.user.role_name == 'Oficial Sr.' ) return false

                if( this.evaluation.evaluated.role_name == 'Coordinación' && this.evaluation.user.role_name == 'Oficial' ) return false

                if( this.evaluation.evaluated.role_name == 'Coordinación' && this.evaluation.user.role_name == 'Mantenimiento' ) return false

                if( this.evaluation.evaluated.role_name == 'Coordinación' && this.evaluation.user.role_name == 'Asistente de programa' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Coordinación' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Subdirección' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Oficial' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Oficial Sr.' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Asistente de programa' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Mantenimiento' ) return false

                if( this.evaluation.evaluated.role_name == 'Dirección' && this.evaluation.user.role_name == 'Oficial del área directiva' ) return false

                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Coordinación' ) return false

                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Oficial' ) return false

                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Oficial Sr.' ) return false

                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Asistente de programa' ) return false
                
                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Mantenimiento' ) return false

                if( this.evaluation.evaluated.role_name == 'Subdirección' && this.evaluation.user.role_name == 'Oficial del área directiva' ) return false

                // if( this.evaluation.evaluated.role_name == 'Coordinación' && this.evaluation.user.role_name == 'Dirección' ) return false

                return true
            }
        },
        methods: {
            getEvaluation(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-evaluation/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.evaluation = response.data.evaluation
                        this.autoevaluation = response.data.autoevaluation
                        this.activities = response.data.activities
                        this.loading = false
                        if( this.ShowResultsTab == false ) this.tab = 'competences'
                    }
                    else {
                        this.message = response.data.type
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            marAsFinished(){
                this.$Modal.confirm({
                    title: 'Terminar evaluación',
                    content: `¿Estas segura (o/e) que deseas concluir la evaluación? Una vez activado este botón ya no podrás hacer cambios.`,
                    okText: 'Terminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/evaluation/mark-as-completed/${this.evaluation.id}`, { autoevaluation_id: this.autoevaluation ? this.autoevaluation.id : null })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                                this.$Message.success('La evaluación fue finalizada correctamente');
                            }
                            else {
                                this.$Message.error('No se pudo finalizar la evaluación, por favor revisa el formulario');
                            }
                        })
                        .catch(() => {
                            this.$Message.error('No se pudo finalizar la evaluación');
                        })
                    },
                });
            },
        },
        mounted(){
            this.getEvaluation()

            window.EventBus.$on('autoevaluation--update-evaluation', (evaluation) => {
                this.evaluation = evaluation
            })

            if( this.ShowResultsTab == false ) this.tab = 'competences'
        },
        components: {
            ResultsGrid,
            CompetencesGrid,
            EvaluationResults,
        }
    }
</script>