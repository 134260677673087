<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo POA" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="poa" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Año" prop="year">
                        <Select v-model="poa.year">
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                            <Option value="2024">2024</Option>
                            <Option value="2025">2025</Option>
                        </Select>
                        <!-- <Input v-model="poa.year" placeholder="2021"/> -->
                    </FormItem>
                    <br><br><br><br>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                poa: {
                    year: ""
                },
                type: '',
                loading: false,
                ruleInline: {
                    year: [
                        { required: true, message: 'Año es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        let url = ''
                        // this.$parent.program it's a project object
                        if( this.type == 'program' ) url = this.$root.API_URL + `/poas/${this.$parent.program.id}/create`
                        else if( this.type == 'project' ) url = this.$root.API_URL + `/poas/project/${this.$parent.program.id}/create`
                        else url = this.$root.API_URL + `/poas/area/${this.$parent.program.id}/create`

                        window.axios.post(url, { ...this.poa, })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-details--add-poa-item', response.data.poa)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.poa = {
                    year: ""
                }
            }
        },
        mounted(){
            window.EventBus.$on('program-details--add-poa', (type) => {
                this.type = type
                this.show = true
            })
        }
    }
</script>