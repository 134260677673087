<template>
    <div class="avatar-list">
        <div v-if="title" class="avatar-list--title">
            <p>{{ title }}</p>
        </div>
        <div class="avatar-list--cont">

            <div v-for="user in users" :key="'avtr_lst_usr_'+user.id" class="avatar-list--item">
                <Tooltip :content="user.name" placement="bottom">
                    <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                </Tooltip>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: { required: false, type: String },
            users: { required: true }
        }
    }
</script>