<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Agregar integrante" v-model="show" :footer-hide="true" :scrollable="true">
        <div style="padding: 0px 0 10px 0; display: flex;">
            <Form style="width: 100%" ref="planForm" :model="program" :rules="ruleInline">
                <Tabs :value="tab">
                    <TabPane label="Coordinación" name="coords" :disabled="hasCoord">
                         <Row :gutter="16">
                            <i-col span="24">
                                <Select size="large" placeholder="Seleccionar" not-found-text="No hay usuarios disponibles" v-model="program.member">
                                    <Option v-for="user in coords" :key="'mbr_ur_'+user.id" :value="user.id" :label="user.name">
                                        <MemberItem :light="true" :program="{}" :member_id="0" :user="user" />
                                    </Option>
                                </Select>
                            </i-col>
                            <i-col span="24">
                                <Button style="margin-top: 125px" size="large" long type="primary" :loading="loading" @click="save('coord')">Agregar coordinación</Button>
                            </i-col>
                        </Row>
                    </TabPane>
                    <TabPane label="Integrantes" name="members">
                        <Row :gutter="16">
                            <i-col span="24">
                                <Select size="large" placeholder="Seleccionar" not-found-text="No hay usuarios disponibles" v-model="program.member">
                                    <Option v-for="user in members" :key="'mbr_ur_'+user.id" :value="user.id" :label="user.name">
                                        <MemberItem :light="true" :program="{}" :member_id="0" :user="user" />
                                    </Option>
                                </Select>
                            </i-col>
                            <i-col span="24">
                                <Button style="margin-top: 200px" size="large" long type="primary" :loading="loading" @click="save('member')">Agregar integrante</Button>
                            </i-col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    </Modal>
</template>

<script>
    import MemberItem from './Item'

    export default {
        props: {
            hasCoord: { required: true, type: Boolean }
        },
        data(){
            return {
                tab: 'coords',
                show: false,
                coords: [],
                members: [],
                program: {
                    member: null,
                },
                loading: false,
                ruleInline: {
                    year: [
                        { required: true, message: 'Año es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            getUsers(){
                this.loading = true
                window.axios.post(this.$root.API_URL + '/programs/get-users-available')
                .then(response => {
                    if(response.data.success){
                        this.coords = response.data.coords
                        this.members = response.data.members
                        this.loading = false
                    }
                })
            },
            save(type){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/programs/create-member/${this.$parent.program.id}`, { id: this.program.member, type: type, })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-details--add-member-to-program', response.data.member)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.program = {
                    member: null,
                }
                this.users = []
            }
        },
        mounted(){
            window.EventBus.$on('program-details--add-member', () => {
                this.show = true
                this.getUsers()
                if( this.hasCoord ) this.tab = 'members'
            })
        },
        components: {
            MemberItem,
        }
    }
</script>