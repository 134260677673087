<template>
    <Modal class-name="scrollable vertical-center-modal" title="Evaluar Resultado" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="evaluation" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Estatus" prop="statuses_id">
                        <Select v-model="evaluation.statuses_id">
                            <Option v-for="status in Statuses" :key="'ev_rest_'+status.id" :value="status.id" :label="status.name">
                                <div class="ivu-tag ivu-tag-size-default ivu-tag-dot ivu-tag-checked" style="border-width: 1px; border-style: solid;">
                                    <span class="ivu-tag-dot-inner" :style="{ 'background': status.hex_color }"></span>
                                    <span class="ivu-tag-text">{{ status.name }}</span>
                                </div>
                                <!-- <Tag type="dot" :color="status.hex_color">{{ status.name }}</Tag> -->
                            </Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Comentario" prop="feedback">
                        <Input type="textarea" :autosize="{minRows: 5,maxRows: 8}" v-model="evaluation.feedback" placeholder="Comentario"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Actualizar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                show: false,
                result_id: null,
                evaluation: {
                    statuses_id: "",
                    feedback: "",
                },
                loading: false,
                ruleInline: {
                    statuses_id: [
                        { required: true, type: 'integer', message: 'Estatus es requerido', trigger: 'change' }
                    ],
                    feedback: [
                        { required: true, message: 'Comentario es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'status_poa'
            ]),
            Statuses(){
                return this.status_poa
            },
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/results/update-evaluation/${this.evaluation.id}`, {
                            statuses_id: this.evaluation.statuses_id,
                            feedback: this.evaluation.feedback,
                        })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-poa--update-result', response.data.result)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.result = {
                    id: null,
                    evaluation: {
                        id: null,
                        statuses_id: "",
                        feedback: "",
                    }
                }
            }
        },
        mounted(){
            window.EventBus.$on('program-poa--update-evaluation', (r, evaluation) => {
                this.result_id = r.id
                this.evaluation = {
                    ...evaluation,
                }
                this.show = true
            })
        }
    }
</script>