<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}`">{{ program.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ program.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="budget" :poa_link="`/programa/${$route.params.name}/${$route.params.year}`" />

            <br>
            <Row style="text-align: center">
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Disponible</strong></p>
                    <h2 style="font-weight: normal">{{ InitialBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Presupuesto</strong></p>
                    <h2 style="font-weight: normal">{{ TotalBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Variación</strong></p>
                    <h2 style="font-weight: normal">{{ DeficitBudget | currency }}</h2>
                </Col>
            </Row>
            <br>
            <br>
            <BudgetTabs tab="available" :poa_link="`/programa/${$route.params.name}/${$route.params.year}`" />
            <div>
                <Row :gutter="24" style="margin: 25px 0">
                    <i-col :xs="24" :sm="12" :md="5" :lg="5"></i-col>
                    <i-col :xs="24" :sm="12" :md="7" :lg="7"></i-col>
                    <i-col :xs="24" :sm="12" :md="12" :lg="12" >
                        <div style="float: right; display: flex;">
                            <div>
                                <Button v-if="CanAddFunder" @click="addFunder" type="primary">Agregar Financiadroa</Button>
                            </div>
                        </div>
                    </i-col>
                </Row>
                <br>
                <template v-if="funders.length == 0">
                    <EmptyState icon="md-cash" title="" :content="`No hay Financiadoras agregados.`" />
                </template>
                <template>
                    <div v-for="funder in funders" :key="'poa_funder_list_'+funder.id">
                        <Row :gutter="24" style="margin: 25px 0">
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <h3 style="margin-right: 15px">{{ funder.name }}</h3>
                                <p style="font-size: 0.9em; color: grey;">
                                    <template v-if="funder.start">
                                        {{ funder.start | moment('DD/MM/YYYY') }}
                                    </template>
                                    <template v-if="funder.end">
                                        a {{ funder.end | moment('DD/MM/YYYY') }}
                                    </template>
                                </p>
                            </i-col>
                            <i-col :xs="24" :sm="24" :md="12" :lg="12" >
                                <div style="display: flex; justify-content: flex-end;">
                                    <Button v-if="CanAddFunder" @click="addBudget(funder)" style="margin-right: 15px" type="primary" ghost size="small">Agregar Presupuesto</Button>
                                    <Dropdown v-if="CanEditFunder" slot="extra" trigger="click" placement="right-start">
                                        <a style="margin-right: 8px" href="javascript:void(0)">
                                            <Icon color="grey" size="16" type="md-more"></Icon>
                                        </a>
                                        <DropdownMenu slot="list">
                                            <DropdownItem v-if="CanEditFunder" @click.native="updateFunder(funder)">Editar</DropdownItem>
                                            <DropdownItem v-if="CanEditFunder && funder.budget.length == 0" @click.native="deleteFunder(funder)">Eliminar</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </i-col>
                        </Row>
                        <Table border :columns="columns1" :data="funder.budget" no-data-text="Esta financiadora no tiene presupuesto creado">
                            <template slot-scope="{ row }" slot="heading">
                                <p>{{ row.heading.name }}</p>
                            </template>
                            <template slot-scope="{ row }" slot="subheading">
                                <p>{{ row.subheading ? row.subheading.name : '' }}</p>
                            </template>
                            <template slot-scope="{ row }" slot="amount">
                                <p style="text-align: right;">{{ row.final_total_price | currency }}</p>
                            </template>
                            <template slot-scope="{ row }" slot="action">
                                <Dropdown v-if="CanEditFunder || CanDeleteFunderBudget" slot="extra" trigger="click" placement="left-end">
                                    <a href="javascript:void(0)">
                                        <Icon color="grey" size="16" type="md-more"></Icon>
                                    </a>
                                    <DropdownMenu slot="list">
                                        <DropdownItem v-if="CanEditFunder" @click.native="updateBudget(row)">Editar</DropdownItem>
                                        <DropdownItem v-if="CanDeleteFunderBudget" @click.native="deleteBudget(row)">Eliminar</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </template>
                            <template slot="footer">
                                <p style="text-align: right; margin-right: 65px;">
                                    <strong>Total: {{ getFoundingTotal(funder) | currency }}</strong>
                                </p>
                            </template>
                        </Table>
                        <br><br>
                    </div>
                </template>
            </div>
        </template>

        <CreateFunder/>
        <UpdateFunder/>
        <AddFunderBudget/>
        <UpdateFunderBudget/>
    </div>
</template>

<script>
    import POATabs from './../../Tabs'
    import BudgetTabs from './Tabs'
    import CreateFunder from './Funders/Create'
    import UpdateFunder from './Funders/Update'
    import AddFunderBudget from './Funders/AddBudget'
    import UpdateFunderBudget from './Funders/UpdateBudget'
    export default {
        data(){
            return {
                poa: null,
                columns1: [
                    {
                        title: 'Rubro',
                        slot: 'heading',
                    },
                    {
                        title: 'Subrubro',
                        slot: 'subheading'
                    },
                    {
                        title: 'Monto total',
                        slot: 'amount',
                        width: 200
                    },
                    {
                        title: ' ',
                        slot: 'action',
                        width: 50,
                        align: 'center'
                    }
                ],
                funders: [],
                program: null,
                activities: [],
                is_loading: true,
            }
        },
        computed: {
            InitialBudget(){
                let total = 0

                window._.each(this.funders, (f) => {
                    window._.each(f.budget, (b) => {
                        total += parseFloat(b.final_total_price)
                    })
                })

                return total
            },
            TotalBudget(){
                let total = 0

                window._.each(this.activities, (activity) => {
                     let ps = parseFloat(activity.budget_total)
                    if( ps ) total += ps
                })

                return total
            },
            DeficitBudget(){
                return this.InitialBudget - this.TotalBudget
            },
            CanAddFunder(){
                return this.$can('poa.budget.available.create') || this.$itsAdminTeammate()
            },
            CanEditFunder(){
                return this.$can('poa.budget.available.edit') || this.$itsAdminTeammate()
            },
            CanDeleteFunderBudget(){
                return this.$can('poa.budget.available.delete') || this.$itsAdminTeammate()
            }
        },
        methods: {
            addFunder(){
                window.EventBus.$emit('poa-budget--create-funder')
            },
            updateFunder(funder){
                window.EventBus.$emit('poa-budget--update-funder-popup', funder)
            },
            deleteFunder(funder){
                this.$Modal.confirm({
                    title: 'Eliminar Financiadora',
                    content: `<p>¿Deseas eliminar esta financiadora?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/delete-funder/${funder.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-budget--delete-funder`, funder)
                                this.$Message.success('Financiadora eliminada correctamente');
                            }
                        })
                    },
                });
            },
            addBudget(funder){
                window.EventBus.$emit('poa-budget--add-funder-budget-popup', funder)
            },
            updateBudget(budget){
                window.EventBus.$emit('poa-budget--update-funder-budget-popup', budget)
            },
            deleteBudget(budget){
                this.$Modal.confirm({
                    title: 'Eliminar Presupuesto',
                    content: `<p>¿Deseas eliminar este presupuesto?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/delete-budget/${budget.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-budget--update-funder`, response.data.funder)
                                this.$Message.success('Presupuesto eliminada correctamente');
                            }
                        })
                    },
                });
            },
            getFoundingTotal(funder){
                let total = 0

                window._.each(funder.budget, (b) => {
                    return total += parseFloat(b.final_total_price)
                })

                return total
            },
            getPOA(program_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${program_slug}/get`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.program = response.data.program
                        this.poa = response.data.poa
                        this.getFunders()
                        this.getActivities()
                        // this.getResults()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.program.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getFunders(){
                window.axios.post(this.$root.API_URL + `/budget/get-funders/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.funders = response.data.funders
                    }
                })
            },
            getActivities(){
                window.axios.post(this.$root.API_URL + `/activities/get-budget/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        window._.each(response.data.activities, act => {
                            this.activities.push({
                                ...act,
                                is_budget_loaded: false,
                                is_budget_loading: true,
                            })
                        })
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){
            let poa_year = this.$route.params.year
            let program_slug = this.$route.params.name

            this.getPOA(program_slug, poa_year)
            this.$collpaseDrawer('poa-content')

            window.EventBus.$on('poa-budget--add-funder', (funder) => {
                this.funders.push(funder)
            })

            window.EventBus.$on('poa-budget--update-funder', (funder) => {
                let fi = window._.findIndex(this.funders, (f) => {
                    return f.id == funder.id
                })

                if(fi != -1){
                    this.$set(this.funders, fi, funder)
                }
            })

            window.EventBus.$on('poa-budget--delete-funder', (funder) => {
                let fi = window._.findIndex(this.funders, (f) => {
                    return f.id == funder.id
                })

                if(fi != -1){
                    this.funders.splice(fi, 1)
                }
            })
        },
        components: {
            POATabs,
            BudgetTabs,
            CreateFunder,
            UpdateFunder,
            AddFunderBudget,
            UpdateFunderBudget,
        }
    }
    
</script>