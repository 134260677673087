<template>
    <Modal class-name="vertical-center-modal" title="" :closable="false" :mask-closable="false" v-model="show" :footer-hide="true">
        
        <h2 style="text-align: center; padding: 25px 0">Por favor, actualiza tu contraseña para continuar</h2>

        <Form style="width: 100%; padding: 0 20px" ref="form" :model="form" :rules="ruleInline">
            <Row :gutter="20">
                <i-col span="24">
                    <FormItem label="Nueva contraseña" prop="password">
                        <Input type="password" v-model="form.password" size="large" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Confirma nueva contraseña" prop="confirm_password">
                        <Input type="password" v-model="form.confirm_password" size="large" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
            </Row>
            <Row :gutter="20">
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" type="primary" long @click="updatePassword">Actualizar contraseña</Button>
                    <br><br>
                </i-col>
            </Row>
        </Form>
        

    </Modal>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                show: false,
                loading: false,
                loading_password: false,
                form: {
                    password: '',
                    confirm_password: '',
                },
                ruleInline: {
                    password: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    confirm_password: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ])
        },
        methods: {
            updatePassword(){
                this.$refs.form.validate(valid => {

                    if(valid){
                        if( this.form.password === this.form.confirm_password ){
                            this.loading_password = true
                            window.axios.post(this.$root.API_URL + '/user/update-password', { password: this.form.password })
                            .then(response => {
                                if( response.data.success ){
                                    this.loading_password = false
                                    this.form.password = ''
                                    this.form.confirm_password = ''
                                    this.$refs['form'].resetFields();
                                    this.$Message.success('Contraseña actualizada correctamente');
                                    this.show = false
                                    this.$store.dispatch("SET_USER", {
                                        ...this.user,
                                        reset_password: 0,
                                    })
                                }
                            })
                        }
                        else {
                            this.$Message.error('Las contraseñas no coinciden');
                            this.loading_password = false
                        }
                    }
                })
            },
        },
        mounted(){
            window.EventBus.$on('profile--show-reset-password-popup', () => {
                this.show = true
            })
        }
    }
</script>