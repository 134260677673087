<template>
    <Modal class-name="scrollable vertical-center-modal" v-model="show" :footer-hide="true" :scrollable="true">
        <!-- <div slot="header" style="color:#843cf6;text-align:center">
            <h2>Metodología</h2>
        </div> -->
        <template v-if="show && items && items.length > 0">
            <div  v-for="item in items" :key="`items_id_${item.id}`">
                <div class="indicator info">
                    <div :style="{ 'background-color': item.hex_color }" class="indicator--tag"></div>
                    <span class="indicator--description">{{ item.name }}</span>
                </div>
                <div class="indicator--footer">
                    <p>{{ item.description  }}</p>
                </div>
            </div>

            <div class="indicator info">
                <div style="background: #ececec" class="indicator--tag"></div>
                <span class="indicator--description">{{ PendingText }}</span>
            </div>
            <div class="indicator--footer"></div>
            <br><br>
        </template>
        <template v-else>
            <div class="indicator info">
                <div style="background: #ee6b5e" class="indicator--tag"></div>
                <span class="indicator--description">Cumplimiento nulo</span>
            </div>
            <div class="indicator--footer">
                <p>Se tiene un mínimo o nulo avance (menos del 20%) en relación a lo que se tenía planeado a la fecha de la evaluación. Se le ha dado poco seguimiento a comparación del que han tenido otros resultados planteados en el POA.</p>
            </div>

            <div class="indicator info">
                <div style="background: #ffaa4a" class="indicator--tag"></div>
                <span class="indicator--description">Cumplimiento mínimo</span>
            </div>
            <div class="indicator--footer">
                <p>Se tiene poco avance en relación a lo que se había planeado hasta la fecha de la evaluación (aproximadamente un 30%), es necesario hacer un análisis de los retos y obstáculos enfrentados para detectar áreas de oportunidad.</p>
            </div>

            <div class="indicator info">
                <div style="background: #ffaa4a" class="indicator--tag"></div>
                <span class="indicator--description">Cumplido parcialmente</span>
            </div>
            <div class="indicator--footer">
                <p>El resultado se ha logrado al menos a un 50%, en relación a lo que se tenía planeado a la fecha de la evaluación, se han establecido las acciones y actividades para continuar avanzando.</p>
            </div>

            <div class="indicator info">
                <div style="background: #1dcb70" class="indicator--tag"></div>
                <span class="indicator--description">Cumplido en su mayoria</span>
            </div>
            <div class="indicator--footer">
                <p>Se ha logrado un avance considerable (al menos un 70%) de lo que se tenía planeado a la fecha de la evaluación, es necesario continuar con las mismas estrategias y acciones para lograr con su cumplimiento total.</p>
            </div>

            <div class="indicator info">
                <div style="background: #0d8b49" class="indicator--tag"></div>
                <span class="indicator--description">Cumplido en su totalidad</span>
            </div>
            <div class="indicator--footer">
                <p>El resultado y sus actividades se han cumplido y efectuado al 100% en relación a lo que se tenía planeado a la fecha de la evaluación.</p>
            </div>

            <template v-if="showNoAplica">
                <div class="indicator info">
                    <div style="background: #843cf6" class="indicator--tag"></div>
                    <span class="indicator--description">No aplica</span>
                </div>
                <div class="indicator--footer">
                    <p>Se utiliza la siguiente escala para aquellos resultados que previo acuerdo con la Dirección, Subdirección y/ o la Coordinación no se han efectuado debido a factores externos al Área, como puede ser financiamiento, contexto social y político, etc. Esta escala no tiene valor y no afecta el promedio de la evaluación.</p>
                </div>
            </template>

            <div class="indicator info">
                <div style="background: #ececec" class="indicator--tag"></div>
                <span class="indicator--description">Pendiente</span>
            </div>

        </template>

        <Button @click="show=false" type="primary" long size="large">Aceptar</Button>

    </Modal>
</template>

<script>
    export default {
        props: {
            name: { required: false, type: String, default: 'default' },
            items: { required: false, type: [Object, Array], },
            showNoAplica: { required: false, type: Boolean, default: false }
        },
        data(){
            return {
                show: false,
            }
        },
        computed: {
            PendingText(){
                if( this.items ){
                    if( this.items[0].category == 'Desempeño' ) return 'Pendiente de evaluar'
                    else return 'Pendiente'
                }
                return 'Pendiente'
            }
        },
        mounted(){
            window.EventBus.$on( `show-evaluation-scales--results--${this.name}`, v => this.show = v )
        }
    }
</script>