<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/proyecto/${project.slug}`">{{ project.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ project.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="bitacora" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />
            <br>

            <BitacoraTabs tab="list" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />
            <br>
            
            <Row type="flex" align="middle">
                <i-col span="20"></i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanModify" @click="showCreateImpactActivity" type="primary">Agregar actividad de impacto</Button>
                </i-col>
            </Row>
            <br>
            <br>
            <template v-if="imp_activities.length == 0">
                <EmptyState icon="md-list-box" title="" :content="`${project.name} (${poa.year}) no tiene actividades de impacto agregadas.`" />
            </template>
            <template v-else>
                <Collapse simple class="header-flex budget result-activities" v-model="collapse">
                    <Panel v-for="(activity, i) in imp_activities" :key="'poa_impact_activity_'+activity.id" :name="activity.impact_activity.name + '_ind_' + i">
                        <div class="budget-header--collapse-panel">
                            <div style="flex-grow: 1">
                                <p style="font-size: 0.8em"><strong>Actividad de impacto</strong></p>
                                <h3 style="flex-grow: 1; display: flex; align-items: center; font-weight: normal">{{ activity.impact_activity.name }}</h3>
                            </div>
                            <div v-if="CanModify" @click.stop="() => {}" class="action">
                                <Dropdown trigger="click" placement="left-end">
                                    <a href="javascript:void(0)">
                                        <Icon color="grey" size="16" type="md-more"></Icon>
                                    </a>
                                    <DropdownMenu slot="list">
                                        <DropdownItem @click.native="showUpdateImpactActivity(activity)">Editar</DropdownItem>
                                        <DropdownItem @click.native="showDeleteImpactActivity(activity)">Eliminar</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <template #content>
                            <div style="padding: 0 30px">
                                <Collapse simple style="border: none !important;">
                                    <Panel>
                                        <div style="width: 100%">
                                            <h4>Actividades relacionadas ({{ activity.activities.length }})</h4>
                                        </div>
                                        <template #content>
                                            <ul style="padding: 0 45px">
                                                <li v-for="act in activity.activities" :key="`poa_impact_activity_${activity.id}_list_${act.id}`">{{ act.name }}</li>
                                            </ul>
                                        </template>
                                    </Panel>
                                </Collapse>
                                <!-- <br> -->
                                <Collapse simple style="border: none !important;">
                                    <Panel>
                                        <div style="width: 100%">
                                            <h4>Perfiles de asistentes</h4>
                                        </div>
                                        <template #content>
                                            <Row type="flex" align="middle">
                                                <i-col span="20"></i-col>
                                                <i-col span="4" style="display: flex; justify-content: flex-end;">
                                                    <Button v-if="CanModify" @click="showCreateProfilePopup(activity)" type="primary">Agregar perfil de asistente</Button>
                                                </i-col>
                                            </Row>
                                            <br>
                                            <Collapse simple>
                                                <Panel v-for="(profile, i) in activity.profiles" :key="`activity_profile_${activity.id}_id_${profile.id}_i_${i}`">
                                                    <div class="budget-header--collapse-panel">
                                                        <div style="flex-grow: 1">
                                                            <h4>{{ profile.profiles.map(p => p.name).join(' - ') }}</h4>
                                                        </div>
                                                        <div v-if="CanModify" @click.stop="() => {}" class="action">
                                                            <Dropdown trigger="click" placement="left-end">
                                                                <a href="javascript:void(0)">
                                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                                </a>
                                                                <DropdownMenu slot="list">
                                                                    <DropdownItem @click.native="updateProfile(activity, profile)">Editar</DropdownItem>
                                                                    <DropdownItem @click.native="deleteProfile(activity, profile)">Eliminar</DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <template #content>
                                                        <div style="margin-left: 30px">
                                                            <br>
                                                            <Row :gutter="16">
                                                                <i-col span="8">
                                                                    <p style="font-size: 0.8em"><strong>Municipio, Estado</strong></p>
                                                                    <p style="font-size: 6px">&nbsp;</p>
                                                                    <p>{{ profile.city | capitalize }}, {{ profile.state | capitalize }}</p>
                                                                </i-col>
                                                                <i-col span="8">
                                                                    <p style="font-size: 0.8em"><strong>Número de asistentes / Personas beneficiadas</strong></p>
                                                                    <table class="info-table">
                                                                        <thead>
                                                                            <th>Mujeres</th>
                                                                            <th>Hombres</th>
                                                                            <th>Otres</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{{ profile.asist_m }}</td>
                                                                                <td>{{ profile.asist_h }}</td>
                                                                                <td>{{ profile.asist_o }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </i-col>
                                                                <i-col span="8">
                                                                    <p style="font-size: 0.8em"><strong>Pertenencia étnica</strong></p>
                                                                    <table class="info-table">
                                                                        <thead>
                                                                            <th>Indígena</th>
                                                                            <th style="line-height: 1; font-size: 0.65em; display: block;">Afromexicanas / Afrodescendientes</th>
                                                                            <th>Otro</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{{ profile.e_indig }}</td>
                                                                                <td>{{ profile.e_afro }}</td>
                                                                                <td>{{ profile.e_otro }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </i-col>
                                                                <i-col span="8">
                                                                    <p style="font-size: 0.8em"><strong>Rango de edad</strong></p>
                                                                    <table class="info-table">
                                                                        <thead>
                                                                            <th> &#62; 18 </th>
                                                                            <th>18 - 29</th>
                                                                            <th>30 - 49</th>
                                                                            <th>50 - 65</th>
                                                                            <th> &#60; 65 </th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{{ profile.a_m_18 }}</td>
                                                                                <td>{{ profile.a_18_29 }}</td>
                                                                                <td>{{ profile.a_30_49 }}</td>
                                                                                <td>{{ profile.a_50_65 }}</td>
                                                                                <td>{{ profile.a_m_65 }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </i-col>
                                                            </Row>
                                                            <br>
                                                            <p v-if="profile.skip_from_count" style="font-weight: bold; font-size: 0.8em; color: #f39c12; text-align: right;">No se contabiliza en el resumen general</p>
                                                        </div>
                                                    </template>
                                                </Panel>
                                            </Collapse>
                                        </template>
                                    </Panel>
                                </Collapse>
                                <!-- <br> -->
                                <Row :gutter="16">
                                    <i-col span="24">
                                        <p>&nbsp;</p>
                                    </i-col>
                                    <i-col span="6">
                                        <p style="font-size: 0.8em"><strong>Personas del proyecto que intervienen en la actividad</strong></p>
                                        <AvatarList :users="getActivitiesResponsible(activity)" />
                                    </i-col>
                                    <i-col span="6">
                                        <p style="font-size: 0.8em"><strong>En alianza con otra organización o entidad</strong></p>
                                        <p>{{ activity.alliances || '-' }}</p>
                                    </i-col>
                                    <i-col span="6">
                                        <p style="font-size: 0.8em"><strong>Costo de la actividad</strong></p>
                                        <p>{{ getTotalOfActivities(activity) | currency }}</p>
                                    </i-col>
                                    <i-col span="6">
                                        <p style="font-size: 0.8em"><strong>Evidencias</strong></p>
                                        <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                                            <a target="_blank" :href="activity.evidences_url">{{ activity.evidences_url }}</a>
                                        </p>
                                    </i-col>
                                </Row>
                            </div>
                        </template>
                    </Panel>
                </Collapse>
            </template>

            <CreateImpactActivity />
            <UpdateImpactActivity />
            <CreateProfile />
            <UpdateProfile />
        </template>
    </div>
</template>

<script>
    import POATabs from './../Tabs'
    import AvatarList from './../../../../General/AvatarList'
    import projectmixin from './../../../../../mixins/project'
    import BitacoraTabs from './Tabs'
    import CreateProfile from './components/Profile/Create'
    import { mapGetters } from 'vuex'
    import UpdateProfile from './components/Profile/Update'
    import CreateImpactActivity from './components/Create'
    import UpdateImpactActivity from './components/Update'

    export default {
        mixins: [ projectmixin ],
        data(){
            return {
                poa: null,
                project: null,
                collapse: '',
                is_loading: true,
                imp_activities: [],
                members: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ]),
            CanModify(){
                return this.isAuthUserMembersOfThisProject() || this.$itsSuperAdmin() || this.$itsAdminTeammate() 
            }
        },
        methods: {
            getPOA(project_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${project_slug}/get-project`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.project = response.data.project
                        this.members = response.data.members
                        this.poa = response.data.poa
                        this.fetchImpactActivities()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.project.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del proyecto');
                })
            },
            fetchImpactActivities(){
                window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/get/${this.poa.id}`,)
                .then(response => {
                    if( response.data.success ){
                        this.imp_activities = response.data.activities
                        this.is_loading = false
                    }
                })
            },
            showCreateImpactActivity(){
                window.EventBus.$emit('poa-bitacora-impact-activity--create-popup', true)
            },
            showCreateProfilePopup(activity){
                window.EventBus.$emit('poa-bitacora-impact-activity--create-profile-popup', activity)
            },
            updateProfile(activity, profile){
                window.EventBus.$emit('poa-bitacora-impact-activity--update-profile-popup', activity, profile)
            },
            deleteProfile(activity, profile){
                this.$Modal.confirm({
                    title: 'Eliminar perfil de asistente',
                    content: `<p>¿Deseas eliminar este perfil de asistente?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/delete-profile/${profile.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-bitacora-impact-activity--delete-profile`, activity, profile)
                                this.$Message.success('Perfil de asistente eliminado correctamente');
                            }
                        })
                    },
                });
            },
            showUpdateImpactActivity(activity){
                window.EventBus.$emit('poa-bitacora-impact-activity--update-popup', activity)
            },
            showDeleteImpactActivity(activity){
                this.$Modal.confirm({
                    title: 'Eliminar actividad de impacto',
                    content: `<p>¿Deseas eliminar esta actividad de impacto?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/delete/${activity.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-bitacora-impact-activity--delete-activity`, activity)
                                this.$Message.success('Actividad de impacto eliminada correctamente');
                            }
                        })
                    },
                });
            },
            getTotalOfActivities(activity){
                let total = 0
                window._.each(activity.activities, a => {
                    total += parseFloat(a.budget_total)
                })
                return total
            },
            getActivitiesResponsible(activity){
                let usrs = []

                window._.each(activity.activities, a => {
                    window._.each(a.responsible, r => {
                        let ui = window._.findIndex(usrs, u => u.id == r.user.id)
                        if( ui == -1 ) usrs.push(r.user)
                    })
                })

                return usrs
            }
        },
        mounted(){
           let poa_year = this.$route.params.year
            let project_slug = this.$route.params.name

            this.getPOA(project_slug, poa_year)

            window.EventBus.$on('poa-bitacora-impact-activity--add-activity', activity => {
                this.imp_activities.push(activity)
            })

            window.EventBus.$on('poa-bitacora-impact-activity--update-activity', activity => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)
                if( ai != -1 ) this.$set(this.imp_activities, ai, activity)
            })

            window.EventBus.$on('poa-bitacora-impact-activity--delete-activity', activity => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)
                if( ai != -1 ) this.imp_activities.splice(ai, 1)
            })

            window.EventBus.$on('poa-bitacora-impact-activity--add-profile', (activity, profile) => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)
                this.imp_activities[ai].profiles.push(profile)
            })

            window.EventBus.$on('poa-bitacora-impact-activity--update-profile', (activity, profile) => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)
                let pi = window._.findIndex(this.imp_activities[ai].profiles, p => p.id == profile.id)
                this.$set(this.imp_activities[ai].profiles, pi, profile)
            })

            window.EventBus.$on('poa-bitacora-impact-activity--delete-profile', (activity, profile) => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)
                let pi = window._.findIndex(this.imp_activities[ai].profiles, p => p.id == profile.id)
                if( ai != -1 && pi != -1 ) this.imp_activities[ai].profiles.splice(pi, 1)
            })
        },
        components: {
            POATabs,
            AvatarList,
            BitacoraTabs,
            CreateProfile,
            UpdateProfile,
            CreateImpactActivity,
            UpdateImpactActivity,
        }
    }
</script>

<style lang="scss">
    table.info-table{
        width: 100%;
        border: 1px solid rgba(#ececec, 1);
        border-radius: 5px;
        border-collapse: collapse;
        font-size: 0.8em;
        color: #515a6e;
        margin-top: 5px;
        table-layout: fixed;
        & th, td {
            border: 1px solid rgba(#ececec, 1);
            text-align: center;
            padding: 5px;
        }
        & tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }
        & tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }
    }
</style>