import * as Sentry from '@sentry/vue'
const axios = require('axios').default;
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.response.use( res => res, error => {
    Sentry.captureException(error)
})

window.dexie.config.where({ name: 'auth' }).first( token => {
    if(token) window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.value;
})
