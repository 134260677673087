<template>
    <div>
        <UpdateModal v-if="CanEditPlanInfo"/>
        <UpdateRIModal/>
        <CreateRIModal v-if="CanAddResultPlan"/>

        <LoadingContainer v-if="is_loading"/>
        <div v-else id="print" class="container top">
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Plan estratégico {{ plans[0].init_year }} - {{ plans[0].end_year }}</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanEditPlanInfo" style="margin-right: 15px" class="display-none-print" ghost type="primary" @click="updatePlan">Editar</Button>
                    <Button class="display-none-print" type="primary" @click="download">Descargar</Button>
                </i-col>
            </Row>
            <br>

            <h2>Visión</h2>
            <p>{{ plans[0].vision }}</p>
            <br>

            <h2>Misión</h2>
            <p>{{ plans[0].mission }}</p>
            <br><br><br>

            <h2>Resultados Institucionales</h2>
            <br>
            
            <div class="plan-rs">
                <div v-for="(r, i) in plans[0].rs" :key="'rs_p_'+i" class="plan-rs--item">
                    <h2 class="plan-rs--item--title">RI{{ i+1 }}</h2>
                    <div class="plan-rs--item--background">
                        <Dropdown v-if="CanUpdateResultPlan || CanRemoveResultPlan" trigger="click" class="plan-rs--item--actions">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="CanUpdateResultPlan" @click.native="updateRI(i, r)">Editar</DropdownItem>
                                <DropdownItem v-if="CanRemoveResultPlan" @click.native="deleteRI(i)">Eliminar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <p>{{ r }}</p>
                    </div>
                </div>
            </div>
            <div style="position: relative">
                <Button v-if="CanAddResultPlan" style="float: right; margin-right: 10px" class="display-none-print" type="primary" @click="addRI">Agregar RI</Button>
            </div>


        </div>
    </div>
</template>

<script>
    import download from './../../mixins/download'
    import UpdateModal from './components/Update'
    import UpdateRIModal from './components/UpdateRI'
    import CreateRIModal from './components/CreateRI'

    export default {
        mixins: [download],
        data(){
            return {
                is_loading: true,
                plans: []
            }
        },
        computed: {
            CanEditPlanInfo(){
                return this.$can('plan.edit')
            },
            CanAddResultPlan(){
                return this.$can('plan.results.add')
            },
            CanUpdateResultPlan(){
                return this.$can('plan.results.edit')
            },
            CanRemoveResultPlan(){
                return this.$can('plan.results.remove')
            },
        },
        methods: {
            download(){
                this.downloadAsImage(document.getElementById('print'), 'plan-inst.png')
            },
            getLastPlan(){
                window.axios.post(this.$root.API_URL + '/strategic-plan/get-last')
                .then(response => {
                    if(response.data.success){
                        this.plans[0] = response.data.plan
                        this.is_loading = false
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            updatePlan(){
                window.EventBus.$emit('strategic-plan--update', this.plans[0])
            },
            addRI(){
                window.EventBus.$emit('strategic-plan--create-ri')
            },
            updateRI(index, text){
                window.EventBus.$emit('strategic-plan--update-ri', index, text)
            },
            deleteRI(index){
                this.$Modal.confirm({
                    title: 'Eliminar RI',
                    content: `<p>¿Deseas eliminar el RI${index+1}?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + '/strategic-plan/delete-ri', { id: this.plans[0].id, i: index })
                        .then(response => {
                            if(response.data.success){
                                this.$set(this.plans, 0, response.data.plan)
                                this.$Message.success('RI eliminado correctamente');
                            }
                        })
                    },
                });
            }
        },
        mounted(){
            /* Unmark active subitem  */
            this.$deleteSubitemsActives()

            // Get last plan
            this.getLastPlan()

            window.EventBus.$on('strategic-plan--update-plan', (plan) => {
                this.$set(this.plans, 0, plan)
            })
        },
        components: {
            UpdateModal,
            UpdateRIModal,
            CreateRIModal,
        }
    }
</script>