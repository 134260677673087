<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo subrubro" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="subheading" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="subheading.name" placeholder="Nombre"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                subheading: {
                    name: ""
                },
                heading: {},
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/headings/subheading/create/${this.heading.id}`, { ...this.subheading })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('budget-headings--add-subheading', this.heading, response.data.subheading)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.subheading = {
                    name: ""
                }
                this.heading = {}
            }
        },
        mounted(){
            window.EventBus.$on('budget-headings--create-subheading-popup', (heading) => {
                this.heading = {
                    ...heading,
                }
                this.show = true
            })
        }
    }
</script>