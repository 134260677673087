<template>
    <!-- <Tabs name="poa" :value="tab" @on-click="switchTab">
        <TabPane label="POA" name="poa"/>
        <TabPane label="Cronograma" name="schedule"></TabPane>
        <TabPane label="Evaluación intermedia" name="evaluation"/>
        <TabPane label="Evaluación final" name="final_evaluation"/>
        <TabPane label="Presupuesto" name="budget"/>
    </Tabs> -->
    <div style="margin: 25px 0 40px 0; border-bottom: 1px solid #ececec; padding-bottom: 9px;">
        <router-link :class="{ 'router-link-active': tab == 'poa' }" class="poa-tab-link" :to="`${poa_link}/tab/poa`">POA</router-link>
        <router-link :class="{ 'router-link-active': tab == 'schedule' }" class="poa-tab-link" :to="`${poa_link}/tab/schedule`">Cronograma</router-link>
        <router-link :class="{ 'router-link-active': tab == 'ev-middle' }" class="poa-tab-link" :to="`${poa_link}/tab/ev-middle`">Evaluación intermedia</router-link>
        <router-link :class="{ 'router-link-active': tab == 'ev-final' }" class="poa-tab-link" :to="`${poa_link}/tab/ev-final`">Evaluación final</router-link>
        <router-link :class="{ 'router-link-active': tab == 'budget' }" class="poa-tab-link" :to="`${poa_link}/tab/budget/available`">Presupuesto</router-link>
        <router-link :class="{ 'router-link-active': tab == 'bitacora' }" class="poa-tab-link" :to="`${poa_link}/tab/bitacora/list`">Bitácora</router-link>
    </div>
</template>

<script>
    export default {
        props: {
            tab: { required: true, type: String, default: 'poa' },
            poa_link: { required: true, type: String, }
        },
        methods:{
            switchTab(name){
                console.log(`${this.poa_link}/tab/${name}`)
                this.$router.push({path: `${this.poa_link}/tab/${name} `}).then(r => {
                    console.log(r)
                })
            },
        },
    }
</script>

<style lang="scss">
    .poa-tab-link{
        height: 100%;
        padding: 8px 16px;
        margin-right: 16px;
        border-bottom: 2px solid transparent;
        color: #515a6e;
        font-size: 0.9em;
        &:hover{
            color: #843cf6;
        }
        &.router-link-active{
            color: #843cf6;
            border-color: #843cf6;
        }
    }
</style>