<template>
    <div class="anual-result">
        <Collapse class="invisible" v-model="collapse_result">
            <Panel name="1">
                <div class="result-header--collapse-panel">
                    <h3 style="position: relative; margin-left: 15px">
                        <span style="position: absolute; top: 7px; left: -11px; margin-left: -10px; width: 11px; height: 11px; border-radius: 50%;" :style="{ 'background-color': result.unique_color }"></span>
                        {{ result.name }}
                    </h3>
                </div>
                <div slot="content">

                    <div class="anual-result--item--indicators">
                        <br>
                        <Row :gutter="24">
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicadores de éxito anual <Button v-if="canModify && SuccessIndicators.length < SuccessIndicatorMaxLength" style="margin-left: 15px" size="small" ghost type="primary" @click="addIndicator('success')">Agregar</Button></p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in SuccessIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <p class="indicator--description">{{ indicator.name }}</p>
                                        <div v-if="canModify" class="indicator--action">
                                            <Dropdown trigger="click" placement="left-end">
                                                <a href="javascript:void(0)">
                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                </a>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem @click.native="updateIndicator(indicator)">Editar</DropdownItem>
                                                    <DropdownItem @click.native="deleteIndicator(indicator)">Eliminar</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </i-col>
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicador de avance del resultado anual <Button v-if="canModify && ProgressIndicators.length < ProgressIndicatorMaxLength" style="margin-left: 15px" size="small" ghost type="primary" @click="addIndicator('progress')">Agregar</Button></p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in ProgressIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <p class="indicator--description">{{ indicator.name }}</p>
                                        <div  v-if="canModify" class="indicator--action">
                                            <Dropdown trigger="click" placement="left-end">
                                                <a href="javascript:void(0)">
                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                </a>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem @click.native="updateIndicator(indicator)">Editar</DropdownItem>
                                                    <DropdownItem @click.native="deleteIndicator(indicator)">Eliminar</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </i-col>
                        </Row>
                    </div>
                    <div class="anual-result--item--action">
                        <div class="left"></div>
                        <div class="right">
                            <Button size="small" ghost type="primary" @click="collapseActivities">Actividades</Button>
                            <Button v-if="canModify" size="small" ghost type="warning" @click="updateResult">Editar resultado</Button>
                            <Button v-if="canModify" size="small" ghost type="error" @click="deleteResult">Eliminar resultado</Button>
                        </div>
                    </div>
                    <Collapse class="invisible hidden" v-model="collapse_activities">
                        <Panel name="1">
                            <div slot="content">
                                <br><br>
                                <Row style="margin: 0 1rem">
                                    <i-col :xs="24" :sm="18" :md="18" :lg="18">
                                        <h3>Actividades</h3>
                                    </i-col>
                                    <i-col :xs="24" :sm="6" :md="6" :lg="6" >
                                        <div style="float: right; display: flex;">
                                            <Button v-if="CanCreateActivity" type="primary" @click="addActivity">Nueva actividad</Button>
                                        </div>
                                    </i-col>
                                </Row>
                                <br><br>
                                <Collapse simple class="header-flex budget result-activities">
                                    <Panel v-for="activity in Activities" :key="'list_activity_'+activity.id" :name="`activity_${activity.id}`">
                                        <div class="budget-header--collapse-panel">
                                            <div style="flex-grow: 1">
                                                <h3>{{ activity.name }}</h3>
                                            </div>
                                            <h3 @click.stop="() => {}" class="action">
                                                <Dropdown  v-if="CanModifyActivity" trigger="click" placement="left-end">
                                                    <a href="javascript:void(0)">
                                                        <Icon color="grey" size="16" type="md-more"></Icon>
                                                    </a>
                                                    <DropdownMenu slot="list">
                                                        <DropdownItem v-if="CanModifyActivity" @click.native="updateActivity(activity)">Editar</DropdownItem>
                                                        <DropdownItem v-if="CanModifyActivity" @click.native="deleteActivity(activity)">Eliminar</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </h3>
                                        </div>
                                        <template #content>
                                            <div style="margin: 15px 30px">
                                                <Row class="row-column-info-list" :gutter="16">
                                                    <i-col :xs="24" :sm="24" :md="24" :lg="24">
                                                        <p style="font-size: 0.9em; margin-bottom: 5px"><b>Responsables: </b>
                                                            <span style="margin-right: 5px" v-for="(resp, i) in activity.responsible" :key="'poa_actvs_resp_'+resp.id">{{ resp.user.name }}{{ activity.responsible[i+1]  ? ',' : '' }}</span>
                                                        </p>
                                                    </i-col>
                                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                                        <p><b>Fechas</b></p>
                                                        <p style="text-transform: capitalize" v-for="date in activity.dates" :key="'poa_actvs_dts_'+date.id">
                                                            <template v-if="date.start == date.end">
                                                                {{ date.start | moment('DD/MMMM/yyyy') }}
                                                            </template>
                                                            <template v-else>
                                                                {{ date.start | moment('DD/MMMM/yyyy') }} <span style="text-transform: lowercase">a</span> {{ date.end | moment('DD/MMMM/yyyy') }}
                                                            </template>
                                                        </p>
                                                    </i-col>
                                                    <i-col v-if="activity.colabs.length > 0" :xs="24" :sm="24" :md="8" :lg="8">
                                                        <p><b>Colaboraciones internas</b></p>
                                                        <AvatarList :users="getColabs(activity)" />
                                                    </i-col>
                                                    <i-col v-if="activity.external_colabs != null" :xs="24" :sm="24" :md="8" :lg="8">
                                                        <p><b>Colaboraciones externas</b></p>
                                                        <p>{{ activity.external_colabs }}</p>
                                                    </i-col>
                                                </Row>
                                            </div>
                                        </template>
                                    </Panel>
                                </Collapse>
                                <div v-if="Activities.length == 0" style="margin: 3rem; text-align: center;">
                                    <h4>Este resultado no tiene actividades.</h4>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </Panel>
        </Collapse>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import AvatarList from './../AvatarList'
    // import ActivityItem from './../../Program/POA/components/Calendar/components/Activity/Item'

    export default {
        props: {
            result: { required: true, type: [Object, Array] },
            program: { required: false, type: [Object, Array] },
            canModify: { required: false, type: Boolean, default: false },
        },
        data(){
            return {
                collapse_result: '0',
                collapse_activities: '0',
                max_length: 5,
                ind_success_max_length: 3,
                ind_progress_max_length: 5,
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',

                program_member: 'program',
            }),
            Activities(){
                return window._.orderBy(this.result.activities, (a) => {
                    return a.result.id
                })
            },
            SuccessIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'success'
                    })
                , ['order'], ['asc'])
            },
            ProgressIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'progress'
                    })
                , ['order'], ['asc'])
            },
            SuccessIndicatorMaxLength(){
                if( this.user.role_name == 'Super Admin' ) return 99
                else return this.ind_success_max_length
            },
            ProgressIndicatorMaxLength(){
                if( this.user.role_name == 'Super Admin' ) return 99
                else return this.ind_progress_max_length
            },
            EvaluationKey(){
                if(this.ev_type == 'middle') return 'middle_evaluation'
                else return 'final_evaluation'
            },
            ResultEvaluation(){
                if(this.evaluation){
                    if(this.ev_type == 'middle'){
                        return this.result.middle_evaluation
                    }
                    if(this.ev_type == 'final'){
                        return this.result.final_evaluation
                    }
                }
                return null;
            },
            CanCreateActivity(){
               return this.canModify
            },
            CanModifyActivity(){
                return this.canModify
            }
        },
        methods: {
            addIndicator(type){
                window.EventBus.$emit('program-poa--create-indicator', this.result, type)
            },
            updateIndicator(ind){
                window.EventBus.$emit('program-poa--update-indicator', this.result, ind)
            },
            deleteIndicator(ind){
                this.$Modal.confirm({
                    title: 'Eliminar Indicador',
                    content: `<p>¿Deseas eliminar este indicador?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/indicators/${ind.id}/delete`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`result-item-${this.result.id}--delete-indicator`, ind)
                                this.$Message.success('RI eliminado correctamente');
                            }
                        })
                    },
                });
            },
            updateResult(){
                window.EventBus.$emit('program-poa--update-result-popup', this.result)
            },
            deleteResult(){
                this.$Modal.confirm({
                    title: 'Eliminar Resultado',
                    content: `<p>¿Deseas eliminar este resultado?</p><p>Al hacer clic en <strong>Eliminar</strong> también se borarrá toda la información relacionada a este resultado.</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/results/delete/${this.result.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`program-poa---delete-result`, this.result)
                                this.$Message.success('Resultado eliminado correctamente');
                            }
                        })
                    },
                });
            },
            collapseActivities(){
               this.collapse_activities = this.collapse_activities == '1' ? '0' : '1'
            },
            updateEvaluation(){
                window.EventBus.$emit('program-poa--update-evaluation', this.result, this.ResultEvaluation)
            },
            evaluateIndicator(indicator){
                window.EventBus.$emit('program-poa--evaluate-indicator', indicator) 
            },
            updateIndicatorStatus(indicator, status){
                window.axios.post(this.$root.API_URL + `/indicators/${indicator.id}/update-status`, { statuses_id: status.id, type: this.ev_type })
                .then(response => {
                    if(response.data.success){
                        let ii = window._.findIndex(this.result.indicators, (i) => {
                            return i.id == indicator.id
                        })

                        if(ii != -1){
                            window.EventBus.$emit('program-poa--update-result', response.data.result)
                            this.$Message.success('Indicador actualizado correctamente');
                        }
                    }
                })
            },
            getColabs(activity){
                let usrs = []

                window._.each(activity.colabs, (c) => {
                    usrs.push(c.user)
                })

                return usrs
            },
            addActivity(){
                window.EventBus.$emit('poa-activities--create', this.result)
            },
            updateActivity(activity){
                window.EventBus.$emit('poa-activities--update', activity)
            },
            deleteActivity(activity){
                this.$Modal.confirm({
                    title: 'Eliminar Actividad',
                    content: `<p>¿Deseas eliminar esta actividad?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/activities/delete/${activity.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`poa-activities-caldendar--delete-activity`, activity)
                                this.$Message.success('Actividad eliminada correctamente');
                            }
                        })
                    },
                });
            }
        },
        mounted(){

            window.EventBus.$on(`result-item-${this.result.id}--add-indicator`, (indicator) => {
                let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii == -1){
                    this.result.indicators.push(indicator)
                }
            })

            window.EventBus.$on(`result-item-${this.result.id}--update-indicator`, (indicator) => {
                let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii != -1){
                    this.$set(this.result.indicators, ii, indicator)
                    this.$updateCollapseHeight(`anual-result-${this.result.id}`)
                }
            })

             window.EventBus.$on(`result-item-${this.result.id}--delete-indicator`, (indicator) => {
                 let ii = window._.findIndex(this.result.indicators, (ind) => {
                    return ind.id == indicator.id 
                })

                if(ii != -1){
                    this.result.indicators.splice(ii, 1)
                    this.$updateCollapseHeight(`anual-result-${this.result.id}`)
                }
             })

        },
        components: {
            AvatarList,
            // ActivityItem
        }
    }
</script>