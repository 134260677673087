<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Breadcrumb>
                <BreadcrumbItem to="/evaluaciones">Evaluaciones</BreadcrumbItem>
                <BreadcrumbItem :to="`/evaluaciones/${$route.params.year}`">{{ $route.params.year }}</BreadcrumbItem>
                <BreadcrumbItem>{{ evaluations[0].evaluated.name }}</BreadcrumbItem>
            </Breadcrumb>

            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Evaluaciones {{ $route.params.year }}</h1>
                </i-col>
            </Row>

            <br><br>
            <h2>Evaluaciones de {{ evaluations[0].evaluated.name }}</h2>
            <p>Integrantes que la han evaluado: 
                <!-- <span v-for="evaluation in EvaluationsExceptAutoevaluation" :key="`evaluated_by_${evaluation.user_id}`">{{ evaluation.user.name }}, </span> -->
            </p>
             <div class="program-members--items">
                <div v-for="evaluation in EvaluationsExceptAutoevaluation" :key="`user_evaluated_${evaluation.user_id}`" class="program-members--item click">
                    <div class="program-members--item--avatar">
                        <Avatar :src="evaluation.user.profile_pic"/>
                    </div>
                    <div class="program-members--item--info">
                        <h4>{{ evaluation.user.name }}</h4>
                        <p>{{ evaluation.user.email }}</p>
                        <p style="color: grey; font-size: 0.8em">{{ evaluation.user.role_name }}</p>
                    </div>
                </div>
            </div>
            <br><br>
            <h3>Resultados de la evaluación</h3>
            <br>
            <Row :gutter="16">
                <i-col v-if="ShowResultsChart" span="12">
                    <h2 style="text-align: center;">Resultados</h2>
                    <SemiCircle :data="buildCompetencesChartData(results_status)" />
                    <!-- <h4 style="text-align: center">Promedio: {{ avg.results.original }} / {{ avg.results.rounded }}</h4> -->
                    <div style="display: flex; align-items: center; justify-content: center;" >
                        <div :style="{ 'background-color': getResultsAvgStatusColor() }" class="indicator--tag"></div>
                        <p style="font-weight: bold; font-size: 0.9em">{{ getResultsAvgStatusName() }}</p>
                    </div>
                </i-col>
                <i-col span="12">
                    <h2 style="text-align: center;">Competencias</h2>
                    <SemiCircle :data="buildCompetencesChartData(competences_status)" />
                    <!-- <h4 style="text-align: center">Promedio: {{ avg.competences.original }} / {{ avg.competences.rounded }}</h4> -->
                    <div style="display: flex; align-items: center; justify-content: center;" >
                        <div :style="{ 'background-color': getCompetenceAvgStatusColor() }" class="indicator--tag"></div>
                        <p style="font-weight: bold; font-size: 0.9em">{{ getCompetenceAvgStatusName() }}</p>
                    </div>
                </i-col>
            </Row>
            <br><br>
            <Collapse class="invisible">
                <Panel v-for="(evaluated, i) in Evaluations" :key="`evaluated_list_user_${i}`" :name="`${i}`">
                    <h2>
                        <template v-if="$route.params.id == evaluated.evaluates_to_id && $route.params.id == evaluated.user_id">
                            Autoevaluación
                        </template>
                        <template v-else>
                            {{ evaluated.user.name }}
                        </template>
                    </h2>
                    <template #content>
                        <Tabs :value="hasResults(evaluated.evaluations) ? 'results' : 'competences'">
                            <TabPane v-if="hasResults(evaluated.evaluations)" label="Planeación Operativa Anual (POA)" name="results">
                                <div class="evaluation--box" v-for="evaluation in getResults(evaluated.evaluations)" :key="'autv_cmp_id_'+evaluation.id">
                                    <Row :gutter="16">
                                        <i-col span="24">
                                            <p class="competence-name">{{ evaluation.classable.name }}</p>
                                        </i-col>
                                        <i-col span="24">
                                            <div class="evaluation--indicator">
                                                <div class="evaluation--indicator--item">
                                                    <div :style="{ 'background-color': getIndicatorColor(evaluation) }" class="indicator--tag"></div>
                                                    <p>{{ getIndicatorName(evaluation) }}</p>
                                                </div>
                                            </div>
                                            <div style="padding: 10px 0">
                                                <p>{{ evaluation.comment }}</p>
                                            </div>
                                        </i-col>
                                    </Row>
                                </div>
                            </TabPane>
                            <TabPane v-if="getCompetences(evaluated.evaluations)" label="Competencias" name="competences">
                                <div class="evaluation--box" v-for="evaluation in getCompetences(evaluated.evaluations)" :key="'autv_cmp_id_'+evaluation.id">
                                    <Row :gutter="16">
                                        <i-col span="24">
                                            <p class="competence-name">{{ evaluation.classable.name }}</p>
                                        </i-col>
                                        <i-col span="24">
                                            <div class="evaluation--indicator">
                                                <div class="evaluation--indicator--item">
                                                    <div :style="{ 'background-color': getIndicatorColor(evaluation) }" class="indicator--tag"></div>
                                                    <p>{{ getIndicatorName(evaluation) }}</p>
                                                </div>
                                            </div>
                                            <div style="padding: 10px 0">
                                                <p>{{ evaluation.comment }}</p>
                                            </div>
                                        </i-col>
                                    </Row>
                                </div>
                            </TabPane>
                            <TabPane v-if="hasEvResultsText(evaluated)" label="Resultados de evaluación" name="ev-results">
                                <div class="evaluation--box">
                                    <h2>Fortalezas/Reconocimiento</h2>
                                    <p>{{ evaluated.strengths }}</p>
                                    <br><br>
                                    <h2>Áreas de oportunidad</h2>
                                    <p>{{ evaluated.opportunities }}</p>
                                    <br><br>
                                    <h2>Compromisos y plan de acción</h2>
                                    <p>{{ evaluated.compromises }}</p>
                                    <br>
                                </div>
                            </TabPane>
                        </Tabs>
                    </template>
                </Panel>
            </Collapse>

        </div>
    </div>
</template>

<script>
    import chartMix from './../../../mixins/chart'
    import SemiCircle from './../../Charts/CustomSemiCircle'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [chartMix],
        data(){
            return {
                loading: true,
                evaluations: [],
                avg: null,
                results_status: null,
                competences_status: null,
            }
        },
        computed: {
            ...mapGetters([
                'status'
            ]),
            Statuses(){
                return this.status
            },
            // GroupEvaluations(){
            //     return window._.groupBy(this.evaluations, e => e.evaluates_to_id)
            // },
            EvaluationsExceptAutoevaluation(){
                return window._.filter(this.evaluations, e => e.user_id != this.$route.params.id)
            },
            ShowResultsChart(){
               let a = 0
               window._.each(this.results_status, status => {
                   a += status
               })
               return a >= 1
            },
            Evaluations(){
                return window._.orderBy(this.evaluations, ['type'])
            },
        },
        methods: {
            hasEvResultsText(evaluation){
                if( evaluation.compromises != null && evaluation.opportunities != null && evaluation.strengths != null ) return true
                else return false
            },
            getResults(evaluations){
                return window._.filter(evaluations, e => e.classable_type == 'result')
            },
            getCompetences(evaluations){
                return window._.filter(evaluations, e => e.classable_type == 'special-competence')
            },
            hasResults(evaluations){
                let rs = this.getResults(evaluations)
                return rs.length > 0 ?? false
            },
            hasCompetences(evaluations){
                let cs = this.getCompetences(evaluations)
                return cs.length > 0 ?? false
            },
            getEvaluations(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-all-evaluations/${this.$route.params.id}`, { year: this.$route.params.year })
                .then(response => {
                    if(response.data.success){
                        this.evaluations = response.data.evaluations
                        this.getEvaluationResults()
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            getEvaluationResults(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-evaluation-results/${this.$route.params.id}/${this.evaluations[0].evaluation.id}`)
                .then(response => {
                    if(response.data.success){
                        this.avg = response.data.avg
                        this.results_status = response.data.results_status
                        this.competences_status = response.data.competences_status
                        this.loading = false
                        // window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        // this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            },
            buildCompetencesChartData(competences){
                return this.getCompetencesChartData(competences)
            },
            getCompetenceAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return '#ececec'
            },
            getResultsAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getResultsAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return 
            },
            getIndicatorColor(evaluation){
                return evaluation.status ? evaluation.status.hex_color : '#ececec'
            },
            getIndicatorName(evaluation){
                return evaluation.status ? evaluation.status.name : 'Pendiente de evaluación'
            },
        },
        mounted(){
            if( ! this.$can('evaluations.view') ) this.$router.replace('/plan')
            this.getEvaluations()
        },
        components: {
            SemiCircle,
        }
    }
</script>