<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo resultado" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="result" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Resultado" prop="text">
                        <Input type="textarea" :autosize="{minRows: 5,maxRows: 8}" v-model="result.text" placeholder="Resultado"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                result: {
                    text: ""
                },
                loading: false,
                ruleInline: {
                    text: [
                        { required: true, message: 'Resultado es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/results/create-poa/${this.$parent.poa.id}`, { name: this.result.text, })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-poa--add-result', response.data.result)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.result = {
                    text: ""
                }
            }
        },
        mounted(){
            window.EventBus.$on('program-poa--create-result', () => {
                this.show = true
            })
        }
    }
</script>