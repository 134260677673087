<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nueva Competencia" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="competence" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Competencia" prop="name">
                        <Input type="textarea" v-model="competence.name" placeholder="" :rows="5"/>
                    </FormItem>
                    <FormItem label="Roles" prop="roles">
                        <Select v-model="competence.roles" multiple >
                            <Option v-for="role in roles" :value="role.id" :key="role.id">{{ role.name }}</Option>
                        </Select>
                    </FormItem>
                    <br><br><br><br><br>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                competence: {
                    name: "",
                    roles: [],
                },
                roles: [],
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                    roles: [
                        { required: true, type: 'array', message: 'Selecciona al menos un rol', trigger: 'change' }
                    ]
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/evaluation/competences/create', { ...this.competence })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('competences--add-competence', response.data.competence)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            getRoles(){
                window.axios.post(this.$root.API_URL + '/evaluation/competences/get-roles-to-attach')
                .then(response => {
                    if(response.data.success){
                       this.roles = response.data.roles
                    }
                })
            },
            clear(){
                this.competence =  {
                    name: "",
                    roles: [],
                }
            }
        },
        mounted(){
            window.EventBus.$on('competences--create-popup', () => {
                if( this.roles.length == 0 ) this.getRoles()
                this.show = true
            })
        }
    }
</script>