<template>
    <div>
        <template v-if="HasFeedback && show_charts">
            <Row :gutter="16">
                <i-col v-if="HasFeedback" span="12">
                    <br><br>
                    <h2 style="text-align: center;">Resultados</h2>
                    <SemiCircle :data="buildResultsDesempChartData(results_status)" />
                    <!-- <h4 style="text-align: center">Promedio: {{ avg.results.original }} / {{ avg.results.rounded }}</h4> -->
                    <div style="display: flex; align-items: center; justify-content: center;" >
                        <div :style="{ 'background-color': getResultsAvgStatusColor() }" class="indicator--tag"></div>
                        <p style="font-weight: bold; font-size: 0.9em">{{ getResultsAvgStatusName() }}</p>
                    </div>
                </i-col>
                <i-col span="12">
                    <br><br>
                    <h2 style="text-align: center;">Competencias</h2>
                    <SemiCircle :data="buildCompetencesChartData(competences_status)" />
                    <!-- <h4 style="text-align: center">Promedio: {{ avg.competences.original }} / {{ avg.competences.rounded }}</h4> -->
                    <div style="display: flex; align-items: center; justify-content: center;" >
                        <div :style="{ 'background-color': getCompetencesAvgStatusColor() }" class="indicator--tag"></div>
                        <p style="font-weight: bold; font-size: 0.9em">{{ getCompetencesAvgStatusName() }}</p>
                    </div>
                </i-col>
            </Row>
            <br><br><br>
            <hr>
            <br><br>
        </template>
        <Row v-if="HasFeedback && feedback != null">
            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <div style="display: flex; align-items: center; flex-direction: column; padding: 15px">
                    <br>
                    <h3 style="text-align: center;">Tu autoevaluación</h3>
                </div>
            </i-col>
            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <div style="display: flex; align-items: center; flex-direction: column; padding: 15px">
                    <h3 style="text-align: center;">Tu retroalimentación por:</h3>
                    <template v-if="!multipleFeedback">
                        <div style="display: flex; align-items: center; margin-top: 15px">
                            <Avatar :src="feedback.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                            <div style="margin-left: 15px">
                                <h4>{{ feedback.user.name }}</h4>
                                <p style="font-size: 0.9em; color: grey;">{{ feedback.user.role_name }}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <br>
                        <div style="display: flex">
                            <div v-for="user_f in feedback" :key="`feedback_usr_${user_f.user.id}`" style="display: flex; align-items: center; margin: 0 15px;">
                                <Avatar :src="user_f.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                                <div style="margin-left: 15px">
                                    <h4>{{ user_f.user.name }}</h4>
                                    <p style="font-size: 0.9em; color: grey;">{{ user_f.user.role_name }}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </i-col>
        </Row>
        <br><br>
        <h2>Fortalezas/Reconocimiento</h2>
        <Row :gutter="50">
            <i-col span="12">
                <template v-if="CanEvaluationBeUpdated">
                    <Input @on-blur="updateField('strengths')" v-model="evaluation.strengths" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                </template>
                <template v-else>
                    <div style="padding: 10px 0">
                        <p v-html="evaluation.strengths"></p>
                    </div>
                </template>
            </i-col>
            <i-col v-if="HasFeedback && feedback != null" span="12">
                <template v-if="!multipleFeedback">
                    <div style="padding: 10px 0">
                        <p v-html="feedback.strengths_feedback"></p>
                    </div>
                </template>
                <template v-else>
                    <div v-for="user_f in feedback" :key="`feedback_usr_ev_${user_f.user.id}`">
                        <h4>Evaluación de {{ user_f.user.name }}:</h4>
                        <br>
                        <div style="padding: 10px 0">
                            <p v-html="user_f.strengths_feedback"></p>
                        </div>
                        <br><br>
                    </div>
                </template>
            </i-col>
        </Row>
        <br><br>
        <h2>Áreas de oportunidad</h2>
        <Row :gutter="50">
            <i-col span="12">
                <template v-if="CanEvaluationBeUpdated">
                    <Input @on-blur="updateField('opportunities')" v-model="evaluation.opportunities" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                </template>
                <template v-else>
                    <div style="padding: 10px 0">
                        <p v-html="evaluation.opportunities"></p>
                    </div>
                </template>
            </i-col>
            <i-col v-if="HasFeedback && feedback != null" span="12">
                <template v-if="!multipleFeedback">
                    <div style="padding: 10px 0">
                        <p v-html="feedback.opportunities_feedback"></p>
                    </div>
                </template>
                <template v-else>
                    <div v-for="user_f in feedback" :key="`feedback_usr_ev_${user_f.user.id}`">
                        <h4>Evaluación de {{ user_f.user.name }}:</h4>
                        <br>
                        <div style="padding: 10px 0">
                            <p v-html="user_f.opportunities_feedback"></p>
                        </div>
                        <br><br>
                    </div>
                </template>
            </i-col>
        </Row>
        <br><br>
        <h2>Compromisos y plan de acción</h2>
        <Row :gutter="50">
            <i-col span="12">
                <template v-if="CanEvaluationBeUpdated">
                    <Input @on-blur="updateField('compromises')" v-model="evaluation.compromises" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                </template>
                <template v-else>
                    <div style="padding: 10px 0">
                        <p v-html="evaluation.compromises"></p>
                    </div>
                </template>
            </i-col>
            <i-col v-if="HasFeedback && feedback != null" span="12">
                <template v-if="!multipleFeedback">
                        <div style="padding: 10px 0">
                            <p v-html="feedback.compromises_feedback"></p>
                        </div>
                    </template>
                    <template v-else>
                        <div v-for="user_f in feedback" :key="`feedback_usr_ev_${user_f.user.id}`">
                            <h4>Evaluación de {{ user_f.user.name }}:</h4>
                            <br>
                            <div style="padding: 10px 0">
                                <p v-html="user_f.compromises_feedback"></p>
                            </div>
                            <br><br>
                        </div>
                    </template>
            </i-col>
        </Row>
        <br><br>
        <br><br>
        <Row :gutter="50">
            <i-col span="12">
                <Checkbox v-if="ShowCheckButton" v-model="checked" :disabled="IsCheckDisabled" @on-change="chceckChanged">Estoy de acuerdo con los resultados de mi evaluación de desempeño individual y quedo enterada/o de mi responsabilidad para cumplir con los compromisos acordados, atender las áreas de oportunidad y dar seguimiento al plan de acción.</Checkbox>
            </i-col>
            <i-col v-if="evaluation.status == 'initial'" span="12">
                <br>
                <Button :disabled="!IsAutoevaluationComplete" @click="marAsFinished" type="primary" long size="large">Terminar autoevaluación</Button>
            </i-col>
        </Row>
    </div>
</template>

<script>
    import chartMix from './../../../../../mixins/chart'
    import SemiCircle from './../../../../Charts/CustomSemiCircle'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [chartMix],
        props: {
            feedback: { required: false, type: [Object, Array] },
            evaluation: { required: true, type: [Object, Array] },
            multipleFeedback: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                avg: null,
                show_charts: false,
                checked: false,
                results_status: null,
                competences_status: null,
            }
        },
        computed: {
            ...mapGetters([
                'status_des',
                'status_comp',
            ]),
            Statuses(){
                return window._.filter(this.status, s => {
                    return s.name != 'No aplica'
                })
            },
            HasFeedback(){
                if( Array.isArray(this.feedback) ) return this.feedback.length > 0
                return this.feedback != null
            },
            IsCheckDisabled(){
                return this.evaluation.status == 'accepted'
            },
            CanEvaluationBeUpdated(){
                let s = [ 'auto-finished', 'boss-finished', 'accepted' ];
                return ! s.includes(this.evaluation.status)
            },
            ButtonAutoevaluationDisabled(){
                if( this.evaluation.status == null ){
                    let a = [];
    
                    window._.each(this.evaluation.evaluations, (evaluation) => {
    
                        if( evaluation.comment != null ) a.push(true)
                        else a.push(false)
    
                        if( evaluation.status != null ) a.push(true)
                        else a.push(false)
    
                    })

                    if( this.evaluation.strengths != '' && this.evaluation.strengths != null ) a.push(true)
                    else a.push(false)

                    if( this.evaluation.opportunities != '' && this.evaluation.opportunities != null ) a.push(true)
                    else a.push(false)

                    if( this.evaluation.compromises != '' && this.evaluation.compromises != null ) a.push(true)
                    else a.push(false)

                    // if( this.checked == true ) a.push(true)
                    // else a.push(false)

                    return a.includes(false)
                }
                else return true;
            },
            ShowCheckButton(){
                if ( this.MultipleFeedback ) return this.areMultilpleFeedbackFinished()
                if( this.evaluation.status == 'boss-finished' || this.evaluation.status == 'accepted' ) return true
                else return false
            },
            IsAutoevaluationComplete(){
                if( this.evaluation.status == 'initial' ) return this.checkAutoevaluationComplete()
                else return true
            },
            MultipleFeedback(){
                if( this.evaluation.evaluated.role_name == 'Coordinación' || this.evaluation.evaluated.role_name == 'Oficial del área directiva' ) return true
                return false
            },
        },
        methods: {
            areMultilpleFeedbackFinished(){
                if ( this.feedback.length == 0 ) return false
                else {
                    let a = []
                    window._.each(this.feedback, f => {
                        if( f.status == 'finished' ) a.push(true)
                        else a.push(false)
                    })
                    return ! a.includes(false)
                }
            },
            checkAutoevaluationComplete(){
                let a = []
                window._.each(this.evaluation.evaluations, e => {
                    if( e.status != null && e.comment != '' ) a.push(true)
                    else a.push(false)
                })

                if( this.evaluation.strengths != null && this.evaluation.strengths != '' ) a.push(true)
                else a.push(false)

                if( this.evaluation.opportunities != null && this.evaluation.opportunities != '' ) a.push(true)
                else a.push(false)

                if( this.evaluation.compromises != null && this.evaluation.compromises != '' ) a.push(true)
                else a.push(false)

                return ! a.includes(false)
            },
            marAsFinished(){
                this.$Modal.confirm({
                    title: 'Terminar autoevaluación',
                    content: `¿Estas segura (o/e) que deseas concluir tu autoevaluación? Una vez activado este botón ya no podrás hacer cambios.`,
                    okText: 'Terminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/evaluation/mark-as-finished/${this.evaluation.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                                this.$Message.success('Tu autoevaluación fue finalizada correctamente');
                            }
                            else {
                                this.$Message.error('No se pudo finalizar tu autoevaluación, por favor revisa el formulario');
                            }
                        })
                        .catch(() => {
                            this.$Message.error('No se pudo finalizar tu autoevaluación');
                        })
                    },
                });
            },
            getEvaluationResults(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-evaluation-results/${this.evaluation.evaluated.id}/${this.evaluation.evaluation.id}`)
                .then(response => {
                    if(response.data.success){
                        this.avg = response.data.avg
                        this.results_status = response.data.results_status
                        this.competences_status = response.data.competences_status
                        let self = this
                        setTimeout(() => {
                            self.show_charts = true
                        }, 1000)
                        // window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        // this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            },
            updateField(field){
                if( this.evaluation[field] != null ){
                    window.axios.post(this.$root.API_URL + `/evaluation/update-field/${this.evaluation.id}`, { field: field, comment: this.evaluation[field] })
                    .then(response => {
                        if(response.data.success){
                            window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                            this.$Message.success('Desempeño actualizado correctamente');
                        }
                    })
                    .catch(() => {
                        this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                    })
                }
            },
            buildResultsDesempChartData(results){
                return this.getResultsDesempChartData(results)
            },
            buildCompetencesChartData(results){
                return this.getCompetencesChartData(results)
            },
            getCompetenceAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return '#ececec'
            },
            getResultsAvgStatusName(){
                let si = window._.findIndex(this.status_des, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.status_des[si].name
                else return 'NA'
            },
            getResultsAvgStatusColor(){
                let si = window._.findIndex(this.status_des, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.status_des[si].hex_color
                else return '#ececec'
            },
            getCompetencesAvgStatusName(){
                let si = window._.findIndex(this.status_comp, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.status_comp[si].name
                else return 'NA'
            },
            getCompetencesAvgStatusColor(){
                let si = window._.findIndex(this.status_comp, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.status_comp[si].hex_color
                else return '#ececec'
            },
            chceckChanged(e){
                if( e == true ){
                    window.axios.post(this.$root.API_URL + `/evaluation/mark-as-accepted/${this.evaluation.id}`)
                    .then(response => {
                        if(response.data.success){
                            window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                            this.$Message.success('Tu autoevaluación fue finalizada correctamente');
                        }
                        else {
                            this.$Message.error('No se pudo marcar tu autoevaluación, por favor revisa el formulario');
                        }
                    })
                    .catch(() => {
                        this.$Message.error('No se pudo marcar tu autoevaluación');
                    })
                }
            }
        },
        mounted(){
            if( this.feedback != null ) this.getEvaluationResults()

            if( this.evaluation.status == 'boss-finished' ) this.checked = false

            if( this.evaluation.status == 'accepted' ) this.checked = true
        },
        components: {
            SemiCircle,
        }
    }
</script>