<template>
    <div>
        <LoadingContainer v-if="is_loading"/>
        <div v-else class="container top">

            <template v-if="active_survey">
                <h1 class="survey-title">
                    Encuesta clima y cultura organizacional
                    <span>{{ survey.name }}</span>
                </h1>
                <br>
                <component :is="ActiveCategory" :form="form" @on-register-answers="onRegisterAnswers" :server_loading="server_loading" />
            </template>

            <template v-else>
                <h1 class="survey-title">
                    Encuesta clima y cultura organizacional
                </h1>
                <br><br><br><br>
                <h1 class="txt-center">Whoops!</h1>
                <p class="txt-center">{{ server_response_message }}</p>
            </template>

        </div>
    </div>
</template>

<script>
    import End from '@/components/WorkEnv/components/End'
    import Cat1 from "@/components/WorkEnv/components/Cat1"
    import Cat2 from "@/components/WorkEnv/components/Cat2"
    import Cat3 from "@/components/WorkEnv/components/Cat3"
    import Cat4 from "@/components/WorkEnv/components/Cat4"
    import Cat5 from "@/components/WorkEnv/components/Cat5"
    import Cat6 from "@/components/WorkEnv/components/Cat6"
    import Cat7 from "@/components/WorkEnv/components/Cat7"

    export default  {
        data(){
            return {
                is_loading: true,
                form: null,
                survey: null,
                server_loading: false,
                active_survey: false,
                server_response_message: "",
            }
        },
        computed: {
            ActiveCategory(){
                let component
                switch( this.form?.last_question_code ){
                    case 'q1':
                        component = 'Cat1'
                        break
                    case 'q6':
                        component = 'Cat2'
                        break
                    case 'q17':
                        component = 'Cat3'
                        break
                    case 'q21':
                        component = 'Cat4'
                        break
                    case 'q23':
                        component = 'Cat5'
                        break
                    case 'q29':
                        component = 'Cat6'
                        break
                    case 'q59':
                        component = 'Cat7'
                        break
                    case 'q65':
                        component = 'End'
                        break
                }
                return component
            }
        },
        methods: {
            fetchInfo(){
                window.axios.get(this.$root.API_URL + '/work-env/get-active-form')
                    .then(response => {
                        if( response.data.success ){
                            this.active_survey = true
                            this.form = response.data.form
                            this.survey = response.data.survey
                            this.is_loading = false
                        }
                        else {
                            this.active_survey = false
                            this.server_response_message = response.data.message
                            this.is_loading = false
                        }
                    })
            },
            onRegisterAnswers(data){
                this.server_loading = true
                window.axios.post(this.$root.API_URL + `/work-env/${this.form.id}/register-answers`, { questions: data.answers,
                    last_question_code: data.last_question_code })
                    .then(response => {
                        if( response.data.success ){
                            this.server_loading = false
                            this.form.last_question_code = data.last_question_code
                            window.$('html, body').animate({
                                scrollTop: 0
                            }, 1000);

                            if( data.last_question_code === 'q65' ){
                                this.$store.dispatch("SET_WORK_ENV_SURVEY_ACTIVE", false)
                            }
                        }
                    })
            }
        },
        mounted() {
            this.fetchInfo()
        },
        components: {
            End,
            Cat1,
            Cat2,
            Cat3,
            Cat4,
            Cat5,
            Cat6,
            Cat7,
        }
    }
</script>

<style lang="scss">
    .survey-title {
        color: #484848;
        margin-top: 10px;
        display: flex;
        align-items: center;
        & span {
            margin-left: 10px;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            color: white;
            background: linear-gradient(140deg,#f16823,#843cf6);
        }
    }

    .env-survey-field-answers{
        margin: 50px 25px 10px;
    }

    .inner-question{
        margin-top: 20px;
    }

    .env-survey-field {
        position: relative;
        & .survey-question{
            font-size: 1.1em;
            font-weight: bold;
        }
        & .inner-question {
            margin-top: 15px;
            font-weight: 600;
            color: #484848;
            margin-bottom: 10px;
        }
    }

    .inline.ivu-checkbox-group.ivu-checkbox-default{
        & label {
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 15px 0;
            & span.ivu-checkbox {
                margin-right: 10px;
            }
        }
    }
</style>