<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
        
            <template v-if="message == ''">
                <h1 class="section--subtitle">Autoevaluación {{ Year }}</h1>
                <br><br>
                <!-- <EvaluationScales /> -->

                <Tabs :value="tab">
                    <TabPane v-if="ShowResultsTab" label="Planeación Operativa Anual (POA)" name="results">
                        <ResultsGrid :multipleFeedback="MultipleFeedback" :evaluation="evaluation" :feedback="feedback" :activities="activities" />
                    </TabPane>
                    <TabPane label="Competencias" name="competences">
                        <CompetencesGrid :multipleFeedback="MultipleFeedback" :evaluation="evaluation" :feedback="feedback"/>
                    </TabPane>
                    <TabPane label="Resultados de evaluación" name="name3">
                        <EvResult :multipleFeedback="MultipleFeedback" :statuses="statuses" :evaluation="evaluation" :feedback="feedback" />
                    </TabPane>
                </Tabs>
                <br><br>
            </template>
            <template v-else>
                <h1 class="section--subtitle">Autoevaluación</h1>
                <br><br>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <Icon size="70" type="md-contact" />
                    <br>
                    <h2>{{ message }}</h2>
                </div>
            </template>

            
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import EvResult from './components/EvResult'
    import ResultsGrid from './components/Results'
    import CompetencesGrid from './components/Competences'
    // import EvaluationScales from './../../../EvaluationScales/Users/Legends'

    export default {
        data(){
            return{
                tab: 'results',
                loading: true,
                feedback: null,
                statuses: [],
                evaluation: {},
                activities: [],
                message: '',
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ]),
            ShowResultsTab(){
                let results = window._.filter(this.evaluation.evaluations, e => e.classable_type == 'result')
                return results.length > 0
            },
            Year(){
                return this.evaluation.evaluation ? this.evaluation.evaluation.name : ''
            },
            IsEvaluationCompleted(){
                return this.feedback != null
            },
            MultipleFeedback(){
                if( this.evaluation.evaluated.role_name == 'Coordinación' || this.evaluation.evaluated.role_name == 'Oficial del área directiva' ) return true
                return false
            },
        },
        methods: {
            getAutoevaluation(){
                window.axios.post(this.$root.API_URL + '/evaluation/get-autoevaluation')
                .then(response => {
                    if(response.data.success){
                        this.feedback = response.data.feedback
                        this.evaluation = response.data.evaluation
                        this.activities = response.data.activities
                        this.loading = false
                        if( this.ShowResultsTab == false ) this.tab = 'competences'
                    }
                    else {
                        this.message = response.data.message
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
        },
        mounted(){
            if( ! this.$can('autoevaluation.view') ) this.$router.replace('/plan')

            this.$collpaseDrawer('evs-content')
            this.$deleteSubitemsActives()
            this.$markSubitemAsActive('Autoevaluación')
            this.getAutoevaluation()

            window.EventBus.$on('autoevaluation--update-evaluation', (evaluation) => {
                this.evaluation = evaluation
            })

        },
        components: {
            EvResult,
            ResultsGrid,
            CompetencesGrid,
            // EvaluationScales,
        }
    }
</script>

