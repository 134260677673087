<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle"> Encuestas clima y cultura organizacional</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button @click="onCreateSurveyButton" type="primary">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <Row :gutter="16" type="flex" align="middle">
                <i-col  v-for="survey in surveys" :key="'survey_'+survey.id" span="6">
                    <Card style="width:100%">
                        <router-link :to="`/work-env-surveys/${survey.id}`">Encuesta {{ survey.name }}</router-link>
                        <Tag style="float: right" v-if="survey.active" color="success">Activa</Tag>
                        <Tag style="float: right" v-else color="red">Inactiva</Tag>
                    </Card>
                </i-col>
            </Row>

            <CreatePopup />
        </template>
    </div>
</template>

<script>
    import CreatePopup from './components/Create'

    export default {
        data(){
            return {
                is_loading: true,
                surveys: [],
            }
        },
        methods: {
            fetchData(){
                window.axios.get(this.$root.API_URL + '/work-env-surveys/get')
                    .then(response => {
                        if(response.data.success){
                            this.surveys = response.data.surveys
                            this.is_loading = false
                        }
                    })
            },
            onCreateSurveyButton(){
                window.EventBus.$emit('surveys--create-popup')
            }
        },
        mounted() {
            if ( ! this.$canWatchWorkEnvData() ) this.$router.replace('/plan')
            else this.fetchData()

            window.EventBus.$on('surveys--add-survey', surveys => {
                this.surveys = surveys
            })
        },
        components: {
            CreatePopup,
        }
    }
</script>