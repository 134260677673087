<template>
    <div class="chart-legends">
        <template v-if="items">
            <div class="chart-legend" v-for="item in items" :key="`indicator_id_${item.id}`">
                <div class="chart-legend--status" :style="{ 'background-color': item.hex_color }"></div>
                <div>
                    <p class="chart-legend--subtitle">{{ item.short_name }}</p>
                    <p class="chart-legend--title">{{ item.name }} </p>
                </div>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status pending"></div>
                <div>
                    <p class="chart-legend--subtitle">P</p>
                    <p class="chart-legend--title">{{ PendingText }}</p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="chart-legend">
                <div class="chart-legend--status nu"></div>
                <p class="chart-legend--title">NU</p>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status mn"></div>
                <p class="chart-legend--title">MN</p>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status p"></div>
                <p class="chart-legend--title">P</p>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status ma"></div>
                <p class="chart-legend--title">MA</p>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status t"></div>
                <p class="chart-legend--title">T</p>
            </div>
            <div v-if="showNoAplica" class="chart-legend">
                <div class="chart-legend--status na"></div>
                <p class="chart-legend--title">No aplica</p>
            </div>
            <div class="chart-legend">
                <div class="chart-legend--status pending"></div>
                <p class="chart-legend--title">Pendiente</p>
            </div>
        </template>

        <Button @click="showResultsEvaluationScale" :class="{ 'go-down': items ? items.length > 4 : false }" class="display-none-print" type="default" size="small">Ver escala de evaluación</Button>
        <ResultsEvaluationScale :showNoAplica="showNoAplica" :items="items" :name="name" />
    </div>
</template>

<script>
    import ResultsEvaluationScale from './Results'

    export default {
        props: {
            name: { required: false, type: String, default: 'default' },
            items: { required: false, type: [Object, Array] },
            showNoAplica: { required: false, type: Boolean, default: false }
        },
        computed: {
            PendingText(){
                if( this.items ){
                    if( this.items[0].category == 'Desempeño' ) return 'Pendiente de evaluar'
                    else return 'Pendiente'
                }
                return 'Pendiente'
            }
        },
        methods: {
            showResultsEvaluationScale(){
                window.EventBus.$emit(`show-evaluation-scales--results--${this.name}`, true)
            }
        },
        components: {
            ResultsEvaluationScale
        }
    }
</script>