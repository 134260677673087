<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <h1 class="section--subtitle">Competencias Institucionales</h1>
            <br>
            <!-- <Row type="flex" align="middle">
                <i-col span="20">
                    <h2>Por puesto</h2>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenCompetence">Agregar</Button>
                </i-col>
            </Row>
            <br> -->
            
            <Create v-if="CanAddCompetence" />
            <Update v-if="CanUpdateCompetence" />
            <CreateSpecial v-if="CanAddCompetence" />
            <UpdateSpecial v-if="CanUpdateCompetence" />
            
            <!-- <table class="cmtp-tble">
                <thead>
                    <tr>
                        <td width="50%"></td>
                        <td class="cmpt-tble-title">Dirección</td>
                        <td class="cmpt-tble-title">Subdirección</td>
                        <td class="cmpt-tble-title">Coordinación</td>
                        <td class="cmpt-tble-title">Oficial Sr</td>
                        <td class="cmpt-tble-title">Oficial Jr</td>
                        <td class="cmpt-tble-title">Oficial Área</td>
                        <td class="cmpt-tble-title">Ast. P.</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <TableItem v-for="competence in competences" :key="'competence_id_'+competence.id" :competence="competence" />
                </tbody>
            </table>
            
            <br><br>
            <hr> -->
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que Oficiales del área evalúan a Dirección y Subdirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que Oficiales del área evalúan a Dirección y Subdirección', category: 'ofs_to_dir_sub'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in OfsToDirSub" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las Oficiales de otras áreas evalúan en la Coordinación</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las Oficiales de otras áreas evalúan en la Coordinación', category: 'ofs_to_other_coords'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in OfsToOthersCoords" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las Oficiales del área evalúan en la Coordinación</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las Oficiales del área evalúan en la Coordinación', category: 'ofs_to_coords'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in OfsToCoords" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las Coordinadoras le evalúan a Dirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las Coordinadoras le evalúan a Dirección', category: 'coords_to_dir'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToDir" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las Coordinadoras le evalúan a Subdirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las Coordinadoras le evalúan a Subdirección', category: 'coords_to_sub'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToSub" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>

            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que Dirección/Subdirección le evalúa a la Coordinación</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que Dirección/Subdirección le evalúa a la Coordinación', category: 'dir_sub_to_coords'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in DirSubToCoord" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <h1 class="section--subtitle">Competencias por puesto</h1>
            <br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Presidenta del Consejo le evalúa a Dirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Presidenta del Consejo le evalúa a Dirección', category: 'pdta_to_dir'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in PdtaToDir" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Dirección le evalúa a Subdirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Dirección le evalúa a Subdirección', category: 'dir_to_sub'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in DirToSub" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Subdirección le evalúa a Dirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Subdirección le evalúa a Dirección', category: 'sub_to_dir'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in SubToDir" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Coordinación le evalúa a Mantenimiento</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Coordinación le evalúa a Mantenimiento', category: 'coords_to_mto'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToMto" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Coordinación le evalúa a Asistente de programa</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Coordinación le evalúa a Asistente de programa', category: 'coords_to_ast'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToAst" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Coordinación le evalúa a Oficial</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Coordinación le evalúa a Oficial', category: 'coords_to_of_jr'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToOfJr" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Coordinación le evalúa a Oficial Sr.</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Coordinación le evalúa a Oficial Sr.', category: 'coords_to_of_sr'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in CoordsToOfSr" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que la Oficial del área directiva le evalúa a Dirección y Subdirección</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que la Oficial del área directiva le evalúa a Dirección y Subdirección', category: 'of_dir_to_dir_sub'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in OfDirToDirSub" :key="'spec_comp_'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las áreas transversales evaluan a las áreas progrmáticas</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las áreas transversales evaluan a las áreas progrmáticas', category: 'trans_to_prog'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in TransToProg" :key="'trans_to_prog'+comp.id" :competence="comp" />
            <br><br>
            <hr>
            <br><br>
            <Row type="flex" align="middle">
                <i-col span="17">
                    <h2>Competencias que las áreas programáticas evaluan a las áreas transversales</h2>
                </i-col>
                <i-col span="7" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanAddCompetence" style="margin-right: 15px" class="display-none-print" type="primary" @click="addOpenSpecialCompetence({name: 'Competencias que las áreas programáticas evaluan a las áreas transversales', category: 'prog_to_trans'})">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <SpecialItem v-for="comp in ProgToTrans" :key="'prog_to_trans_'+comp.id" :competence="comp" />
            <br><br>
            <hr>

        </div>
    </div>
</template>

<script>
    import Create from './components/Create'
    import Update from './components/Update'
    // import TableItem from './components/TableItem'
    import SpecialItem from './components/SpecialItem'
    import CreateSpecial from './components/CreateSpecial'
    import UpdateSpecial from './components/UpdateSpecial'

    export default {
        data(){
            return {
                loading: true,
                special: [],
                competences: [],
            }
        },
        computed: {
            CanAddCompetence(){
                return this.$can('competences.add')
            },
            CanUpdateCompetence(){
                return this.$can('competences.update')
            },
            DirSubToCoord(){
                return window._.filter(this.special, s => s.category == 'dir_sub_to_coords' )
            },
            OfsToCoords(){
                return window._.filter(this.special, s => s.category == 'ofs_to_coords' )
            },
            OfsToOthersCoords(){
                return window._.filter(this.special, s => s.category == 'ofs_to_other_coords' )
            },
            CoordsToDir(){
                return window._.filter(this.special, s => s.category == 'coords_to_dir' )
            },
            CoordsToSub(){
                return window._.filter(this.special, s => s.category == 'coords_to_sub' )
            },
            OfsToDirSub(){
                return window._.filter(this.special, s => s.category == 'ofs_to_dir_sub' )
            },
            PdtaToDir(){
                return window._.filter(this.special, s => s.category == 'pdta_to_dir' )
            },
            DirToSub(){
                return window._.filter(this.special, s => s.category == 'dir_to_sub' )
            },
            SubToDir(){
                return window._.filter(this.special, s => s.category == 'sub_to_dir' )
            },
            CoordsToMto(){
                return window._.filter(this.special, s => s.category == 'coords_to_mto' )
            },
            CoordsToAst(){
                return window._.filter(this.special, s => s.category == 'coords_to_ast' )
            },
            CoordsToOfJr(){
                return window._.filter(this.special, s => s.category == 'coords_to_of_jr' )
            },
            CoordsToOfSr(){
                return window._.filter(this.special, s => s.category == 'coords_to_of_sr' )
            },
            OfDirToDirSub(){
                return window._.filter(this.special, s => s.category == 'of_dir_to_dir_sub' )
            },
            TransToProg(){
                return window._.filter(this.special, s => s.category == 'trans_to_prog' )
            },
            ProgToTrans(){
                return window._.filter(this.special, s => s.category == 'prog_to_trans' )
            }
        },
        methods: {
            addOpenCompetence(){
                window.EventBus.$emit('competences--create-popup')
            },
            addOpenSpecialCompetence(data){
                window.EventBus.$emit('competences--create-special-popup', data)
            },
            getCompetences(){
                window.axios.post(this.$root.API_URL + '/evaluation/competences/get')
                .then(response => {
                    if(response.data.success){
                       this.competences = response.data.competences
                       this.special = response.data.special
                       this.loading = false
                    }
                })
            }
        },
        mounted(){
            if( ! this.$can('competences.view') ) this.$router.replace('/plan')
            this.getCompetences()

            window.EventBus.$on('competences--add-competence', (competence) => {
                this.competences.push(competence)
            })

            window.EventBus.$on('competences--add-special-competence', (competence) => {
                this.special.push(competence)
            })

            window.EventBus.$on('competences--update-item-competence', (competence) => {
                let ci = window._.findIndex(this.competences, (c) => {
                    return c.id == competence.id
                })

                if( ci != -1 ){
                    this.$set(this.competences, ci, competence)
                }
            })

            window.EventBus.$on('competences--update-special-competence', (competence) => {
                let ci = window._.findIndex(this.special, (c) => {
                    return c.id == competence.id
                })

                if( ci != -1 ){
                    this.$set(this.special, ci, competence)
                }
            })

            window.EventBus.$on('competences--delete-item-competence', (competence) => {
                let ci = window._.findIndex(this.competences, (c) => {
                    return c.id == competence.id
                })

                if( ci != -1 ){
                    this.competences.splice(ci, 1)
                }
            })

            window.EventBus.$on('competences--delete-item-special-competence', (competence) => {
                let ci = window._.findIndex(this.special, (c) => {
                    return c.id == competence.id
                })

                if( ci != -1 ){
                    this.special.splice(ci, 1)
                }
            })
        },
        components: {
            Create,
            Update,
            // TableItem,
            SpecialItem,
            CreateSpecial,
            UpdateSpecial,
        }    
    }
</script>

<style lang="scss">
    .cmtp-tble{
        border-collapse: separate;
        border-spacing: 0 16px;
        font-size: 0.9em;
        & thead tr:hover{
            background-color: transparent !important;
        }
        & tr:hover{
            background-color: rgba(#843cf6, 0.1);
        }
    }

    .cmtp-text{
        padding: 10px;
    }

    .cmpt-tble-title{
        width: 5%;
        max-width: 5% !important;
        text-align: center;
        padding: 0 5px;
        font-size: 0.75em;
        font-weight: bold;
    }
</style>