<template>
    <div class="container top">
        <Row type="flex" align="middle">
            <i-col span="20">
                <h1 class="section--subtitle">Actividades de impacto</h1>
            </i-col>
            <i-col span="4" style="display: flex; justify-content: flex-end;">
                <Button v-if="CanWatchImpActivities" @click="showCreateImpactActivity" type="primary">Nuevo actividad de impacto</Button>
            </i-col>
        </Row>
        <br>
        <br>
        <div v-for="activity in imp_activities" :key="`imp_activity_id_${activity.id}`" class="special-cmpt">
            <div class="cont">
                <p>{{ activity.name }}</p>
            </div>
            <div class="actions">
                <Dropdown slot="extra" trigger="click" placement="left">
                    <a href="javascript:void(0)">
                        <Icon color="grey" size="16" type="md-more"></Icon>
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem v-if="CanWatchImpActivities" @click.native="showUpdateImpactActivity(activity)">Editar</DropdownItem>
                        <DropdownItem  v-if="CanWatchImpActivities" @click.native="showDeleteImpactActivity(activity)">Ocultar</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
        
        <br><br>
        <hr>
        <br><br>
        <Row type="flex" align="middle">
            <i-col span="20">
                <h1 class="section--subtitle">Perfiles de asistentes</h1>
            </i-col>
            <i-col span="4" style="display: flex; justify-content: flex-end;">
                <Button v-if="CanWatchImpActivities" @click="showCreateProfile" type="primary">Nuevo perfil de asistente</Button>
            </i-col>
        </Row>
        <br>
        <br>
        <div v-for="profile in profiles_ats" :key="`profile_id_${profile.id}`" class="special-cmpt">
            <div class="cont">
                <p>{{ profile.name }}</p>
            </div>
            <div class="actions">
                <Dropdown slot="extra" trigger="click" placement="left">
                    <a href="javascript:void(0)">
                        <Icon color="grey" size="16" type="md-more"></Icon>
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem v-if="CanWatchImpActivities" @click.native="showUpdateProfile(profile)">Editar</DropdownItem>
                        <DropdownItem  v-if="CanWatchImpActivities" @click.native="showDeleteProfile(profile)">Ocultar</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
        <p>&nbsp;</p>

        <CreateImpactActivity />
        <UpdateImpactActivity />
        <CreateProfile />
        <UpdateProfile />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import CreateProfile from './components/Profile/Create'
    import UpdateProfile from './components/Profile/Update'
    import CreateImpactActivity from './components/Activities/Create'
    import UpdateImpactActivity from './components/Activities/Update'

    export default {
        data(){
            return {
                is_loading: true,
                imp_activities: [],
                profiles_ats: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ]),
            CanWatchImpActivities(){
                if( this.$itsSuperAdmin() ) return true
                // Coordinadoras: DIE y Admin
                else if( this.$itsCoordOf('Administración') || this.$itsCoordOf('Desarrollo Institucional y Evaluación') ) return true
                // Fernanda de DIE 
                else if ( this.user.id == 5 ) return true
                else return false
            },
        },
        methods: {
            fetchData(){
                window.axios.post(this.$root.API_URL + '/bitacora/impact-activity/get')
                .then(response => {
                    if(response.data.success){
                       this.imp_activities = response.data.activities
                       this.profiles_ats = response.data.profiles
                       this.is_loading = false
                    }
                })
            },
            showCreateImpactActivity(){
                window.EventBus.$emit('imp-activities--create-popup', true)
            },
            showUpdateImpactActivity(activity){
                window.EventBus.$emit('imp-activities--update-popup', activity)
            },
            showDeleteImpactActivity(activity){
                this.$Modal.confirm({
                    title: 'Eliminar actividad de impacto',
                    content: `<p>¿Deseas eliminar esta actividad de impacto?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/bitacora/impact-activity/delete/${activity.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`imp-activities--delete-activity`, activity)
                                this.$Message.success('Actividad de impacto eliminada correctamente');
                            }
                        })
                    },
                });
            },
            showCreateProfile(){
                window.EventBus.$emit('imp-activities-profiles--create-popup', true)
            },
            showUpdateProfile(profile){
                window.EventBus.$emit('imp-activities-profiles--update-popup', profile)
            },
            showDeleteProfile(profile){
                this.$Modal.confirm({
                    title: 'Eliminar',
                    content: `<p>¿Deseas eliminar este perfil de asistente?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/bitacora/profiles/delete/${profile.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`imp-activities--delete-profile`, profile)
                                this.$Message.success('Perfil eliminado correctamente');
                            }
                        })
                    },
                });
            },
        },
        mounted(){
            if( this.CanWatchImpActivities ) this.fetchData()
            else this.$router.replace('/plan')

            window.EventBus.$on('imp-activities--add-activity', (activity) => {
                this.imp_activities.push(activity)
            })

            window.EventBus.$on('imp-activities--update-activity', (activity) => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)

                if( ai != -1 ) this.$set(this.imp_activities, ai, activity)
            })

            window.EventBus.$on('imp-activities--delete-activity', (activity) => {
                let ai = window._.findIndex(this.imp_activities, a => a.id == activity.id)

                if( ai != -1 ) this.imp_activities.splice(ai, 1)
            })

            window.EventBus.$on('imp-activities--add-profile', (profile) => {
                this.profiles_ats.push(profile)
            })

            window.EventBus.$on('imp-activities--update-profile', (profile) => {
                let pi = window._.findIndex(this.profiles_ats, p => p.id == profile.id)

                if( pi != -1 ) this.$set(this.profiles_ats, pi, profile)
            })

            window.EventBus.$on('imp-activities--delete-profile', (profile) => {
                let pi = window._.findIndex(this.profiles_ats, p => p.id == profile.id)

                if( pi != -1 ) this.profiles_ats.splice(pi, 1)
            })
        },
        components: {
            CreateProfile,
            UpdateProfile,
            CreateImpactActivity,
            UpdateImpactActivity,
        }
    }
</script>