<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Fechas claves institucionales</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanCreate" @click="addDate" type="primary">Agregar</Button>
                </i-col>
            </Row>
            <br><br>
            <div class="imp-date--container" v-for="date in dates" :key="`imp_date_${date.id}`">
                <div class="imp-date--content">
                    <div style="display: flex; align-items: center;">
                        <Icon type="md-calendar" />
                        <p style="font-size: 0.9em; margin-left: 10px">
                            {{ date.start | moment('DD/MM/YYYY') }}
                            <template v-if="date.end">
                                - {{ date.end | moment('DD/MM/YYYY') }}
                            </template>
                        </p>
                    </div>
                    <h2>{{ date.name }}</h2>
                    <p>{{ date.description }}</p>
                </div>
                <div class="imp-date--actions">
                    <Dropdown v-if="CanUpdate || CanDelete" slot="extra" trigger="click">
                        <a href="javascript:void(0)">
                            <Icon color="grey" size="16" type="md-more"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem  v-if="CanUpdate" @click.native="updateDate(date)">Editar</DropdownItem>
                            <DropdownItem v-if="CanDelete" @click.native="deleteDate(date)">Eliminar</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <Create v-if="CanCreate"/>
            <Update v-if="CanUpdate"/>
        </template>
    </div>
</template>

<script>
    import Create from './components/Create'
    import Update from './components/Update'

    export default {
        data(){
            return {
                is_loading: true,
                dates: [], 
            }
        },
        computed: {
            CanCreate(){
                return this.$can('imp-date-global.create')
            },
            CanUpdate(){
                return this.$can('imp-date-global.update')
            },
            CanDelete(){
                return this.$can('imp-date-global.delete')
            }
        },
        methods: {
            getDates(){
                window.axios.post(this.$root.API_URL + '/imp-dates/get-global')
                .then(response => {
                    if( response.data.success ){
                        this.dates = response.data.dates
                        this.is_loading = false
                    }
                })
            },
            addDate(){
                window.EventBus.$emit('imp-date-grid--create')
            },
            updateDate(date){
                window.EventBus.$emit('imp-date-grid--update', date)
            },
            deleteDate(date){
                this.$Modal.confirm({
                    title: 'Eliminar fecha clave',
                    content: `<p>¿Deseas eliminar esta fecha clave?</p><p>Al hacer clic en <strong>Eliminar</strong> esta acción es irreversible.</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/imp-dates/delete-global/${date.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('imp-date-grid--delete-item', date)
                                this.$Message.success('Fecha clave eliminada correctamente');
                            }
                        })
                    },
                });
            }
        },
        mounted(){
            this.getDates()

            window.EventBus.$on('imp-date-grid--push-item', () => {
                this.is_loading = true
                this.getDates()
            })

            window.EventBus.$on('imp-date-grid--update-item', (date) => {
                let di = window._.findIndex(this.dates, d => {
                    return d.id == date.id
                })

                if( di != -1 ){
                    this.$set(this.dates, di, date)
                }
            })

            window.EventBus.$on('imp-date-grid--delete-item', (date) => {
                let di = window._.findIndex(this.dates, d => {
                    return d.id == date.id
                })

                if( di != -1 ){
                    this.dates.splice(di ,1)
                }
            })
        },
        components: {
            Create,
            Update,
        } 
    }
</script>