<template>
    <!-- <Tabs name="budget" :value="tab" @on-click="switchTab">
        <TabPane label="Disponible" tab="budget" name="available">
            <AvailableGrid :poa="poa" :funders="funders"/>
        </TabPane>
        <TabPane label="Presupuesto" tab="budget" name="initial">
            <BudgetGrid :poa="poa" :program="program" :activities="activities" :funders="funders"/>
        </TabPane>
        <TabPane label="Solicitudes" tab="budget" name="requests">
            <RequestsGrid :poa="poa" :program="program" :activities="activities" :funders="funders"/>
        </TabPane>
        <TabPane label="Total" tab="budget" name="total">
            <TotalGrid :poa="poa" :activities="activities" :funders="funders" :funders_loaded="funders_loaded" />
        </TabPane>
    </Tabs> -->
    <div style="margin: 25px 0 40px 0; border-bottom: 1px solid #ececec; padding-bottom: 9px;">
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/budget/available`">Disponible</router-link>
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/budget/fill`">Presupuesto</router-link>
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/budget/requests`">Solicitudes</router-link>
        <router-link class="poa-tab-link" :to="`${poa_link}/tab/budget/recap`">Total</router-link>
    </div>
</template>

<script>
    export default {
        props: {
            // tab: { required: true, type: String, default: 'poa' },
            poa_link: { required: true, type: String, }
        },
        // methods:{
        //     switchTab(name){
        //         console.log(`${this.poa_link}/tab/${name}`)
        //         this.$router.push({path: `${this.poa_link}/tab/${name} `}).then(r => {
        //             console.log(r)
        //         })
        //     },
        // },
    }
</script>