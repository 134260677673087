<template>
    <Modal class-name="scrollable vertical-center-modal" title="Agregar actividad de impacto" v-model="show" :footer-hide="true" :scrollable="true">
        <LoadingContainer v-if="!data_fetched"/>
        <template v-else>
            <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
                <Row :gutter="16">
                    <i-col span="24">
                        <FormItem label="Actividad de impacto" prop="activity_id">
                            <Select v-model="activity.activity_id" style="width: 100%">
                                <Option v-for="activity in impact_activities" :key="`impact_activity_id_${activity.id}`" :value="activity.id">{{ activity.name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="Actividades" prop="activities">
                            <Select v-model="activity.activities" multiple style="width: 100%">
                                <Option v-for="activity in poa_activities" :key="`poa_activity_id_${activity.id}`" :value="activity.id">{{ activity.name }}</Option>
                            </Select>
                        </FormItem>
                        <!-- <FormItem prop="profiles">
                            <template #label>
                                <p>Perfil de asistentes</p>
                                <p style="font-weight: normal; margin-top: 5px"><small>Usar más de una opción si se requiere</small></p>
                            </template>
                            <Select v-model="activity.profiles" multiple style="width: 100%">
                                <Option v-for="profile in profiles" :key="`profile_id_${profile.id}`" :value="profile.id">{{ profile.name }}</Option>
                            </Select>
                        </FormItem> -->
                        <!-- <Row :gutter="16">
                            <i-col span="24">
                                <p><strong>Número de asistentes / Personas beneficiadas</strong></p>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Mujeres" prop="asist_h">
                                    <InputNumber  :min="0" v-model="activity.asist_m"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Hombres" prop="asist_m">
                                    <InputNumber  :min="0" v-model="activity.asist_h"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Otres" prop="asist_o">
                                    <InputNumber  :min="0" v-model="activity.asist_o"></InputNumber>
                                </FormItem>
                            </i-col>
                        </Row>
                        <Row :gutter="16">
                            <i-col span="24">
                                <p><strong>Rangos de edad</strong></p>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="< 18" prop="a_m_18">
                                    <InputNumber  :min="0" v-model="activity.a_m_18"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="18 - 29" prop="a_18_29">
                                    <InputNumber  :min="0" v-model="activity.a_18_29"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="30 - 49" prop="a_30_49">
                                    <InputNumber  :min="0" v-model="activity.a_30_49"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                 <FormItem class="label-weight" label="50 - 65" prop="a_50_65">
                                    <InputNumber  :min="0" v-model="activity.a_50_65"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                 <FormItem class="label-weight" label="> 65" prop="a_m_65">
                                    <InputNumber  :min="0" v-model="activity.a_m_65"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4"></i-col>
                        </Row>
                        <FormItem label="Estado" prop="state">
                            <Input v-model="activity.state" placeholder="" />
                        </FormItem>
                        <FormItem label="Municipio" prop="city">
                            <Input v-model="activity.city" placeholder="" />
                        </FormItem> -->
                        <FormItem label="En alianza con otra organización o entidad" prop="alliances">
                            <Input v-model="activity.alliances" placeholder="" />
                        </FormItem>
                        <FormItem label="Evidencias" prop="evidences_url">
                            <template #label>
                                <p>Evidencias</p>
                                <p style="font-weight: normal; margin-top: 5px"><small>Acceso a link con fotografías, trípticos, folletos, cartas descriptivas, artículos publicados en periódicos y/o revistas, certificados, reconocimientos, premios otorgados al programa</small></p>
                            </template>
                            <Input v-model="activity.evidences_url" placeholder="" />
                        </FormItem>
                    </i-col>
                    <i-col span="24">
                        <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                    </i-col>
                </Row>
            </Form>
        </template>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                activity: {
                    profiles: [],
                    activity_id: '',
                    activities: [],
                    asist_h: 0,
                    asist_m: 0,
                    asist_o: 0,
                    a_m_18: 0,
                    a_18_29: 0,
                    a_30_49: 0,
                    a_50_65: 0,
                    a_m_65: 0,
                    state: '',
                    city: '',
                    alliances: '',
                    evidences_url: '',
                },
                data_fetched: false,
                impact_activities: [],
                poa_activities: [],
                profiles: [],
                loading: false,
                ruleInline: {
                    activity_id: [
                        { required: true, type: 'integer', message: 'Actividad es requerida', trigger: 'change' },
                    ],
                    activities: [
                        { required: true, type: 'array', min: 1, message: 'Actividades son requeridas', trigger: 'change' },
                    ],
                    alliances: [
                        { required: false, message: 'Alianzas es requerido', trigger: 'change' },
                    ],
                    evidences_url: [
                        { required: true, message: 'Evidencias es requerido', trigger: 'change' },
                    ],
                },
            }
        },
        computed: {
            ActivitiesCreated(){
                let as = []
                window._.each(this.$parent.imp_activities, a => as.push(a.impact_activity_id))
                return as
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/create/${this.$parent.poa.id}`, { ...this.activity })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-bitacora-impact-activity--add-activity', response.data.impact_activity)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            disabledOption(activity_impact_id){
                return this.ActivitiesCreated.includes(activity_impact_id)
            },
            fetchData(){
                window.axios.post(this.$root.API_URL + `/bitacora/impact-activity/get-data/${this.$parent.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.impact_activities = response.data.impact_activities
                        this.poa_activities = response.data.poa_activities
                        this.profiles = response.data.profiles
                        this.data_fetched = true
                    }
                })
            },
            clear(){
                this.activity =  {
                    profiles: [],
                    activity_id: '',
                    activities: [],
                    asist_h: 0,
                    asist_m: 0,
                    asist_o: 0,
                    a_m_18: 0,
                    a_18_29: 0,
                    a_30_49: 0,
                    a_50_65: 0,
                    a_m_65: 0,
                    state: '',
                    city: '',
                    alliances: '',
                    evidences_url: '',
                }
                this.$refs.planForm.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('poa-bitacora-impact-activity--create-popup', () => {
                if( ! this.data_fetched ) this.fetchData()
                this.show = true
            })
        }
    }
</script>

<style lang="scss">
    .ivu-select-dropdown{
        max-width: 488px;
    }

    .ivu-select-item{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces !important;
    }

    .label-weight .ivu-form-item-label{
        font-size: 0.7em !important;
        /* font-weight: normal; */
        margin-bottom: -5px;
    }

    .ivu-form .ivu-form-item-label{
        text-align: left !important;
    }
</style>