<template>
    <div>
        <LoadingContainer v-if="is_loading"/>
        <div v-else class="container top">
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Rubros</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button style="margin-right: 15px" class="display-none-print" type="primary" @click="addHeading">Agregar</Button>
                </i-col>
            </Row>
            <br>
            <template v-if="headings.length == 0">
                <EmptyState icon="md-pricetag" title="" :content="`No hay rubros creados. <br> Haz clic en el botón de abajo para agregar el primer rubro.`" cta_title="Agregar rubro" :cta="() => { addHeading() }" />
            </template>
            <template v-else>
                
                <Collapse class="invisible">
                    <Panel v-for="heading in AvailableHeadings" :key="'budget_list_heading_'+heading.id" :name="'heading_'+heading.id">
                        <div class="result-header--collapse-panel">
                            <h3>{{ heading.name }}</h3>
                        </div>
                        <template #content>
                            <div class="anual-result--item--indicators">
                                <Row :gutter="16">
                                    <Col span="12"  v-for="subheading in heading.subheadings" :key="'budget_list_heading_'+heading.id+'_subheading_'+subheading.id" style="margin-bottom: 16px">
                                        <Card class="subheading-price">
                                            <p slot="title"> {{ subheading.name }} </p>
                                            <Dropdown slot="extra" trigger="click">
                                                <a href="javascript:void(0)">
                                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                                </a>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem @click.native="createSubheadingPrice(subheading)">Nuevo precio</DropdownItem>
                                                    <DropdownItem @click.native="updateSubheading(heading, subheading)">Editar</DropdownItem>
                                                    <DropdownItem @click.native="hideSubheading(heading, subheading)">Ocultar</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>

                                            <Table stripe border :columns="heading.name == 'Honorarios' ? priceColumnsHonorarios : priceColumnsGeneral" :data="filteredPrices(subheading.prices)" no-data-text="No se han agregado precios">
                                                <template slot-scope="{ row }" slot="name">
                                                    <p><small><strong>{{ row.name }}</strong></small></p>
                                                    <p style="margin-bottom: 5px"><small>{{ row.description }}</small></p>
                                                </template>
                                                <template slot-scope="{ row }" slot="price">
                                                    <p><small>{{ row.price | currency }}</small></p>
                                                </template>
                                                <template slot-scope="{ row }" slot="gross_price">
                                                    <p><small>{{ row.gross_price | currency }}</small></p>
                                                </template>
                                                <template slot-scope="{ row }" slot="action">
                                                    <Dropdown slot="extra" trigger="click" placement="left">
                                                        <a href="javascript:void(0)">
                                                            <Icon color="grey" size="16" type="md-more"></Icon>
                                                        </a>
                                                        <DropdownMenu slot="list">
                                                            <DropdownItem @click.native="updateSubheadingPrice(heading.id, row)">Editar</DropdownItem>
                                                            <DropdownItem @click.native="hidePrice(row)">Ocultar</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </template>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div class="anual-result--item--action">
                                <div class="left"></div>
                                <div class="right">
                                    <Button size="small" ghost type="primary" @click="createSubheading(heading)">Agregar Subrubro</Button>
                                    <Button size="small" ghost type="warning" @click="updateHeading(heading)">Editar rubro</Button>
                                    <Button size="small" ghost type="error" @click="hideHeading(heading)">Ocultar rubro</Button>
                                </div>
                            </div>
                        </template>
                    </Panel>
                </Collapse>

            </template>
        </div>

        <CreateHeading/>
        <UpdateHeading/>
        <CreateSubheading/>
        <UpdateSubheading/>
        <CreateSubheadingPrice/>
        <UpdateSubheadingPrice/>
    </div>
</template>

<script>
    import CreateHeading from './components/Create'
    import UpdateHeading from './components/Update'
    import CreateSubheading from './components/Subheadings/Create'
    import UpdateSubheading from './components/Subheadings/Update'
    import CreateSubheadingPrice from './components/Subheadings/Prices/Create'
    import UpdateSubheadingPrice from './components/Subheadings/Prices/Update'

    export default {
        data(){
            return {
                is_loading: true,
                headings: [],
                priceColumnsGeneral: [
                    {
                        title: 'Nombre',
                        slot: 'name',
                        width: 250,
                    },
                    {
                        title: 'Mínimo',
                        slot: 'price',
                    },
                    {
                        title: 'Máximo',
                        slot: 'gross_price',
                    },
                    {
                        title: ' ',
                        slot: 'action',
                        width: 40,
                    }
                ],
                priceColumnsHonorarios: [
                    {
                        title: 'Nombre',
                        slot: 'name',
                        width: 250,
                    },
                    {
                        title: 'Neto',
                        slot: 'price',
                    },
                    {
                        title: 'Bruto',
                        slot: 'gross_price',
                    },
                    {
                        title: ' ',
                        slot: 'action',
                        width: 40,
                    }
                ],
            }
        },
        computed: {
            AvailableHeadings(){
                return window._.filter(this.headings, (h) => {
                    return h.hidden == false
                })
            }
        },
        methods: {
            getHeadings(){
                window.axios.post(this.$root.API_URL + `/budget/headings/get`)
                .then(response => {
                    if(response.data.success){
                        this.headings = response.data.headings
                        this.is_loading = false
                    }
                })
            },
            addHeading(){
                window.EventBus.$emit('budget-headings--create-popup')
            },
            updateHeading(heading){
                window.EventBus.$emit('budget-headings--update-popup', heading)
            },
            hideHeading(heading){
                this.$Modal.confirm({
                    title: 'Ocultar rubro',
                    content: `<p>¿Deseas ocultrar este rubro?</p><p>Al hacer clic en <strong>Ocultar</strong> este rubro ya no estará disponible para nuevos presupuestos..</p>`,
                    okText: 'Ocultar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/headings/update/${heading.id}`, { ...heading, hidden: true })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('budget-headings--update-heading', response.data.heading)
                                this.$Message.success('Rubro ocultado correctamente');
                            }
                        })
                    },
                });
            },
            createSubheading(heading){
                window.EventBus.$emit('budget-headings--create-subheading-popup', heading)
            },
            updateSubheading(heading, subheading){
                window.EventBus.$emit('budget-headings--update-subheading-popup', heading, subheading)
            },
            hideSubheading(heading, subheading){
                this.$Modal.confirm({
                    title: 'Ocultar subrubro',
                    content: `<p>¿Deseas ocultrar este subrubro?</p><p>Al hacer clic en <strong>Ocultar</strong> este subrubro ya no estará disponible para nuevos presupuestos..</p>`,
                    okText: 'Ocultar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/headings/subheading/update/${subheading.id}`, { ...subheading, hidden: true })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('budget-headings--update-heading', response.data.heading, response.data.subheading)
                                this.$Message.success('Rubro ocultado correctamente');
                            }
                        })
                    },
                });
            },
            createSubheadingPrice(subheading){
                window.EventBus.$emit('budget-headings--create-subheading-price-popup', subheading)
            },
            updateSubheadingPrice(heading_id, price){
                window.EventBus.$emit('budget-headings--update-subheading-price-popup', {
                    ...price,
                    heading_id: heading_id,
                })
            },
            hidePrice(price){
                this.$Modal.confirm({
                    title: 'Ocultar precio',
                    content: `<p>¿Deseas ocultrar este precio?</p><p>Al hacer clic en <strong>Ocultar</strong> este precio ya no estará disponible para nuevos presupuestos..</p>`,
                    okText: 'Ocultar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/headings/subheading/update-price/${price.id}`, { ...price, hidden: true })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('budget-headings--update-heading', response.data.heading)
                                this.$Message.success('Precio ocultado correctamente');
                            }
                        })
                    },
                });
            },
            filteredPrices(prices){
                return window._.filter(prices, (p) => {
                    return p.hidden == false
                })
            }
        },
        mounted(){
            if( this.$itsSuperAdmin() || this.$itsAdminTeammate() ) this.getHeadings()
            else this.$router.push('/plan')

            window.EventBus.$on('budget-headings--add-heading', (heading) => {
                this.headings.push({
                    ...heading,
                    hidden: false,
                })
            })

            window.EventBus.$on('budget-headings--update-heading', (heading) => {
                let hi = window._.findIndex(this.headings, (h) => {
                    return h.id == heading.id
                })

                if(hi != -1){
                    this.$set(this.headings, hi, heading)
                }
            })

            window.EventBus.$on('budget-headings--add-subheading', (heading, subheading) => {
                
                let hi = window._.findIndex(this.headings, (h) => {
                    return h.id == heading.id
                })

                if( hi != -1 ){
                    this.headings[hi].subheadings.push(subheading)
                }

            })

            window.EventBus.$on('budget-headings--update-subheading', (heading, subheading) => {

                let hi = window._.findIndex(this.headings, (h) => {
                    return h.id == heading.id
                })

                if( hi != -1 ){
                    let shi = window._.findIndex(heading.subheadings, (s) => {
                        return s.id == subheading.id
                    })

                    if( shi != -1 ){

                        if(subheading.hidden == true){
                            this.headings[hi].subheadings.splice(shi, 1)
                            console.log('subheading spliced')
                        }
                        else {
                            this.$set(this.headings[hi].subheadings, shi, subheading)
                        }

                    }

                }

            })

        },
        components: {
            CreateHeading,
            UpdateHeading,
            CreateSubheading,
            UpdateSubheading,
            CreateSubheadingPrice,
            UpdateSubheadingPrice,
        }
    }
</script>