<template>
    <LoadingContainer v-if="loading"/>
    <div v-else class="container top">
        <h1 class="section--subtitle">Evaluación {{ evaluation.name }}</h1>

        <div style="padding: 25px 0">
            <p style="margin-bottom: 15px; font-weight: bold">Estás evaluando a: </p>
            <div style="display: flex; align-items: center;">
                <Avatar style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-square" />
                <div style="margin-left: 15px">
                    <p style="font-weight: bold">{{ program_evaluation.evaluated.name }}</p>
                    <p style="font-size: 0.9em; color: grey;">
                        Área {{ program_evaluation.evaluated.category == 'prog' ? 'programática' : 'transversal'  }}
                    </p>
                </div>
            </div>
        </div>

        <EvaluationScales :items="status_ev_trans"/>

        <Tabs :value="tab">
            <TabPane label="Competencias" name="competences">
                
                <div class="evaluation--box" v-for="evaluation in program_evaluation.competences" :key="'prog_ev_cmtp_'+evaluation.id">
                    <Row :gutter="50">
                        <i-col :xs="24" :sm="24" :md="12" :lg="12">
                            <p class="competence-name">{{ evaluation.special_competence.name }}</p>
                        </i-col>
                        <i-col :xs="24" :sm="24" :md="12" :lg="12">
                            <div class="evaluation--indicator">
                                <template v-if="program_evaluation.status == 'init'">
                                    <Dropdown placement="bottom-start" trigger="click">
                                        <div class="evaluation--indicator--item">
                                            <div :style="{ 'background-color':  evaluation.status.hex_color }" class="indicator--tag"></div>
                                            <p>{{ evaluation.status.name }}</p>
                                        </div>
                                        <DropdownMenu slot="list">
                                            <DropdownItem @click.native="updateEvIndicator(evaluation, status)" v-for="status in status_ev_trans" :key="'res_ev_ind_upt_succ_'+status.id" >
                                                <div class="indicator--short">
                                                    <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                                    {{ status.name }}
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </template>
                                <template v-else>
                                    <div class="evaluation--indicator--item">
                                        <div :style="{ 'background-color': evaluation.status.hex_color }" class="indicator--tag"></div>
                                        <p>{{ evaluation.status.name }}</p>
                                    </div>
                                </template>
                            </div>
                            <template v-if="program_evaluation.status == 'init'">
                                <Input @on-blur="updateEvComment(evaluation)" v-model="evaluation.comment" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                            </template>
                            <template v-else>
                                <div style="padding: 10px 0">
                                    <p style="white-space: break-spaces;" v-html="evaluation.comment"></p>
                                </div>
                            </template>
                        </i-col>
                    </Row>
                </div>

            </TabPane>
            <TabPane label="Recomendaciones" name="evaluation-results">
                
                <br>
                <Row>
                    <h2>Recomendaciones y/o comentarios para mejorar el desempeño del área</h2>
                    <i-col :xs="24" :sm="24" :md="12" :lg="12">
                        <template v-if="program_evaluation.status == 'init'">
                            <Input @on-blur="updateProgEvComment" v-model="program_evaluation.comments" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                        </template>
                        <template v-else>
                            <div style="padding: 10px 0">
                                <p style="white-space: break-spaces;" v-html="program_evaluation.comments"></p>
                            </div>
                        </template>
                    </i-col>
                </Row>
                <br>
                <Row>
                    <i-col v-if="program_evaluation.status == 'init'" span="12">
                        <br>
                        <Button :disabled="!IsEvaluationCompleted" @click="markEvaluationAsCompleted" type="primary" long size="large">Terminar evaluación</Button>
                    </i-col>
                    <i-col span="12"></i-col>
                </Row>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import EvaluationScales from './../../../EvaluationScales/Legends'

    export default {
        data(){
            return {
                loading: true,
                message: '',
                tab: 'competences',
                evaluation: null,
                program_evaluation: null,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'status_ev_trans',
            ]),
            IsEvaluationCompleted(){
                if( this.program_evaluation.status == 'init' ) return this.checkIfEvaluationInCompleted()
                else return true
            },
        },
        methods: {
            getEvaluation(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-area-evaluation/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                       this.evaluation = response.data.evaluation
                       this.program_evaluation = response.data.program_evaluation
                       this.loading = false
                    }
                    else {
                        this.message = response.data.type
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            updateEvIndicator(evaluation, status){
                window.axios.post(this.$root.API_URL + `/evaluation/update-evarea-status/${evaluation.id}`, { status_id: status.id })
                .then(response => {
                    if( response.data.success ){
                        let evi = window._.findIndex(this.program_evaluation.competences, c => c.id == evaluation.id)
                        if( evi != -1 ){
                            this.$set(this.program_evaluation.competences, evi, { ...evaluation, status: status, status_id: status.id  })
                            this.$Message.success('Desempeño actualizado correctamente');
                        }
                    }
                })
            },
            updateEvComment(evaluation){
                if( evaluation.comment != null ){
                    window.axios.post(this.$root.API_URL + `/evaluation/update-evara-field/${evaluation.id}`, { comment: evaluation.comment })
                    .then(response => {
                        if( response.data.success ) this.$Message.success('Desempeño actualizado correctamente');
                    })
                }
            },
            updateProgEvComment(){
                if( this.program_evaluation.comments != null ){
                    window.axios.post(this.$root.API_URL + `/evaluation/update-evarea-comment/${this.program_evaluation.id}`, { comment: this.program_evaluation.comments })
                    .then(response => {
                        if( response.data.success ) this.$Message.success('Desempeño actualizado correctamente');
                    })
                }
            },
            checkIfEvaluationInCompleted(){
                let a = []
                window._.each(this.program_evaluation.competences, e => {
                    if( e.status_id != 7 && e.comment != '' && e.comment != null ) a.push(true)
                    else a.push(false)
                })

                if( this.program_evaluation.comments != null && this.program_evaluation.comments != '' ) a.push(true)
                else a.push(false)

                return ! a.includes(false)
            },
            markEvaluationAsCompleted(){
                this.$Modal.confirm({
                    title: 'Terminar evaluación',
                    content: `¿Estas segura (o/e) que deseas concluir esta evaluación? Una vez activado este botón ya no podrás hacer cambios.`,
                    okText: 'Terminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/evaluation/mark-evarea-as-finished/${this.program_evaluation.id}`)
                        .then(response => {
                            this.program_evaluation.status = 'completed'
                            if( response.data.success ) this.$Message.success('Desempeño actualizado correctamente');
                        })
                    },
                });
            }
        },
        mounted(){
            if( this.user.role_name != 'Coordinación' ) this.$router.replace('/plan')
            this.getEvaluation()
        },
        components: {
            EvaluationScales,
        }
    }
</script>