<template>
    <div class="program-members--item" :class="{ 'light' : light }">
        <div v-if="show_actions && light == false" class="program-members--actions">
            <Dropdown v-if="CandDeleteProgramMembers" trigger="click" placement="left">
                <a href="javascript:void(0)">
                    <Icon color="grey" size="16" type="md-more"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem v-if="CandDeleteProgramMembers" @click.native="deleteMember">Eliminar</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
        <div class="program-members--item--avatar">
            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
        </div>
        <div class="program-members--item--info">
            <Tag v-if="show_actions" :color=" type == 'coord' ? 'primary' : 'default' ">
                {{ type == 'coord' ? 'Coordinación' : 'Integrante' }}
            </Tag>
            <h4>{{ user.name }}</h4>
            <p>{{ user.email }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            user: { required: true, type: [Object, Array] },
            light: { required: false, type: Boolean, default: false },
            program: { required: true },
            member_id: { required: true },
            type: { required: true, type: String, },
            show_actions: { required: false, type: Boolean, default: false },
        },
        computed: {
            CandDeleteProgramMembers(){
                return this.$can('programs.members.delete')
            },
        },
        methods: {
            deleteMember(){
                this.$Modal.confirm({
                    title: 'Eliminar Integrante',
                    content: `<p>¿Deseas eliminar al integrante ${this.user.name}?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/programs/delete-member/${this.program.id}`, { id: this.member_id })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-details--delete-member', this.member_id)
                                this.$Message.success('Integrante eliminado correctamente');
                            }
                        })
                    },
                });
            }
        }
    }
</script>