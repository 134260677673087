<template>
    <Modal class-name="scrollable vertical-center-modal" title="Actualizar perfil de asistente" v-model="show" :footer-hide="true" :scrollable="true">
        <LoadingContainer v-if="!data_fetched"/>
        <template v-else>
            <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
                <Row :gutter="16">
                    <i-col span="24">
                        <FormItem prop="profiles">
                            <template #label>
                                <p>Perfil de asistentes</p>
                                <p style="font-weight: normal; margin-top: 5px"><small>Usar más de una opción si se requiere</small></p>
                            </template>
                            <Select v-model="activity.profiles" style="width: 100%">
                                <Option v-for="profile in profiles" :key="`profile_id_${profile.id}`" :value="profile.id">{{ profile.name }}</Option>
                            </Select>
                        </FormItem>
                        <Row :gutter="16">
                            <i-col span="24">
                                <p><strong>Número de asistentes / Personas beneficiadas</strong></p>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Mujeres" prop="asist_h">
                                    <InputNumber  :min="0" v-model="activity.asist_m"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Hombres" prop="asist_m">
                                    <InputNumber  :min="0" v-model="activity.asist_h"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Otres" prop="asist_o">
                                    <InputNumber  :min="0" v-model="activity.asist_o"></InputNumber>
                                </FormItem>
                            </i-col>
                        </Row>
                        <Row :gutter="16">
                            <i-col span="24">
                                <p><strong>Pertenencia étnica</strong></p>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Indígena" prop="e_indig">
                                    <template #label>
                                        <p>Indígena</p>
                                    </template>
                                    <InputNumber  :min="0" v-model="activity.e_indig"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="7">
                                <FormItem class="label-weight" label="" prop="e_afro">
                                    <template #label>
                                        <p style="line-height: 1; font-size: 0.7em; display: block; margin-top: 2px">Afromexicanas/Afrodescendientes</p>
                                    </template>
                                    <InputNumber style="width: 100%"  :min="0" v-model="activity.e_afro"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="Otro" prop="e_otro">
                                    <template #label>
                                        <p>Otro</p>
                                    </template>
                                    <InputNumber  :min="0" v-model="activity.e_otro"></InputNumber>
                                </FormItem>
                            </i-col>
                        </Row>
                        <Row :gutter="16">
                            <i-col span="24">
                                <p><strong>Rangos de edad</strong></p>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="< 18" prop="a_m_18">
                                    <InputNumber  :min="0" v-model="activity.a_m_18"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="18 - 29" prop="a_18_29">
                                    <InputNumber  :min="0" v-model="activity.a_18_29"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem class="label-weight" label="30 - 49" prop="a_30_49">
                                    <InputNumber  :min="0" v-model="activity.a_30_49"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                 <FormItem class="label-weight" label="50 - 65" prop="a_50_65">
                                    <InputNumber  :min="0" v-model="activity.a_50_65"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                 <FormItem class="label-weight" label="> 65" prop="a_m_65">
                                    <InputNumber  :min="0" v-model="activity.a_m_65"></InputNumber>
                                </FormItem>
                            </i-col>
                            <i-col span="4"></i-col>
                        </Row>
                        <FormItem label="Estado" prop="state">
                            <Select v-model="activity.state" filterable>
                                <Option v-for="estado in estados" :value="estado.nombre" :key="estado.clave">{{ estado.nombre | capitalize }}</Option>
                            </Select>
                            <!-- <Input v-model="activity.state" placeholder="" /> -->
                        </FormItem>
                        <FormItem label="Municipio" prop="city">
                            <Select v-model="activity.city" filterable>
                                <Option v-for="(municipio, i) in MunicipiosPorEstado" :value="municipio" :key="`municipio_${i}`">{{ municipio | capitalize }}</Option>
                            </Select>
                            <!-- <Input v-model="activity.city" placeholder="" /> -->
                        </FormItem>
                    </i-col>
                     <i-col span="24">
                         <Checkbox v-model="activity.skip_from_count">No contabilizar en el resumen general</Checkbox>
                     </i-col>
                    <i-col span="24">
                        <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Actualizar</Button>
                    </i-col>
                </Row>
            </Form>
        </template>
    </Modal>
</template>

<script>
    import estadosMex from './../../../../../../../services/estados.json'
    import municipiosMex from './../../../../../../../services/municipios.json'

    export default {
        data(){
            return {
                show: false,
                imp_activity: null,
                activity: {
                    profiles: [],
                    activity_id: '',
                    activities: [],
                    skip_from_count: false,
                    asist_h: 0,
                    asist_m: 0,
                    asist_o: 0,
                    a_m_18: 0,
                    a_18_29: 0,
                    a_30_49: 0,
                    a_50_65: 0,
                    a_m_65: 0,
                    state: '',
                    city: '',
                    alliances: '',
                    evidences_url: '',
                },
                estados: [],
                municipios: [],
                data_fetched: false,
                profiles: [],
                loading: false,
                ruleInline: {
                    profiles: [
                        { required: true, type: 'integer', message: 'Perfiles son requeridos', trigger: 'change' },
                    ],
                    asist_h: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    asist_m: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    asist_o: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_m_18: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_18_29: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_30_49: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_50_65: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_m_65: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    state: [
                        { required: true, message: 'Estado es requerido', trigger: 'change' },
                    ],
                    city: [
                        { required: true, message: 'Municipio es requerido', trigger: 'change' },
                    ],
                },
            }
        },
        computed: {
            MunicipiosPorEstado(){
                if( this.activity.state == '' ) return []
                else return this.municipios[this.activity.state] || []
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/update-profile/${this.activity.id}`, { ...this.activity })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-bitacora-impact-activity--update-profile', this.imp_activity, response.data.profile)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            disabledOption(activity_impact_id){
                return this.ActivitiesCreated.includes(activity_impact_id)
            },
            fetchData(){
                window.axios.post(this.$root.API_URL + `/bitacora/impact-activity/get-data/${this.$parent.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        // this.impact_activities = response.data.impact_activities
                        // this.poa_activities = response.data.poa_activities
                        this.profiles = response.data.profiles
                        this.data_fetched = true
                    }
                })
            },
            clear(){
                this.activity =  {
                    profiles: [],
                    activity_id: '',
                    activities: [],
                    asist_h: 0,
                    asist_m: 0,
                    asist_o: 0,
                    a_m_18: 0,
                    a_18_29: 0,
                    a_30_49: 0,
                    a_50_65: 0,
                    a_m_65: 0,
                    state: '',
                    city: '',
                    alliances: '',
                    evidences_url: '',
                }
                this.$refs.planForm.resetFields()
            }
        },
        mounted(){
            this.estados = estadosMex 
            this.municipios = municipiosMex

            window.EventBus.$on('poa-bitacora-impact-activity--update-profile-popup', (imp_activity, activity) => {
                if( ! this.data_fetched ) this.fetchData()
                this.imp_activity = imp_activity
                this.activity = { ...activity }
                this.activity.asist_h = parseInt(activity.asist_h)
                this.activity.asist_m = parseInt(activity.asist_m)
                this.activity.asist_o = parseInt(activity.asist_o)
                this.activity.a_m_18 = parseInt(activity.a_m_18)
                this.activity.a_18_29 = parseInt(activity.a_18_29)
                this.activity.a_30_49 = parseInt(activity.a_30_49)
                this.activity.a_50_65 = parseInt(activity.a_50_65)
                this.activity.a_m_65 = parseInt(activity.a_m_65)
                this.activity.skip_from_count = activity.skip_from_count
                this.activity.profiles = activity.profiles.map(a => a.id)
                this.show = true
            })
        }
    }
</script>

<style lang="scss">
    .ivu-select-dropdown{
        max-width: 488px;
    }

    .ivu-select-item{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces !important;
    }

    .label-weight .ivu-form-item-label{
        font-size: 0.7em !important;
        /* font-weight: normal; */
        margin-bottom: -5px;
    }

    .ivu-form .ivu-form-item-label{
        text-align: left !important;
    }
</style>