<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Actualizar solicitud de transferencia" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="transfer" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Fecha solicitud" prop="date">
                        <DatePicker type="date" placeholder="Seleccionar" v-model="transfer.date" style="width: 100%"/>
                    </FormItem>
                </i-col>
               <i-col span="24">
                    <FormItem label="Importe" prop="amount">
                        <InputNumber :min="1" v-model="transfer.amount" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Tipo" prop="type">
                        <Select v-model="transfer.type" style="width: 100%">
                        <Option value="Transferencia">Transferencia</Option>
                        <Option value="Cheque">Cheque</Option>
                    </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="A favor de" prop="in_favor_of">
                        <Input v-model="transfer.in_favor_of" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Nombre del banco" prop="bank_name">
                        <Input v-model="transfer.bank_name" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Titular de cuenta" prop="bank_holder">
                        <Input v-model="transfer.bank_holder" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Cuenta" prop="bank_account">
                        <Input v-model="transfer.bank_account" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="CLABE" prop="bank_clabe">
                        <Input v-model="transfer.bank_clabe" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Datos para transferencia internacional" prop="international">
                        <Input type="textarea" :rows="4" v-model="transfer.international" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>

                <i-col span="24">
                    <Button size="large" long type="primary" :loading="loading" @click="save">Guardar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                subheading: {
                    budget: {
                        available: 0,
                    }
                },
                transfer: {
                    date: new Date(),
                    amount: 0,
                    in_favor_of: '',
                    bank_holder: '',
                    bank_name: '',
                    bank_account: '',
                    bank_clabe: '',
                    type:'Transferencia',
                },
                activity: null,
                budget: null,
                loading: false,
                ruleInline: {
                    date: [
                        { required: true, type: 'date', message: 'Fecha es requerida', trigger: 'change' },
                    ],
                    amount: [
                        { required: true, type: 'number', message: 'Importe es requerido', trigger: 'change' },
                    ],
                    in_favor_of: [
                        { required: true,  message: 'Información requerida', trigger: 'change' },
                    ],
                    bank_name: [
                        { required: true,  message: 'Banco es requerido', trigger: 'change' },
                    ],
                    bank_holder: [
                        { required: true,  message: 'Información es requerida', trigger: 'change' },
                    ],
                    bank_account: [
                        { required: true,  message: 'Cuenta es requerida', trigger: 'change' },
                    ],
                    bank_clabe: [
                        { required: true,  message: 'CLABE es requerida', trigger: 'change' },
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/update-transfer/${this.transfer.id}`, { ...this.transfer })
                        .then(response => {
                            if(response.data.success){
                               window.EventBus.$emit('poa-budget-requests--update-transfer', this.activity, this.budget, response.data.transfer)
                                this.show = false
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
        },
        mounted(){
            window.EventBus.$on('poa-budget--update-transfer-poup', (activity, budget, transfer) => {
                this.transfer = {
                    ...transfer,
                    amount: parseFloat(transfer.amount),
                }
                this.activity = {
                    ...activity,
                }
                this.budget = {
                    ...budget,
                }
                this.show = true
            })
        },
        components: {
        }
    }
</script>