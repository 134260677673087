<template>
    <Grid/>
</template>

<script>
    import Grid from './../components/Program/POA/components/Bitacora/Recap'

    export default {
        components: {
            Grid,
        }
    }
</script>