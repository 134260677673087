<template>
    <div>
        <!-- Q28 -->
        <div :id="`q_${q28_id}`" class="work-env-report-item">
            <Row justify="center" align="middle">
                <i-col span="24">
                    <h2 style="margin-bottom: 10px">28. ¿Con qué frecuencia tu coordinación realiza las siguientes acciones?</h2>
                    <Button class="display-none-print" size="small" type="primary" @click="download(`q_${q28_id}`)">Descargar</Button>
                </i-col>

                <!-- Q28 -->
                <i-col span="12">
                    <h3>Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q28']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q29 -->
                <i-col span="12">
                    <h3>Se preocupa por tu seguridad y autocuidado</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q29']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q30 -->
                <i-col span="12">
                    <h3>Te apoya en tu formación profesional y acceso a cursos, diplomados, etc.</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q30']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q31 -->
                <i-col span="12">
                    <h3>Te escucha y toma en cuenta tus opiniones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q31']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q32 -->
                <i-col span="12">
                    <h3>Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q32']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q33 -->
                <i-col span="12">
                    <h3>Te acompaña en el desarrollo de tus habilidades estratégicas y en tu liderazgo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q33']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q34 -->
                <i-col span="12">
                    <h3>Genera espacios de discusión colectiva para alinear las estrategias del programa con los objetivos</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q34']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q35 -->
                <i-col span="12">
                    <h3>Propone alternativas útiles para solucionar problemas y retos del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q35']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q36 -->
                <i-col span="12">
                    <h3>Recibe asertivamente tu retroalimentación</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q36']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q37 -->
                <i-col span="12">
                    <h3>Fomenta y valora el trabajo en equipo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q37']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q38 -->
                <i-col span="12">
                    <h3>Fomenta y valora el trabajo en equipo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q38']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q39 -->
                <i-col span="12">
                    <h3>Te comparte información imprescindible y necesaria para realizar tu trabajo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q39']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q40 -->
                <i-col span="12">
                    <h3>Planifica y organiza las actividades del área y asigna plazos de ejecución y entregas razonables</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q40']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q41 -->
                <i-col span="12">
                    <h3>Delega las cargas de trabajo entre los integrantes del equipo de manera equitativa</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q41']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q42 -->
                <i-col span="12">
                    <h3>Se comunica asertivamente</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q42']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- Q43 -->
                <i-col span="12">
                    <h3>Te trata con respeto y dignidad</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q43']['answers'])" :legend="{ position: 'right' }" />
                </i-col>
            </Row>
        </div>

        <!-- Q29 -->
        <div :id="`q_${q30_id}`" class="work-env-report-item">
            <Row justify="center" align="middle">
                <i-col span="24">
                    <h2 style="margin-bottom: 10px">29. ¿Con qué frecuencia las oficiales de tu área realizan las siguientes acciones?</h2>
                    <Button class="display-none-print" size="small" type="primary" @click="download(`q_${q30_id}`)">Descargar</Button>
                </i-col>

                <!-- q59 -->
                <i-col span="12">
                    <h3>Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q59']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q60 -->
                <i-col span="12">
                    <h3>Se preocupa por tu seguridad y autocuidado</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q60']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q61 -->
                <i-col span="12">
                    <h3>Te escucha y toma en cuenta tus opiniones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q61']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q62 -->
                <i-col span="12">
                    <h3>Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q62']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q63 -->
                <i-col span="12">
                    <h3>Participa en los  espacios de discusión colectiva para alinear las estrategias del programa con los objetivos</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q63']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q64 -->
                <i-col span="12">
                    <h3>Propone alternativas útiles para solucionar problemas y retos del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q64']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q65 -->
                <i-col span="12">
                    <h3>Recibe asertivamente tu retroalimentación</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q65']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q66 -->
                <i-col span="12">
                    <h3>Fomenta y valora el trabajo en equipo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q66']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q67 -->
                <i-col span="12">
                    <h3>Te comparte información imprescindible y necesaria para realizar tu trabajo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q67']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q68 -->
                <i-col span="12">
                    <h3>Se comunica asertivamente</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q68']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q69 -->
                <i-col span="12">
                    <h3>Te trata con respeto y dignidad</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q69']['answers'])" :legend="{ position: 'right' }" />
                </i-col>
            </Row>
        </div>

        <!-- Q30 -->
        <div :id="`q_${q29_id}`" class="work-env-report-item">
            <Row justify="center" align="middle">
                <i-col span="24">
                    <h2 style="margin-bottom: 10px">30. ¿Con qué frecuencia el área directiva realiza las siguientes acciones?</h2>
                    <Button class="display-none-print" size="small" type="primary" @click="download(`q_${q29_id}`)">Descargar</Button>
                </i-col>

                <!-- q44 -->
                <i-col span="12">
                    <h3>Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q44']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q45 -->
                <i-col span="12">
                    <h3>Se preocupa por tu seguridad y autocuidado</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q45']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q46 -->
                <i-col span="12">
                    <h3>Te apoya en tu formación profesional y acceso a cursos, diplomados, etc.</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q46']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q47 -->
                <i-col span="12">
                    <h3>Te escucha y toma en cuenta tus opiniones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q47']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q48 -->
                <i-col span="12">
                    <h3>Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q48']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q49 -->
                <i-col span="12">
                    <h3>Te acompaña en el desarrollo de tus habilidades estratégicas y en tu liderazgo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q49']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q50 -->
                <i-col span="12">
                    <h3>Genera espacios de discusión colectiva para alinear las estrategias del programa con los objetivos</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q50']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q51 -->
                <i-col span="12">
                    <h3>Propone alternativas útiles para solucionar problemas y retos del área</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q51']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q52 -->
                <i-col span="12">
                    <h3>Recibe asertivamente tu retroalimentación</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q52']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q53 -->
                <i-col span="12">
                    <h3>Fomenta y valora el trabajo en equipo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q53']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q54 -->
                <i-col span="12">
                    <h3>Te comparte información imprescindible y necesaria para realizar tu trabajo</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q54']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q55 -->
                <i-col span="12">
                    <h3>Planifica y organiza las actividades del área y asigna plazos de ejecución y entregas razonables</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q55']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q56 -->
                <i-col span="12">
                    <h3>Delega las cargas de trabajo entre los integrantes del equipo de manera equitativa</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q56']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q57 -->
                <i-col span="12">
                    <h3>Se comunica asertivamente</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q57']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

                <!-- q58 -->
                <i-col span="12">
                    <h3>Te trata con respeto y dignidad</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObjectForScales(answers_data['q58']['answers'])" :legend="{ position: 'right' }" />
                </i-col>

            </Row>
        </div>
    </div>
</template>

<script>
    import download from '@/mixins/download'
    import SemiCircle from "@/components/Charts/SemiCircle"

    export default {
        mixins: [ download ],
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                q28_id: (Math.random() + 1).toString(36).substring(7),
                q29_id: (Math.random() + 1).toString(36).substring(7),
                q30_id: (Math.random() + 1).toString(36).substring(7),
            }
        },
        methods: {
            buildChartObjectForScales(answers_data){
                let object = [
                    {
                        name: 'Nunca',
                        value: 0,
                        hex_color: '#dc6967',
                    },
                    {
                        name: 'Casi nunca',
                        value: 0,
                        hex_color: '#dcb467',
                    },
                    {
                        name: 'A veces',
                        value: 0,
                        hex_color: '#dcd767',
                    },
                    {
                        name: 'Casi siempre',
                        value: 0,
                        hex_color: '#bedc67',
                    },
                    {
                        name: 'Siempre',
                        value: 0,
                        hex_color: '#7ddc67',
                    }
                ]

                window._.each(answers_data, answer => {
                    let oi = window._.findIndex(object, o => o.name === answer.name)
                    if( oi !== -1 ) object[oi].value = answer.count
                })

                return object
            },
            download(id){
                this.loading_img = true
                this.downloadAsImage(document.getElementById(id), `respuesta-${id}.png`)
            },
        },
        components: {
            SemiCircle,
        }
    }
</script>