<template>
    <div>
        <!-- Q7 -->
        <WorkEnvReportItemHalfPie
            question="7. ¿En tu área de trabajo hay personas que tienen prestaciones y beneficios laborales u otro tipo de concesiones fuera de lo estipulado en el puesto o manual de políticas del ILSB?"
            :answers="answers_data['q7']" />

        <!-- Q8 -->
        <WorkEnvReportItemHalfPie
            question="8. ¿En tu área de trabajo las evaluaciones de tu desempeño laboral dependen de la calidad de trabajo y no de otra cuestión personal, aspecto físico, identidad de género, orientación sexual, situación económica, género, clase social, etc.?" :answers="answers_data['q8']" />

        <!-- Q9 -->
        <WorkEnvReportItemHalfPie question="9. ¿En el ILSB eres libre de vestirte de la forma que deseas sin ser criticada/o y/o juzgada/o? " :answers="answers_data['q9']" />

        <!-- Q10 -->
        <WorkEnvReportItemHalfPie question="10. ¿En el ILSB eres libre de manifestar tu orientación sexual sin sentirte incómoda/o y/o juzgada/o? "
                                  :answers="answers_data['q10']" />

        <!-- Q11 -->
        <WorkEnvReportItemHalfPie question="11. ¿En el ILSB eres libre de manifestar tu identidad de género sin sentirte incómoda/o y/o juzgada/o? "
                                  :answers="answers_data['q11']" />

        <!-- Q12 -->
        <WorkEnvReportItemHalfPie question="12. ¿En el ILSB puedes manifestar tus preferencias políticas sin sentirte incómoda/o y/o juzgada/o? "
                                  :answers="answers_data['q12']" />

        <!-- Q13 -->
        <WorkEnvReportItemHalfPie
            question="13. ¿En el ILSB las personas que se autoadscriben a un pueblo indígena y/o hablan una lengua indígena pueden hacerlo sin sentirse incómodas/os, juzgadas/os o criticadas/os? "
                                  :answers="answers_data['q13']" />

        <!-- Q14 -->
        <WorkEnvReportItemHalfPie
            question="14. ¿En el ILSB las personas que se autoadscriben como afromexicanas pueden hacerlo sin sentirse incómodas/os, juzgadas/os o criticadas/os?  " :answers="answers_data['q14']" />

        <!-- Q15 -->
        <WorkEnvReportItemHalfPie question="15. ¿En el ILSB te has sentido discriminada por una condición de discapacidad o neurodivergencia?" :answers="answers_data['q15']" />

        <!-- Q16 -->
        <WorkEnvReportItemHalfPie question="16. ¿Consideras que en el ILSB se da seguimiento y atención a prácticas discriminatorias?"
                                  :answers="answers_data['q16']" has_yes_no_answers />

        <!-- Q17 -->
        <WorkEnvReportItemHalfPie question="17. ¿En tu área de trabajo consideras que has sido discriminada/o?"
                                  :answers="answers_data['q17']" has_yes_no_answers />
    </div>
</template>

<script>
    //import download from '@/mixins/download'
    import WorkEnvReportItemHalfPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-HalfPie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            WorkEnvReportItemHalfPie,
        }
    }
</script>