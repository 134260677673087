import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        status: [],
        drawer: false,
        program: null,
        is_mobile: false,
        permissions: [],
        notifications_count: 0,
        work_env_survey_active: false,
    },
    mutations: {
        SET_DRAWER: (state, drawer) => {
            state.drawer = drawer
        },
        SET_IS_MOBILE: (state, is) => {
            state.is_mobile = is
        },
        SET_USER: (state, user) => {
            state.user = user
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_PROGRAM: (state, program) => {
            state.program = program
        },
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_NOTIFICATIONS_COUNT: (state, count) => {
            state.notifications_count = count
        },
        SET_WORK_ENV_SURVEY_ACTIVE: (state, active) => {
            state.work_env_survey_active = active
        },
    },
    actions: {
        SET_DRAWER: ({ commit }, drawer) => {
            commit('SET_DRAWER', drawer)
        },
        SET_IS_MOBILE: ({ commit }, is) => {
            commit('SET_IS_MOBILE', is)
        },
        SET_USER: ({ commit }, user) => {
            commit('SET_USER', user)
        },
        SET_PERMISSIONS: ({ commit }, permissions) => {
            commit('SET_PERMISSIONS', permissions)
        },
        SET_PROGRAM: ({ commit }, program) => {
            commit('SET_PROGRAM', program)
        },
        SET_STATUS: ({ commit }, status) => {
            commit('SET_STATUS', status)
        },
        SET_NOTIFICATIONS_COUNT: ({ commit }, count) => {
            commit('SET_NOTIFICATIONS_COUNT', count)
        },
        SET_WORK_ENV_SURVEY_ACTIVE: ({ commit }, active) => {
            commit('SET_WORK_ENV_SURVEY_ACTIVE', active)
        },
    },
    getters: {
        user: state => state.user,
        status: state => state.status,
        status_tdc: state => {
            return window._.filter(state.status, s => s.category == 'TDC')
        },
        status_poa: state => {
            return window._.filter(state.status, s => s.category == 'POA')
        },
        status_des: state => {
            return window._.filter(state.status, s => s.category == 'Desempeño')
        },
        status_comp: state => {
            return window._.filter(state.status, s => s.category == 'Competencias')
        },
        status_ev_trans: state => {
            return window._.filter(state.status, s => s.category == 'Ev-Trans')
        },
        drawer: state => state.drawer,
        program: state => state.program,
        is_mobile: state => state.is_mobile,
        permissions: state => state.permissions,
        notifications_count: state => state.notifications_count,
        work_env_survey_active: state => state.work_env_survey_active,
    }
})
