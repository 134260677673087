<template>
    <div>
        <v-calendar ref="calendar" v-model="value" locale="es" color="primary" @change="updateTitle" :events="Events" @click:event="showEvent">
            <template v-slot:event="{ event }">
                <div
                class="v-current-time">
                    <div style="position: relative;">
                        <span :class="{ 'square' : event.type == 'global', 'tri' : event.type == 'imp_date' }" style="position: absolute; top: 4px; left: 17px; margin-left: -10px; width: 11px; height: 11px; border-radius: 50%;" :style="{ 'background-color': event.color }"></span>
                        <p style="margin-left: 23px">{{ event.name }}</p>
                    </div>
                </div>
            </template>
        </v-calendar>
        <br><br>
        <div style="display:flex; justify-content: flex-end;">
            <div style="position: relative; display: flex; align-items: center; margin-left: 2rem;">
                <span style="width: 11px; height: 11px; border-radius: 50%; background-color: grey;"></span>
                <p style="margin-left: 10px; font-weight: bold; font-size: 0.8em;">Actividades</p>
            </div>
            <div style="position: relative; display: flex; align-items: center; margin-left: 2rem;">
                <span class="square" style="width: 11px; height: 11px; border-radius: 50%; background-color: grey;"></span>
                <p style="margin-left: 10px; font-weight: bold; font-size: 0.8em;">Fechas institucionales</p>
            </div>
            <div style="position: relative; display: flex; align-items: center; margin-left: 2rem;">
                <span class="tri" style="width: 11px; height: 11px; border-radius: 50%; border-bottom-color: grey;"></span>
                <p style="margin-left: 10px; font-weight: bold; font-size: 0.8em;">Fechas claves</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            results: { required: true, type: [Object, Array] },
            imp_dates: { required: true, },
            activities: { required: true, },
        },
        data(){
            return {
                value: new Date(),
            }
        },
        computed: {
            Events(){
                let items = []

                window._.each(this.activities, (a) => {
                    let ri = window._.findIndex(this.results, r => {
                        return r.id == a.result.id
                    })

                    window._.each(a.dates, (d) => {
                        items.push({
                            type: 'activity',
                            color: ri != -1 ? this.results[ri].unique_color : '#ececec',
                            name: a.name,
                            start: d.start,
                            // end: d.end || "",
                            class: a,
                        })

                        if( d.start != d.end ){
                            items.push({
                                type: 'activity',
                                color: ri != -1 ? this.results[ri].unique_color : '#ececec',
                                name: a.name,
                                start: d.end,
                                // end: d.end || "",
                                class: a,
                            })
                        }
                    })
                })

                window._.each(this.imp_dates, (d) => {
                    items.push({
                        type: d.poa_id == null ? 'global' : 'imp_date',
                        color: d.poa_id == null ? '#f16823' : 'transparent',
                        name: d.name,
                        start: d.start,
                        // end: d.end || "",
                        class: d,
                    })

                    if( d.start != d.end && d.end != null ){
                            items.push({
                                type: d.poa_id == null ? 'global' : 'imp_date',
                                color: d.poa_id == null ? '#f16823' : 'transparent',
                                name: d.name,
                                start: d.end,
                                // end: d.end || "",
                                class: d,
                            })
                        }
                })

                return items
            }
        },
        methods: {
            updateTitle(){
                window.EventBus.$emit('activities-calendar-set-title', this.$refs.calendar.title)
            },
            nextMonth() {
                this.$refs.calendar.next()
                // this.updateTitle()
                console.log('next')
            },
            prevMonth() {
                this.$refs.calendar.prev()
                // this.updateTitle()
                console.log('prev')
            },
            showEvent(e){
                // if(e.event.type != 'global'){
                    window.EventBus.$emit('show-activity-details-popup', e.event)
                // }
            }
        },
        activated(){
            this.updateTitle()
        },
        mounted(){
            let self = this
            setTimeout(() => {
                self.updateTitle()
            }, 1000)

            window.EventBus.$on('activities-calendar-next', () => { this.nextMonth() })

            window.EventBus.$on('activities-calendar-prev', () => { this.prevMonth() })

            window.EventBus.$on('activities-calendar-today', () => { this.value = new Date() })

        }
    }
</script>

<style lang="scss">
    .square{
        border-radius: 0 !important;
    }

    .tri {
        top: 6px !important;
        width: 0 !important; 
        height: 0 !important; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid #f03434;
        border-radius: 0 !important;
    }
</style>