<template>
    <p>
        <span v-for="(dif, i) in Diffs" :class="{ 'text-green': dif.added, 'text-red': dif.removed }" :key="'meta_list_'+i">
            {{dif.value}}
        </span>
    </p>
</template>

<script>
    export default {
        props: {
            meta: { required: true },
        },
        computed: {
            Diffs(){
                return window.Diff.diffChars(this.meta.old || '', this.meta.new)
            }
        }
    }
</script>