var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.funder.name))]),_c('br'),_c('Table',{attrs:{"row-key":"uid","show-summary":"","summary-method":_vm.handleSummary,"columns":_vm.columns,"data":_vm.FunderData,"border":""},scopedSlots:_vm._u([{key:"requested",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.requested < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.requested)))])]}},{key:"available",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.available < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.available)))])]}},{key:"deficit",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.deficit < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.deficit)))])]}},{key:"spent",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.spent < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.spent)))])]}},{key:"to_spent",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.to_spent < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.to_spent)))])]}}])}),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }