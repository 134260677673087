<template>
    <Drawer class-name="map-drawer" :closable="is_mobile" placement="left" width="275" :mask="is_mobile" v-model="drawer" @on-close="onClose">
        <div class="logo-container">
            <img @click="$router.push('/')" src="/img/logo-color.svg" alt="">
        </div>

        <div class="drawer-container">

            <!-- <div class="auth">
                <Avatar src="https://lh3.google.com/u/4/ogw/ADGmqu-8uMhlwC0_h7bK7YKhRJEJbVBsE6Q0LmZnlTDF=s83-c-mo" :size="100" />
                <h2>Javier Rodríguez</h2>
                <p>Administrador</p>
            </div> -->
            <div class="top">

                <div v-if="work_env_survey_active" class="drawer-item white" :class="{ 'active' : colorAsActive(['/work-env']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/work-env')">
                            <Icon size="18" type="md-list-box" />
                            <p>Encuesta clima organizacional</p>
                        </div>
                    </div>
                </div>

                <div @click="showProfilePopup" class="drawer-item">
                    <div class="cont">
                        <div class="item-content">
                            <Icon size="18" type="md-person" />
                            <p>Mi Perfil</p>
                        </div>
                        <div class="item-action"></div>
                    </div>
                </div>

                <div class="drawer-item" :class="{ 'active' : colorAsActive(['/plan', '/programa']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/plan')">
                            <Icon size="18" type="md-bookmark" />
                            <p>Plan estratégico</p>
                        </div>
                    </div>
                </div>

                <div class="drawer-item">
                    <div class="cont accordion-btn" @click="$collapse('poa-content')"> 
                        <div class="item-content">
                            <Icon size="18" type="md-bookmarks" />
                            <p>Áreas</p>
                        </div>
                        <div class="item-action">
                            <Button type="text" shape="circle" ghost icon="md-arrow-dropdown"/>
                        </div>
                    </div>
                    <div class="accordion-cont" id="poa-content" aria-expanded="false">
                        <div class="accordion-cont--wrapper">

                            <!-- Área directiva -->
                            <div v-if="CanWatchAreaDir" class="drawer-item--subitem" aria-name="Área directiva"
                                 @click="$router.push(`/area/area-directiva`)">
                                <p>Área directiva</p>
                            </div>

                            <div v-for="program in programs" :key="'draw_prg_'+program.id" class="drawer-item--subitem" :aria-name="program.name" @click="$router.push(`/programa/${program.slug}`)">
                                <p>
                                    {{ program.name }}
                                    <span style="background-color: #f56c6c; font-size: 0.7em; padding: 5px;" v-if="!program.enable" >
                                        Desactivada</span>
                                </p>
                            </div>
                            <div v-if="$can('programs.create')" class="drawer-item--subitem" @click="addProgram">
                                <div style="display: flex; align-items: center;">
                                    <Icon style="margin-left: 40px" size="17" color="white" type="md-add-circle" />
                                    <p style="margin-left: 10px">Nueva área</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="drawer-item">
                    <div class="cont accordion-btn" @click="$collapse('projects-content')"> 
                        <div class="item-content">
                            <Icon size="18" type="md-folder" />
                            <p>Proyectos</p>
                        </div>
                        <div class="item-action">
                            <Button type="text" shape="circle" ghost icon="md-arrow-dropdown"/>
                        </div>
                    </div>
                    <div class="accordion-cont" id="projects-content" aria-expanded="false">
                        <div class="accordion-cont--wrapper">
                            <div v-for="project in projects" :key="'draw_prjj_'+project.id" class="drawer-item--subitem" :aria-name="project.name" @click="$router.push(`/proyecto/${project.slug}`)">
                                <p>{{ project.name }}</p>
                            </div>
                            <div v-if="$can('programs.create')" class="drawer-item--subitem" @click="addProject">
                                <div style="display: flex; align-items: center;">
                                    <Icon style="margin-left: 40px" size="17" color="white" type="md-add-circle" />
                                    <p style="margin-left: 10px">Nuevo proyecto</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="$can('users.view')" class="drawer-item" :class="{ 'active' : colorAsActive(['/usuarios']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/usuarios')">
                            <Icon size="18" type="md-people" />
                            <p>Usuarios</p>
                        </div>
                    </div>
                </div>

                <div v-if="user.role_name != 'Super Admin'" class="drawer-item">
                    <div class="cont accordion-btn" @click="$collapse('evs-content')"> 
                        <div class="item-content">
                            <Icon size="18" type="md-checkmark-circle" />
                            <p>Evaluación</p>
                        </div>
                        <div class="item-action">
                            <Button type="text" shape="circle" ghost icon="md-arrow-dropdown"/>
                        </div>
                    </div>
                    <div class="accordion-cont" id="evs-content" aria-expanded="false">
                        <div class="accordion-cont--wrapper">
                            <div v-if="$can('autoevaluation.view')" class="drawer-item--subitem" aria-name="Autoevaluación" @click="$router.push('/evaluacion/autoevaluacion')" >
                                <p>Autoevaluación</p>
                            </div>
                            <div class="drawer-item--subitem" aria-name="Evaluaciones" @click="$router.push('/evaluacion')" >
                                <p>Evaluación de mi equipo</p>
                            </div>
                            <div class="drawer-item--subitem" aria-name="Mi equipo" @click="$router.push('/evaluaciones/mi-equipo')" >
                                <p>Resultados de evaluación de mi equipo</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="$can('evaluations.view')" class="drawer-item" :class="{ 'active' : colorAsActive(['/evaluaciones']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/evaluaciones')">
                            <Icon size="18" type="md-checkmark-circle" />
                            <p>Evaluaciones</p>
                        </div>
                    </div>
                </div>

                <div v-if="$can('evaluation.scales.view')" class="drawer-item" :class="{ 'active' : colorAsActive(['/escalas-evaluaciones']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/escalas-evaluaciones')">
                            <Icon size="18" type="md-checkmark-circle" />
                            <p>Escalas de evaluaciones</p>
                        </div>
                    </div>
                </div>

                <div v-if="$itsAdminTeammate() || $itsSuperAdmin()" class="drawer-item" :class="{ 'active' : colorAsActive(['/rubros']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/rubros')">
                            <Icon size="18" type="md-pricetag" />
                            <p>Rubros</p>
                        </div>
                    </div>
                </div>

                <div v-if="$can('competences.view')" class="drawer-item" :class="{ 'active' : colorAsActive(['/competencias']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/competencias')">
                            <Icon size="18" type="md-contacts" />
                            <p>Competencias</p>
                        </div>
                    </div>
                </div>

                <div v-if="$can('imp-date-global.view')" class="drawer-item" :class="{ 'active' : colorAsActive(['/imp-dates']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/imp-dates')">
                            <Icon size="18" type="md-calendar" />
                            <p>Fechas claves institucionales</p>
                        </div>
                    </div>
                </div>

                <div v-if="CanWatchImpActivities" class="drawer-item" :class="{ 'active' : colorAsActive(['/impact-activities']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/impact-activities')">
                            <Icon size="18" type="md-star" />
                            <p>Actividades de impacto</p>
                        </div>
                    </div>
                </div>

                <div v-if="$canWatchWorkEnvData()" class="drawer-item" :class="{ 'active' : colorAsActive(['/work-env-surveys']) }">
                    <div class="cont">
                        <div class="item-content" @click="$router.push('/work-env-surveys')">
                            <Icon size="18" type="md-list-box" />
                            <p>Encuestas clima organizacional</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <CreateProgram v-if="$can('programs.create')"/>
        <CreateProject v-if="$can('programs.create')"/>
    </Drawer>
</template>

<script>
    import CreateProgram from './Program/components/CreatePopup'
    import CreateProject from './Project/components/CreatePopup'
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                programs: [],
                projects: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'drawer',
                'is_mobile',
                'work_env_survey_active',
            ]),
            CanWatchImpActivities(){
                if( this.$itsSuperAdmin() ) return true
                // Coordinadoras: DIE y Admin
                else if( this.$itsCoordOf('Administración') || this.$itsCoordOf('Desarrollo Institucional y Evaluación') ) return true
                // Fernanda de DIE 
                else if ( this.user.id == 5 ) return true
                else return false
            },
            CanWatchAreaDir(){
                if( this.$itsSuperAdmin() ) return true
                else if( this.$itsAdminTeammate()  ) return true
                else if( this.$itsDirector() || this.$itsSubdirector() || this.$hasRole('Oficial del área directiva') ) return true
                else return false
            },
            ActiveRoute(){
                return this.$route.fullPath                                             
            },
        },
        methods: {
            onClose(){
                this.$store.dispatch('SET_DRAWER', false)
            },
            colorAsActive(routes){
                return routes.includes(this.ActiveRoute)
            },
            getPrograms(){
                window.axios.post(this.$root.API_URL + '/programs/get-to-navbar')
                .then(response => {
                    if(response.data.success){
                        this.programs = response.data.programs
                    }
                })
            },
            getProjects(){
                window.axios.post(this.$root.API_URL + '/projects/get')
                .then(response => {
                    if(response.data.success){
                        this.projects = response.data.projects
                    }
                })
            },
            addProgram(){
                window.EventBus.$emit('drawer--create-program')
            },
            addProject(){
                window.EventBus.$emit('drawer--create-project')
            },
            showProfilePopup(){
                window.EventBus.$emit('profile--show-popup')
            }
        },
        mounted(){
            let self = this

            this.getPrograms()
            this.getProjects()

            window.$(window).resize(function() {
                
                let width = window.$( window ).width()

                if(width > 1000) {
                    self.$store.dispatch('SET_IS_MOBILE', false)
                    self.$store.dispatch('SET_DRAWER', true)
                }
                else {
                    self.$store.dispatch('SET_IS_MOBILE', true)
                    self.$store.dispatch('SET_DRAWER', false)
                } 

            })

            window.EventBus.$on('drawer--add-program', (program) => {
                this.programs.push(program)
                this.$updateCollapseHeight('poa-content')
            })

            window.EventBus.$on('drawer--add-project', (project) => {
                this.projects.push(project)
                this.$updateCollapseHeight('projects-content')
            })

            window.EventBus.$on('program--update-program', (program) => {
                let pi = window._.findIndex(this.programs, p => p.id === program.id)
                if( pi !== -1 ) this.$set(this.programs, pi, program)
            })
        },
        components: {
            CreateProgram,
            CreateProject,
        }
    }
</script>