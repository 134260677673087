function getAuthUser() {
    return new Promise(resolve => {
        window.dexie.config.where({ name: 'auth' }).first( config =>  resolve(config) )
    })
}

export const auth = (to, from, next) => {
    getAuthUser().then(r => {
        if (r != null) setTimeout(() => { next() }, 200)
        else next({ path: '/' })
    })
}

export const guest = (to, from, next) => {
    getAuthUser().then(r => {
        if (r == null) next()
        else next({ path: '/plan' })
    })
}