<template>
    <Layout>
        <Navbar v-if="user" />
        <ProfilePopup v-if="user" />
        <ResetPasswordPopup v-if="user" />
        <Content :class="{ 'content-drawer-margin': user, 'on': !is_mobile }">
            <div id="app">
                <div v-if="work_env_survey_active" class="work-env-alert" @click="goToWorkEnvSurvey" :class="{ 'd-none':
                $route.path.includes('/work-env')}">
                    <p>La encuesta de clima organizacional ya está disponible</p>
                </div>
                <router-view/>
            </div>
        </Content>
    </Layout>
</template>

<script>
    import Navbar from './components/Navbar'
    import { mapGetters } from 'vuex'
    import ProfilePopup from './components/Profile/Popup'
    import ResetPasswordPopup from './components/Profile/ResetPassword'

    export default {
        computed: {
            ...mapGetters([
                'user',
                'is_mobile',
                'work_env_survey_active',
            ])
        },
        watch:{
            // $route (){
            //     if(this.is_mobile) this.$store.dispatch('SET_DRAWER', false)
            // }
        },
        methods: {
            goToWorkEnvSurvey(){
                this.$router.push('/work-env')
            }
        },
        mounted(){

            let width = window.$( window ).width()

            if(width > 1000) {
                this.$store.dispatch('SET_IS_MOBILE', false)
                this.$store.dispatch('SET_DRAWER', true)
            }
            else{
                this.$store.dispatch('SET_IS_MOBILE', true)
                this.$store.dispatch('SET_DRAWER', false)
            }

        },
        components: {
            Navbar,
            ProfilePopup,
            ResetPasswordPopup,
        }
    }
</script>

<style lang="scss">
    @import './styles/app.scss';
</style>