<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Breadcrumb>
                <BreadcrumbItem to="/evaluaciones">Evaluaciones</BreadcrumbItem>
                <BreadcrumbItem>{{ $route.params.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Evaluaciones {{ $route.params.year }}</h1>
                </i-col>
            </Row>

            <!-- <div v-for="(evaluated, i) in GroupEvaluations" :key="`evaluated_list_user_${evaluated[0].id}_${i}`">
                <router-link :to="`/evaluaciones/${$route.params.year}/${evaluated[0].evaluated.id}`">{{ evaluated[0].evaluated.name }}</router-link>
            </div> -->

            <br><br>

            <Tabs :value="tab">
                <TabPane label="Desempeño" name="users">
                    <br>
                    <h2 style="margin-left: 15px">Área directiva</h2>
                    <div class="program-members--items">
                        <div v-for="(user, i) in groups['directiva']" :key="`user_groups_${i}`" class="program-members--item click" :class="{ 'disabled': ! user.has_evaluations }" @click="watchEvaluationDetails(user)">
                            <div class="program-members--item--avatar">
                                <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                            </div>
                            <div class="program-members--item--info">
                                <h4>{{ user.name }}</h4>
                                <p>{{ user.email }}</p>
                                <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-for="program in Programs" :key="`program_list_${program.id}`">
                    <br><br>
                        <h2 style="margin-left: 15px">{{ program.name }}</h2>
                        <div class="program-members--items">
                            <div v-for="(user, i) in groups[program.name]" :key="`user_groups_${i}`" class="program-members--item click" :class="{ 'disabled': ! user.has_evaluations }" @click="watchEvaluationDetails(user)">
                                <div class="program-members--item--avatar">
                                    <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                </div>
                                <div class="program-members--item--info">
                                    <h4>{{ user.name }}</h4>
                                    <p>{{ user.email }}</p>
                                    <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br><br>
                    <h1 class="section--subtitle">Evaluaciones aleatorias {{ $route.params.year }}</h1>
                    <br>
                    <div v-for="(ev, i) in secrets" :key="`secret_evaluation_${i}_${ev.id}`" class="program-members--items center">
                        <div class="program-members--item">
                            <div class="program-members--item--avatar">
                                <Avatar :src="ev.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                            </div>
                            <div class="program-members--item--info">
                                <h4>{{ ev.user.name }}</h4>
                                <p>{{ ev.user.email }}</p>
                                <p style="color: grey; font-size: 0.8em">{{ ev.user.role_name }}</p>
                            </div>
                        </div>
                        <div class="program-members--item--arrow">
                            <Icon size="24" type="md-arrow-round-forward" />
                            <p>Evalua a</p>
                        </div>
                        <div class="program-members--item">
                            <div class="program-members--item--avatar">
                                <Avatar :src="ev.evaluated.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                            </div>
                            <div class="program-members--item--info">
                                <h4>{{ ev.evaluated.name }}</h4>
                                <p>{{ ev.evaluated.email }}</p>
                                <p style="color: grey; font-size: 0.8em">{{ ev.evaluated.role_name }}</p>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane label="Áreas" name="trans" :disabled="$route.params.year === '2021' || $route.params.year === '2022'">
                    <br>
                    <p>Selecciona un área para ver el resultado de su evaluación:</p>
                    <ol>
                        <li v-for="program in Programs" :key="`prog_lis_ev_t_${program.id}`">
                            <router-link :to="`/evaluaciones/area/${$route.params.year}/${program.id}`" style="padding-left: 10px;"> &bull; {{ program.name }}</router-link>
                        </li>
                    </ol>
                    <br><br>
                    <h2>Evaluación de areas transversales a áreas programáticas</h2>
                    <div v-for="(ev, i) in TransToProg" :key="`trnas_to_prog_ev${i}_${ev.id}`" class="program-members--items center">
                        <div class="program-members--item">
                            <div class="program-members--item--info">
                                <h4 style="text-align: center">{{ ev.evaluator.name }}</h4>
                            </div>
                        </div>
                        <div class="program-members--item--arrow">
                            <Icon size="24" type="md-arrow-round-forward" />
                            <p>Evalua a</p>
                        </div>
                        <div class="program-members--item">
                            <div class="program-members--item--info">
                                <h4 style="text-align: center">{{ ev.evaluated.name }}</h4>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <h2>Evaluación de areas programáticas a áreas transversales</h2>
                    <div v-for="(ev, i) in ProgToTrans" :key="`prog_to_trans_ev${i}_${ev.id}`" class="program-members--items center">
                        <div class="program-members--item">
                            <div class="program-members--item--info">
                                <h4 style="text-align: center">{{ ev.evaluator.name }}</h4>
                            </div>
                        </div>
                        <div class="program-members--item--arrow">
                            <Icon size="24" type="md-arrow-round-forward" />
                            <p>Evalua a</p>
                        </div>
                        <div class="program-members--item">
                            <div class="program-members--item--info">
                                <h4 style="text-align: center">{{ ev.evaluated.name }}</h4>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>


            <br><br>

        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                loading: true,
                tab: 'users',
                groups: [],
                secrets: [],
                programs: [],
                evaluations: [],
                programs_evaluations: [],
            }
        },
        computed: {
            Programs(){
                return window._.orderBy(this.programs, ['name', 'DESC'])
            },
            GroupEvaluations(){
                return window._.groupBy(this.evaluations, e => e.evaluates_to_id)
            },
            TransToProg(){
                return window._.filter(this.programs_evaluations, e => e.category == 'trans_to_prog' )
            },
            ProgToTrans(){
                return window._.filter(this.programs_evaluations, e => e.category == 'prog_to_trans' )
            }
        },
        methods: {
            getEvaluations(){
                window.axios.post(this.$root.API_URL + '/evaluation/get-all-evaluations', { year: this.$route.params.year })
                .then(response => {
                    if(response.data.success){
                        this.evaluations = response.data.evaluations
                        this.groups = response.data.groups
                        this.programs = response.data.programs
                        this.secrets = response.data.secrets
                        this.programs_evaluations = response.data.program_evaluations
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            watchEvaluationDetails(user){
                if( user.has_evaluations ) this.$router.push(`/evaluaciones/${this.$route.params.year}/${user.id}`)
            }
        },
        mounted(){
            if( ! this.$can('evaluations.view') ) this.$router.replace('/plan')
            this.getEvaluations()
        }
    }
</script>