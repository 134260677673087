<template>
    <div>

        <h2 class="section--subtitle">Resolución de Conflictos al Interior del ILSB</h2>
        <br>

        <Form style="width: 100%" ref="cat7Form" :model="answers" :rules="ruleInline">

            <FormItem class="two-column"
                      label="31. Con toda honestidad, ¿qué tanto conoces la política de resolución de conflictos del ILSB?"
                      prop="q70">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q70" type="button" button-style="solid">
                        <Radio v-for="(scale, i) in scales" :key="`q70_scales_${i}`" :label="scale"></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <FormItem class="two-column" label="32. ¿Qué tan útil consideras que es la política de resolución de conflictos del ILSB?"
                      prop="q71">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q71" type="button" button-style="solid">
                        <Radio v-for="(scale, i) in scales" :key="`q71_scales_${i}`" :label="scale"></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <FormItem label="33. ¿Tienes alguna propuesta para mejorar la política de resolución de conflicto del ILSB?" prop="q72">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q72">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                    <div v-if=" Q72IsTrue " class="inner-question">
                        <FormItem label="¿Cuál?" prop="q72_reason">
                            <Input style="width: 50%" v-model="answers.q72_reason" type="textarea" :rows="4"
                                   placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </div>
                </div>
            </FormItem>

            <FormItem v-if=" AuthUserIfOfficial " label="34. ¿En la actualidad tienes algún conflicto con alguna persona oficial?"
                      prop="q73">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q73">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <FormItem v-if=" AuthUserIsCoord " label="35. ¿En la actualidad tienes algún conflicto con alguna coordinación?" prop="q74">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q74">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <FormItem v-if=" AuthUserIsCoord " label="36. ¿En la actualidad tienes algún conflicto con Dirección o subdirección? "
                      prop="q75">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q75">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <FormItem
                label="37. ¿Consideras que existen las políticas necesarias en el ILSB para poder abordar cualquier conflicto que te suceda? "
                      prop="q76">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q76">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            const q63OtherValidation = (rule, value, callback) => {
                if( this.Q72IsTrue ){
                    if( value === '' ) return callback(new Error('Esta pregunta es requerida'))
                    else callback()
                }
                else callback()
            }

            return {
                answers: {
                    q70: "",
                    q71: "",
                    q72: "",
                    q72_reason: "",
                    q73: "",
                    q74: "",
                    q75: "",
                    q76: "",
                },
                scales: [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10" ],
                ruleInline: {
                    q70: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q71: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q72: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q72_reason: [ { validator: q63OtherValidation, trigger: 'change' } ],
                    q73: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q74: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q75: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q76: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
            }),
            Q72IsTrue(){
                return this.answers.q72 === "Si"
            },
            AuthUserIsCoord(){
                //return true
                return this.$hasRole('Coordinación')
            },
            AuthUserIfOfficial(){
                //return false
                return this.$hasRole('Oficial') || this.$hasRole('Oficial del área directiva') ||
                    this.$hasRole('Asistente de programa') || this.$hasRole('Mantenimiento')
            }
        },
        methods: {
            goToNext(){
                this.$refs.cat7Form.validate(valid => {
                    console.log(valid)
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q70',
                                answer_type: 'single',
                                answer: this.answers.q70,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q71',
                                answer_type: 'single',
                                answer: this.answers.q71,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q72',
                                answer_type: 'single',
                                answer: this.answers.q72,
                                answers: null,
                                other_option: null,
                                reason_option: this.answers.q72_reason,
                            },
                            {
                                code: 'q73',
                                answer_type: 'single',
                                answer: this.answers.q73,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q74',
                                answer_type: 'single',
                                answer: this.answers.q74,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q75',
                                answer_type: 'single',
                                answer: this.answers.q75,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q76',
                                answer_type: 'single',
                                answer: this.answers.q76,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q65' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>