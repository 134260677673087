<template>
    <div>
        <Row :gutter="24">
            <template v-if="poas.length == 0">
                <EmptyState icon="md-albums" title="" :show_cta="$can('poa.create')" cta_title="Agregar año" :cta="() => { addPOA() }">
                    <template v-if="$can('poa.create')">
                        <p>{{program.name}} no tiene POAs creados. <br> Haz clic en el botón de abajo para agregar el primer POA de {{ type == 'program' ? 'esta área.' : 'este proyeto.' }}</p>
                    </template>
                </EmptyState>
            </template>
            <template v-else>
                <i-col v-for="poa in poas" :key="'poas_t_'+poa.id" :xs="24" :sm="24" :md="12" :lg="12" style="margin-bottom: 24px">
                    <Card style="width: 100%">
                        <Dropdown v-if="$can('poa.delete')" trigger="click" slot="extra">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="$can('poa.delete')" @click.native="deletePOA(poa)">Eliminar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <SemiCircle @click.native="goToPOADetails(poa)" :data="ChartData(poa)" :legend="{ position: 'right' }" :title="{
                            text: poa.year, fontSize: 16, paddingTop: 7 }"/>
                    </Card>
                </i-col>
                <i-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 24, offset: 0 }" :md="{ span: 6, offset: 18 }" :lg="{ span: 6, offset: 18 }" style="margin-bottom: 24px">
                    <Button v-if="$can('poa.create')" @click="addPOA" long ghost type="primary">Agregar año</Button>
                </i-col>
            </template>
        </Row>
        
        <CreatePOA v-if="$can('poa.create')"/>
    </div>
</template>

<script>
    import CreatePOA from './CreatePOAPopup'
    import SemiCircle from './../../Charts/SemiCircle'
    import chartMixin from './../../../mixins/chart'

    export default {
        mixins: [ chartMixin ],
        props: {
            poas: { required: true, type: [Object, Array] },
            program: { required: true, type: [Object, Array] },
            type: { required: false, type: String, default: 'program' }
        },
        methods: {
            EmptyStateContent(){
                if( this.$can('poa.create') ){
                    return `${this.program.name} no tiene POAs creados. <br> Haz clic en el botón de abajo para agregar el primer POA de esta área.`
                }
                else {
                    return `${this.program.name} no tiene POAs creados.`
                }
            },
            addPOA(){
                window.EventBus.$emit('program-details--add-poa', this.type)
            },
            ChartData(poa){
                return this.getResultsChartData(this.ResultsWithFinalEvaluation(poa.results), 'final_evaluation')
            },
            ResultsWithFinalEvaluation(results){
                let rs = []

                window._.each(results, (r) => {
                    
                    // Set final result evaluation
                     let ei = window._.findIndex(r.evaluations, (e) => {
                        return e.type == 'final'
                    })

                    if(ei != -1) r.final_evaluation = r.evaluations[ei]

                    window._.each(r.indicators, (ind) => {

                        // Set final indicator evaluation
                        let ii = window._.findIndex(ind.evaluations, (i) => {
                            return i.type == 'final'
                        })
    
                        if(ii != -1) ind.final_evaluation = ind.evaluations[ii]

                    })

                    rs.push(r)
                })

                return rs
            },
            deletePOA(poa){
                this.$Modal.confirm({
                    title: 'Eliminar POA',
                    content: `<p>¿Deseas ocultrar este POA?</p><p>Al hacer clic en <strong>Eliminar</strong> se eliminará por completo toda la información relacionada a este POA.</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/poas/delete/${poa.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('program-details--remove-poa', poa)
                            }
                        })
                    },
                });
            },
            goToPOADetails(poa){
                if( this.type === 'program' ) this.$router.push(`/programa/${this.program.slug}/${poa.year}/tab/poa`)
                else if( this.type === 'project' ) this.$router.push(`/proyecto/${this.program.slug}/${poa.year}/tab/poa`)
                else this.$router.push(`/area/${this.program.slug}/${poa.year}/tab/poa`)
            }
        },
        components: {
            CreatePOA,
            SemiCircle,
        }
    }
</script>