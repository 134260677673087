<template>
    <Modal class-name="scrollable vertical-center-modal" title="" v-model="show" :footer-hide="true" :scrollable="true">
        <br>
        <h3 style="text-align: center">Tabulador de precios de {{ subheading.name }}</h3>
        <br>
        <template v-if="loading">
            <LoadingContainer/>
        </template>
        <template v-else>
            <Table stripe border :columns="heading.name == 'Honorarios' ? priceColumnsHonorarios : priceColumnsGeneral" :data="prices" no-data-text="No se han agregado precios">
                <template slot-scope="{ row }" slot="name">
                    <p><small><strong>{{ row.name }}</strong></small></p>
                    <p style="margin-bottom: 5px"><small>{{ row.description }}</small></p>
                </template>
                <template slot-scope="{ row }" slot="price">
                    <p><small>{{ row.price | currency }}</small></p>
                </template>
                <template slot-scope="{ row }" slot="gross_price">
                    <p><small>{{ row.gross_price | currency }}</small></p>
                </template>
            </Table>
        </template>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                subheading: {
                    name: "",
                    prices: [],
                },
                heading: null,
                priceColumnsGeneral: [
                    {
                        title: 'Nombre',
                        slot: 'name',
                        width: 250,
                    },
                    {
                        title: 'Mínimo',
                        slot: 'price',
                    },
                    {
                        title: 'Máximo',
                        slot: 'gross_price',
                    },
                ],
                priceColumnsHonorarios: [
                    {
                        title: 'Nombre',
                        slot: 'name',
                        width: 250,
                    },
                    {
                        title: 'Neto',
                        slot: 'price',
                    },
                    {
                        title: 'Bruto',
                        slot: 'gross_price',
                    },
                ],
                prices: [],
                loading: true,
            }
        },
        methods: {
            filteredPrices(prices){
                return window._.filter(prices, (p) => {
                    return p.hidden == false
                })
            },
            fetchPrices(){
                window.axios.post(this.$root.API_URL + `/budget/headings/subheading/get-prices/${this.subheading.id}`)
                .then(response => {
                    if(response.data.success){
                        this.prices = response.data.prices
                        this.loading = false
                    }
                })
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--watch-subheading-prices', (heading, subheading) => {
                this.prices = []
                this.heading = heading
                this.loading = true
                this.show = true
                this.subheading = {
                    ...subheading
                }
                this.fetchPrices()
            })
        },
        components: {
        }
    }
</script>