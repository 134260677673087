<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Evaluaciones</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="CanCreateEvaluation" style="margin-right: 15px" class="display-none-print" @click="addHandler" type="primary">Agregar</Button>
                </i-col>
            </Row>
            <Create v-if="CanCreateEvaluation" />

            <br>
            <Row :gutter="16" type="flex" align="middle">
                <i-col  v-for="evaluation in evaluations" :key="'evv_'+evaluation.id" span="6">
                    <Card style="width:100%">
                        <router-link :to="`/evaluaciones/${evaluation.name}`">Evaluación {{ evaluation.name }}</router-link>
                    </Card>
                </i-col>
            </Row>
            <!-- <p v-for="evaluation in evaluations" :key="'evv_'+evaluation.id">{{ evaluation.name }}</p> -->
        </div>
    </div>
</template>

<script>
    import Create from './components/Create'

    export default {
        data(){
            return {
                loading: true,
                evaluations: [],
            }
        },
        computed: {
            CanCreateEvaluation(){
                return this.$can('evaluations.create')
            }
        },
        methods: {
            getEvaluations(){
                window.axios.post(this.$root.API_URL + '/evaluation/usr/get')
                .then(response => {
                    if(response.data.success){
                        this.evaluations = response.data.evaluations
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            addHandler(){
                window.EventBus.$emit('evaluations--create-popup', true)
            }
        },
        mounted(){
            if( ! this.$can('evaluations.view') ) this.$router.replace('/plan')
            this.getEvaluations()

            window.EventBus.$on('evaluations--add-evaluation', (evaluation) => {
                this.evaluations.push(evaluation)
            })
        },
        components: {
            Create,
        }    
    }
</script>