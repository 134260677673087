<template>
    <Modal class-name="vertical-center-modal" title="" v-model="show" :footer-hide="true">
        <div class="profile--container">
            <div class="profile--img" :style="{ 'background': user ? `url(${user.profile_pic})` : '#ececec' }">
                <input id="uploader" type="file" ref="picture" @change="uploadProfilePicture">
                <div class="profile--img--icon">
                    <Icon size="35" color="white" type="md-create" />
                </div>
            </div>
        </div>

        <Form style="width: 100%" ref="planForm" :model="user">
            <FormItem label="Nombre" prop="name">
                <Input v-model="user.name" size="large" disabled style="width: 100%" />
            </FormItem>

            <FormItem label="Correo electrónico" prop="email">
                <Input v-model="user.email" size="large" disabled style="width: 100%" />
            </FormItem>

            <FormItem label="Puesto">
                <Input v-model="user.role_name" size="large" disabled style="width: 100%" />
            </FormItem>
        </Form>

        <Form style="width: 100%" ref="form" :model="form" :rules="ruleInline">
            <Row :gutter="20">
                <i-col span="12">
                    <FormItem label="Nueva contraseña" prop="password">
                        <Input type="password" v-model="form.password" size="large" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <FormItem label="Confirma nueva contraseña" prop="confirm_password">
                        <Input type="password" v-model="form.confirm_password" size="large" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
            </Row>
        </Form>
        
        <Row :gutter="20">
            <i-col span="12">
                <Button style="margin-top: 25px" size="large" type="default" long @click="updatePassword">Cambiar contraseña</Button>
            </i-col>
            <i-col span="12">
                <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="signOut">Cerrar sesión</Button>
            </i-col>
        </Row>

    </Modal>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                show: false,
                loading: false,
                loading_password: false,
                form: {
                    password: '',
                    confirm_password: '',
                },
                ruleInline: {
                    password: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    confirm_password: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ])
        },
        methods: {
            uploadProfilePicture(){
                let file = this.$refs.picture.files[0]
                let bag = new FormData()
                bag.append('file', file)

                window.axios.post(this.$root.API_URL + '/user/update-profile', bag, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                } )
                .then(response => {
                    if( response.data.success ){
                        let pic_path = response.data.user.profile_pic
                         this.$store.dispatch("SET_USER", {
                             ...this.user,
                             profile_pic: pic_path,
                         })
                    }
                })
            },
            updatePassword(){
                this.$refs.form.validate(valid => {

                    if(valid){
                        if( this.form.password === this.form.confirm_password ){
                            this.loading_password = true
                            window.axios.post(this.$root.API_URL + '/user/update-password', { password: this.form.password })
                            .then(response => {
                                if( response.data.success ){
                                    this.loading_password = false
                                    this.form.password = ''
                                    this.form.confirm_password = ''
                                    this.$refs['form'].resetFields();
                                    this.$Message.success('Contraseña actualizada correctamente');
                                }
                            })
                        }
                        else {
                            this.$Message.error('Las contraseñas no coinciden');
                            this.loading_password = false
                        }
                    }
                })
            },
            signOut(){
                this.loading = false
                window.axios.post(this.$root.API_URL + '/auth/sign-out')
                .then(response => {
                    if(response.data.success){
                        window.dexie.config.where({ name: 'auth' }).delete().then(() => {
                            this.show = false
                            this.loading = false
                            this.$store.dispatch("SET_USER", null)
                            this.$store.dispatch("SET_PERMISSIONS", [])
                            this.$router.push("/")
                            window.location.reload()
                        })
                    }
                })
                .catch(() => {
                    this.loading = false
                })
            }
        },
        mounted(){
            window.EventBus.$on('profile--show-popup', () => {
                this.show = true
            })
        }
    }
</script>