<template>
    <div>
        <!-- Q31 -->
        <WorkEnvReportItemBars question="31. Con toda honestidad, ¿qué tanto conoces la política de resolución de conflictos del ILSB?"
                               :answers="buildChartObjectForScalesNumbers(answers_data['q70']['answers'])" />

        <!-- Q32 -->
        <WorkEnvReportItemBars question="32. ¿Qué tan útil consideras que es la política de resolución de conflictos del ILSB?"
                               :answers="buildChartObjectForScalesNumbers(answers_data['q71']['answers'])" />

        <!-- Q33 -->
        <WorkEnvReportItemHalfPie question="33. ¿Tienes alguna propuesta para mejorar la política de resolución de conflicto del ILSB?"
                                  :answers="answers_data['q72']" has_yes_no_answers :show_negative_answers="false" />

        <!-- Q34 -->
        <WorkEnvReportItemHalfPie question="34. ¿En la actualidad tienes algún conflicto con alguna persona oficial?"
                                  :answers="answers_data['q73']" />

        <!-- Q35 -->
        <WorkEnvReportItemHalfPie question="35. ¿En la actualidad tienes algún conflicto con alguna coordinación?"
                                  :answers="answers_data['q74']" />

        <!-- Q36 -->
        <WorkEnvReportItemHalfPie question="36. ¿En la actualidad tienes algún conflicto con Dirección o subdirección?"
                                  :answers="answers_data['q75']" />
    </div>
</template>

<script>
    import WorkEnvReportItemBars from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-Bars"
    import WorkEnvReportItemHalfPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-HalfPie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObjectForScalesNumbers(answers_data){
                let object = [
                    {
                        name: '1',
                        value: 0,
                    },
                    {
                        name: '2',
                        value: 0,
                    },
                    {
                        name: '3',
                        value: 0,
                    },
                    {
                        name: '4',
                        value: 0,
                    },
                    {
                        name: '5',
                        value: 0,
                    },
                    {
                        name: '6',
                        value: 0,
                    },
                    {
                        name: '7',
                        value: 0,
                    },
                    {
                        name: '8',
                        value: 0,
                    },
                    {
                        name: '9',
                        value: 0,
                    },
                    {
                        name: '10',
                        value: 0,
                    }
                ]

                window._.each(answers_data, answer => {
                    let oi = window._.findIndex(object, o => Number.parseFloat(o.name) === Number.parseFloat(answer.name))
                    if( oi !== -1 ) object[oi].value = answer.count
                })

                return object
            },
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            WorkEnvReportItemHalfPie,
            WorkEnvReportItemBars,
        }
    }
</script>