<template>
    <div>
        <!-- Q22 -->
        <WorkEnvReportItemPie question="22. ¿Has vivido alguna de estas situaciones en el ILSB?" :answers="answers_data['q22']" />

        <!-- Q23 -->
        <WorkEnvReportItemPie question="23. ¿En el ILSB has vivido alguna de estas situaciones?" :answers="answers_data['q23']" />
    </div>
</template>

<script>
    //import download from '@/mixins/download'
    import WorkEnvReportItemPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-Pie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            WorkEnvReportItemPie,
        }
    }
</script>