<template>
    <div>

        <h2 class="section--subtitle">Espacio laboral libre de discriminación </h2>
        <br>

        <Form style="width: 100%" ref="cat2Form" :model="answers" :rules="ruleInline">

            <!-- Q7 -->
            <FormItem
                label="7. ¿En tu área de trabajo hay personas que tienen prestaciones y beneficios laborales u otro tipo de concesiones fuera de lo estipulado en el puesto o manual de políticas del ILSB? " prop="q7">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q7">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                    <!--<div class="inner-question">
                        <FormItem label="¿Por qué?" prop="q7_reason">
                            <Input style="width: 50%" v-model="answers.q7_reason" type="textarea" :rows="4"
                                   placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </div>-->
                </div>
            </FormItem>

            <!-- Q8 -->
            <FormItem
                label="8. ¿En tu área de trabajo las evaluaciones de tu desempeño laboral dependen de la calidad de trabajo y no de otra cuestión personal, aspecto físico, identidad de género, orientación sexual, situación económica, género, clase social, etc.? " prop="q8">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q8">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q9 -->
            <FormItem
                label="9. ¿En el ILSB eres libre de vestirte de la forma que deseas sin ser criticada/o y/o juzgada/o? " prop="q9">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q9">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q10 -->
            <FormItem
                label="10. ¿En el ILSB eres libre de manifestar tu orientación sexual sin sentirte incómoda/o y/o juzgada/o? " prop="q10">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q10">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q11 -->
            <FormItem
                label="11. ¿En el ILSB eres libre de manifestar tu identidad de género sin sentirte incómoda/o y/o juzgada/o? "
                prop="q11">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q11">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q12 -->
            <FormItem
                label="12. ¿En el ILSB puedes manifestar tus preferencias políticas sin sentirte incómoda/o y/o juzgada/o? "
                prop="q12">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q12">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q13 -->
            <FormItem
                label="13. ¿En el ILSB las personas que se autoadscriben a un pueblo indígena y/o hablan una lengua indígena pueden hacerlo sin sentirse incómodas/os, juzgadas/os o criticadas/os? "
                prop="q13">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q13">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q14 -->
            <FormItem
                label="14. ¿En el ILSB las personas que se autoadscriben como afromexicanas pueden hacerlo sin sentirse incómodas/os, juzgadas/os o criticadas/os? " prop="q14">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q14">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q15 -->
            <FormItem
                label="15. ¿En el ILSB te has sentido discriminada por una condición de discapacidad o neurodivergencia?" prop="q15">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q15">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q16 -->
            <FormItem label="16. ¿Consideras que en el ILSB se da seguimiento y atención a prácticas discriminatorias?"
                      prop="q16">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q16">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                    <div class="inner-question">
                        <FormItem label="¿Por qué?" prop="q16_reason">
                            <Input style="width: 50%" v-model="answers.q16_reason" type="textarea" :rows="4"
                                   placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </div>
                </div>
            </FormItem>

            <!-- Q17 -->
            <FormItem
                label="17. ¿En tu área de trabajo consideras que has sido discriminada/o? " prop="q16">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q17">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                    <div class="inner-question">
                        <FormItem label="¿Por qué?" prop="q17_reason">
                            <Input style="width: 50%" v-model="answers.q17_reason" type="textarea" :rows="4"
                                   placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </div>
                </div>
            </FormItem>

        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                answers: {
                    q7: "",
                    q7_reason: "",
                    q8: "",
                    q9: "",
                    q10: "",
                    q11: "",
                    q12: "",
                    q13: "",
                    q14: "",
                    q15: "",
                    q16: "",
                    q16_reason: "",
                    q17: "",
                    q17_reason: "",
                },

                ruleInline: {
                    q7: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    //q7_reason: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q8: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q9: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q10: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q11: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q12: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q13: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q14: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q15: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q16: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q16_reason: [ { required: true, whitespace: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q17: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q17_reason: [ { required: true, whitespace: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {

        },
        methods: {
            goToNext(){
                this.$refs.cat2Form.validate(valid => {
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q7',
                                answer_type: 'single',
                                answer: this.answers.q7,
                                answers: null,
                                other_option: null,
                                reason_option: this.answers.q7_reason,
                            },
                            {
                                code: 'q8',
                                answer_type: 'single',
                                answer: this.answers.q8,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q9',
                                answer_type: 'single',
                                answer: this.answers.q9,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q10',
                                answer_type: 'single',
                                answer: this.answers.q10,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q11',
                                answer_type: 'single',
                                answer: this.answers.q11,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q12',
                                answer_type: 'single',
                                answer: this.answers.q12,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q13',
                                answer_type: 'single',
                                answer: this.answers.q13,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q14',
                                answer_type: 'single',
                                answer: this.answers.q14,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q15',
                                answer_type: 'single',
                                answer: this.answers.q15,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q16',
                                answer_type: 'single',
                                answer: this.answers.q16,
                                answers: null,
                                other_option: null,
                                reason_option: this.answers.q16_reason,
                            },
                            {
                                code: 'q17',
                                answer_type: 'single',
                                answer: this.answers.q17,
                                answers: null,
                                other_option: null,
                                reason_option: this.answers.q17_reason,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q17' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>