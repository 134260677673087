<template>
    <Modal class-name="scrollable vertical-center-modal" title="Editar actividad de impacto" v-model="show" :footer-hide="true" :scrollable="true">
        <LoadingContainer v-if="!data_fetched"/>
        <template v-else>
            <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
                <Row :gutter="16">
                    <i-col span="24">
                        <FormItem label="Actividad de impacto" prop="activity_id">
                            <Select v-model="activity.activity_id" style="width: 100%">
                                <Option v-for="activity in impact_activities" :key="`impact_activity_id_${activity.id}`" :value="activity.id">{{ activity.name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="Actividades" prop="activities">
                            <Select v-model="activity.activities" multiple style="width: 100%">
                                <Option v-for="activity in poa_activities" :key="`poa_activity_id_${activity.id}`" :value="activity.id">{{ activity.name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="En alianza con otra organización o entidad" prop="alliances">
                            <Input v-model="activity.alliances" placeholder="" />
                        </FormItem>
                        <FormItem label="Evidencias" prop="evidences_url">
                            <template #label>
                                <p>Evidencias</p>
                                <p style="font-weight: normal; margin-top: 5px"><small>Acceso a link con fotografías, trípticos, folletos, cartas descriptivas, artículos publicados en periódicos y/o revistas, certificados, reconocimientos, premios otorgados al programa</small></p>
                            </template>
                            <Input v-model="activity.evidences_url" placeholder="" />
                        </FormItem>
                    </i-col>
                    <i-col span="24">
                        <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Actualizar</Button>
                    </i-col>
                </Row>
            </Form>
        </template>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                activity: {
                    activity_id: '',
                    activities: [],
                    alliances: '',
                    evidences_url: '',
                },
                data_fetched: false,
                impact_activities: [],
                poa_activities: [],
                profiles: [],
                loading: false,
                ruleInline: {
                    profiles: [
                        { required: true, type: 'array', min: 1, message: 'Perfiles son requeridos', trigger: 'change' },
                    ],
                    activity_id: [
                        { required: true, type: 'integer', message: 'Actividad es requerida', trigger: 'change' },
                    ],
                    activities: [
                        { required: true, type: 'array', min: 1, message: 'Actividades son requeridas', trigger: 'change' },
                    ],
                    asist_h: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    asist_m: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    asist_o: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_m_18: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_18_29: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_30_49: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_50_65: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    a_m_65: [
                        { required: true, type: 'integer', min: 0, message: 'Requerido', trigger: 'change' },
                    ],
                    state: [
                        { required: true, message: 'Estado es requerido', trigger: 'change' },
                    ],
                    city: [
                        { required: true, message: 'Municipio es requerido', trigger: 'change' },
                    ],
                    alliances: [
                        { required: false, message: 'Alianzas es requerido', trigger: 'change' },
                    ],
                    evidences_url: [
                        { required: true, message: 'Evidencias es requerido', trigger: 'change' },
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/update/${this.activity.id}`, { ...this.activity })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-bitacora-impact-activity--update-activity', response.data.impact_activity)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            fetchData(){
                window.axios.post(this.$root.API_URL + `/bitacora/impact-activity/get-data/${this.$parent.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.impact_activities = response.data.impact_activities
                        this.poa_activities = response.data.poa_activities
                        this.profiles = response.data.profiles
                        this.data_fetched = true
                    }
                })
            },
            clear(){
                this.activity =  {
                    profiles: [],
                    activity_id: '',
                    activities: [],
                    asist_h: 0,
                    asist_m: 0,
                    asist_o: 0,
                    a_m_18: 0,
                    a_18_29: 0,
                    a_30_49: 0,
                    a_50_65: 0,
                    a_m_65: 0,
                    state: '',
                    city: '',
                    alliances: '',
                    evidences_url: '',
                }
                this.$refs.planForm.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('poa-bitacora-impact-activity--update-popup', (impact_activity) => {
                this.show = true
                if( ! this.data_fetched ) this.fetchData()
                
                let activities_ids = []
                
                window._.each(impact_activity.activities, a => activities_ids.push(a.id))

                this.activity = {
                    ...impact_activity,
                    activity_id: impact_activity.impact_activity_id,
                    activities: activities_ids,
                }

            })
        }
    }
</script>

<style lang="scss">
    .ivu-select-dropdown{
        max-width: 488px;
    }

    .ivu-select-item{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces !important;
    }

    .label-weight .ivu-form-item-label{
        font-size: 0.7em !important;
        /* font-weight: normal; */
        margin-bottom: -5px;
    }

    .ivu-form .ivu-form-item-label{
        text-align: left !important;
    }
</style>