<template>
    <Modal class-name="scrollable vertical-center-modal" v-model="show" :width="680" :footer-hide="true" :class="{ 'border-primary': color == 'primary', 'border-accent' : color == 'accent' }">
        <div style="padding: 25px">
            <template v-if="item.type == 'activity'">
                <p><strong>Actividad:</strong></p>
                <h3 style="font-weight: normal">{{ i_class.name }}</h3>
                <br>
                <p><strong>Resultado:</strong></p>
                <h3 style="font-weight: normal">{{ i_class.result.name }}</h3>
                <br>
                <p style="margin-top: 10px"><b>Responsables: </b>
                    <span style="margin-right: 5px" v-for="(resp, i) in i_class.responsible" :key="'poa_actvs_resp_'+resp.id">{{ resp.user.name }}{{ i_class.responsible[i+1]  ? ',' : '' }}</span>
                </p>
                <br>

                <template v-if="i_class.colabs.length > 0">
                    <AvatarList :users="getColabs(i_class)" title="Colaboraciones" />
                    <br>
                </template>

                <template v-if="i_class.external_colabs != null">
                    <p><b>Colaboraciones externas: </b> {{ i_class.external_colabs }}</p>
                    <br>
                </template>

                <p><b>Fechas: </b></p>
                <div style="display: flex; flex-wrap: wrap">
                    <Tag v-for="date in i_class.dates" :key="'act_ddts_dts_'+date.id">
                        <template v-if="date.start == date.end">
                            {{ date.start | moment('DD/MMMM/yyyy') }}
                        </template>
                        <template v-else>
                            {{ date.start | moment('DD/MMMM/yyyy') }} <span style="text-transform: lowercase">a</span> {{ date.end | moment('DD/MMMM/yyyy') }}
                        </template>
                    </Tag>
                </div>
            </template>

            <template v-if="item.type == 'imp_date' || item.type == 'global'">
                <h3>{{ i_class.name }}</h3>
                <br>
                <p>{{ i_class.description }}</p>
                <br>

                <p><b>Fechas: </b></p>
                <Tag :key="'act_ddts_dts_'+i_class.id">
                    <template v-if="i_class.start == i_class.end">
                        {{ i_class.start | moment('DD/MMMM/yyyy') }}
                    </template>
                    <template v-else>
                        {{ i_class.start | moment('DD/MMMM/yyyy') }} <span style="text-transform: lowercase">a</span> {{ i_class.end | moment('DD/MMMM/yyyy') }}
                    </template>
                </Tag>
            </template>
            <br>

            <template v-if="item.type == 'imp_date' && canUpdate">
                <br><br>
                <Row>
                    <i-col offset="18" span="6">
                        <Button @click="updateImportantDate(item)" style="margin-right: 10px" ghost size="small" type="primary">Editar</Button>
                        <Button @click="deleteImportatDate(item)" ghost size="small" type="primary">Eliminar</Button>
                    </i-col>
                </Row>
            </template>
        </div>
    </Modal>
</template>

<script>
    import AvatarList from './../../../../../General/AvatarList'

    export default {
        props: {
            canUpdate: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                show: false,
                item: {
                    type: '',
                },
                i_class: null,
                color: 'primary',
            }
        },
        methods: {
            updateImportantDate(item){
                window.EventBus.$emit('poa-activities--update-imp-date', item)
                this.show = false
            },
            deleteImportatDate(item){
                console.log(item)
                this.$Modal.confirm({
                    title: 'Eliminar fecha clave',
                    content: `<p>¿Deseas eliminar esta fecha clave?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/imp-dates/delete/${item.class.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-activities-caldendar--delete-imp-date', item.class)
                                this.$Message.success('Fecha clave eliminada correctamente');
                                this.show = false
                            }
                        })
                    },
                });
            },
            getColabs(activity){
                let usrs = []

                window._.each(activity.colabs, (c) => {
                    usrs.push(c.user)
                })

                return usrs
            },
        },
        mounted(){

            window.EventBus.$on('show-activity-details-popup', (item) => {
                this.show = true
                this.item = item
                this.i_class = item.class

                if(this.item.type == 'imp_date') this.color = 'accent'
                else if(this.item.type == 'global') this.color = 'accent'
                else this.color = 'primary'
            })

        },
        components: {
            AvatarList,
        }
    }
</script>