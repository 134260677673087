<template>
    <div class="container top">
        <LoadingContainer time_warning v-if="is_loading"/>
        <template v-else>
            <h1 class="survey-title">
                Encuesta clima y cultura organizacional
                <span>{{ survey.name }}</span>
            </h1>
            <br>

            <ReportTabs :report_link="`/work-env-surveys/${$route.params.id}`" tab="users" />

            <div v-if="AreaDirectivaUsers.length > 0">
                <br><br>
                <h2 style="margin-left: 15px">Área directiva</h2>
                <div class="program-members--items">
                    <div v-for="(group_item, i) in AreaDirectivaUsers" :key="`user_add_${i}`" class="program-members--item click" :class="{
                        'disabled': ! group_item.completed }" style="cursor: default !important;" >
                        <div class="program-members--item--avatar">
                            <Avatar :src="group_item.user.profile_pic" style="background: linear-gradient(280deg, #f16823,#843cf6)" icon="md-person"/>
                        </div>
                        <div class="program-members--item--info">
                            <h4>{{ group_item.user.name }}</h4>
                            <p>{{ group_item.user.email }}</p>
                            <p style="color: grey; font-size: 0.8em">{{ group_item.user.role }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="(group, i) in UsersGroupByProgram" :key="`program_group_${i}`">
                <br><br>
                <h2 style="margin-left: 15px">{{ group[0].program.name }}</h2>
                <div class="program-members--items">
                    <div v-for="(group_item, i) in group" :key="`user_groups_${i}`" class="program-members--item click" :class="{
                        'disabled': ! group_item.completed }" style="cursor: default !important;" >
                        <div class="program-members--item--avatar">
                            <Avatar :src="group_item.user.profile_pic" style="background: linear-gradient(280deg, #f16823,#843cf6)" icon="md-person"/>
                        </div>
                        <div class="program-members--item--info">
                            <h4>{{ group_item.user.name }}</h4>
                            <p>{{ group_item.user.email }}</p>
                            <p style="color: grey; font-size: 0.8em">{{ group_item.user.role }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
    import ReportTabs from "@/components/WorkEnvSurvey/Details/components/Report/ReportTabs"

    export default {
        data(){
            return {
                is_loading: true,
                tab: "users",
                survey: null,
                survey_users: [],
                answers_data: null,
            }
        },
        computed: {
            UsersGroupByProgram(){
                let users = window._.filter(this.survey_users, u => u.program.name !== 'Área directiva')
                let users_sorted = window._.sortBy(users, 'program.name')
                return window._.groupBy(users_sorted, 'program.name')
            },
            AreaDirectivaUsers(){
                return window._.filter(this.survey_users, u => u.program.name === 'Área directiva')
            }
        },
        methods: {
            fetchData(id){
                window.axios.get(this.$root.API_URL + `/work-env-surveys/get/${id}`)
                    .then(response => {
                        if(response.data.success){
                            this.survey = response.data.survey
                            this.survey_users = response.data.survey_users
                            this.answers_data = response.data.answers
                            this.is_loading = false
                        }
                    })
            }
        },
        mounted() {
            if ( ! this.$canWatchWorkEnvData() ) this.$router.replace('/plan')
            else this.fetchData( this.$route.params.id )
        },
        components: {
            ReportTabs,
        }
    }
</script>