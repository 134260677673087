<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo precio" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="price" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="price.name" placeholder="Nombre"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Descripción" prop="description">
                        <Input type="textarea" :autosize="{minRows: 3,maxRows: 5}" v-model="price.description" placeholder="Descripción"/>
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <FormItem :label=" subheading.heading_id == 1 ? 'Neto' : 'Mínimo' " prop="price">
                        <Input type="number" v-model="price.price" placeholder="0.0"/>
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <FormItem :label=" subheading.heading_id == 1 ? 'Bruto' : 'Máximo '" prop="gross_price">
                        <Input type="number" v-model="price.gross_price" placeholder="0.0"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                price: {
                    name: "",
                    description: "",
                    price: "",
                    gross_price: "",
                },
                subheading: {},
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' },
                    ],
                    description: [
                        { required: false, message: 'Descripción es requerido', trigger: 'change' },
                    ],
                    price: [
                        { required: true, message: 'Mínimo es requerido', trigger: 'change' },
                    ],
                    gross_price: [
                        { required: false, message: 'Máximo es requerido', trigger: 'change' },
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/headings/subheading/create-price/${this.subheading.id}`, { ...this.price })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('budget-headings--update-heading', response.data.heading)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.price = {
                    name: "",
                    description: "",
                    price: "",
                    gross_price: "",
                }
                this.subheading = {
                    name: ""
                }
            }
        },
        mounted(){
            window.EventBus.$on('budget-headings--create-subheading-price-popup', (subheading) => {
                this.subheading = {
                    ...subheading,
                }
                this.show = true
            })
        }
    }
</script>