<template>
    <div class="chart--semi-circle" ref="chartdiv"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core"
    import * as am4charts from "@amcharts/amcharts4/charts"

    // function am4themes_ILSBChartTheme(target){
    //     if (target instanceof am4core.ColorSet) {
    //         target.list = [
    //             am4core.color("#20CB74"),
    //             am4core.color("#ffc432"),
    //             am4core.color("#FFAA50"),
    //             am4core.color("#EE6B5F"),
    //             am4core.color("#ececec"),
    //         ];
    //     }
    // }

    // am4core.useTheme(am4themes_ILSBChartTheme)

    export default {
        props: {
            title: { required: false, type: Object },
            data: { required: false, type: Array },
            legend: { required: false, type: Object },
            hideLabels: { required: false, type: Boolean, default: true },
            alignLabels: { required: false, type: Boolean, default: false },
        },
        data(){
            return {
                chart: null,
                empty_chart: [
                    {
                        name: "NU",
                        value: 0,
                    },
                    {
                        name: "CM",
                        value: 0,
                    },
                    {
                        name: "CP",
                        value: 0,
                    },
                    {
                        name: "MA",
                        value: 0,
                    },
                    {
                        name: "T",
                        value: 0,
                    },
                    {
                        name: "NA",
                        value: 0,
                    },
                    {
                        name: "Pendiente",
                        value: 1,
                    }
                ]
            }
        },
        watch: {
            data(){
                this.chart.data = this.data
            }
        },
        mounted(){
            let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
            chart.hiddenState.properties.opacity = 0;

            chart.data = this.data || this.empty_chart

            chart.radius = am4core.percent(70);
            chart.innerRadius = am4core.percent(40);
            chart.startAngle = 180;
            chart.endAngle = 360;

            if( this.legend ) {
                chart.legend = new am4charts.Legend()
                chart.legend.useDefaultMarker = false
                chart.legend.position = this.legend.position || 'right'
                chart.legend.fontSize = this.legend.fontSize || 11
                if( this.legend.height ) chart.legend.height = this.legend.height 
    
                chart.legend.itemContainers.template.paddingTop = 3;
                chart.legend.itemContainers.template.paddingBottom = 5;
    
                let marker = chart.legend.markers.template.children.getIndex(0)
                marker.cornerRadius(12,12,12,12)
    
                let markerTemplate = chart.legend.markers.template
                markerTemplate.width = 10
                markerTemplate.height = 10
                markerTemplate.marginRight = 5
            }

            var series = chart.series.push(new am4charts.PieSeries());
            series.dataFields.value = "value";
            series.dataFields.category = "name";
            series.slices.template.propertyFields.fill = "hex_color";

            series.slices.template.cornerRadius = 0;
            series.slices.template.innerCornerRadius = 0;
            series.slices.template.draggable = false;
            series.slices.template.inert = false;
            series.alignLabels = this.alignLabels;
            // series.legend.position = 'right'

            if( this.hideLabels ){
                series.ticks.template.events.on("ready", hideLabel);
                series.ticks.template.events.on("visibilitychanged", hideLabel);
                series.labels.template.events.on("ready", hideLabel);
                series.labels.template.events.on("visibilitychanged", hideLabel);
            }
            // series.fontSize = 0

            function hideLabel(ev){
                ev.target.hide()
            }

            if( this.title ){
                let label = series.createChild(am4core.Label);
                label.text = this.title.text;
                label.horizontalCenter = this.title.horizontalCenter || "middle";
                label.fontSize = this.title.fontSize || 14;
                label.paddingTop = this.title.paddingTop || null
                label.paddingBottom = this.title.paddingBottom || null
            }

            series.hiddenState.properties.startAngle = 90;
            series.hiddenState.properties.endAngle = 90;

            this.chart = chart;
        
        },
        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose();
            }
        }
    }
</script>