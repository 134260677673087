<template>
    <div>
        <Collapse class="invisible no-arrow header-no-padding-left" v-model="collapse">
            <Panel name="1">
                <Button size="small" type="primary" ghost>Actividades</Button>
                <template #content>
                    <ul>
                        <li style="margin-bottom: 10px; font-size: 0.9em" v-for="(activity, i) in activities" :key="`activity_${i}`">{{ activity.name }}</li>
                    </ul>
                </template>
            </Panel>
        </Collapse>
    </div>
</template>

<script>
    export default {
        props: {
            activities: { required: true, type: [Object, Array] }
        },
        data(){
            return {
                collapse: '0',
            }
        }
    }
</script>