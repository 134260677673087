<template>
    <div class="container top">
        <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Usuarios</h1>
                </i-col>
                <i-col span="4" style="display: flex; justify-content: flex-end;">
                    <Button v-if="$can('users.create')" @click="createUser" type="primary">Nuevo Usuario</Button>
                </i-col>
            </Row>
            <br>
            <br>

            <h2 style="margin-left: 15px">Área directiva</h2>
            <div class="program-members--items">
                <div v-for="(user, i) in user_groups['Área directiva']" :key="`user_groups_${i}`" class="program-members--item">
                    <div class="program-members--actions">
                        <Dropdown v-if="CanUpdateUser || CanDeleteUser" trigger="click" placement="left">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="CanUpdateUser" @click.native="updateMember(user)">Editar</DropdownItem>
                                <DropdownItem v-if="CanDeleteUser" @click.native="deleteMember(user)">Deshabilitar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div class="program-members--item--avatar">
                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                    </div>
                    <div class="program-members--item--info">
                        <h4>{{ user.name }}</h4>
                        <p>{{ user.email }}</p>
                        <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                    </div>
                </div>
            </div>
            
            <div v-for="program in programs" :key="`program_list_${program.id}`">
                <br><br>
                <h2 style="margin-left: 15px">{{ program.name }}</h2>
                <div class="program-members--items">
                    <div v-for="(user, i) in user_groups[program.name]" :key="`user_groups_${i}`" class="program-members--item">
                        <div class="program-members--actions">
                            <Dropdown v-if="CanUpdateUser || CanDeleteUser" trigger="click" placement="left">
                                <a href="javascript:void(0)">
                                    <Icon color="grey" size="16" type="md-more"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem v-if="CanUpdateUser" @click.native="updateMember(user)">Editar</DropdownItem>
                                    <DropdownItem v-if="CanDeleteUser" @click.native="deleteMember(user)">Deshabilitar</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div class="program-members--item--avatar">
                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                        </div>
                        <div class="program-members--item--info">
                            <h4>{{ user.name }}</h4>
                            <p>{{ user.email }}</p>
                            <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <hr>
            <br><br>

            <h2 style="margin-left: 15px">Otros usuarios</h2>
            <div class="program-members--items">
                <div v-for="(user, i) in user_groups['otros']" :key="`user_groups_${i}`" class="program-members--item">
                    <div class="program-members--actions">
                        <Dropdown v-if="CanUpdateUser || CanDeleteUser" trigger="click" placement="left">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="CanUpdateUser" @click.native="updateMember(user)">Editar</DropdownItem>
                                <DropdownItem v-if="CanDeleteUser" @click.native="deleteMember(user)">Deshabilitar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div class="program-members--item--avatar">
                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                    </div>
                    <div class="program-members--item--info">
                        <h4>{{ user.name }}</h4>
                        <p>{{ user.email }}</p>
                        <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                    </div>
                </div>
            </div>

            <hr>
            <br><br>

            <h2 style="margin-left: 15px">Deshabilitados</h2>
            <div class="program-members--items">
                <div v-for="(user, i) in user_groups['deshabilitados']" :key="`user_groups_${i}`" class="program-members--item">
                    <div class="program-members--actions">
                        <Dropdown v-if="CanUpdateUser || CanDeleteUser" trigger="click" placement="left">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="CanUpdateUser" @click.native="updateMember(user)">Editar</DropdownItem>
                                <DropdownItem v-if="CanDeleteUser" @click.native="activeMember(user)">Habilitar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div class="program-members--item--avatar">
                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                    </div>
                    <div class="program-members--item--info">
                        <h4>{{ user.name }}</h4>
                        <p>{{ user.email }}</p>
                        <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                    </div>
                </div>
            </div>

            <Create v-if="$can('users.create')"/>
            <Update v-if="CanUpdateUser"/>
    </div>
</template>

<script>
    import Create from './components/Create'
    import Update from './components/Update'

    export default {
        data(){
            return{
                programs: [], 
                user_groups: [], 
            } 
        },
        computed: {
            CanUpdateUser(){
                return this.$can('users.edit')
            },
            CanDeleteUser(){
                return this.$can('users.delete')
            },
        },
        methods: {
            getUsers(){
                window.axios.post(this.$root.API_URL + `/users/get`)
                .then(response => {
                    if( response.data.success ){
                        this.programs = response.data.programs
                        this.user_groups = response.data.users
                    }
                })
            },
            createUser(){
                window.EventBus.$emit('users-grid--create', true)
            },
            updateMember(user){
                window.EventBus.$emit('users-grid--update', user)
            },
            deleteMember(user){
                this.$Modal.confirm({
                    title: 'Deshabilitar usuario',
                    content: `¿Deseas deshabilitar a ${user.name}'? <br> Un usuario deshabilitado no puede iniciar sesión en la plataforma ni ser relacionado a ninguna área.`,
                    okText: 'Deshabilitar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/users/active/${user.id}`, { active: false })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('users-gird--update-users', response.data.users)
                                this.$Message.success('Usuario actualizado exitosamente');
                            }
                            else {
                                this.$Message.error(response.data.message);
                            }
                        })
                    }
                });
            },
            activeMember(user){
                this.$Modal.confirm({
                    title: 'Habilitar usuario',
                    content: `¿Deseas habilitar a ${user.name}'? <br> El usuario podrá iniciar sesión en la plataforma y podrá ser integrante de un área.`,
                    okText: 'Habilitar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/users/active/${user.id}`, { active: true })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('users-gird--update-users', response.data.users)
                                this.$Message.success('Usuario actualizado exitosamente');
                            }
                            else {
                                this.$Message.error(response.data.message);
                            }
                        })
                    }
                });
            }
        },
        mounted(){
            this.getUsers()

            window.EventBus.$on('users-gird--update-users', users => {
                this.user_groups = users
            })
        },
        components:{
            Create,
            Update,
        } 
    }
</script>