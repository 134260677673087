<template>
    <div class="loading-container">
        <v-progress-circular indeterminate color="primary"/>
        <p v-if="time_warning">Esto puede tardar tiempo</p>
    </div>
</template>

<script>
    export default {
        props: {
            time_warning: { required: false, type: Boolean, default: false },
        }
    }
</script>

<style lang="scss">

    .loading-container{
        min-height: 350px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        & p {
            margin-top: 20px;
        }
    }

</style>