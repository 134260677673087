<template>
    <div class="sign-in--container">
        <div class="sign-in--card">
            <div class="sign-in--card--background">
                <img src="/img/logo-color.svg" alt="">
                <h2>Plataforma de evaluación</h2>
                
                <Form style="width: 100%" ref="signInForm" :model="signIn" :rules="ruleInline">
                    <FormItem prop="email">
                        <Input size="large" type="text" v-model="signIn.email" placeholder="Correo electrónico"/>
                    </FormItem>
                    <FormItem prop="password">
                        <Input size="large" type="password" v-model="signIn.password" placeholder="Contraseña"/>
                    </FormItem>
                    <FormItem style="margin-bottom: 0 !important">
                        <Button :loading="loading" long size="large" type="primary" @click="handleSubmit">Iniciar Sesión</Button>
                        <Button @click="$router.push('/forgot-password')" style="margin-top: 25px" size="small" type="text">¿Olvidaste tu contraseña?</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                signIn: {
                    email: '',
                    password: ''
                },
                loading: false,
                ruleInline: {
                    email: [
                        { required: true, message: 'Correo electrónico es requerido', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: 'Contraseña es requerida', trigger: 'blur' },
                    ]
                }
            }
        },
        methods: {
            handleSubmit(){
                this.$refs.signInForm.validate(valid => {
                    console.log(valid)
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/auth/sign-in', { ...this.signIn })
                        .then(response => {

                            if(response.data.success){
                                
                                window.dexie.config.add({ name: 'auth', value: response.data.token })
                                setTimeout(() => {
                                    location.reload()
                                }, 500)

                            }
                            else {
                                this.$Message.error('Ha ingresado un correo electrónico o contraseña inválidos');
                                this.loading = false
                            }

                        })
                        .catch(() => {
                            this.$Message.error('Whoops, algo salió mal');
                            this.loading = false
                        })

                    }
                })
            }
        }
    }
</script>