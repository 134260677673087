<template>
    <Grid/>
</template>

<script>
    import Grid from './../components/Budget/Headings/Grid.vue'

    export default {
        components: {
            Grid,
        }
    }
</script>