<template>
    <div>

        <h2 class="section--subtitle">Liderazgo y gestión de equipo </h2>
        <br>

        <Form style="width: 100%" ref="cat6Form" :model="answers" :rules="ruleInline">

            <template v-if="AuthUserIfOfficial">
                <p style="font-size: 1.1em; font-weight: 700; margin-bottom: 20px">28. ¿Con qué frecuencia tu coordinación realiza las siguientes acciones?</p>


                <!-- q28 -->
                <FormItem class="two-column" label="Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área"
                          prop="q28">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q28" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q28_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q29 -->
                <FormItem class="two-column" label="Se preocupa por tu seguridad y autocuidado"
                          prop="q29">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q29" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q29_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q30 -->
                <FormItem class="two-column" label="Te apoya en tu formación profesional y acceso a cursos, diplomados, etc."
                          prop="q30">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q30" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q30_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q31 -->
                <FormItem class="two-column" label="Te escucha y toma en cuenta tus opiniones"
                          prop="q31">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q31" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q31_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q32 -->
                <FormItem class="two-column" label="Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones"
                          prop="q32">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q32" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q32_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q33 -->
                <FormItem class="two-column" label="Te acompaña en el desarrollo de tus habilidades estratégicas y en tu liderazgo"
                          prop="q33">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q33" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q33_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q34 -->
                <FormItem class="two-column" label="Genera espacios de discusión colectiva para alinear las estrategias del programa con los objetivos"
                          prop="q34">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q34" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q34_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q35 -->
                <FormItem class="two-column" label="Propone alternativas útiles para solucionar problemas y retos del área"
                          prop="q35">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q35" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q35_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q36 -->
                <FormItem class="two-column" label="Recibe asertivamente tu retroalimentación"
                          prop="q36">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q36" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q36_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q37 -->
                <FormItem class="two-column" label="Fomenta y valora el trabajo en equipo"
                          prop="q37">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q37" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q37_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q38 -->
                <FormItem class="two-column" label="Fomenta y valora el trabajo en equipo"
                          prop="q38">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q38" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q38_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q39 -->
                <FormItem class="two-column" label="Te comparte información imprescindible y necesaria para realizar tu trabajo"
                          prop="q39">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q39" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q39_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q40 -->
                <FormItem class="two-column" label="Planifica y organiza las actividades del área y asigna plazos de ejecución y entregas razonables"
                          prop="q40">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q40" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q40_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q41 -->
                <FormItem class="two-column" label="Delega las cargas de trabajo entre los integrantes del equipo de manera equitativa"
                          prop="q41">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q41" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q41_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q42 -->
                <FormItem class="two-column" label="Se comunica asertivamente"
                          prop="q42">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q42" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q42_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q43 -->
                <FormItem class="two-column" label="Te trata con respeto y dignidad"
                          prop="q43">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q43" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q43_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

            </template>

            <template v-if="AuthUserIsCoord">
                <p style="font-size: 1.1em; font-weight: 700; margin-bottom: 20px">29. ¿Con qué frecuencia las oficiales de tu área
                    realizan las siguientes acciones?</p>

                <!-- q59 -->
                <FormItem class="two-column" label="Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área"
                          prop="q59">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q59" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q59_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q60 -->
                <FormItem class="two-column" label="Se preocupa por tu seguridad y autocuidado"
                          prop="q60">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q60" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q60_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q61 -->
                <FormItem class="two-column" label="Te escucha y toma en cuenta tus opiniones"
                          prop="q61">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q61" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q61_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q62 -->
                <FormItem class="two-column" label="Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones	"
                          prop="q62">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q62" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q62_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q63 -->
                <FormItem class="two-column" label="Participa en los  espacios de discusión colectiva para alinear las estrategias del programa con los objetivos"
                          prop="q63">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q63" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q63_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q64 -->
                <FormItem class="two-column" label="Propone alternativas útiles para solucionar problemas y retos del área"
                          prop="q64">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q64" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q64_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q65 -->
                <FormItem class="two-column" label="Recibe asertivamente tu retroalimentación"
                          prop="q65">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q65" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q65_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q66 -->
                <FormItem class="two-column" label="Fomenta y valora el trabajo en equipo"
                          prop="q66">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q66" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q66_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q67 -->
                <FormItem class="two-column" label="Te comparte información imprescindible y necesaria para realizar tu trabajo"
                          prop="q67">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q67" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q67_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q68 -->
                <FormItem class="two-column" label="Se comunica asertivamente"
                          prop="q68">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q68" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q68_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q69 -->
                <FormItem class="two-column" label="Te trata con respeto y dignidad"
                          prop="q69">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q69" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q69_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

            </template>

            <div>
                <br><br><br><br>
                <p style="font-size: 1.1em; font-weight: 700; margin-bottom: 20px">30. ¿Con qué frecuencia el área directiva realiza las
                    siguientes acciones?</p>

                <!-- q44 -->
                <FormItem class="two-column" label="Realiza la evaluación de tu desempeño de manera objetiva y de acuerdo al POA del área"
                          prop="q44">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q44" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q44_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q45 -->
                <FormItem class="two-column" label="Se preocupa por tu seguridad y autocuidado"
                          prop="q45">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q45" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q45_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q46 -->
                <FormItem class="two-column" label="Te apoya en tu formación profesional y acceso a cursos, diplomados, etc."
                          prop="q46">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q46" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q46_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q47 -->
                <FormItem class="two-column" label="Te escucha y toma en cuenta tus opiniones"
                          prop="q47">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q47" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q47_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q48 -->
                <FormItem class="two-column" label="Respeta tus horarios de trabajo, días económicos, días de reposición y vacaciones"
                          prop="q48">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q48" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q48_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q49 -->
                <FormItem class="two-column" label="Te acompaña en el desarrollo de tus habilidades estratégicas y en tu liderazgo"
                          prop="q49">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q49" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q49_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q50 -->
                <FormItem class="two-column" label="Genera espacios de discusión colectiva para alinear las estrategias del programa con los objetivos"
                          prop="q50">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q50" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q50_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q51 -->
                <FormItem class="two-column" label="Propone alternativas útiles para solucionar problemas y retos del área"
                          prop="q51">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q51" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q51_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q52 -->
                <FormItem class="two-column" label="Recibe asertivamente tu retroalimentación"
                          prop="q52">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q52" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q52_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q53 -->
                <FormItem class="two-column" label="Fomenta y valora el trabajo en equipo"
                          prop="q53">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q53" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q53_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q54 -->
                <FormItem class="two-column" label="Te comparte información imprescindible y necesaria para realizar tu trabajo"
                          prop="q54">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q54" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q54_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q55 -->
                <FormItem class="two-column" label="Planifica y organiza las actividades del área y asigna plazos de ejecución y entregas razonables"
                          prop="q55">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q55" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q55_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q56 -->
                <FormItem class="two-column" label="Delega las cargas de trabajo entre los integrantes del equipo de manera equitativa"
                          prop="q56">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q56" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q56_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q57 -->
                <FormItem class="two-column" label="Se comunica asertivamente"
                          prop="q57">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q57" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q57_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <!-- q58 -->
                <FormItem class="two-column" label="Te trata con respeto y dignidad"
                          prop="q58">
                    <div class="env-survey-field-answers">
                        <RadioGroup v-model="answers.q58" type="button" button-style="solid">
                            <Radio v-for="(scale, i) in scales" :key="`q58_scales_${i}`" :label="scale"></Radio>
                        </RadioGroup>
                    </div>
                </FormItem>

                <br><br>

            </div>
        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                answers: {
                    q28: "",
                    q29: "",
                    q30: "",
                    q31: "",
                    q32: "",
                    q33: "",
                    q34: "",
                    q35: "",
                    q36: "",
                    q37: "",
                    q38: "",
                    q39: "",
                    q40: "",
                    q41: "",
                    q42: "",
                    q43: "",
                    //coord 1
                    q44: "",
                    q45: "",
                    q46: "",
                    q47: "",
                    q48: "",
                    q49: "",
                    q50: "",
                    q51: "",
                    q52: "",
                    q53: "",
                    q54: "",
                    q55: "",
                    q56: "",
                    q57: "",
                    q58: "",
                    // coord 2
                    q59: "",
                    q60: "",
                    q61: "",
                    q62: "",
                    q63: "",
                    q64: "",
                    q65: "",
                    q66: "",
                    q67: "",
                    q68: "",
                    q69: "",
                },
                scales: [ "Nunca", "Casi nunca", "A veces", "Casi siempre", "Siempre" ],
                ruleInline: {
                    q28: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q29: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q30: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q31: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q32: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q33: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q34: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q35: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q36: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q37: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q38: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q39: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q40: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q41: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q42: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q43: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q44: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q45: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q46: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q47: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q48: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q49: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q50: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q51: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q52: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q53: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q54: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q55: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q56: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q57: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q58: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q59: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q60: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q61: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q62: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q63: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q64: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q65: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q66: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q67: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q68: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q69: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
            }),
            AuthUserIsCoord(){
                //return true
                return this.$hasRole('Coordinación')
            },
            AuthUserIfOfficial(){
                //return false
                return this.$hasRole('Oficial') || this.$hasRole('Oficial del área directiva') ||
                    this.$hasRole('Asistente de programa') || this.$hasRole('Mantenimiento')
            }
        },
        methods: {
            goToNext(){
                this.$refs.cat6Form.validate(valid => {
                    console.log(valid)
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q28',
                                answer_type: 'single',
                                answer: this.answers.q28,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q29',
                                answer_type: 'single',
                                answer: this.answers.q29,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q30',
                                answer_type: 'single',
                                answer: this.answers.q30,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q31',
                                answer_type: 'single',
                                answer: this.answers.q31,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q32',
                                answer_type: 'single',
                                answer: this.answers.q32,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q33',
                                answer_type: 'single',
                                answer: this.answers.q33,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q34',
                                answer_type: 'single',
                                answer: this.answers.q34,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q35',
                                answer_type: 'single',
                                answer: this.answers.q35,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q36',
                                answer_type: 'single',
                                answer: this.answers.q36,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q37',
                                answer_type: 'single',
                                answer: this.answers.q37,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q38',
                                answer_type: 'single',
                                answer: this.answers.q38,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q39',
                                answer_type: 'single',
                                answer: this.answers.q39,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q40',
                                answer_type: 'single',
                                answer: this.answers.q40,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q41',
                                answer_type: 'single',
                                answer: this.answers.q41,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q42',
                                answer_type: 'single',
                                answer: this.answers.q42,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q43',
                                answer_type: 'single',
                                answer: this.answers.q43,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q44',
                                answer_type: 'single',
                                answer: this.answers.q44,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q45',
                                answer_type: 'single',
                                answer: this.answers.q45,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q46',
                                answer_type: 'single',
                                answer: this.answers.q46,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q47',
                                answer_type: 'single',
                                answer: this.answers.q47,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q48',
                                answer_type: 'single',
                                answer: this.answers.q48,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q49',
                                answer_type: 'single',
                                answer: this.answers.q49,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q50',
                                answer_type: 'single',
                                answer: this.answers.q50,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q51',
                                answer_type: 'single',
                                answer: this.answers.q51,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q52',
                                answer_type: 'single',
                                answer: this.answers.q52,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q53',
                                answer_type: 'single',
                                answer: this.answers.q53,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q54',
                                answer_type: 'single',
                                answer: this.answers.q54,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q55',
                                answer_type: 'single',
                                answer: this.answers.q55,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q56',
                                answer_type: 'single',
                                answer: this.answers.q56,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q57',
                                answer_type: 'single',
                                answer: this.answers.q57,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q58',
                                answer_type: 'single',
                                answer: this.answers.q58,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q59',
                                answer_type: 'single',
                                answer: this.answers.q59,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q60',
                                answer_type: 'single',
                                answer: this.answers.q60,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q61',
                                answer_type: 'single',
                                answer: this.answers.q61,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q62',
                                answer_type: 'single',
                                answer: this.answers.q62,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q63',
                                answer_type: 'single',
                                answer: this.answers.q63,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q64',
                                answer_type: 'single',
                                answer: this.answers.q64,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q65',
                                answer_type: 'single',
                                answer: this.answers.q65,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q66',
                                answer_type: 'single',
                                answer: this.answers.q66,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q67',
                                answer_type: 'single',
                                answer: this.answers.q67,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q68',
                                answer_type: 'single',
                                answer: this.answers.q68,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q69',
                                answer_type: 'single',
                                answer: this.answers.q69,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q59' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>

<style lang="scss">
    .two-column.ivu-form-item{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ececec;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 0;
        transition: all ease-in-out 0.3s;
        & .ivu-form-item-label{
            width: 50%;
            flex-grow: 1;
            border-right: 1px solid #ececec;
        }
        & .env-survey-field-answers{
            margin-top: 0 !important;
        }
        &:hover{
            background-color: rgba(#843cf6, 0.07);
        }
    }
</style>