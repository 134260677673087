<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Crear solicitud de transferencia" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="transfer" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Fecha solicitud" prop="date">
                        <DatePicker type="date" placeholder="Seleccionar" v-model="transfer.date" style="width: 100%"/>
                    </FormItem>
                </i-col>
               <i-col span="24">
                    <FormItem label="Importe" prop="amount">
                        <!-- <Tag color="warning">Disponible: {{ MaxBudget | currency }}</Tag> -->
                        <InputNumber :min="1" v-model="transfer.amount" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Tipo" prop="type">
                        <Select v-model="transfer.type" style="width: 100%">
                        <Option value="Transferencia">Transferencia</Option>
                        <Option value="Cheque">Cheque</Option>
                    </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="A favor de" prop="favor">
                        <Input v-model="transfer.favor" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Nombre del banco" prop="bank">
                        <Input v-model="transfer.bank" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Titular de cuenta" prop="holder">
                        <Input v-model="transfer.holder" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Cuenta" prop="account">
                        <Input type="number" v-model="transfer.account" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="CLABE" prop="clabe">
                        <Input type="number" v-model="transfer.clabe" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Datos para transferencia internacional" prop="international">
                        <Input type="textarea" :rows="4" v-model="transfer.international" placeholder="" style="width: 100%" />
                    </FormItem>
                </i-col>

                <i-col span="24">
                    <Button size="large" long type="primary" :loading="loading" @click="save">Guardar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                subheading: {
                    budget: {
                        available: 0,
                    }
                },
                budget: {},
                activity: {},
                transfer: {
                    date: new Date(),
                    amount: 0,
                    favor: '',
                    holder: '',
                    bank: '',
                    account: '',
                    clabe: '',
                    type:'Transferencia',
                    international: '',
                },
                loading: false,
                ruleInline: {
                    date: [
                        { required: true, type: 'date', message: 'Fecha es requerida', trigger: 'change' },
                    ],
                    amount: [
                        { required: true, type: 'number', message: 'Importe es requerido', trigger: 'change' },
                    ],
                    favor: [
                        { required: true,  message: 'Información requerida', trigger: 'change' },
                    ],
                    bank: [
                        { required: true,  message: 'Banco es requerido', trigger: 'change' },
                    ],
                    holder: [
                        { required: true,  message: 'Información es requerida', trigger: 'change' },
                    ],
                    account: [
                        { required: true,  message: 'Cuenta es requerida', trigger: 'change' },
                    ],
                    clabe: [
                        { required: true,  message: 'CLABE es requerida', trigger: 'change' },
                        { min: 18, message: 'CLABE debe de tener al menos 18 caracteres' },
                        { max: 18, message: 'CLABE no debe de tener más 18 caracteres' },
                    ],
                },
            }
        },
        computed: {
            MaxBudget(){
                return this.subheading.budget.available || 0
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/create-transfer/${this.budget.id}`, { ...this.transfer })
                        .then(response => {
                            if(response.data.success){
                            //    window.EventBus.$emit('poa-activities-caldendar--update-activity', response.data.activity)
                               window.EventBus.$emit('poa-budget-requests--add-transfer', this.activity, this.budget, response.data.transfer)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.transfer = {
                    date: new Date(),
                    amount: 0,
                    favor: '',
                    holder: '',
                    bank: '',
                    account: '',
                    clabe: '',
                    type:'Transferencia',
                }
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--create-transfer-poup', (activity, budget) => {
                this.budget = {
                    ...budget,
                }
                this.activity = activity
                this.show = true
            })
        },
        components: {
        }
    }
</script>