<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}`">{{ program.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ program.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="poa" :poa_link="`/programa/${$route.params.name}/${$route.params.year}`" />

            <ResultsGrid :poa="poa" :program="program" :results="results"/>

        </template>

        <!-- <UpdateEvaluation/>
        <EvaluateIndicator/> -->

        <CreateActivity v-if="CanCreateActivity"/>
        <UpdateActivity v-if="CanCreateActivity"/>
    </div>
</template>

<script>
    import POATabs from './components/Tabs'
    import ResultsGrid from './components/Results/Grid'
    import { mapGetters } from 'vuex'
    import CreateActivity from './components/Calendar/components/Activity/Create'
    import UpdateActivity from './components/Calendar/components/Activity/Update'
    // import UpdateEvaluation from './components/Evaluation/components/Update'
    // import EvaluateIndicator from './components/Results/components/Indicators/Evaluate'

    export default {
        data(){
            return {
                tab: 'poa',
                poa: null,
                program: null,
                results: [],
                imp_dates: [],
                activities: [],
                is_loading: true,
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status_poa: 'status_poa',
                program_member: 'program',
            }),
            Statuses(){
                return this.status_poa
            },
            CanCreateActivity(){
                if( this.user.role_name == 'Super Admin' ) return true
                else if( this.user.role_name == 'Coordinación' ){
                    if( this.program_member != null && this.program != null ){
                        if( this.program_member.id == this.program.id ) return true;
                        else return false;
                    }
                    else return false;
                }
                else {
                    return false
                }
            }
        },
        methods: {
            getPOA(program_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${program_slug}/get`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.program = response.data.program
                        this.poa = response.data.poa
                        this.getResults()
                        // this.getActivities()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.program.name)

                        // this.is_loading = false
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getResults(){
                window.axios.post(this.$root.API_URL + `/results/get-poa/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        // this.results = response.data.results
                        window._.each(response.data.results, result => {

                            let ri = window._.findIndex(this.results, r => {
                                return r.id == result.id
                            })

                            if( ri == -1 ){
                                let r = {
                                    ...result,
                                }
    
                                this.results.push(r)
                                window._.each(result.activities, a => this.activities.push({
                                    ...a,
                                    headings: [], 
                                    is_budget_loaded: false,
                                    is_budget_loading: true,
                                }))
                            }
                        })
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){
            
            let poa_year = this.$route.params.year
            let program_slug = this.$route.params.name

            this.getPOA(program_slug, poa_year)

           /* Collapse drawer menu */
            this.$collpaseDrawer('poa-content')

            if( this.$route.query.tab ) this.tab = this.$route.query.tab

            window.EventBus.$on('program-poa--add-result', () => {
                // if( result ) window.location.reload()
                // this.results.push(result)
                this.getResults()
            })

            window.EventBus.$on('program-poa--update-result', (result) => {
                let ri = window._.findIndex(this.results, (r) => {
                    return r.id == result.id
                })

                if(ri != -1){
                    let r = {
                        ...result,
                        unique_color: this.results[ri].unique_color,
                    }
                    this.$set(this.results, ri, r)
                    // this.getResults()
                }
            })

            window.EventBus.$on(`program-poa---delete-result`, (result) => {
                let ri = window._.findIndex(this.results, (r) => {
                    return r.id == result.id
                })

                if(ri != -1){

                    window._.each(result.activities, (a) => {
                        window.EventBus.$emit('poa-activities-caldendar--delete-activity', a)
                    })

                    this.results.splice(ri, 1)
                }
            })

            window.EventBus.$on('poa-activities-caldendar--add-activity', (activity) => {
                this.activities.push(activity)

                let ri = window._.findIndex(this.results, r => {
                    return r.id == activity.result.id
                }) 

                if( ri != -1 ){
                    this.results[ri].activities.push(activity)
                }
            })

            window.EventBus.$on('poa-activities-caldendar--update-activity', (activity) => {
                console.log(activity)
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    this.$set(this.activities, ai, activity)
                }

                let ri = window._.findIndex(this.results, r => {
                    return r.id == activity.result.id
                }) 

                if( ri != -1 ){
                    let aai = window._.findIndex(this.results[ri].activities, a => {
                        return a.id == activity.id
                    })

                    if( aai != -1 ){
                        this.$set(this.results[ri].activities, aai, activity)
                    }
                }

            })

            window.EventBus.$on('poa-activities-caldendar--update-activity-budget', (activity, budget) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    let bi = window._.findIndex(this.activities[ai].budget, (b) => {
                        return b.id == budget.id
                    })

                    if(bi != -1){
                        this.$set(this.activities[ai].budget, bi, budget)
                        console.log('budget updated')
                    }
                }
            })

            window.EventBus.$on('poa-activities-caldendar--add-activity-budget', (activity, budget) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    this.activities[ai].budget.push(budget)
                }
            })

            window.EventBus.$on('poa-activities-caldendar--delete-activity', (activity) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    this.activities.splice(ai, 1)
                }

                let ri = window._.findIndex(this.results, r => {
                    return r.id == activity.result.id
                }) 

                if( ri != -1 ){
                    let aai = window._.findIndex(this.results[ri].activities, a => {
                        return a.id == activity.id
                    })

                    if( aai != -1 ){
                        this.results[ri].activities.splice(aai, 1)
                    }
                }
            })

        },
        components: {
            // BudgetGrid,
            POATabs,
            ResultsGrid,
            UpdateActivity,
            CreateActivity,
            // CalendarGrid,
            // HistoriesGrid,
            // FinalEvaluation,
            // MiddleEvaluation,
            // UpdateEvaluation,
            // EvaluateIndicator,
        }
    }
</script>