<template>
    <div class="anual-result">
        <Collapse class="invisible" v-model="collapse_result">
            <Panel name="1">
                <div class="result-header--collapse-panel">
                    <Tag v-if="ResultEvaluation" type="dot" :color="ResultEvaluation.status.hex_color">{{ ResultEvaluation.status.name }}</Tag>
                    <h3> {{ result.name }}</h3>
                </div>
                <div slot="content">
                    <!-- Evaluation feedback -->
                    <p v-if="ResultEvaluation" style="white-space: pre-line;">{{ ResultEvaluation.feedback }}</p>

                    <div class="anual-result--item--indicators">
                        <br>
                        <Row :gutter="24">
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicadores de éxito anual</p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in SuccessIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <template v-if="canModify">
                                            <Dropdown trigger="click">
                                                <div :style="{ 'background-color': getIndicatorBackground(indicator) }" class="indicator--tag"></div>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem v-for="status in statuses" :key="'res_ev_ind_upt_succ_'+status.id" @click.native="updateIndicatorStatus(indicator, status)">
                                                        <div class="indicator--short">
                                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                                            {{ status.short_name }}
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </template>
                                        <template v-else>
                                            <div :style="{ 'background-color': getIndicatorBackground(indicator) }" class="indicator--tag"></div>
                                        </template>

                                        <p class="indicator--description">{{ indicator.name }}</p>
                                    </div>
                                </div>
                            </i-col>
                            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                                <p class="title">Indicador de avance del resultado anual</p>
                                <div class="anual-result--item--indicators--tags">
                                    <div v-for="indicator in ProgressIndicators" :key="'indctrs_td_'+indicator.id" class="indicator">
                                        <template v-if="canModify">
                                            <Dropdown trigger="click">
                                                <div :style="{ 'background-color': getIndicatorBackground(indicator) }" class="indicator--tag"></div>
                                                <DropdownMenu slot="list">
                                                    <DropdownItem v-for="status in statuses" :key="'res_ev_ind_upt_succ_'+status.id" @click.native="updateIndicatorStatus(indicator, status)">
                                                        <div class="indicator--short">
                                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                                            {{ status.short_name }}
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </template>
                                        <template v-else>
                                            <div :style="{ 'background-color': getIndicatorBackground(indicator) }" class="indicator--tag"></div>
                                        </template>
                                        <p class="indicator--description">{{ indicator.name }}</p>
                                    </div>
                                </div>
                            </i-col>
                        </Row>
                    </div>

                    <div class="anual-result--item--action">
                        <div class="left"></div>
                        <div  class="right">
                            <Button v-if="canModify" size="small" ghost type="primary" @click="updateEvaluation">Evaluar Resultado</Button>
                        </div>
                    </div>

                </div>
            </Panel>
        </Collapse>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            result: { required: true, type: [Object, Array] },
            ev_type: { required: false, type: String, default: "" },
            canModify: { required: false, type: Boolean, default: false },
        },
        data(){
            return {
                collapse_result: '0',
                collapse_activities: '0',
                max_length: 5,
                ind_success_max_length: 3,
                ind_progress_max_length: 5,
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                statuses: 'status_poa',
            }),
            SuccessIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'success'
                    })
                , ['order'], ['asc'])
            },
            ProgressIndicators(){
                return window._.orderBy(
                    window._.filter(this.result.indicators, (result) => {
                        return result.type == 'progress'
                    })
                , ['order'], ['asc'])
            },
            EvaluationKey(){
                if(this.ev_type == 'middle') return 'middle_evaluation'
                else return 'final_evaluation'
            },
            ResultEvaluation(){
                if(this.ev_type == 'middle') return this.result.middle_evaluation
                if(this.ev_type == 'final')return this.result.final_evaluation
                return null;
            },
        },
        methods: {
            updateEvaluation(){
                window.EventBus.$emit('program-poa--update-evaluation', this.result, this.ResultEvaluation)
            },
            updateIndicatorStatus(indicator, status){
                window.axios.post(this.$root.API_URL + `/indicators/${indicator.id}/update-status`, { statuses_id: status.id, type: this.ev_type })
                .then(response => {
                    if(response.data.success){
                        let ii = window._.findIndex(this.result.indicators, (i) => {
                            return i.id == indicator.id
                        })

                        if(ii != -1){
                            window.EventBus.$emit('program-poa--update-result', response.data.result)
                            this.$Message.success('Indicador actualizado correctamente');
                        }
                    }
                })
            },
            getIndicatorBackground(indicator){
                return indicator[this.EvaluationKey].status.hex_color ?? '#ececec'
            }
        },
        
    }
</script>