<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Agregar actividad" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
            <Row :gutter="16">
                <!-- <i-col span="24">
                    <FormItem label="Resultado" prop="result">
                        <Select v-model="activity.result" style="width:100%">
                            <Option v-for="result in results" :value="result.id" :key="'nw_actvy_rst_'+result.id">
                                {{ result.name.substring(0, 65) }} {{ result.name.length > 65 ? '...' : '' }}
                            </Option>
                        </Select>
                    </FormItem>
                </i-col> -->
                <i-col span="24">
                    <FormItem label="Actividad" prop="name">
                        <Input type="textarea" :autosize="{minRows: 4,maxRows: 4}" v-model="activity.name" placeholder="Actividad"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Responsables" prop="resp">
                        <Select multiple size="large" placeholder="Seleccionar" not-found-text="No hay usuarios disponibles" v-model="activity.resp">
                            <Option v-for="member in members" :key="'mbr_ur_'+member.id" :value="member.id" :label="member.name">
                                <div style="width: 100%" class="program-members--item light">
                                    <div class="program-members--item--avatar">
                                        <Avatar :src="member.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                    </div>
                                    <div class="program-members--item--info">
                                        <h4>{{ member.name }}</h4>
                                        <p>{{ member.program ? member.program.program.name : member.email }}</p>
                                    </div>
                                </div>
                            </Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Colaboraciones internas" prop="colb_int">
                        <Select multiple size="large" placeholder="Seleccionar" not-found-text="No hay usuarios disponibles" v-model="activity.colb_int">
                            <Option v-for="user in users" :key="'usr_lt_'+user.id" :value="user.id" :label="user.name">
                                <div style="width: 100%" class="program-members--item light">
                                    <div class="program-members--item--avatar">
                                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                    </div>
                                    <div class="program-members--item--info">
                                        <h4>{{ user.name }}</h4>
                                        <p>{{ user.program ? user.program.program.name : user.email }}</p>
                                    </div>
                                </div>
                            </Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Colaboraciones externas" prop="colb_ext">
                        <Input v-model="activity.colb_ext" placeholder="Colaboraciones externas"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Row v-for="(item, i) in activity.dates" :key="'act_dts_'+i">
                        <i-col span="20">
                            <Row>
                                <i-col span="12">
                                    <FormItem label="Inicio" :rules="{required: true, type: 'date', message: 'Fecha requerida', trigger: 'change'}" :prop="'dates.'+i+'.start'">
                                        <DatePicker type="date" placeholder="Seleccionar" v-model="item.start" format="dd/MM/yyyy"></DatePicker>
                                    </FormItem>
                                </i-col>
                                <i-col span="12">
                                    <FormItem label="Fin" :rules="{required: true, type: 'date', message: 'Fecha requerida', trigger: 'change'}" :prop="'dates.'+i+'.end'">
                                        <DatePicker type="date" placeholder="Seleccionar" v-model="item.end" format="dd/MM/yyyy"></DatePicker>
                                    </FormItem>
                                </i-col>
                            </Row>
                        </i-col>
                        <i-col span="4">
                            <div style="display: flex; height: 100%; align-items: flex-end; margin-bottom: 0rem;">
                                <Button v-if="i != 0" style="margin-bottom: 1.5rem;" type="error" @click="deleteDate(i)">Eliminar</Button>
                            </div>
                        </i-col>
                    </Row>
                </i-col>
                <i-col span="12">
                    <Button style="margin-top: 25px" size="large" long @click="addDate" type="primary" ghost>Agregar fecha</Button>
                    <!-- <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button> -->
                </i-col>
                <i-col span="12">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Crear actividad</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    // import MemberItem from './../../../../../components/Members/Item'

    export default {
        data(){
            return {
                show: false,
                users: [],
                results: [],
                members: [],
                activity: {
                    name: "",
                    result: "",
                    resp: [],
                    colb_int: [],
                    colb_ext: "",
                    dates: [
                        {
                            start: "",
                            end: "",
                        }
                    ]
                },
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Actividad es requerida', trigger: 'change' }
                    ],
                    result: [
                        { required: true, type: 'integer', message: 'Resultado es requerido', trigger: 'change' }
                    ],
                    resp: [
                        { required: true, type: 'array', message: 'Responsable es requerido', trigger: 'change' },
                    ],
                    colb_int: [
                        { required: false, type: 'array', message: 'Colab. internas es requerido', trigger: 'change' },
                    ],
                    colb_ext: [
                        { required: false, message: 'Colab. externas es requerido', trigger: 'change' },
                    ],
                },
            }
        },
        methods: {
            getUsers(){
                this.loading = true
                window.axios.post(this.$root.API_URL + `/projects/get-users`, { project_id: this.$parent.project.id })
                .then(response => {
                    if(response.data.success){
                        this.users = response.data.users
                        this.loading = false
                    }
                })
            },
            getMembers(){
                this.loading = true
                window.axios.post(this.$root.API_URL + `/projects/get-members/${this.$parent.project.id}`)
                .then(response => {
                    if(response.data.success){
                        this.members = response.data.members
                        this.loading = false
                    }
                })
            },
            // getResults(){
            //     window.axios.post(this.$root.API_URL + `/results/get-poa/${this.$parent.poa.id}`)
            //     .then(response => {
            //         if(response.data.success){
            //             this.results = response.data.results
            //         }
            //     })
            // },
            addDate(){
                this.activity.dates.push({
                    start: "",
                    end: "",
                })
            },
            deleteDate(i){
                this.activity.dates.splice(i, 1)
            },
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/activities/create/${this.activity.result}`, { ...this.activity })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-activities-caldendar--add-activity', response.data.activity)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.users = []
                this.members = []
                this.activity = {
                    name: "",
                    result: '',
                    resp: [],
                    colb_int: [],
                    colb_ext: "",
                    dates: [
                        {
                            start: "",
                            end: "",
                        }
                    ]
                }
            }
        },
        mounted(){
            window.EventBus.$on('poa-activities--create', (result) => {
                this.show = true
                this.getUsers()
                this.getMembers()
                this.activity.result = result.id
                // this.getResults()
            })
        },
        components: {
            // MemberItem
        }
    }
</script>