<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/proyecto/${project.slug}`">{{ project.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ project.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="budget" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />

            <br>
            <Row style="text-align: center">
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Disponible</strong></p>
                    <h2 style="font-weight: normal">{{ InitialBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Presupuesto</strong></p>
                    <h2 style="font-weight: normal">{{ TotalBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Variación</strong></p>
                    <h2 style="font-weight: normal">{{ DeficitBudget | currency }}</h2>
                </Col>
            </Row>
            <br>
            <br>
            <BudgetTabs tab="available" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />
            <br>
            <Collapse simple class="header-flex budget" v-model="collapse">
                <Panel v-for="activity in activities" :key="'budget_list_activity_'+activity.id" :name="activity.name" @click.native="getBudgetInfo(activity.id)">
                    <div class="budget-header--collapse-panel">
                        <div style="flex-grow: 1">
                            <p style="font-size: 0.9em; margin-top: 5px">
                                <strong>Resultado: </strong> {{ activity.result.name }}
                            </p>
                            <p style="font-size: 0.9em; margin-bottom: 5px"><b>Responsables: </b>
                                <span style="margin-right: 5px" v-for="(resp, i) in activity.responsible" :key="'poa_actvs_resp_'+resp.id">{{ resp.user.name }}{{ activity.responsible[i+1]  ? ',' : '' }}</span>
                            </p>
                            <h3>{{ activity.name }}</h3>
                        </div>
                        <h3 class="action">{{ activity.budget_total | currency }}</h3>
                    </div>
                    <template #content>
                        <div style="margin: 15px 30px">

                            <div :class="{ 'hidden': activity.type == null }">
                                <Row class="row-column-info-list" :gutter="16">
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>Financiadoras</b></p>
                                        <p v-for="funder in activity.funders" :key="`activity_${activity.id}_funder_${funder}`">
                                            {{ funder }}
                                            <!-- PROPUESTA RECHAZADA / NO SE ELIMINA DEBIDO A QUE EXISTE LA ESTRUCTURA DENTRO DEL WS.  -->
                                            <!-- <template v-if=" activity.funders_ct && activity.funders_ct[funder] ">
                                                <small ><strong>( {{ activity.funders_ct[funder] }} )</strong></small>
                                            </template> -->
                                        </p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>Tipo</b></p>
                                        <p style="text-transform: capitalize">{{ activity.type }}</p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>¿Dónde?</b></p>
                                        <p style="text-transform: capitalize">{{ activity.where }}</p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>¿Quién? (equipo/redes/externo)</b></p>
                                        <p style="text-transform: capitalize">{{ activity.who }}</p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>¿Cuántas/cuántos? (participantes, impresiones, etc)</b></p>
                                        <p style="text-transform: capitalize">{{ activity.quantity }}</p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>Fechas</b></p>
                                        <p style="text-transform: capitalize" v-for="date in activity.dates" :key="'poa_actvs_dts_'+date.id">
                                            <template v-if="date.start == date.end">
                                                {{ date.start | moment('DD/MMMM/yyyy') }}
                                            </template>
                                            <template v-else>
                                                {{ date.start | moment('DD/MMMM/yyyy') }} <span style="text-transform: lowercase">a</span> {{ date.end | moment('DD/MMMM/yyyy') }}
                                            </template>
                                        </p>
                                    </i-col>
                                    <i-col v-if="activity.colabs.length > 0" :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>Colaboraciones internas</b></p>
                                        <AvatarList :users="getColabs(activity)" />
                                    </i-col>
                                    <i-col v-if="activity.external_colabs != null" :xs="24" :sm="24" :md="8" :lg="8">
                                        <p><b>Colaboraciones externas</b></p>
                                        <p>{{ activity.external_colabs }}</p>
                                    </i-col>
                                    <i-col :xs="24" :sm="24" :md="8" :lg="8">
                                        <Button v-if="CanModify" @click="updateActivityBudgetProp(activity)" style="margin: 15px" ghost size="small" type="warning">Editar</Button>
                                    </i-col>
                                </Row>
                                <br>
                                
                                <template v-if=" ! activity.is_budget_loaded && activity.is_budget_loading ">
                                    <LoadingContainer />
                                </template>
                                <template v-else>
                                    <Collapse simple class="header-flex">
                                        <Panel v-for="detail in activity.budget_details" :key="'budget_list_activity_heading_'+detail.heading.id" :name="detail.heading.name">
                                            <div class="budget-header--collapse-panel">
                                                <h4 style="flex-grow: 1">{{ detail.heading.name }}</h4>
                                                <h4 class="action">{{ detail.heading.total | currency }}</h4>
                                            </div>
                                            <template #content>
                                                <div style="margin: 0 10px">
                                                    <List>
                                                        <div v-for="d_subheading in detail.subheadings" :key="'budget_list_activity_heading_sub_'+d_subheading.subheading.id">

                                                            <div class="bgt--subheader--container">
                                                                <Row :gutter="16" style="width: 100% !important;">
                                                                    <!-- Subheading header  -->
                                                                    <i-col :xs="24" :sm="24" :md="24" :lg="24">
                                                                        <div style="display: flex; align-items: center;">
                                                                            <Tooltip content="Agregar concepto">
                                                                                <Button v-if="CanCreateBudgetConcept" @click="createBudgetForHeadingActivity(activity, detail.heading, d_subheading.subheading)" type="primary" size="small" shape="circle" icon="md-add-circle"></Button>
                                                                            </Tooltip>
                                                                            <Tooltip content="Tabulador de precios">
                                                                                <Button @click="watchPrices(detail.heading, d_subheading.subheading)" style="margin-left: 10px" size="small" shape="circle" icon="md-pricetag"></Button>
                                                                            </Tooltip>
                                                                            <h4 style="flex-grow: 1; margin-left: 10px; margin-right: 10px; color: #666">{{ d_subheading.subheading.name }}</h4>
                                                                        </div>
                                                                    </i-col>
                                                                    <!-- Subheading budget input -->
                                                                    <i-col v-for="(budget, i) in d_subheading.budgets" :key="'budget_list_activity_heading_sub_'+d_subheading.subheading.id+'_budget_'+budget.id" offset="1" :xs="24" :sm="24" :md="23" :lg="23">
                                                                        <Row class="bgt--subheader--item" :gutter="16" style="width: 100% !important;">
                                                                            <i-col class="bgt--subheader--actions" :xs="24" :sm="24" :md="1" :lg="1">
                                                                                <template>
                                                                                    <template v-if="budget.authorized">
                                                                                        <Tooltip content="Cancelar autorización">
                                                                                            <Button :disabled="!CanAuthorizeBudget" @click="toggleBudgetAuthState(activity, budget)" style="margin-top: -25px; background-color: #2ecc71; border-color: #2ecc71" size="small" type="primary" shape="circle" icon="md-checkmark-circle"></Button>
                                                                                        </Tooltip>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <Tooltip content="Autorizar concepto">
                                                                                            <Button :disabled="!CanAuthorizeBudget" @click="toggleBudgetAuthState(activity, budget)" style="margin-top: -25px" size="small" type="text" shape="circle" icon="md-checkmark-circle"></Button>
                                                                                        </Tooltip>
                                                                                    </template>
                                                                                </template>
                                                                            </i-col>
                                                                            <i-col :xs="24" :sm="24" :md="6" :lg="6">
                                                                                <p class="bgt--subheader--label">Financiadora</p>
                                                                                <Select v-model="budget.funders_id" :disabled="FunderSelectDisabled(budget)" @on-change="updateBudget(activity, budget)"  style="width: 100%">
                                                                                    <Option v-for="funder in funders" :disabled="funderHasHeading(funder, detail.heading.name)" :key="`budget_funder_list_${funder.id}`" :value="funder.id">{{ funder.name }}</Option>
                                                                                </Select>
                                                                            </i-col>
                                                                            <i-col :xs="24" :sm="24" :md="3" :lg="3">
                                                                                <p class="bgt--subheader--label">Porcentaje</p>
                                                                                <Input v-model="budget.funder_p" :disabled="FunderSelectDisabled(budget)" @on-change="onChange(activity, budget)" type="number" placeholder="30" style="width: 100%">
                                                                                    <template slot="append">
                                                                                        <span style="font-size: 0.8em; font-weight: bold;">%</span>
                                                                                    </template>
                                                                                </Input>
                                                                            </i-col>
                                                                            <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                                <!-- <p class="bgt--subheader--label">Cálculo</p> -->
                                                                                <p class="bgt--subheader--label">&nbsp;</p>
                                                                                <p style="margin-top: -6px; padding-left: 9px; border-bottom: 1px solid #ececec; height: 25px"><small>{{ budget.funder_cal | currency }} </small></p>
                                                                                <p style="font-weight: bold;"><small> = {{ budget.funder_p_calc | currency }}</small></p>
                                                                            </i-col>

                                                                            <template v-if="detail.heading.name == 'Honorarios'">
                                                                            <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                                <p class="bgt--subheader--label">Neto</p>
                                                                                <Input type="number" v-model="budget.initial_total_price" :disabled="BudgetInputDisabled(budget)" @on-change="onChange(activity, budget)" placeholder="Neto" style="width: 100%" />
                                                                            </i-col>
                                                                            </template>
                                                                            <template v-else>
                                                                                <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                                    <p>&nbsp;</p>
                                                                                </i-col>
                                                                            </template>

                                                                            <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                                <p v-if="detail.heading.name == 'Honorarios'" class="bgt--subheader--label">Bruto</p>
                                                                                <p v-else style="font-size: 76%" class="bgt--subheader--label">Monto Presupuestado</p>
                                                                                <Input type="number" v-model="budget.final_gross_price" :disabled="BudgetInputDisabled(budget)" @on-change="onChange(activity, budget)" placeholder="Neto" style="width: 100%" />
                                                                            </i-col>

                                                                            <i-col class="bgt--subheader--actions" :xs="24" :sm="24" :md="1" :lg="1">
                                                                                <template v-if="CanDeleteConcept">
                                                                                    <template v-if="i >= 0 && budget.transfers.length <= 0 || ! budget.authorized">
                                                                                        <Tooltip content="Eliminar concepto">
                                                                                            <Button @click="deleteBudget(activity, budget)" style="margin-top: -25px" size="small" type="text" shape="circle" icon="md-trash"></Button>
                                                                                        </Tooltip>
                                                                                    </template>
                                                                                </template>
                                                                            </i-col>
                                                                        </Row>
                                                                    </i-col>
                                                                    

                                                                    <!--  -->
                                                                </Row>
                                                            </div>


                                                            <!-- <ListItem v-for="d_subheading in detail.subheadings" :key="'budget_list_activity_heading_sub_'+d_subheading.subheading.id">
                                                                <template v-if="detail.heading.name == 'Honorarios'">
                                                                    <Row :gutter="16" style="width: 100% !important;">
                                                                        <i-col :xs="24" :sm="24" :md="16" :lg="16">
                                                                            <div style="display: flex; align-items: center;">
                                                                                <Icon @click="watchPrices(d_subheading.subheading)" style="cursor: pointer" color="#843cf6" size="18" type="md-information-circle" />
                                                                                <p style="flex-grow: 1; margin-left: 10px; margin-right: 10px;">{{ d_subheading.subheading.name }}</p> -->

                                                                                <!-- <Tooltip v-if="$can('poa.budget.authorize')" content="Autorizar" placement="left">
                                                                                    <Button :disabled="disabledStateAuthBudgetButton(d_subheading.subheading.budget)" @click="toggleBudgetAuthState(activity, subheading.budget)" shape="circle" :type="authBudgetButtonType(d_subheading.subheading.budget)" icon="ios-checkmark-circle"/>
                                                                                </Tooltip> -->

                                                                            <!-- </div>
                                                                        </i-col> -->

                                                                        <!-- <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                            <Input type="number" v-model="d_subheading.subheading.budget.initial_gross_price" placeholder="Neto" style="width: 100%" :disabled="d_subheading.subheading.budget.authorized == true || $can('poa.budget.available.edit') == false" />
                                                                        </i-col>
                                                                        <i-col :xs="24" :sm="24" :md="4" :lg="4">
                                                                            <Input type="number" @on-change="onChange(d_subheading.subheading.budget, d_subheading.subheading, activity)" v-model="d_subheading.subheading.budget.final_gross_price" placeholder="Bruto" style="width: 100%" :disabled="d_subheading.subheading.budget.authorized == true || $can('poa.budget.available.edit') == false" />
                                                                        </i-col> -->

                                                                    <!-- </Row>
                                                                </template>
                                                            </ListItem> -->

                                                        </div>
                                                    </List>
                                                </div>
                                            </template>
                                        </Panel>
                                    </Collapse>
                                </template>
                            </div>

                            <div v-show="activity.type == null">
                                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 10px">Se requiere agregar más información a esta actividad para continuar con este proceso</p>
                                    <Button v-if="CanAddMoreInformationOnActivity" @click="updateActivityBudgetProp(activity)" type="primary">Agregar información</Button>
                                </div>
                            </div>

                        </div>
                    </template>
                </Panel>
            </Collapse>

            <UpdateBudgetActivityProp v-if="CanAddMoreInformationOnActivity" :funders="funders"/>
            <WatchSubheadingPrices/>
        </template>
    </div>
</template>

<script>
    import POATabs from './../../../Tabs'
    import BudgetTabs from './../Tabs'
    import AvatarList from './../../../../../../General/AvatarList'
    import projectminxin from './../../../../../../../mixins/project'
    import { mapGetters } from 'vuex'
    import WatchSubheadingPrices from './components/Prices'
    import UpdateBudgetActivityProp from './components/UpdateBudget'

    export default {
        mixins: [ projectminxin ],
        data(){
            return {
                headings: [],
                timer_id: null,
                collapse: '',
                poa: null,
                funders: [],
                project: null,
                activities: [],
                is_loading: true,
                members: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                program_member: 'program',
            }),
            CanModify(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserCoordOfThisProject()
            },
            CanAddMoreInformationOnActivity(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserCoordOfThisProject()
            },
            CanCreateBudgetConcept(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserMembersOfThisProject()
            },
            CanAuthorizeBudget(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.$can('poa.budget.authorize');
            },
            CanDeleteConcept(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserCoordOfThisProject()
            },
            InitialBudget(){
                let total = 0

                window._.each(this.funders, (f) => {
                    window._.each(f.budget, (b) => {
                        total += parseFloat(b.final_total_price)
                    })
                })

                return total
            },
            TotalBudget(){
                let total = 0

                window._.each(this.activities, (activity) => {
                     let ps = parseFloat(activity.budget_total)
                    if( ps ) total += ps
                })

                return total
            },
            DeficitBudget(){
                return this.InitialBudget - this.TotalBudget
            }
        },
        methods: {
            FunderSelectDisabled(budget){
                if( budget.authorized ) return true
                else if ( this.$itsSuperAdmin() ) return false
                else if ( this.$itsAdminTeammate() ) return false
                else if( this.isAuthUserCoordOfThisProject() ) return false
                else return true
            },
            BudgetInputDisabled(budget){
                if( budget.authorized ) return true
                else if ( this.$itsSuperAdmin() ) return false
                else if ( this.$itsAdminTeammate() ) return false
                else if ( this.isAuthUserMembersOfThisProject() ) return false
                else return true
            },
            disabledStateAuthBudgetButton(budget){
                return budget.id == null
            },
            authBudgetButtonType(budget){
                return budget.authorized == true ? 'success' : 'text'
            },
            toggleBudgetAuthState(activity, budget){
                this.$Modal.confirm({
                    title: `${budget.authorized == true ? 'Desautorizar' : 'Autorizar'} presupuesto`,
                    content: `<p>¿Deseas ${budget.authorized == true ? 'desautorizar' : 'autorizar'} este presupuesto?</p>`,
                    okText: `${budget.authorized == true ? 'Desautorizar' : 'Autorizar'}`,
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/update-activity-auth-budget/${budget.id}`, { state: !budget.authorized })
                        .then(response => {
                            if(response.data.success){
                                budget.authorized = !budget.authorized
                                window.EventBus.$emit('poa-budget--update-activity-budget', activity, budget)
                                this.$Message.success('Presupuesto actualizado correctamente');
                            }
                        })
                    },
                });
            },
            getHeadings(){
                return new Promise((resolve) => {
                    window.axios.post(this.$root.API_URL + `/budget/headings/get`)
                    .then(response => {
                        if(response.data.success){
                            resolve(response.data.headings)
                        }
                    })
                })
            },
            updateActivityBudgetProp(activity){
                window.EventBus.$emit('poa-budget--update-activity-budget-props', activity)
            },
            onChange(activity, budget){
                clearTimeout(this.timer_id)

                this.timer_id = setTimeout(() => {
                    this.updateBudget(activity, budget)
                }, 1000)

            },
            getColabs(activity){
                let usrs = []

                window._.each(activity.colabs, (c) => {
                    usrs.push(c.user)
                })

                return usrs
            },
            createBudgetForHeadingActivity(activity, heading, subheading){
                window.axios.post(this.$root.API_URL + `/budget/create-activity-budget/${activity.id}`, { activity_id: activity.id, heading_id: heading.id, subheading_id: subheading.id  })
                .then(response => {
                    if(response.data.success){
                        // window.EventBus.$emit('poa-budget--update-activity', response.data.activity)
                        let ai = window._.findIndex(this.activities, a => a.id == activity.id)
                        let hi = window._.findIndex(this.activities[ai].budget_details, bd => bd.heading.id == heading.id)
                        let si = window._.findIndex(this.activities[ai].budget_details[hi].subheadings, s => s.subheading.id == subheading.id)

                       this.activities[ai].budget_details[hi].subheadings[si].budgets.push(response.data.budget)

                        this.$Message.success('Presupuesto actualizado')
                    }
                })
            },
            updateBudget(activity, budget){
                let final_gross_price = budget.final_gross_price || 0
                let initial_total_price = budget.initial_total_price || 0
                if( budget.heading.name == 'Honorarios' && budget.subheading.name != 'Becas' ){
                    final_gross_price = parseFloat(initial_total_price) / 0.95333333334  * 1.16
                    final_gross_price = final_gross_price.toFixed(2)
                    budget.final_gross_price = final_gross_price
                }
                else {
                    final_gross_price = budget.final_gross_price
                }

                window.axios.post(this.$root.API_URL + `/budget/update-activity-budget/${budget.id}`, { final_gross_price: final_gross_price,  initial_total_price: initial_total_price, funders_id: budget.funders_id, funder_p: budget.funder_p })
                .then(response => {
                    if(response.data.success){
                        // window.EventBus.$emit('poa-budget--update-activity-totals', response.data.activity)
                        // this.calcFunderPercentageByActivity(response.data.activity, budget)
                        window.EventBus.$emit('poa-budget--update-activity-totals', activity)
                        this.$Message.success('Presupuesto actualizado')
                        // this.calcFunderPercentage(budget)
                        // this.calcFunderPercentageText(budget)
                    }
                })
            },
            deleteBudget(activity, budget){
                this.$Modal.confirm({
                    title: `Eliminar concepto`,
                    okText: `Aceptar`,
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/budget/delete-activity-budget/${budget.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-budget--update-activity-delete-budget', activity, budget)
                                window.EventBus.$emit('poa-budget--update-activity-totals', activity)
                                // this.calcFunderPercentageByActivity(activity)
                                this.$Message.success('Presupuesto actualizado')
                            }
                        })
                    },
                })
                

            },
            // getTotalOfHeading(heading){
            //     let total = 0

            //     window._.each(heading.subheadings, (sub) => {
            //         let ps = parseFloat(sub.budget.final_gross_price)
            //         if( ps ) total += ps
            //     })

            //     return total
            // },
            getTotalOfActivity(activity){
                let total = 0

                window._.each(activity.budget, (budget) => {
                    let ps = parseFloat(budget.final_gross_price)
                    if( ps ) total += ps
                })

                return total
            },
            watchPrices(heading, subheading){
                window.EventBus.$emit('poa-budget--watch-subheading-prices', heading, subheading)
            },
            getBudgetInfo(activity_id){
                // Find if has budget data loaded
                let ai = window._.findIndex(this.activities, a => a.id == activity_id)
                if( ai != -1 ){
                    let acty = this.activities[ai]
                    if( acty.is_budget_loaded ) return
                    else if (acty.type == null) return
                    else this.fetchBudgetInfo(activity_id)
                }
            },
            fetchBudgetInfo(activity_id){
                window.axios.post(this.$root.API_URL + `/activities/budget/${activity_id}`)
                .then(response => {
                    if(response.data.success){
                        let ai = window._.findIndex(this.activities, a => a.id == activity_id)
                        if( ai != -1 ){
                            let acty = this.activities[ai]
                            let n_acty = {
                                ...acty,
                                //budget: response.data.budget,
                                budget_details: response.data.budget_details,
                                is_budget_loaded: true,
                                is_budget_loading: false,
                            }

                            window._.each(n_acty.budget_details, bgt => {
                                window._.each(bgt.subheadings, subheading => {
                                    window._.each(subheading.budgets, budget => {
                                        this.calcFunderPercentage(budget)
                                        this.calcFunderPercentageText(budget)
                                    })
                                })
                            })

                            setTimeout(() => {
                                window.EventBus.$emit('poa-budget--update-activity', n_acty)
                            }, 500)
                        }
                    }
                })
            },
            funderHasHeading(funder, heading_name){
                let fi = window._.findIndex(funder.budget, b => b.heading.name === heading_name)
                return fi == -1
            },
            calcFunderPercentage(budget){
                if( budget.funders_id == null && budget.funder_p == null ) return 0
                else {
                    let funder_heading_total = 0
                    let fi = window._.findIndex(this.funders, f => f.id == budget.funders_id)

                    if( fi != -1 ){
                        // Find with the same subheadings, priority
                        let sub_items = window._.filter(this.funders[fi].budget, b => b.subheading != null && b.subheading.id == budget.subheading.id)
                        // Find with the same heading
                        let hed_items = window._.filter(this.funders[fi].budget, b => b.heading.id == budget.heading.id)
                        
                        if( sub_items.length > 0 ){
                            window._.each(sub_items, bgt => {
                                funder_heading_total += parseFloat(bgt.final_total_price)
                            })
                        }
                        else {
                            window._.each(hed_items, bgt => {
                                funder_heading_total += parseFloat(bgt.final_total_price)
                            })
                        }

                    }

                    budget.funder_cal = funder_heading_total

                    return funder_heading_total
                }
            },
            calcFunderPercentageText(budget){
                if( budget.funder_cal == undefined ) return 0
                else {
                    let pp = parseFloat(budget.funder_p) / 100
                    let p_t = budget.funder_cal * pp
                    budget.funder_p_calc = p_t
                    return p_t
                }
            },
            calcFunderPercentageByActivity(activity, budget){
                let ai = window._.findIndex(this.activities, a => a.id == activity.id)

                if( ai != -1 ){
                    window._.each(this.activities[ai].budget_details, bgt => {
                        window._.each(bgt.subheadings, subheading => {
                            let filtr = window._.filter(subheading.budgets, b => b.id == budget.id)

                            window._.each(filtr, budget => {
                                this.calcFunderPercentage(budget)
                                this.calcFunderPercentageText(budget)
                                // window.EventBus.$emit('poa-budget--update-activity-totals', activity)
                            })

                        })
                    })
                }

            },
            getPOA(project_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${project_slug}/get-project`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.project = response.data.project
                        this.poa = response.data.poa
                        this.members = response.data.members
                        this.getFunders()
                        this.getActivities()
                        // this.getResults()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.project.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getFunders(){
                window.axios.post(this.$root.API_URL + `/budget/get-funders/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.funders = response.data.funders
                    }
                })
            },
            getActivities(){
                window.axios.post(this.$root.API_URL + `/activities/get-budget/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        window._.each(response.data.activities, act => {
                            this.activities.push({
                                ...act,
                                is_budget_loaded: false,
                                is_budget_loading: true,
                            })
                        })
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){
            let poa_year = this.$route.params.year
            let project_slug = this.$route.params.name

            this.getPOA(project_slug, poa_year)

           /* Collapse drawer menu */
            this.$collpaseDrawer('projects-content')

            window.EventBus.$on('poa-budget--update-collapse', (activity) => {
                let self = this
                setTimeout(() => {
                    self.collapse = ''
                    self.collapse = activity.name
                }, 500)
            })

            window.EventBus.$on('poa-budget--calc-for-activity', (activity) => {
                this.calcFunderPercentageByActivity(activity)
            })

            window.EventBus.$on('poa-budget--update-activity', (activity) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    this.$set(this.activities, ai, activity)
                }

            })

            window.EventBus.$on('poa-budget--update-activity-totals', (activity) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    let nwact = {
                        ...activity,
                    }

                    let nw_act_budget_total = 0
                    window._.each(nwact.budget_details, bd => {
                        let nw_heading_total = 0
                        window._.each(bd.subheadings, sh => {
                            window._.each(sh.budgets, budget => {
                                nw_heading_total += parseFloat(budget.final_gross_price || 0)
                                this.calcFunderPercentageByActivity(activity, budget)
                            })
                        })
                        bd.heading.total = nw_heading_total
                        if( nw_heading_total ) nw_act_budget_total += nw_heading_total
                    })

                    nwact.budget_total = nw_act_budget_total

                    this.$set(this.activities, ai, nwact)
                }

            })

            window.EventBus.$on('poa-budget--update-activity-delete-budget', (activity, budget) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    
                    let nwact = {
                        ...activity,
                    }

                    let dbi = window._.findIndex(nwact.budget_details, bd => bd.heading.id == budget.heading.id)
                    let dbsi = window._.findIndex(nwact.budget_details[dbi].subheadings, sb => sb.subheading.id == budget.subheading.id)
                    let dbbi = window._.findIndex(nwact.budget_details[dbi].subheadings[dbsi].budgets, b => b.id == budget.id)

                    if( dbbi != -1 ){
                        nwact.budget_details[dbi].subheadings[dbsi].budgets.splice(dbbi, 1)
                        this.$set(this.activities, ai, nwact)
                    }

                }

            })
            
            window.EventBus.$on('poa-budget--update-activity-budget', (activity, budget) => {
                let ai = window._.findIndex(this.activities, a => a.id == activity.id)
                if( ai != -1 ){
                    let dbi = window._.findIndex(activity.budget_details, bd => bd.heading.id == budget.heading.id)
                    let dbsi = window._.findIndex(activity.budget_details[dbi].subheadings, sb => sb.subheading.id == budget.subheading.id)
                    let dbbi = window._.findIndex(activity.budget_details[dbi].subheadings[dbsi].budgets, b => b.id == budget.id)

                    if( dbbi != -1 ) this.$set(this.activities[ai].budget_details[dbi].subheadings[dbsi].budgets, dbbi, budget)
                }
            })

        },
        components: {
            POATabs,
            BudgetTabs,
            AvatarList,
            WatchSubheadingPrices,
            UpdateBudgetActivityProp,
        }
    }
</script>