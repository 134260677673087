<template>
    <Modal class-name="scrollable vertical-center-modal min-height-700" v-model="show" :footer-hide="true" :scrollable="true">
        <div class="activity-comments--container">
            <LoadingContainer v-if="is_loading"/>
            <template v-else>

                <template v-if="comments.length == 0">
                    <div style="flex: 1; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <Icon size="30" type="md-chatbubbles" />
                        <p style="margin-top: 10px; text-align: center; color: #515a6e;">Esta actividad no tiene comentarios</p>
                    </div>
                </template>
                <template v-else>
                    <div class="activity-comments--comments">

                        <div v-for="comment in comments" :key="`comment_id_${comment.id}`" class="activity-comments--comment">
                            <div class="activity-comments--comment--user">
                                <Avatar :src="comment.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                                <div style="margin-left: 15px">
                                    <h3>{{ comment.user.name }}</h3>
                                    <p style="font-size: 0.9em; color: grey; margin-top: -2px">{{ comment.user.role_name }} {{ comment.user.program ? `en ${comment.user.program.program.name}` : '' }}</p>
                                </div>
                            </div>
                            <div class="activity-comments--comment--content">
                                <p>{{ comment.comment }}</p>
                                <p class="activity-comments--comment--date">{{ comment.created_at | moment('DD/MM/YY HH:mm') }}</p>
                            </div>
                        </div>

                    </div>
                </template>
                <div class="activity-comments--form">
                    <Row :gutter="16" type="flex" justify="center" align="middle"> 
                        <i-col span="24">
                            <Form ref="formInline" :model="comment" :rules="rules">
                                <FormItem prop="comment" style="width: 100%; margin-bottom: 0;">
                                    <Input style="width: 100%; margin-bottom: 0;" :rows="3" type="textarea" :autosize="false" v-model="comment.comment" placeholder="Comentario"></Input>
                                </FormItem>
                            </Form>
                        </i-col>
                        <i-col span="24">
                            <div style="padding-top: 15px; position: relative;">
                                <Button @click="makeComment" :loading="loading_button" style="float: right;" icon="md-send" type="primary">Comentar</Button>
                            </div>
                        </i-col>
                    </Row>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                comment: {
                    comment: '',
                },
                is_loading: true,
                loading_button: false,
                comments: [],
                rules: {
                    comment: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                }
            }
        },
        methods: {
            fetchComments(){
                window.axios.post(this.$root.API_URL + `/activities/get-comments/${this.activity.id}`)
                .then(response => {
                    if(response.data.success){
                        this.comments = response.data.comments
                        this.is_loading = false
                    }
                })
            },
            makeComment(){
                this.$refs.formInline.validate(v => {
                    if( v ){
                        this.loading_button = true
                        window.axios.post(this.$root.API_URL + `/activities/create-comment/${this.activity.id}`, { ...this.comment })
                        .then(response => {
                            if(response.data.success){
                                this.comments.unshift(response.data.comment)
                                window.EventBus.$emit('poa-budget-request--update-activity', {
                                    ...this.activity,
                                    comments_count: this.activity.comments_count += 1,
                                })
                                this.clear()
                                this.loading_button = false
                            }
                        })
                    }
                })
            },
            clear(){
                this.comment.comment = ''
                this.$refs.formInline.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--activity-comments', activity => {
                this.is_loading = true
                this.comments = []
                this.activity = activity
                this.fetchComments()
                this.show = true
            })
        }
    }
</script>