import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'status_poa',
            'status_des',
            'status_comp',
        ]),
    },
    methods: {
        buildObjectForPoa() {
            let a = []

            window._.each(this.status_poa, (s) => {
                a.push({
                    name: s.short_name,
                    value: 0,
                    hex_color: s.hex_color,
                })
            })

            a.push({
                name: 'P',
                value: 0,
                hex_color: '#ececec',
            })

            return a
        },
        buildObjectForResultsDesemp() {
            let a = []

            window._.each(this.status_des, (s) => {
                a.push({
                    name: s.short_name,
                    value: 0,
                    hex_color: s.hex_color,
                })
            })

            a.push({
                name: 'P',
                value: 0,
                hex_color: '#ececec',
            })

            return a
        },
        buildObjectForCompetences() {
            let a = []

            window._.each(this.status_comp, (s) => {
                a.push({
                    name: s.short_name,
                    value: 0,
                    hex_color: s.hex_color,
                })
            })

            a.push({
                name: 'P',
                value: 0,
                hex_color: '#ececec',
            })

            return a
        },
        // buildObject(exclude_na = false) {
        //     let all_status = []
        //     if (exclude_na) all_status = window._.filter(this.ev_status, s => {
        //         return s.name != 'No aplica'
        //     })
        //     else all_status = this.ev_status

        //     let a = []
        //     window._.each(all_status, (s) => {
        //         a.push({
        //             name: s.short_name,
        //             value: 0,
        //             hex_color: s.hex_color,
        //         })
        //     })
        //     return a
        // },
        getResultsChartData(results, type) {
            let ev_object = this.buildObjectForPoa()
            let g = window._.groupBy(results, `${type}.status.short_name`)

            if (window._.isEmpty(g)) g.P = ["1"]
            
            window._.each(ev_object, e => {
                e.value = g[e.name] ? g[e.name].length : 0
            })

            return ev_object
        },
        getResultsDesempChartData(results) {
            let ev_object = this.buildObjectForResultsDesemp()

            if (window._.isEmpty(results)) ev_object.P = ["1"]
            
            window._.each(ev_object, e => {
                e.value = results[e.name] ? results[e.name] : 0
            })

            return ev_object
        },
        getCompetencesChartData(results) {
            let ev_object = this.buildObjectForCompetences()

            if (window._.isEmpty(results)) ev_object.P = ["1"]
            
            window._.each(ev_object, e => {
                e.value = results[e.name] ? results[e.name] : 0
            })

            return ev_object
        },
        // getCompetencesChartData(competences) {
        //     let ev_object = this.buildObject(true)

        //     window._.each(ev_object, e => {
        //         e.value = competences[e.name] || 0
        //     })

        //     return ev_object
        // }
    },
}