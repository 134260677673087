<template>
    <Grid/>
</template>

<script>
    import Grid from './../components/Program/Grid'

    export default {
        components: {
            Grid,
        }
    }
</script>