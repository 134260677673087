<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan estratégico</BreadcrumbItem>
                <BreadcrumbItem>{{ project.name }}</BreadcrumbItem>
            </Breadcrumb>

            <Row :gutter="24">
                <i-col :xs="24" :sm="24" :md="24" :lg="24">
                    <h2 class="program--title">{{ project.name }} <Button style="margin-left: 15px" ghost type="primary" size="small" @click="collapseMembers">Integrantes</Button></h2>
                    <Button v-if="$can('programs.create')" style="float: right" type="primary" @click="updateProject">Editar proyecto</Button>
                    <br>
                    <!-- Integrantes -->
                    <div class="accordion-cont" id="tdc-members-div" aria-expanded="false">
                        <div class="accordion-cont--wrapper">
                            <div class="program-members--container">
                                <template v-if="members.length == 0">
                                    <EmptyState icon="md-contacts" :title="`${project.name} no tiene integrantes`" />
                                </template>
                                <template v-else>
                                    <h3>Integrantes</h3>
                                    <br>
                                    <div v-for="program_member in Programs" :key="`proj_mmb_lss_${program_member.name}`">
                                        <h3>{{ program_member.name }}</h3>
                                        <div style="margin-top: 0" class="program-members--items">
                                            <div v-for="user in program_member.members" :key="`proj_mmb_lss_${program_member.name}_usr_${user.id}`" class="program-members--item">
                                                <div class="program-members--item--avatar">
                                                    <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                                </div>
                                                <div class="program-members--item--info">
                                                    <h4>{{ user.name }}</h4>
                                                    <p>{{ user.email }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </template>
                            </div>
                        </div>
                    </div>
                </i-col>
            </Row>
             <br>
            <h3>POAs</h3>
            <br>

            <UpdateProject  v-if="$can('programs.create')" />

            <POAsList :program="project" :poas="poas" type="project"/>
        </template>
    </div>
</template>

<script>
    import POAsList from './../Program/components/POAList'
    import UpdateProject from './components/UpdatePopup'

    export default {
        data(){
            return {
                is_loading: true,
                project: null,
                members: [], 
                poas: [],
            }
        },
         watch: {
            '$route.params.name': function (name) {
                this.is_loading = true
                this.project = null
                 this.getProject(name)
            },
            'collapse_tdc': function(v_new){
                if( v_new == '0' ){
                    if( this.collapse_ev_tdc == '1' ) this.collapse_ev_tdc = '0'
                }
            }
        },
        computed: {
            Programs(){
                let prgs = []
                let programs = window._.groupBy(this.members, 'program_name')

                window._.each(programs, (program) => {
                    let item = {
                        name: program[0].program_name,
                        members: program,
                    }

                    prgs.push(item)
                })

                return prgs
            }
        },
        methods: {
            getProject(slug){
                window.axios.post(this.$root.API_URL + '/projects/get-project', { field: 'slug', value: slug })
                .then(response => {
                    if(response.data.success){
                        this.project = response.data.project
                        // this.results = response.data.results
                        this.poas = response.data.poas
                        this.members = response.data.members
                        this.is_loading = false

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.project.name)
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            collapseMembers(){
                this.$collapse('tdc-members-div')
            },
            updateProject(){
                window.EventBus.$emit('project-grid--update', this.project, this.Programs)
            }
        },
        mounted(){
            let project_slug = this.$route.params.name
            this.getProject(project_slug)

            /* Collapse drawer menu */
            this.$collpaseDrawer('projects-content')

            window.EventBus.$on('project-details--update-project', () => {
                location.reload()
            })

            window.EventBus.$on('program-details--add-poa-item', (poa) => {
                this.poas.push(poa)
            })

            window.EventBus.$on('program-details--remove-poa', (poa) => {
                let pi = window._.findIndex(this.poas, p => {
                    return p.id == poa.id
                })

                if( pi != -1 ){
                    this.poas.splice(pi, 1)
                    this.$Message.success('POA eliminado correctamente')
                }
            })
        },
        components: {
            POAsList,
            UpdateProject,
        }
    }
</script>