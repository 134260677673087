<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <template v-if="message == ''">
                <Breadcrumb>
                    <BreadcrumbItem to="/evaluaciones/mi-equipo">Resultados de evaluación de mi equipo</BreadcrumbItem>
                    <BreadcrumbItem>{{ evaluation.name }} - {{ evaluated.name }}</BreadcrumbItem>
                </Breadcrumb>

                <Row type="flex" align="middle">
                    <i-col span="20">
                        <h1 class="section--subtitle">Evaluación <span>{{ evaluation.name }}</span> del equipo de <span>{{ evaluated.name }}</span></h1>
                    </i-col>
                </Row>
                <br><br>

                <Row :gutter="16">
                    <i-col v-if="HasResultsEvaluations" span="12">
                        <h2 style="text-align: center;">Resultados</h2>
                        <SemiCircle :data="buildResultsDesempChartData(results_status)" />
                        <!-- <h4 style="text-align: center">Promedio: {{ avg.results.original }} / {{ avg.results.rounded }}</h4> -->
                        <div style="display: flex; align-items: center; justify-content: center;" >
                            <div :style="{ 'background-color': getResultsAvgStatusColor() }" class="indicator--tag"></div>
                            <p style="font-weight: bold; font-size: 0.9em">{{ getResultsAvgStatusName() }}</p>
                        </div>
                    </i-col>
                    <i-col :span="HasResultsEvaluations ? 12 : 24">
                        <h2 style="text-align: center;">Competencias</h2>
                        <SemiCircle :data="buildCompetencesChartData(competences_status)" />
                        <!-- <h4 style="text-align: center">Promedio: {{ avg.competences.original }} / {{ avg.competences.rounded }}</h4> -->
                        <div style="display: flex; align-items: center; justify-content: center;" >
                            <div :style="{ 'background-color': getCompetenceAvgStatusColor() }" class="indicator--tag"></div>
                            <p style="font-weight: bold; font-size: 0.9em">{{ getCompetenceAvgStatusName() }}</p>
                        </div>
                    </i-col>
                </Row>
                <br><br>
                <Tabs :value="tab">
                    <TabPane v-if="HasResultsEvaluations" label="Planeación Operativa Anual (POA)" name="results">
                        <EvaluationScale :items="status_des" />
                        <br>

                        <div class="my-team-evaluation" v-for="(evaluation, i) in Results" :key="`evaluation_item_${i}`">
                            <h3>{{ evaluation.name }}</h3>
                            <div class="my-team-evaluation--item" v-for="(ev, j) in evaluation.evs" :key="`evaluation_item_${i}_evs_${j}`">
                                <template v-if="shouldShowEvaluation(ev)">
                                    <div class="my-team-evaluation--item--tag" >
                                        <div :style="{ 'background-color': ev.status.hex_color }" class="indicator--tag"></div>
                                        <p style="font-weight: bold; font-size: 0.9em">{{ ev.status.name }}</p>
                                    </div>
                                    <p style="white-space: pre-wrap;">{{ ev.comment }}</p>
                                </template>
                            </div>
                        </div>

                    </TabPane>
                    <TabPane label="Competencias" name="competences">
                        <EvaluationScale :items="status_comp" />
                        <br>

                        <div class="my-team-evaluation" v-for="(evaluation, i) in Competences" :key="`evaluation_item_${i}`">
                            <h3>{{ evaluation.name }}</h3>
                            <div class="my-team-evaluation--item" v-for="(ev, j) in evaluation.evs" :key="`evaluation_item_${i}_evs_${j}`">
                                <template v-if="shouldShowEvaluation(ev)">
                                    <div class="my-team-evaluation--item--tag" >
                                        <div :style="{ 'background-color': ev.status.hex_color }" class="indicator--tag"></div>
                                        <p style="font-weight: bold; font-size: 0.9em">{{ ev.status.name }}</p>
                                    </div>
                                    <p style="white-space: pre-wrap;">{{ ev.comment }}</p>
                                </template>
                            </div>
                        </div>
                        
                    </TabPane>
                    <TabPane v-if="HasEvResults" label="Resultados de evaluación" name="ev-results">
                       <br>
                       <h2>Fortalezas/Reconocimiento</h2>
                        <p style="margin-bottom: 10px" v-for="(comment, i) in comments.strengths" :key="`strengths_${i}`">{{ comment }}</p>

                       <br>
                       <h2>Áreas de oportunidad</h2>
                        <p style="margin-bottom: 10px" v-for="(comment, i) in comments.opportunities" :key="`opportunities_${i}`">{{ comment }}</p>
                       
                       <br>
                       <h2>Compromisos y plan de acción</h2>
                        <p style="margin-bottom: 10px" v-for="(comment, i) in comments.compromises" :key="`compromises_${i}`">{{ comment }}</p>

                    </TabPane>
                </Tabs>
            </template>
            <template v-else>
                <br>
                <h2 style="text-align: center;">{{ message }}</h2>
            </template>

        </div>
    </div>
</template>

<script>
    import chartMix from './../../../../mixins/chart'
    import SemiCircle from './../../../Charts/CustomSemiCircle'
    import EvaluationScale from './../../../EvaluationScales/Legends'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [chartMix],
        data(){
            return {
                tab: 'results',
                evaluated: null,
                avg: null,
                message: '',
                loading: true,
                evaluation: null,
                evaluations: [

                ],
                comments: {
                    strengths: [],
                    opportunities: [],
                    compromises: [],
                },
                results_status: null,
                competences_status: null,
            }
        },
        watch: {
            'evaluations': function(){
                if( this.Results.length == 0 ) this.tab = 'competences'
            },
        },
        computed: {
            ...mapGetters([
                'user',
                'status',
                'status_des',
                'status_comp',
            ]),
            Statuses(){
                return this.status
            },
            EvaluationsHasInfo(){
                return ! this.message != ''
            },
            Results(){
                return window._.filter(this.evaluations, e => e.type == 'result')
            },
            Competences(){
                let cs = window._.filter(this.evaluations, e => e.type == 'special-competence')
                return window._.orderBy(cs, ['order'])
            },
            HasResultsEvaluations(){
                return this.Results.length > 0
            },
            HasEvResults(){
                return this.comments.compromises.length > 0 && this.comments.opportunities.length > 0 && this.comments.strengths.length > 0
            }
        },
        methods: {
            getEvaluations(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-my-team/${this.$route.params.year}/${this.$route.params.id}/dir`).then(response => {
                    if(response.data.success){
                        this.evaluated = response.data.user
                        this.evaluation = response.data.evaluation
                        this.evaluations = response.data.evaluations
                        if( response.data.comments.length > 0 ) this.comments = response.data.comments

                        // Avg results
                        this.avg = response.data.avg_results.avg
                        this.results_status = response.data.avg_results.results_status
                        this.competences_status = response.data.avg_results.competences_status

                        this.loading = false
                    }
                    else {
                        this.loading = false
                        this.message = response.data.message
                    }
                })
            },
            // getEvaluationResults(){
            //     window.axios.post(this.$root.API_URL + `/evaluation/get-evaluation-results/${this.$route.params.id}/${this.$route.params.year}`)
            //     .then(response => {
            //         if(response.data.success){
            //             this.avg = response.data.avg
            //             this.results_status = response.data.results_status
            //             this.competences_status = response.data.competences_status
            //             this.loading = false
            //         }
            //     })
            // },
            buildCompetencesChartData(competences){
                return this.getCompetencesChartData(competences)
            },
            buildResultsDesempChartData(results){
                return this.getResultsDesempChartData(results)
            },
            getCompetenceAvgStatusName(){
                let si = window._.findIndex(this.status_comp, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.status_comp[si].name
                else return 'NA'
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.status_comp, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.status_comp[si].hex_color
                else return '#ececec'
            },
            getResultsAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getResultsAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return '#ececec'
            },
            shouldShowEvaluation(item){
                return item.comment != null && item.status != null
            }
        },
        mounted(){
            this.$collpaseDrawer('evs-content')
            this.$deleteSubitemsActives()
            this.$markSubitemAsActive('Mi equipo')

            this.getEvaluations()
            // this.getEvaluationResults()
        },
        components: {
            SemiCircle,
            EvaluationScale,
        }
    }
</script>