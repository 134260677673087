<template>
    <div>

        <h2 class="section--subtitle">Misión y visión</h2>
        <br>

        <Form style="width: 100%" ref="cat1Form" :model="answers" :rules="ruleInline">
            <!-- Q1 -->
            <FormItem label="1. ¿Conoces la misión, visión y cadena de cambio propuestas desde el LSB?" prop="q1">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q1">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q2 -->
            <FormItem label="2.	¿Te identificas con la misión, visión y cadena de cambio del ILSB?" prop="q2">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q2">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q3 -->
            <FormItem label="3. ¿Qué es lo que más te gusta del ILSB? (puedes seleccionar tantas como quieras)" prop="q3">
                <div class="env-survey-field-answers">
                    <CheckboxGroup v-model="answers.q3" class="inline">
                        <Checkbox v-for="(answer, i) in q3_answers" :key="`answer_${i}`" :label="answer" border></Checkbox>
                    </CheckboxGroup>

                    <template v-if=" Q3OtherSelected ">
                        <FormItem label="¿Cuál?" prop="q3_other">
                            <Input style="width: 50%" v-model="answers.q3_other" placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </template>
                </div>
            </FormItem>

            <!-- Q4 -->
            <FormItem label="4. ¿Qué es lo que menos te gusta del ILSB? (puedes seleccionar tantas como quieras)" prop="q4">
                <div class="env-survey-field-answers">
                    <CheckboxGroup v-model="answers.q4" class="inline">
                        <Checkbox v-for="(answer, i) in q4_answers" :key="`answer_${i}`" :label="answer" border></Checkbox>
                    </CheckboxGroup>

                    <template v-if=" Q4OtherSelected ">
                        <FormItem label="¿Cuál?" prop="q4_other">
                            <Input style="width: 50%" v-model="answers.q4_other" placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </template>
                </div>
            </FormItem>

            <!-- Q5 -->
            <FormItem label="5. ¿Por los próximos dos años, ¿Te ves trabajando en el ILSB?" prop="q5">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q5">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>

                    <template v-if=" Q5IsNegative ">
                        <div class="inner-question">
                            <FormItem label="¿Cuáles podrían ser las razones de esta decisión?" prop="q5_neg_answers">
                                <CheckboxGroup v-model="answers.q5_neg_answers" class="inline">
                                    <Checkbox v-for="(answer, i) in q5_negative_answers" :key="`answer_${i}`" :label="answer" border></Checkbox>
                                </CheckboxGroup>
                            </FormItem>

                            <template v-if=" Q5NegativeOtherSelected ">
                                <FormItem label="¿Cuál?" prop="q5_neg_answer_other">
                                    <Input style="width: 50%" v-model="answers.q5_neg_answer_other" placeholder="Escribe aquí tu respuesta" />
                                </FormItem>
                            </template>
                        </div>

                    </template>
                </div>
            </FormItem>

            <!-- Q6 -->
            <FormItem label="6. ¿Consideras que el ambiente de trabajo refleja los principios institucionales que predica el ILSB?" prop="q6">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q6">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>

                    <div class="inner-question">
                        <FormItem label="¿Por qué?" prop="q6_reason">
                            <Input style="width: 50%" v-model="answers.q6_reason" type="textarea" :rows="4" placeholder="Escribe aquí tu respuesta" />
                        </FormItem>
                    </div>
                </div>
            </FormItem>
        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            const q3OtherValidation = (rule, value, callback) => {
                if( this.Q3OtherSelected ){
                    if( value === '' ) return callback(new Error('Esta pregunta es requerida'))
                    else callback()
                }
                else callback()
            }

            const q4OtherValidation = (rule, value, callback) => {
                if( this.Q4OtherSelected ){
                    if( value === '' ) return callback(new Error('Esta pregunta es requerida'))
                    else callback()
                }
                else callback()
            }

            const q5NegativeAnswersValidation = (rule, value, callback) => {
                if( this.Q5IsNegative ){
                    if( value.length === 0 ) return callback(new Error('Esta pregunta es requerida'))
                    else callback()
                }
                else callback()
            }

            const q5NegativeAnswerOtherValidation = (rule, value, callback) => {
                if( this.Q5NegativeOtherSelected ){
                    if( value === '' ) return callback(new Error('Esta pregunta es requerida'))
                    else callback()
                }
                else callback()
            }

            return {
                answers: {
                    q1: "",
                    q2: "",
                    q3: [],
                    q3_other: "",
                    q4: [],
                    q4_other: "",
                    q5: "",
                    q5_neg_answers: [],
                    q5_neg_answer_other: "",
                    q6: "",
                    q6_reason: "",
                },
                q3_answers: [
                    "La posibilidad de avanzar en las agendas de los feminismos, antirracistas y la justicia social",
                    "La posibilidad de acompañar procesos de formación, seguimiento y vinculación a movimientos y liderazgos sociales",
                    "La posibilidad de vincularte con actores del sector con quienes generamos alianzas para lograr cambios sociales",
                    "La forma en que se organiza el trabajo en el ILSB (espacios de planeación, evaluación, reflexiones colectivas, formaciones, etc)",
                    "El desarrollo y profesionalización del propio liderazgo que otorga estar en ILSB",
                    "El ambiente organizacional y relación con colegas",
                    "Las condiciones laborales (salario, prestaciones, conciliación)",
                    "Otro aspecto",
                ],
                q4_answers: [
                    "La posibilidad de avanzar en las agendas de los feminismos, antirracistas y la justicia social",
                    "La posibilidad de acompañar procesos de formación, seguimiento y vinculación a movimientos y liderazgos sociales",
                    "La posibilidad de vincularte con actores del sector con quienes generamos alianzas para  lograr cambios sociales",
                    "La forma en que se organiza el trabajo en el ILSB (espacios de planeación, evaluación, reflexiones colectivas, formaciones, etc)",
                    "El desarrollo y profesionalización del propio liderazgo que otorga estar en ILSB",
                    "El ambiente organizacional y relación con colegas",
                    "La condiciones laborales (salario, prestaciones, conciliación)",
                    "Otro aspecto",
                ],
                q5_negative_answers: [
                    "Ya no comparto la apuesta política por la causa",
                    "Mis funciones/ actividades ya no son satisfactorias para mí",
                    "El ambiente organizacional y la relación con las colegas",
                    "Busco otras oportunidades de desarrollo y profesionalización de mi liderazgo",
                    "Las condiciones laborales no son suficientemente satisfactorias para mí (salario, prestaciones, conciliación, etc)",
                    "Situación de discriminación o acoso y hostigamiento laboral y sexual",
                    "Otra",
                ],
                ruleInline: {
                    q1: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q2: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q3: [ { required: true, type: 'array', message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q3_other: [ { validator: q3OtherValidation, trigger: 'change' } ],
                    q4: [ { required: true, type: 'array', message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q4_other: [ { validator: q4OtherValidation, trigger: 'change' } ],
                    q5: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q5_neg_answers: [ { validator: q5NegativeAnswersValidation, trigger: 'change' } ],
                    q5_neg_answer_other:[ { validator: q5NegativeAnswerOtherValidation, trigger: 'change' } ],
                    q6: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q6_reason: [
                        { required: true, whitespace: true, message: "Esta pregunta es requerida",
                            trigger: 'change', }
                    ],
                },
            }
        },
        computed: {
            Q3OtherSelected(){
                return this.answers.q3.includes('Otro aspecto')
            },
            Q4OtherSelected(){
                return this.answers.q4.includes('Otro aspecto')
            },
            Q5IsNegative(){
                return this.answers.q5 === 'No'
            },
            Q5NegativeOtherSelected(){
                return this.answers.q5_neg_answers.includes('Otra')
            }
        },
        methods: {
            goToNext(){
                this.$refs.cat1Form.validate(valid => {
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q1',
                                answer_type: 'single',
                                answer: this.answers.q1,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q2',
                                answer_type: 'single',
                                answer: this.answers.q2,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q3',
                                answer_type: 'multiple',
                                answer: null,
                                answers: this.answers.q3,
                                other_option: this.answers.q3_other,
                                reason_option: null,
                            },
                            {
                                code: 'q4',
                                answer_type: 'multiple',
                                answer: null,
                                answers: this.answers.q4,
                                other_option: this.answers.q4_other,
                                reason_option: null,
                            },
                            {
                                code: 'q5',
                                answer_type: 'multiple',
                                answer: this.answers.q5,
                                answers: this.answers.q5_neg_answers,
                                other_option: this.answers.q5_neg_answer_other,
                                reason_option: null,
                            },
                            {
                                code: 'q6',
                                answer_type: 'single',
                                answer: this.answers.q6,
                                answers: null,
                                other_option: null,
                                reason_option: this.answers.q6_reason,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q6' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        },
        mounted() {
            // Q1
            let q1_i = window._.findIndex(this.form.answers, a => a.question_code === 'q1')
            if( q1_i !== -1 ) this.answers.q1 = this.form.answers[q1_i].answer

            // Q2
            let q2_i = window._.findIndex(this.form.answers, a => a.question_code === 'q2')
            if( q2_i !== -1 ) this.answers.q2 = this.form.answers[q2_i].answer

            // Q3
            let q3_i = window._.findIndex(this.form.answers, a => a.question_code === 'q3')
            if( q3_i !== -1 ) {
                this.answers.q3 = this.form.answers[q3_i].multiple_answers
                this.answers.q3_other = this.form.answers[q3_i].other_option_answer || ""
            }

            // Q4
            let q4_i = window._.findIndex(this.form.answers, a => a.question_code === 'q4')
            if( q4_i !== -1 ) {
                this.answers.q4 = this.form.answers[q4_i].multiple_answers
                this.answers.q4_other = this.form.answers[q4_i].other_option_answer || ""
            }

            // Q5
            let q5_i = window._.findIndex(this.form.answers, a => a.question_code === 'q5')
            if( q5_i !== -1 ) {
                this.answers.q5 = this.form.answers[q5_i].answer
                if( this.answers.q5 === 'No' ) this.answers.q5_neg_answers = this.form.answers[q5_i].multiple_answers || []
                this.answers.q5_neg_answer_other = this.form.answers[q5_i].other_option_answer || ""
            }

            // Q6
            let q6_i = window._.findIndex(this.form.answers, a => a.question_code === 'q6')
            if( q6_i !== -1 ) {
                this.answers.q6 = this.form.answers[q6_i].answer
                this.answers.q6_reason = this.form.answers[q6_i].reason_option_answer || ""
            }
        }
    }
</script>