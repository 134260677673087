<template>
    <Grid/>
</template>

<script>
    import Grid from './../components/Area/POA/components/Budget/components/Total/Grid'

    export default {
        components: {
            Grid,
        }
    }
</script>