<template>
    <Modal class-name="scrollable vertical-center-modal" title="Actualizar escala" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="scaleForm" :model="evaluation" :rules="ruleInline">
            <Row :gutter="16">
                <!-- <i-col span="24">
                    <FormItem label="Categoría" prop="name">
                        <Select v-model="evaluation.category" style="width: 100%">
                            <Option value="result">Resultado</Option>
                            <Option value="competence">Competencia</Option>
                        </Select>
                    </FormItem>
                </i-col> -->
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="evaluation.name" placeholder="" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Código" prop="short_name">
                        <Input v-model="evaluation.short_name" placeholder="" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Descripción" prop="description">
                        <Input type="textarea" :autosize="{minRows: 3,maxRows: 5}" v-model="evaluation.description" placeholder="" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Cólor" prop="short_name">
                        <Input v-model="evaluation.hex_color" placeholder="#ECECEC" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Puntos" prop="short_name">
                        <Input type="number" v-model="evaluation.score" placeholder="" />
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Active" prop="active">
                        <Select v-model="evaluation.active" style="width: 100%">
                            <Option :value="1">Activo</Option>
                            <Option :value="0">Inactivo</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Actualizar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                loading: false,
                evaluation: {
                    name: '',
                    short_name: '',
                    category: 'result',
                    description: '',
                    hex_color: '',
                    score: 0,
                },
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                    short_name: [
                        { required: true, message: 'Código es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.scaleForm.validate(valid => {
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/evaluation/update-scale/${this.evaluation.id}`, { ...this.evaluation })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('evaluation-scales--update', response.data.scale)
                                this.show = false
                                this.loading = false
                            }
                        })
                    }
                })
            },
        },
        mounted(){
            window.EventBus.$on('evaluation-scales--update-popup', (scale) => {
                this.evaluation = {
                    ...scale,
                }
                this.show = true
            })
        }
    }
</script>