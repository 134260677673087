<template>
    <div class="empty-state--container" :class="classnames">
        <div class="empty-state--icon">
            <Icon size="60" :type="icon"/>
        </div>
        <div class="empty-state--info">
            <h3>{{ title }}</h3>
            <p v-html="content"></p>
            <slot/>
        </div>
        <div v-if="show_cta && cta_title && cta" class="empty-state--actions">
            <Button @click="cta" type="primary">{{ cta_title }}</Button>
            <p>&nbsp;</p>
            <br><br>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: { required: true, type: String,},
            title: { required: true, type: String,},
            content: { required: false, type: String,},
            cta_title: { required: false, type: String,},
            cta: { required: false, },
            classnames: { required: false, type: Object, default: () => { return {} } },  
            show_cta: { required: false, type: Boolean, default: true },
        }
    }
</script>

<style lang="scss">
    .empty-state{
        &--container{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 25px;
            &.no-margin{
                margin: 0 !important;
            }
        }
        &--icon{
            margin-bottom: 15px;
        }
        &--info{
            margin-bottom: 15px;
            text-align: center;
            max-width: 60%;
        }
    }
</style>