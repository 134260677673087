import { toPng } from 'html-to-image';
import download from 'downloadjs'


export default {
    methods: {
        downloadAsImage(target = 'body', name = 'download.png') {
            window.$('.display-none-print').css({ 'visibility': 'hidden' })
            toPng(target).then(dataUrl => {
                download(dataUrl, name)
                window.EventBus.$emit('to-img-download', false)
                window.$('.display-none-print').css({ 'visibility': 'visible' })
            })
        }
    }
}