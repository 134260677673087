<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/proyecto/${project.slug}`">{{ project.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ project.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="ev-final" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />

            <SemiCircle :data="ChartData" />
            <ScalesLegends :items="status_poa" />
            <br>
            <Result v-for="result in ResultsWithFinalEvaluation" :key="'rest_l_ev_final_'+result.id" ev_type="final" :result="result" :canModify="CanEvaluate" />

            <UpdateResult />
        </template>
    </div>
</template>

<script>
    import Result from './../../../../../General/Result/ItemEvaluation'
    import POATabs from './../../Tabs'
    import SemiCircle from './../../../../../Charts/SemiCircle'
    import chartMixin from './../../../../../../mixins/chart'
    import UpdateResult from './../components/Update'
    import ScalesLegends from './../../../../../EvaluationScales/Legends'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [ chartMixin ],
        data(){
            return {
                is_loading: true,
                poa: [],
                project: [],
                results: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status_poa: 'status_poa',
                program_member: 'program',
            }),
            ChartData(){
                return this.getResultsChartData(this.ResultsWithFinalEvaluation, 'final_evaluation')
            },
            ResultsWithFinalEvaluation(){
                let rs = []

                window._.each(this.results, (r) => {
                    
                    // Set final result evaluation
                     let ei = window._.findIndex(r.evaluations, (e) => {
                        return e.type == 'final'
                    })

                    if(ei != -1) r.final_evaluation = r.evaluations[ei]

                    window._.each(r.indicators, (ind) => {

                        // Set final indicator evaluation
                        let ii = window._.findIndex(ind.evaluations, (i) => {
                            return i.type == 'final'
                        })
    
                        if(ii != -1) ind.final_evaluation = ind.evaluations[ii]

                    })

                    rs.push(r)
                })

                return rs
            },
            CanEvaluate(){
                if( this.user.role_name == 'Super Admin' ) return true
                else return false
            }
        },
        methods: {
            getPOA(project_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${project_slug}/get-project`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.project = response.data.project
                        this.poa = response.data.poa
                        this.getResults()
                        // this.getActivities()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.project.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getResults(){
                window.axios.post(this.$root.API_URL + `/results/get-poa/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        // this.results = response.data.results
                        window._.each(response.data.results, result => {

                            let ri = window._.findIndex(this.results, r => {
                                return r.id == result.id
                            })

                            if( ri == -1 ){
                                let r = {
                                    ...result,
                                }
    
                                this.results.push(r)
                            }
                        })
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){
            let poa_year = this.$route.params.year
            let program_slug = this.$route.params.name

            this.getPOA(program_slug, poa_year)

           /* Collapse drawer menu */
            this.$collpaseDrawer('projects-content')

            window.EventBus.$on('program-poa--update-result', (result) => {
                let ri = window._.findIndex(this.results, (r) => {
                    return r.id == result.id
                })

                if(ri != -1){
                    let r = {
                        ...result,
                        unique_color: this.results[ri].unique_color,
                    }
                    this.$set(this.results, ri, r)
                    // this.getResults()
                }
            })
        },
        components: {
            Result,
            POATabs,
            SemiCircle,
            UpdateResult,
            ScalesLegends,
        }    
    }
</script>