<template>
    <Modal class-name="scrollable vertical-center-modal" title="Agregar Financiadora" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" label-position="top" :model="funder" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="funder.name" placeholder="Nombre"/>
                    </FormItem>
                    <FormItem label="Fecha inicio" prop="start">
                        <DatePicker type="date" placeholder="Seleccionar" v-model="funder.start" style="width: 100%"/>
                    </FormItem>
                    <FormItem label="Fecha fin" prop="start">
                        <DatePicker type="date" placeholder="Seleccionar" v-model="funder.end" style="width: 100%"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                funder: {
                    name: "",
                    start: "",
                    end: "",
                },
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/create-funder/${this.$parent.poa.id}`, { ...this.funder })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-budget--add-funder', response.data.funder)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.funder = {
                    name: "",
                }

                this.$refs['planForm'].resetFields();
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--create-funder', () => {
                this.show = true
            })
        }
    }
</script>