<template>
    <Modal class-name="scrollable vertical-center-modal min-height-300" title="Actualizar actividad" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Financiadora" prop="funders">
                        <Select multiple size="large" placeholder="" v-model="activity.funders">
                            <Option v-for="funder in funders" :key="'fndr_id_'+funder.id" :value="funder.name" :label="funder.name"/>
                        </Select>
                    </FormItem>
                </i-col>
                <!-- <i-col span="24">
                    <p style="margin-bottom: 10px"><strong>Indica el procentaje/monto indicado para cada Financiadora seleccionada:</strong></p>
                    <FormItem v-for="(funder, i) in activity.funders" :key="`funder_ct_${i}`" :label="funder">
                        <Input v-model="ct_funders[i].text" placeholder=""/>
                    </FormItem>
                </i-col> -->
                <i-col span="24">
                    <FormItem label="Presencial/Virtual" prop="type">
                        <Select size="large" placeholder="" v-model="activity.type">
                            <Option value="Presencial" label="Presencial"/>
                            <Option value="Virtual" label="Virtual"/>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="¿Dónde?" prop="where">
                        <Input v-model="activity.where" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="¿Quién? (equipo/redes/externo)" prop="who">
                        <Input v-model="activity.who" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="¿Cuántas/cuántos? (participantes, impresiones, etc)" prop="quantity">
                        <Input v-model="activity.quantity" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="" prop="require_evaluation">
                        <Checkbox v-model="activity.require_evaluation">El proceso requiere evaluación?</Checkbox>
                    </FormItem>
                </i-col>

                <i-col span="24">
                    <Button size="large" long type="primary" :loading="loading" @click="save">Guardar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        props: {
            funders: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                show: false,
                users: [],
                members: [],
                ct_funders: [],
                activity: {
                    type: "",
                    funders: [],
                    where: "",
                    who: "",
                    quantity: "",
                    require_evaluation: false,
                },
                loading: false,
                ruleInline: {
                    funders: [
                        { required: true, type: 'array', message: 'Financiadora es requerida', trigger: 'change' },
                    ],
                    type: [
                        { required: true, message: 'Tipo es requerido', trigger: 'change' }
                    ],
                    where: [
                        { required: true, message: '¿Dónde? es requerido', trigger: 'change' }
                    ],
                    who: [
                        { required: true, message: '¿Quién? es requerido', trigger: 'change' }
                    ],
                    quantity: [
                        { required: true, message: 'Cantidad es requerido', trigger: 'change' }
                    ],
                    
                },
            }
        },
        // watch: {
        //     'activity.funders': function(value){
        //         window._.each(value, f => {
        //             let fi = window._.findIndex(this.ct_funders, fr => fr.funder == f)
        //             if( fi == -1 ){
        //                 this.ct_funders.push({
        //                     funder: f, 
        //                     text: '',
        //                 })
        //             }
        //         })
        //     },
        // },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/update-activity-budget-props/${this.activity.id}`, { ...this.activity,  })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-budget--update-activity', response.data.activity )
                                window.EventBus.$emit('poa-budget--update-collapse', response.data.activity)
                                this.$parent.getBudgetInfo(response.data.activity.id)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--update-activity-budget-props', (activity) => {
                this.show = true
                let funders =Array.isArray(activity.funders) ? activity.funders : null

                this.activity = {
                    ...activity,
                    funders
                }

                if( activity.funders_ct != [] ){
                    window._.each(activity.funders_ct, (ct_funder, i) => {
                        this.ct_funders.push({
                            funder: i,
                            text: ct_funder
                        })
                    })
                }

                this.$refs['planForm'].resetFields();
            })
        },
        components: {
        }
    }
</script>