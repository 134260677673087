<template>
    <div class="chart--pie" ref="chartdiv"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core"
    import * as am4charts from "@amcharts/amcharts4/charts"

    export default {
        props: {
            data: {required: false, type: Array},
        },
        mounted() {
            let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart)
            chart.hiddenState.properties.opacity = 0

            chart.data = this.data

            var series = chart.series.push(new am4charts.PieSeries());
            series.dataFields.value = "value";
            series.dataFields.radiusValue = "value";
            series.dataFields.category = "name";
            series.slices.template.cornerRadius = 6;
            series.colors.step = 3;
            series.labels.template.wrap = true
            series.labels.template.maxWidth = 350

            series.hiddenState.properties.endAngle = -90;

            chart.legend = new am4charts.Legend();

        }
    }
</script>

<style lang="scss">
    .chart--pie{
        height: 500px;
    }
</style>