<template>
    <Modal class-name="scrollable vertical-center-modal" width="90%" v-model="show" :footer-hide="true" :scrollable="true">
        <iframe type="application/pdf" style="width: 100%; height: 95vh;" id="pdf-preview"></iframe>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                budget_transfer: {

                },
                src: undefined,
                numPages: 1,
            }
        },
        methods: {
            fetchPdf(){
                window.axios.post(this.$root.API_URL + `/budget/transfer-format/${this.budget_transfer.id}`, {}, { headers: {responseType: 'blob'} } )
                .then(response => {
                    var binary = atob(  response.data.replace(/\s/g, ''));
                    var len = binary.length;
                    var buffer = new ArrayBuffer(len);
                    var view = new Uint8Array(buffer);
                    for (var i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }


                    const blob = new Blob([view], {type: 'application/pdf'});
                    const objectUrl = URL.createObjectURL(blob);
                    document.querySelector("iframe").src = objectUrl;
                    this.src = objectUrl
                })
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--view-transfer-format-poup', (budget_transfer) => {
                this.budget_transfer = {
                    ...budget_transfer,
                }

                // var loadingTask = pdf.createLoadingTask(this.$root.API_URL + `/budget/transfer-format/${budget_transfer.id}`);

                // this.src = loadingTask
                // this.src.promise.then(pdf => {
                //     this.numPages = pdf.numPages
                // })

                this.fetchPdf()
                this.show = true
            })
        },
    }
</script>