<template>
    <div>

        <h2 class="section--subtitle">Acoso y Hostigamiento Laboral</h2>
        <br>

        <Form style="width: 100%" ref="cat4Form" :model="answers" :rules="ruleInline">

            <!-- Q22 -->
            <FormItem label="22. ¿Has vivido alguna de estas situaciones en el ILSB?" prop="q22">
                <div class="env-survey-field-answers">
                    <CheckboxGroup v-model="answers.q22" class="inline">
                        <Checkbox v-for="(answer, i) in q22_answers" :key="`answer_${i}`" :label="answer" border></Checkbox>
                    </CheckboxGroup>
                </div>
            </FormItem>

            <!-- Q23 -->
            <FormItem label="23. ¿En el ILSB has vivido alguna de estas situaciones?" prop="q23">
                <div class="env-survey-field-answers">
                    <CheckboxGroup v-model="answers.q23" class="inline">
                        <Checkbox v-for="(answer, i) in q23_answers" :key="`answer_${i}`" :label="answer" border></Checkbox>
                    </CheckboxGroup>
                </div>
            </FormItem>

        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                answers: {
                    q22: [],
                    q23: [],
                },
                q22_answers: [
                    "Burlas y bromas intentando ridiculizar tu forma de vestir, hablar, de andar, etc.",
                    "Críticas acerca de aspectos de tu vida personal y privada",
                    "Amenazas verbales o gestos intimidatorios",
                    "Violencia física",
                ],
                q23_answers: [
                    "Conductas ilícitas o antiéticas para perjudicar tu imagen y reputación",
                    "Comunicación con gritos y groserías",
                    "Desvalorización de tu trabajo, sin una retroalimentación formal ni objetiva",
                    "Designación de trabajos innecesarios y monótonos, repetitivos, sin valor o utilidad alguna",
                    "Designación de tareas por debajo de tus cualificaciones, habilidades o competencias habituales",
                    "No asignación de ningún tipo de trabajo",
                    "Exceso de trabajo (presión injustificada o establecimientos de plazos imposibles de cumplir)",
                    "Colegas de trabajo que te ignoran, fingen no verte y no te dirigen la palabra",
                    "Promoción de la división entre colegas de trabajo al enfrentarlos o confrontarlos",
                ],
                ruleInline: {
                    q22: [ { required: false, type: 'array', message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q23: [ { required: false, type: 'array', message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {

        },
        methods: {
            goToNext(){
                this.$refs.cat4Form.validate(valid => {
                    console.log(valid)
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q22',
                                answer_type: 'multiple',
                                answer: null,
                                answers: this.answers.q22,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q23',
                                answer_type: 'multiple',
                                answer: null,
                                answers: this.answers.q23,
                                other_option: null,
                                reason_option: null,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q23' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>