<template>
    <div>

        <h2 class="section--subtitle">Acoso y Hostigamiento Sexual </h2>
        <br>

        <Form style="width: 100%" ref="cat3Form" :model="answers" :rules="ruleInline">

            <!-- Q18 -->
            <FormItem label="18. ¿En tu área de trabajo has recibido comentarios sugestivos o sexistas, bromas ofensivas, miradas obscenas, o petición de prácticas indeseables relacionadas con asuntos sexuales?  " prop="q18">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q18">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q19 -->
            <FormItem label="19. ¿En tu área de trabajo has vivido contacto físico sexual no deseado?" prop="q19">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q19">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q20 -->
            <FormItem label="20. ¿En tu área de trabajo se logran recompensas o incentivos laborales a cambio de favores sexuales? "
                      prop="q20">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q20">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q21 -->
            <FormItem label="21. ¿En tu área de trabajo o en el ILSB hay comentarios sexistas, racistas, capacitistas, homolesbotranfóbicos? " prop="q21">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q21">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                answers: {
                    q18: "",
                    q19: "",
                    q20: "",
                    q21: "",
                },
                ruleInline: {
                    q18: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q19: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q20: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q21: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {

        },
        methods: {
            goToNext(){
                this.$refs.cat3Form.validate(valid => {
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q18',
                                answer_type: 'single',
                                answer: this.answers.q18,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q19',
                                answer_type: 'single',
                                answer: this.answers.q19,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q20',
                                answer_type: 'single',
                                answer: this.answers.q20,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q21',
                                answer_type: 'single',
                                answer: this.answers.q21,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q21' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>