<template>
    <div class="container top">
        <LoadingContainer time_warning v-if="is_loading"/>
        <template v-else>
            <h1 class="survey-title">
                Encuesta clima y cultura organizacional
                <span>{{ survey.name }}</span>
            </h1>
            <br>

            <ReportTabs :report_link="`/work-env-surveys/${$route.params.id}`" :tab="this.$route.params.tab" />

            <component :is="$route.params.tab" :answers_data="answers_data" />

        </template>
    </div>
</template>

<script>
    import Cat1 from './components/Report/Cat1'
    import Cat2 from './components/Report/Cat2'
    import Cat3 from './components/Report/Cat3'
    import Cat4 from './components/Report/Cat4'
    import Cat5 from './components/Report/Cat5'
    import Cat6 from './components/Report/Cat6'
    import Cat7 from './components/Report/Cat7'
    import ReportTabs from "@/components/WorkEnvSurvey/Details/components/Report/ReportTabs"

    export default {
        data(){
            return {
                is_loading: true,
                tab: "users",
                survey: null,
                survey_users: [],
                answers_data: null,
            }
        },
        methods: {
            fetchData(id){
                window.axios.get(this.$root.API_URL + `/work-env-surveys/get/${id}`)
                    .then(response => {
                        if(response.data.success){
                            this.survey = response.data.survey
                            this.survey_users = response.data.survey_users
                            this.answers_data = response.data.answers
                            this.is_loading = false
                        }
                    })
            }
        },
        mounted() {
            if ( ! this.$canWatchWorkEnvData() ) this.$router.replace('/plan')
            else this.fetchData( this.$route.params.id )
        },
        components: {
            ReportTabs,
            Cat1,
            Cat2,
            Cat3,
            Cat4,
            Cat5,
            Cat6,
            Cat7,
        }
    }
</script>
