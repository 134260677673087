<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Breadcrumb>
                    <BreadcrumbItem to="/evaluaciones/mi-equipo">Resultados de evaluación de mi equipo</BreadcrumbItem>
                    <BreadcrumbItem>{{ $route.params.year }} - {{ program.name }}</BreadcrumbItem>
                </Breadcrumb>

                <Row type="flex" align="middle">
                    <i-col span="20">
                        <h1 class="section--subtitle">Evaluación <span>{{ $route.params.year }}</span> de <span>{{ program.name }}</span></h1>
                    </i-col>
                </Row>
                <br><br>

                <Row :gutter="16">
                    <i-col span="24">
                        <h2 style="text-align: center;">Competencias</h2>
                        <SemiCircle :data="buildCompetencesChartData(evaluation_data.competences_status)" />
                        <div style="display: flex; align-items: center; justify-content: center;" >
                            <div :style="{ 'background-color': getCompetenceAvgStatusColor() }" class="indicator--tag"></div>
                            <p style="font-weight: bold; font-size: 0.9em">{{ getCompetencesAvgStatusName() }}</p>
                        </div>
                    </i-col>
                </Row>
                <br><br>

                <EvaluationScales :items="status_ev_trans"/>

                <Tabs name="evs">
                    <TabPane label="Competencias">
                        <br>
                        <div class="my-team-evaluation" v-for="(evaluation, i) in Competences" :key="`evaluation_item_${i}`">
                            <h3>{{ evaluation.name }}</h3>
                            <div class="my-team-evaluation--item" v-for="(ev, j) in evaluation.evaluations" :key="`evaluation_item_${i}_evs_${j}`">
                                <template>
                                    <div class="my-team-evaluation--item--tag" >
                                        <div :style="{ 'background-color': ev.status.hex_color }" class="indicator--tag"></div>
                                        <p style="font-weight: bold; font-size: 0.9em">{{ ev.status.name }}</p>
                                    </div>
                                    <p v-if="CanWatchComments" style="white-space: pre-wrap;">{{ ev.comment }}</p>
                                </template>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane label="Recomendaciones">
                        <br>
                        <h2>Recomendaciones y/o comentarios para mejorar el desempeño del área</h2>
                        <div v-for="ev in program_evaluation" :key="`comment_ev_id_${ev.id}`" class="my-team-evaluation--item">
                            <div style="padding: 10px 0">
                                <p style="white-space: break-spaces;" v-html="ev.comments"></p>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
        </div>
    </div>
</template>

<script>
    import chartMix from './../../../../mixins/chart'
    import SemiCircle from './../../../Charts/SemiCircle'
    import EvaluationScales from './../../../EvaluationScales/Legends'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [ chartMix ],
        data(){
            return {
                loading: true,
                program_evaluation: null,
                program: null,
                evaluation_data: null,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'status_ev_trans',
            ]),
            Competences(){
                let competences = []
                window._.each(this.program_evaluation, pe => {
                    window._.each(pe.competences, comp => {
                        let ci = window._.findIndex(competences, c => c.name == comp.special_competence.name)
                        if( ci === -1 ){
                            let item = {
                                name: comp.special_competence.name,
                                evaluations: [],
                            }
                            item.evaluations.push({ comment: comp.comment, status: comp.status })
                            competences.push(item)
                        }
                        else {
                            competences[ci].evaluations.push({ comment: comp.comment, status: comp.status })
                        }
                    })
                })
                return competences
            },
            CanWatchComments(){
                return true
            }
        },
        methods: {
            getEvaluations(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-my-team/area`, { year: this.$route.params.year })
                .then(response => {
                    if( response.data.success ){
                        this.program_evaluation = response.data.evaluation
                        this.program = response.data.program
                        this.evaluation_data = response.data.data
                        this.loading = false
                    }
                })
            },
            buildCompetencesChartData(competences){
                return this.getCompetencesChartData(competences)
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.status_ev_trans, s => s.score == this.evaluation_data.avg.competences.rounded)
                if( si != -1 ) return this.status_ev_trans[si].hex_color
                else return '#ececec'
            },
            getCompetencesAvgStatusName(){
                let si = window._.findIndex(this.status_ev_trans, s => s.score == this.evaluation_data.avg.competences.rounded)
                if( si != -1 ) return this.status_ev_trans[si].name
                else return 'NA'
            },
        },
        mounted(){
            this.getEvaluations()
        },
        components: {
            SemiCircle,
            EvaluationScales,
        }
    }
</script>