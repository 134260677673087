<template>
    <div :id="`q_${c_id}`" class="work-env-report-item">
        <Row justify="center" align="middle">
            <i-col span="12">
                <h3 style="margin-bottom: 10px">{{ question }}</h3>
                <Button class="display-none-print" size="small" type="primary" @click="download">Descargar</Button>
            </i-col>
            <i-col span="12">
                <SemiCircle :data="buildChartObject(answers['answers'])" :legend="{ position: 'right' }" />
            </i-col>
        </Row>
        <Row v-if="has_yes_no_answers">
            <i-col span="12">
                <div class="work-env--inner">
                    <h3>Respuestas positivas</h3>
                    <ul class="work-env--other-list">
                        <li v-for="(other_answer, i) in answers['answers']['Si']['answers']" :key="`q3_o_${i}`">
                            {{ other_answer }}
                        </li>
                    </ul>
                </div>
            </i-col>
            <i-col v-if="show_negative_answers" span="12">
                <div class="work-env--inner">
                    <h3>Respuestas negativas</h3>
                    <ul class="work-env--other-list">
                        <li v-for="(other_answer, i) in answers['answers']['No']['answers']" :key="`q3_o_${i}`">
                            {{ other_answer }}
                        </li>
                    </ul>
                </div>
            </i-col>
        </Row>
    </div>
</template>

<script>
    import download from '@/mixins/download'
    import SemiCircle from "@/components/Charts/SemiCircle"

    export default {
        mixins: [ download ],
        props: {
            question: { required: true, type: String, },
            answers: { required: true, type: [Array, Object] },
            has_yes_no_answers: { required: false, type: Boolean, default: false, },
            show_negative_answers: { required: false, type: Boolean, default: true, },
        },
        data(){
            return {
                c_id: (Math.random() + 1).toString(36).substring(7),
            }
        },
        methods:{
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#7ddc67"
                else if ( value === "No" ) return "#dc6967"
                else return "#000"
            },
            download(){
                this.downloadAsImage(document.getElementById(`q_${this.c_id}`), `respuesta-${this.c_id}.png`)
            }
        },
        components: {
            SemiCircle,
        }
    }
</script>