<template>
    <div>
        <!-- Q24 -->
        <WorkEnvReportItemHalfPie question="24. ¿En tu área de trabajo todxs tienen las mismas facilidades para atender problemas familiares no previstos?" :answers="answers_data['q24']" />

        <!-- Q25 -->
        <WorkEnvReportItemHalfPie question="25. ¿Tu horario de trabajo te permite conciliar la vida familiar/personal con la laboral?" :answers="answers_data['q25']" />

        <!-- Q26 -->
        <WorkEnvReportItemHalfPie question="26. ¿En tu área de trabajo se otorgan permisos para atender situaciones familiares como cuidado de hijas e hijos, personas enfermas o personas adultas mayores?"
                                  :answers="answers_data['q26']" />
        <!-- Q27 -->
        <WorkEnvReportItemHalfPie question="27. ¿En tu área de trabajo encuentras facilidades para acceder a ajustes razonables a tu puesto de trabajo para conciliar tu vida personal y profesional?" :answers="answers_data['q27']" />

    </div>
</template>

<script>
    //import download from '@/mixins/download'
    import WorkEnvReportItemHalfPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-HalfPie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            WorkEnvReportItemHalfPie,
        }
    }
</script>