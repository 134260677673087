var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container top"},[(_vm.is_loading)?_c('LoadingContainer'):[_c('Breadcrumb',[_c('BreadcrumbItem',{attrs:{"to":"/plan"}},[_vm._v("Plan institucional")]),_c('BreadcrumbItem',{attrs:{"to":("/programa/" + (_vm.program.slug))}},[_vm._v(_vm._s(_vm.program.name))]),_c('BreadcrumbItem',[_vm._v(_vm._s(_vm.poa.year))])],1),_c('Row',[_c('i-col',{attrs:{"span":"24"}},[_c('h2',{staticClass:"program--title"},[_vm._v(_vm._s(_vm.program.name)+" ( "+_vm._s(_vm.poa.year)+" )")])])],1),_c('br'),_c('POATabs',{attrs:{"tab":"budget","poa_link":("/programa/" + (_vm.$route.params.name) + "/" + (_vm.$route.params.year))}}),_c('br'),_c('Row',{staticStyle:{"text-align":"center"}},[_c('Col',{attrs:{"xs":12,"sm":12,"md":8,"lg":8}},[_c('p',[_c('strong',[_vm._v("Disponible")])]),_c('h2',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.InitialBudget)))])]),_c('Col',{attrs:{"xs":12,"sm":12,"md":8,"lg":8}},[_c('p',[_c('strong',[_vm._v("Presupuesto")])]),_c('h2',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.TotalBudget)))])]),_c('Col',{attrs:{"xs":12,"sm":12,"md":8,"lg":8}},[_c('p',[_c('strong',[_vm._v("Variación")])]),_c('h2',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.DeficitBudget)))])])],1),_c('br'),_c('br'),_c('BudgetTabs',{attrs:{"tab":"available","poa_link":("/programa/" + (_vm.$route.params.name) + "/" + (_vm.$route.params.year))}}),_c('br'),_c('br'),_c('h2',[_vm._v("Resumen general")]),_c('br'),_c('Table',{attrs:{"row-key":"uid","show-summary":"","summary-method":_vm.handleSummary,"columns":_vm.columns,"data":_vm.GeneralData,"border":""},scopedSlots:_vm._u([{key:"requested",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.requested < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.requested)))])]}},{key:"available",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.available < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.available)))])]}},{key:"deficit",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.deficit < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.deficit)))])]}},{key:"spent",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.spent < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.spent)))])]}},{key:"to_spent",fn:function(ref){
var row = ref.row;
return [_c('p',{class:{ 'red-text': row.to_spent < 0 },staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("currency")(row.to_spent)))])]}}])}),_c('br'),_c('br'),_c('br'),_vm._l((_vm.funders),function(funder){return _c('FunderTable',{key:'funder_list_item'+funder.id,attrs:{"poa":_vm.poa,"funder":funder,"headings":_vm.headings,"activities":_vm.activities}})})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }