<template>
    <div :id="`q_${c_id}`" class="work-env-report-item">
        <Row justify="center" align="middle">
            <i-col span="12">
                <h3 style="margin-bottom: 10px">{{ question }}</h3>
                <Button class="display-none-print" size="small" type="primary" @click="download">Descargar</Button>
                <Row>
                    <i-col>
                        <br>
                        <h2 class="txt-center">{{ AnswersAvarage }}</h2>
                        <p class="txt-center"><small>Promedio</small></p>
                    </i-col>
                </Row>
            </i-col>
            <i-col span="12">
                <Column :data="answers" />
            </i-col>
        </Row>
    </div>
</template>

<script>
    import download from '@/mixins/download'
    import Column from "@/components/Charts/Column"

    export default {
        mixins: [ download ],
        props: {
            question: { required: true, type: String, },
            answers: { required: true, type: [Array, Object] },
        },
        data(){
            return {
                c_id: (Math.random() + 1).toString(36).substring(7),
            }
        },
        computed: {
            AnswersAvarage(){
                let sum = 0
                let count = 0
                window._.each(this.answers, a => {
                    if( a.value !== 0 ){
                        sum += Number.parseInt(a.name)
                        count += 1
                    }
                })
                console.log(sum)
                console.log(count)
                return parseFloat( sum / count).toFixed(2)
            }
        },
        methods:{
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#7ddc67"
                else if ( value === "No" ) return "#dc6967"
                else return "#000"
            },
            download(){
                this.downloadAsImage(document.getElementById(`q_${this.c_id}`), `respuesta-${this.c_id}.png`)
            }
        },
        components: {
            Column,
        }
    }
</script>