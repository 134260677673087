<template>
    <Drawer class="notifications-drawer" placement="right" width="295" v-model="drawer" @on-close="onClose">
        <div class="drawer-container">
            <h3>Notificaciones</h3>
            <Button v-if="notifications_count >= 1" @click="markAllAsRead" class="notification-mark-read" type="primary" color="primary" size="small" long>Marcar todas como leídas</Button>
            <br>

            <!-- <div class="notification-item">
                <a href="#">
                    <p class="notification-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet animi consequatur tenetur, quibusdam voluptas eveniet consectetur enim placeat.</p>
                    <p class="notification-date">12/07/2021 16:18</p>
                </a>
                <Button class="notification-mark-read" ghost type="primary" color="primary" size="small" long>Marcar como leída</Button>
            </div> -->

            <div v-for="notification in notifications" :key="`notification_list_${notification.id}`" class="notification-item">
                <template v-if="notification.data.link != null">
                    <a @click="goToLink(notification)">
                        <p class="notification-text">{{ notification.data.text }}</p>
                        <p class="notification-date">{{ notification.created_at | moment('DD/MM/YYYY') }}</p>
                    </a>
                </template>
                <template v-else>
                    <p class="notification-text">{{ notification.data.text }}</p>
                    <p class="notification-date">{{ notification.created_at | moment('DD/MM/YYYY') }}</p>
                </template>
                <Button v-if="notification.read_at == null" @click="markAsRead(notification)" class="notification-mark-read" ghost type="primary" color="primary" size="small" long>Marcar como leída</Button>
            </div>

            <template v-if="notifications.length == 0">
                <br><br>
                <EmptyState icon="md-notifications" title="No tienes notificaciones pendientes para leer" :classnames="{ 'no-margin': true }" />
            </template>

        </div>

    </Drawer>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                drawer: false,
                notifications: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'notifications_count',
            ]),
        },
        methods: {
            onClose(){
                console.log('notifications drawer closed')
            },
            getNotifications(){
                window.axios.post(this.$root.API_URL + '/user/notifications')
                .then(response => {
                    if( response.data.success ){
                        this.notifications = response.data.notifications

                        let initial_count = 0
                        window._.each(this.notifications, n => {
                            if( n.read_at == null ) initial_count += 1 
                        })

                        this.$store.dispatch("SET_NOTIFICATIONS_COUNT", initial_count)

                        if( initial_count >= 1 ) this.drawer = true
                    }
                })
            },
            goToLink(notification){
                new Promise(resolve => {
                    if( notification.read_at == null ) resolve(this.markAsRead(notification))
                    else resolve(true)
                }).then(() => {
                    this.$router.push(notification.data.link)
                    this.drawer = false
                })
            },
            markAsRead(notification){
                window.axios.post(this.$root.API_URL + '/user/mark-notification-read', { id: notification.id })
                .then(response => {
                    if( response.data.success ){
                        let ni = window._.findIndex(this.notifications, n => {
                            return n.id == notification.id
                        })

                        if( ni != -1 ){
                            this.$set(this.notifications, ni, { ...notification, read_at: new Date() })
                            this.$store.dispatch("SET_NOTIFICATIONS_COUNT", this.notifications_count -= 1)
                        }
                    }
                })
            },
            markAllAsRead(){
                window.axios.post(this.$root.API_URL + '/user/mark-all-notification-read')
                .then(response => {
                    if( response.data.success ){
                        window._.each(this.notifications, n => {
                            if( n.read_at == null ) n.read_at = new Date()
                        })

                        this.$store.dispatch("SET_NOTIFICATIONS_COUNT", 0)

                    }
                })
            }
        },
        mounted(){
            window.EventBus.$on('show-notifications-drawer', (v) => {
                this.drawer = v
            })

            this.getNotifications()
        }
    }
</script>