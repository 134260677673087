<template>
    <Modal class-name="scrollable vertical-center-modal" title="Agregar Presupuesto" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" label-position="top" :model="budget" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Rubro" prop="heading">
                        <Select v-model="budget.heading">
                            <Option v-for="heading in headings" :value="heading.id" :key="'headings_'+heading.id">{{ heading.name }}</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Subrubro" prop="subheading">
                        <Select v-model="budget.subheading">
                            <Option v-for="subheading in SelectedSubheading" :value="subheading.id" :key="'subheading'+subheading.id">{{ subheading.name }}</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Presupuesto" prop="amount">
                        <Input type="number" v-model="budget.amount" placeholder="" />
                    </FormItem>
                    <br>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                funder: [],
                budget: {
                    heading: "",
                    subheading: null,
                    amount: "",
                },
                headings: [],
                loading: false,
                ruleInline: {
                    heading: [
                        { required: true, type: 'integer', message: 'Rubro es requerido', trigger: 'change' }
                    ],
                    subheading: [
                        { required: false, type: 'integer', message: 'Subrubro es requerido', trigger: 'change' }
                    ],
                    amount: [
                        { required: true, message: 'Presupuesto es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        computed: {
            SelectedSubheading(){
                let shi = window._.findIndex(this.headings, (h) => {
                    return h.id == this.budget.heading
                })

                return shi != -1 ? this.headings[shi].subheadings : []

            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + `/budget/create-budget-poa/${this.funder.id}`, { ...this.budget })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('poa-budget--update-funder', response.data.funder)
                                this.show = false
                                this.reset()
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            getHeadings(){
                window.axios.post(this.$root.API_URL + `/budget/headings/get`)
                .then(response => {
                    if(response.data.success){
                        this.headings = response.data.headings
                        this.budget.heading = this.headings[0].id

                    }
                })
            },
            reset(){
                this.budget = {
                    heading: "",
                    subheading: null,
                    amount: "",
                }
            }
        },
        mounted(){
            window.EventBus.$on('poa-budget--add-funder-budget-popup', (funder) => {
                this.getHeadings()
                this.funder = {
                    ...funder,
                }
                this.show = true
            })
        }
    }
</script>