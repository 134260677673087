<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan estratégico</BreadcrumbItem>
                <BreadcrumbItem>{{ area.name }}</BreadcrumbItem>
            </Breadcrumb>

            <Row :gutter="24">
                <i-col :xs="24" :sm="24" :md="24" :lg="24">
                    <h2 class="program--title">
                        {{ area.name }}
                        <Button style="margin-left: 15px" ghost type="primary" size="small" @click="collapseMembers">Integrantes</Button>
                    </h2>
                    <br>
                    <!-- Integrantes -->
                    <div class="accordion-cont" id="tdc-members-div" aria-expanded="false">
                        <div class="accordion-cont--wrapper">
                            <div class="program-members--container">
                                <template v-if="members.length == 0">
                                    <EmptyState icon="md-contacts" :title="`${area.name} no tiene integrantes`"/>
                                </template>
                                <template v-else>
                                    <h3>Integrantes</h3>
                                    <div class="program-members--items">
                                        <MemberItem v-for="member in members" :key="'program_d_mbrs_'+member.id"
                                                    :user="member" :member_id="member.id" :program="area" :type="member.role_name" />
                                    </div>
                                    <br>
                                </template>
                            </div>
                        </div>
                    </div>

                </i-col>
            </Row>
            <br>
            <h3>POAs</h3>
            <br>
            <POAList :program="area" :poas="poas" type="area"/>

        </template>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import MemberItem from './../Program/components/Members/Item'
    import POAList from "@/components/Program/components/POAList"

    export default {
        data(){
            return {
                poas: [],
                members: [],
                area: null,
                is_loading: true,
            }
        },
        watch: {
            '$route.params.name': function (name) {
                this.is_loading = true
                this.area = null
                this.getProgram(name)
            },
        },
        computed: {
            ...mapGetters({
                user: 'user',
            }),
        },
        methods: {
            getArea(slug){
                window.axios.post(this.$root.API_URL + '/areas/get-area', { field: 'slug', value: slug })
                .then(response => {
                    if(response.data.success){
                        this.area = response.data.area
                        this.poas = response.data.poas
                        this.members = response.data.members
                        this.is_loading = false

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.area.name)
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            collapseMembers(){
                this.$collapse('tdc-members-div')
            }
        },
        mounted(){

            if( this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.$itsDirector() || this.$itsSubdirector() ||
                this.$hasRole('Oficial del área directiva') ){
                let program_slug = this.$route.params.name
                this.getArea(program_slug)
            }
            else this.$router.push('/')



            /* Collapse drawer menu */
            this.$collpaseDrawer('poa-content')

            window.EventBus.$on('program-details--add-poa-item', (poa) => {
                this.poas.push(poa)
            })

            window.EventBus.$on('program-details--remove-poa', (poa) => {
                let pi = window._.findIndex(this.poas, p => {
                    return p.id == poa.id
                })

                if( pi != -1 ){
                    this.poas.splice(pi, 1)
                    this.$Message.success('POA eliminado correctamente')
                }
            })

        },
        components: {
            POAList,
            MemberItem,
        }
    }
</script>