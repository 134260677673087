<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Resultados de evaluación de mi equipo</h1>
                </i-col>
            </Row>

            <br>
            <template v-if="evaluations.length > 0">
                <Tabs name="evs" :value="tab">
                    <TabPane v-for="evaluation in Evaluations" :key="`evaluations_group_tab_${evaluation.id}`" :label="evaluation.name" :name="evaluation.name">
                        <br><br>
                        <template v-if="user.role_name != 'Presidenta del consejo'">
                            <h2 style="margin-left: 10px">Tu evaluación</h2>
                            <div class="program-members--items">
                                <div class="program-members--item click primary" @click="watchEvaluationDetails(evaluation, { has_evaluations: true, ...user})">
                                    <div class="program-members--item--avatar">
                                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                    </div>
                                    <div class="program-members--item--info">
                                        <h4>{{ user.name }}</h4>
                                        <p>{{ user.email }}</p>
                                        <p style="color: rgba(255,255,255, 0.7); font-size: 0.8em">{{ user.role_name }}</p>
                                    </div>
                                </div>
                                <!-- Coords  -->
                                <template v-if="user.role_name == 'Coordinación'">
                                    <div class="program-members--item click" @click="watchCoordEvaluationDetails(evaluation, user)">
                                        <div class="program-members--item--avatar">
                                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                        </div>
                                        <div class="program-members--item--info">
                                            <h4>{{ user.name }}</h4>
                                            <p>{{ user.email }}</p>
                                            <p style="font-size: 0.8em">La evaluación de tu equipo</p>
                                        </div>
                                    </div>
                                </template>
                                <!-- Sub  -->
                                <template v-if="user.role_name == 'Subdirección'">
                                    <div class="program-members--item click" @click="watchSubEvaluationDetails(evaluation, user)">
                                        <div class="program-members--item--avatar">
                                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                        </div>
                                        <div class="program-members--item--info">
                                            <h4>{{ user.name }}</h4>
                                            <p>{{ user.email }}</p>
                                            <p style="font-size: 0.8em">La evaluación de tu equipo</p>
                                        </div>
                                    </div>
                                </template>
                                <!-- Dir  -->
                                <template v-if="user.role_name == 'Dirección'">
                                    <div class="program-members--item click" @click="watchDirEvaluationDetails(evaluation, user)">
                                        <div class="program-members--item--avatar">
                                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                        </div>
                                        <div class="program-members--item--info">
                                            <h4>{{ user.name }}</h4>
                                            <p>{{ user.email }}</p>
                                            <p style="font-size: 0.8em">La evaluación de tu equipo</p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <br><br>
                        </template>

                        <template v-if="evaluation.name != '2021' && program != null">
                            <h2 style="margin-left: 10px">Evaluación de tu área</h2>
                            <div @click="watchMyProgramEvaluations(evaluation.name)" class="program-members--item click">
                                <div class="program-members--item--avatar">
                                    <Avatar style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-square"/>
                                </div>
                                <div class="program-members--item--info">
                                    <h4>{{ program.name }}</h4>
                                    <p>Área {{ program.category == 'prog' ? 'programática' : 'transversal'  }}</p>
                                </div>
                            </div>
                            <br><br>
                        </template>

                        <div v-for="(group_name, i) in groups[evaluation.name][0].keys" :key="`group_id_${evaluation.id}_${i}`">
                            <h2 style="margin-left: 15px">{{ group_name }}</h2>
                            <div class="program-members--items">
                                <div v-for="(user, i) in groups[evaluation.name][0]['groups'][group_name]" :key="`user_groups_${i}`" class="program-members--item click" :class="{ 'disabled': ! user.has_evaluations }" @click="watchEvaluationDetails(evaluation, user)">
                                    <div class="program-members--item--avatar">
                                        <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person"/>
                                    </div>
                                    <div class="program-members--item--info">
                                        <h4>{{ user.name }}</h4>
                                        <p>{{ user.email }}</p>
                                        <p style="color: grey; font-size: 0.8em">{{ user.role_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <br><br>
                        </div>
                    </TabPane>
                </Tabs>
            </template>
            <template v-else>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <Icon size="70" type="md-contact" />
                    <br>
                    <h2>No se encontraron resultados para mostrar</h2>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                tab: '',
                loading: true,
                groups: [],
                evaluations: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'program',
            ]),
            Evaluations(){
                let evs = this.evaluations
                return evs.sort( (a,b) => { return b.name.localeCompare(a.name) } )
            }
        },
        methods: {
            getEvaluations(){
                window.axios.post(this.$root.API_URL + '/evaluation/get-my-team')
                .then(response => {
                    if(response.data.success){
                        this.groups = response.data.groups
                        this.evaluations = response.data.evaluations
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            addHandler(){
                window.EventBus.$emit('evaluations--create-popup', true)
            },
            watchEvaluationDetails(evaluation, user){
                if( user.has_evaluations ) this.$router.push(`/evaluaciones/mi-equipo/${evaluation.id}/${user.id}`)
            },
            watchCoordEvaluationDetails(evaluation, user){
                this.$router.push(`/evaluaciones/mi-equipo/${evaluation.id}/${user.id}/coord`)
            },
            watchSubEvaluationDetails(evaluation, user){
                this.$router.push(`/evaluaciones/mi-equipo/${evaluation.id}/${user.id}/sub`)
            },
            watchDirEvaluationDetails(evaluation, user){
                this.$router.push(`/evaluaciones/mi-equipo/${evaluation.id}/${user.id}/dir`)
            },
            watchMyProgramEvaluations(year){
                this.$router.push(`/evaluaciones/mi-equipo/${year}/area`)
            }
        },
        mounted(){
            this.$collpaseDrawer('evs-content')
            this.$deleteSubitemsActives()
            this.$markSubitemAsActive('Mi equipo')

            this.getEvaluations()
        },  
    }
</script>