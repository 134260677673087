<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/proyecto/${project.slug}`">{{ project.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ project.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="schedule" :poa_link="`/proyecto/${$route.params.name}/${$route.params.year}`" />

            <div class="calendar-title--container">
                <div class="calendar-title--actions">
                    <ButtonGroup v-if="ShowCalendarNav">
                        <Button @click="calendarPrevMonth">
                            <Icon size="20" type="ios-arrow-back" />
                        </Button>
                        <Button @click="goToToday">Hoy</Button>
                        <Button @click="calendarNextMonth">
                            <Icon size="20" type="ios-arrow-forward" />
                        </Button>
                    </ButtonGroup>
                </div>
                <div class="calendar-title--title">
                    <h2 style="text-transform: capitalize; text-align: center;">{{ calendar_title || '' }}</h2>
                </div>
                <div class="calendar-title--action">
                    <Button v-if="CanAddImpDate" style="margin-left: 15px" type="primary" ghost @click="addImpDate">Nueva fecha clave</Button>
                </div>
            </div>

            <Calendar :poa="poa" :program="project" :activities="activities" :results="results" :imp_dates="imp_dates"/>
        </template>

        <DetailsPopup :canUpdate="CanUpdateImpDate"/>
        <CreateImpDate v-if="CanAddImpDate"/>
        <UpdateImpDate v-if="CanUpdateImpDate" />
    </div>
</template>

<script>
    var randomColor = require('randomcolor');

    import POATabs from './../Tabs'
    import Calendar from './components/Calendar'
    import DetailsPopup from './components/DetailsPopup'
    import projectMixin from './../../../../../mixins/project'
    import { mapGetters } from 'vuex'
    import CreateImpDate from './components/ImpDate/Create'
    import UpdateImpDate from './components/ImpDate/Update'

    export default {
        mixins: [ projectMixin ],
        data(){
            return {
                poa: [],
                sc_view: 'calendar',
                project: [],
                activities: [],
                results: [],
                members: [],
                imp_dates: [],
                is_loading: true,
                calendar_title: '',
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                program_member: 'program',
            }),
            ShowCalendarNav(){
                return this.sc_view =='calendar'
            },
            CanAddImpDate(){
                if( this.user.role_name == 'Super Admin' ) return true
                else return this.isAuthUserMembersOfThisProject()
            },
            CanUpdateImpDate(){
                if( this.user.role_name == 'Super Admin' ) return true
                else return this.isAuthUserMembersOfThisProject()
            },
            CanCreateActivity(){
                if( this.user.role_name == 'Super Admin' ) return true
                else if( this.user.role_name == 'Coordinación' ){
                    if( this.program_member != null ){
                        if( this.program_member.id == this.project.id ) return true;
                        else return false;
                    }
                    else return false;
                }
                else {
                    return false
                }
            }
        },
        methods: {
            calendarNextMonth(){
                window.EventBus.$emit('activities-calendar-next')
            },
            calendarPrevMonth(){
                window.EventBus.$emit('activities-calendar-prev')
            },
            addActivity(){
                window.EventBus.$emit('poa-activities--create')
            },
            addImpDate(){
                window.EventBus.$emit('poa-activities--create-imp-date')
            },
            goToToday(){
                window.EventBus.$emit('activities-calendar-today')
            },
            getPOA(project_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${project_slug}/get-project`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.project = response.data.project
                        this.poa = response.data.poa
                        this.members = response.data.members
                        this.getResults()
                        this.getImpDates()
                        // this.getActivities()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.project.name)

                        // this.is_loading = false
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getResults(){
                window.axios.post(this.$root.API_URL + `/results/get-poa/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        // this.results = response.data.results
                        window._.each(response.data.results, result => {

                            let ri = window._.findIndex(this.results, r => {
                                return r.id == result.id
                            })

                            if( ri == -1 ){
                                let r = {
                                    ...result,
                                    unique_color: randomColor(),
                                }
    
                                this.results.push(r)
                                window._.each(result.activities, a => this.activities.push({
                                    ...a,
                                    headings: [], 
                                    is_budget_loaded: false,
                                    is_budget_loading: true,
                                }))
                            }
                        })
                        this.is_loading = false
                    }
                })
            },
            getImpDates(){
                window.axios.post(this.$root.API_URL + `/activities/get-imp-dates/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.imp_dates = response.data.dates
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){

            let poa_year = this.$route.params.year
            let project_slug = this.$route.params.name

            this.getPOA(project_slug, poa_year)

           /* Collapse drawer menu */
            this.$collpaseDrawer('projects-content')

            window.EventBus.$on('activities-calendar-set-title', title => this.calendar_title = title)

            window.EventBus.$on('poa-activities-caldendar--add-imp-date', (date) => {
                this.imp_dates.push(date)
            })

            window.EventBus.$on('poa-activities-caldendar--update-imp-date', (date) => {
                let di = window._.findIndex(this.imp_dates, d => d.id == date.id)
                if( di != -1 ) this.$set(this.imp_dates, di, date)
            })

            window.EventBus.$on('poa-activities-caldendar--delete-imp-date', (date) => {
                let di = window._.findIndex(this.imp_dates, d => d.id == date.id)
                if( di != -1 ) this.imp_dates.splice(di, 1)
            })

        },
        components: {
            POATabs,
            Calendar,
            DetailsPopup,
            CreateImpDate,
            UpdateImpDate,
        }
    }
</script>