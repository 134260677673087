import Vue from 'vue'
import store from './store'

function collapse(t) {
    let target = window.$(`#${t}`)

    let target_wrapper = window.$(`#${t} .accordion-cont--wrapper`)
    let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"
    let parent_affected = target.attr('aria-parent') || null

    let target_h = target_wrapper.outerHeight(true) + 'px'
    console.log(`target height ${target_h}`)

    if (is_expanded == "true") {
        // affect parent height
        if (parent_affected) {
            let parent_new_height = window.$(`#${parent_affected}`).height() - target_wrapper.height()
            window.$(`#${parent_affected}`).height( parent_new_height )
        }

        target[0].style.height = "0px"
        window.$(target[0]).attr('aria-expanded', 'false')
        window.$(this).toggleClass('active')
        window.$(`[aria-target="${t}"] > .actions > button`).removeClass('rotate')
    }
    else {
        // affect parent height
        if (parent_affected) {
            let parent_new_height = window.$(`#${parent_affected}`).height() + target_wrapper.height()
            window.$(`#${parent_affected}`).height( parent_new_height )
        }

        target[0].style.height = target_h
        window.$(target[0]).attr('aria-expanded', 'true')
        window.$(this).toggleClass('active')
        window.$(`[aria-target="${t}"] > .actions > button`).addClass('rotate')
    }

    return {
        expanded: is_expanded == "false"
    }
}

function updateCollapseHeight(t, timeout = 200) {
    setTimeout(() => {
        let target = window.$(`#${t}`)
        let target_wrapper = window.$(`#${t} .accordion-cont--wrapper`)
        let target_h = target_wrapper.outerHeight(true) + 'px'
        console.log(`target update height ${target_h}`) 

        
        target[0].style.height = target_h
    }, timeout)
}

Vue.prototype.$collpaseDrawer = (target) => {
    if (window.$(`#${target}`).attr('aria-expanded') == "false") {
        setTimeout(() => { collapse(target) }, 1000)
    }
}

Vue.prototype.$markSubitemAsActive = (target) => {
    window.$(`.drawer-item--subitem[aria-name="${target}"]`).addClass('active')
}

Vue.prototype.$deleteSubitemsActives = () => {
    window.$(`.drawer-item--subitem`).removeClass('active')
}


Vue.prototype.$collapse = (t) => {
    return new Promise(resolve => { resolve( collapse(t) ) }) 
}

Vue.prototype.$updateCollapseHeight = (t, timeout = 200) => {
    return new Promise(resolve => resolve( updateCollapseHeight(t, timeout) ) )
}

Vue.prototype.$can = (permission_name) => {
    return store.getters.permissions.includes(permission_name)
}

Vue.prototype.$itsAdminTeammate = () => {
    if (store.getters.user == null) return false
    else if ( store.getters.program == null ) return false
    else return store.getters.program.name == 'Administración'
}

Vue.prototype.$itsSuperAdmin = () => {
    if (store.getters.user == null) return false
    else if ( store.getters.user.role_name == null || store.getters.user.role_name == '' ) return false
    else return store.getters.user.role_name == 'Super Admin'
}

Vue.prototype.$itsMemberOf = (program_name) => {
    if (store.getters.user == null) return false
    else if ( store.getters.program == null ) return false
    else return store.getters.program.name == program_name
}

Vue.prototype.$itsCoordOf = (program_name) => {
    if (store.getters.user == null) return false
    else if ( store.getters.program == null ) return false
    else return store.getters.program.name == program_name && store.getters.user.role_name == 'Coordinación'
}

Vue.prototype.$itsDirector = () => {
    if (store.getters.user == null) return false
    else if ( store.getters.user.role_name == null || store.getters.user.role_name == '' ) return false
    else return store.getters.user.role_name == 'Dirección'
    
}

Vue.prototype.$itsSubdirector = () => {
    if (store.getters.user == null) return false
    else if ( store.getters.user.role_name == null || store.getters.user.role_name == '' ) return false
    else return store.getters.user.role_name == 'Subdirección'
}

Vue.prototype.$hasRole = (role_name) => {
    if (store.getters.user == null) return false
    else if ( store.getters.user.role_name == null || store.getters.user.role_name == '' ) return false
    else return store.getters.user.role_name === role_name
}

Vue.prototype.$canWatchWorkEnvData = () => {
    if (store.getters.user == null) return false
    else if ( store.getters.user.role_name == null || store.getters.user.role_name === '' ) return false
    else if (store.getters.user.role_name === 'Super Admin' ) return true
    else return store.getters.user.email === 'melisa.pineda@ilsb.org.mx'
    //else return store.getters.program.name === 'Formación' && store.getters.user.role_name === 'Coordinación'
}