<template>
    <div>
        <div class="evaluation--box" v-for="evaluation in Competences" :key="'autv_cmp_id_'+evaluation.id">
            <p class="competence-name">{{ evaluation.classable.name }}</p>
            <Row :gutter="50">
                <i-col :xs="24" :sm="24" :md="12" :lg="12">
                    <h4 style="margin-bottom: 10px">Autoevaluación:</h4>
                    <div class="evaluation--indicator">
                        <div class="evaluation--indicator--item">
                            <div :style="{ 'background-color': getAutoevaluationIndicator(evaluation.classable.id) }" class="indicator--tag"></div>
                            <p>{{ getAutoevaluationIndicatorName(evaluation.classable.id) }}</p>
                        </div>
                    </div>
                    <div style="padding: 10px 0">
                        <p>{{ getAutoevaluationComment(evaluation.classable.id) }}</p>
                    </div>
                </i-col>
                <i-col :xs="24" :sm="24" :md="12" :lg="12">
                    <div class="evaluation--indicator">
                        <template v-if="CanEvaluationBeUpdated">
                            <Dropdown placement="bottom-start" trigger="click">
                                <div class="evaluation--indicator--item">
                                    <div :style="{ 'background-color': getFeedbackIndicatorBackground(evaluation) }" class="indicator--tag"></div>
                                    <p>{{ getFeedbackIndicatorName(evaluation) }}</p>
                                </div>
                                <DropdownMenu slot="list">
                                    <DropdownItem @click.native="updateEvIndicator(evaluation, status)" v-for="status in Statuses" :key="'res_ev_ind_upt_succ_'+status.id" >
                                        <div class="indicator--short">
                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                            {{ status.name }}
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                        <template v-else>
                            <div class="evaluation--indicator--item">
                                <div :style="{ 'background-color': getFeedbackIndicatorBackground(evaluation) }" class="indicator--tag"></div>
                                <p>{{ getFeedbackIndicatorName(evaluation) }}</p>
                            </div>
                        </template>
                    </div>
                    <template v-if="CanEvaluationBeUpdated">
                        <Input @on-blur="updateEvComment(evaluation)" v-model="evaluation.comment" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                    </template>
                    <template v-else>
                        <div style="padding: 10px 0">
                            <p>{{ evaluation.comment }}</p>
                        </div>
                    </template>
                </i-col>
            </Row>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            evaluation: { required: true, type: [Object, Array] },
            isSpecial: { required: true, type: Boolean, default: false },
            autoevaluation: { required: false, type: [Object, Array] },
        },
        computed:{
            ...mapGetters({
                'status': 'status_comp',
            }),
            Statuses(){
                return this.status
            },
            Competences(){
                return window._.filter(this.evaluation.evaluations, e => e.classable_type == 'competence' || e.classable_type == 'special-competence')
            },
            CanEvaluationBeUpdated(){
                let s = [ 'finished' ];
                return ! s.includes(this.evaluation.status)
            }
        },
        methods: {
            getIndicatorBackground(evaluation){
                return evaluation.status ? evaluation.status.hex_color : '#ececec'
            },
            getAutoevaluationIndicator(competence_id){
                let auii = window._.findIndex(this.autoevaluation.evaluations, ev => ev.classable.id == competence_id)
                if( auii != -1 ){
                    return this.autoevaluation.evaluations[auii].status ? this.autoevaluation.evaluations[auii].status.hex_color : '#ececec';
                }
                return '#ececec'
            },
            getAutoevaluationIndicatorName(competence_id){
                let auii = window._.findIndex(this.autoevaluation.evaluations, ev => ev.classable.id == competence_id)
                if( auii != -1 ){
                    return this.autoevaluation.evaluations[auii].status ? this.autoevaluation.evaluations[auii].status.name : 'No disponible';
                }
                return '#ececec'
            },
            getAutoevaluationComment(competence_id){
                let auii = window._.findIndex(this.autoevaluation.evaluations, ev => ev.classable.id == competence_id && ev.classable_type == 'special-competence')
                if( auii != -1 ){
                    return this.autoevaluation.evaluations[auii].comment || 'No disponible'
                }
                return 'No disponible'
            },
            getFeedbackIndicatorBackground(evaluation){
                return evaluation.status ? evaluation.status.hex_color : '#ececec'
            },
            getIndicatorName(evaluation){
                return evaluation.status ? evaluation.status.name : 'Selecciona tu desempeño'
            },
            getFeedbackIndicatorName(evaluation){
                let placeholder = this.isSpecial ? 'Selecciona el desempeño' : 'Selecciona tu desempeño'
                return evaluation.status ? evaluation.status.name : placeholder
            },
            updateEvIndicator(evaluation, status){
                window.axios.post(this.$root.API_URL + `/evaluation/update-status/${evaluation.id}`, { field: 'statuses_id', status_id: status.id })
                .then(response => {
                    if(response.data.success){
                        window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            },
            updateEvComment(evaluation){
                window.axios.post(this.$root.API_URL + `/evaluation/update-comment/${evaluation.id}`, { field: 'comment', comment: evaluation.comment })
                .then(response => {
                    if(response.data.success){
                        window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            }
        },
    }
</script>