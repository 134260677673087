<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/area/${area.slug}`">{{ area.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ area.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="budget" :poa_link="`/area/${$route.params.name}/${$route.params.year}`" />

            <br>
            <Row style="text-align: center">
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Disponible</strong></p>
                    <h2 style="font-weight: normal">{{ InitialBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Presupuesto</strong></p>
                    <h2 style="font-weight: normal">{{ TotalBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Variación</strong></p>
                    <h2 style="font-weight: normal">{{ DeficitBudget | currency }}</h2>
                </Col>
            </Row>
            <br>
            <br>
            <BudgetTabs tab="available" :poa_link="`/area/${$route.params.name}/${$route.params.year}`" />
            <br>
            <br>
            <h2>Resumen general</h2>
            <br>
            <Table row-key="uid" show-summary :summary-method="handleSummary" :columns="columns" :data="GeneralData" border>
                <template slot-scope="{ row }" slot="requested">
                    <p :class="{ 'red-text': row.requested < 0 }" style="text-align: right;">{{ row.requested | currency }}</p>
                </template>

                <template slot-scope="{ row }" slot="available">
                    <p :class="{ 'red-text': row.available < 0 }" style="text-align: right;">{{ row.available | currency }}</p>
                </template>

                <template slot-scope="{ row }" slot="deficit">
                    <p :class="{ 'red-text': row.deficit < 0 }" style="text-align: right;">{{ row.deficit | currency }}</p>
                </template>

                <template slot-scope="{ row }" slot="spent">
                    <p :class="{ 'red-text': row.spent < 0 }" style="text-align: right;">{{ row.spent | currency }}</p>
                </template>

                <template slot-scope="{ row }" slot="to_spent">
                    <p :class="{ 'red-text': row.to_spent < 0 }" style="text-align: right;">{{ row.to_spent | currency }}</p>
                </template>
            </Table>
            <br>
            <br>
            <br>
            <FunderTable v-for="funder in funders" :key="'funder_list_item'+funder.id" :poa="poa" :funder="funder" :headings="headings" :activities="activities" />
        </template>
    </div>
</template>

<script>
    import POATabs from './../../../Tabs'
    import BudgetTabs from './../Tabs'
    import FunderTable from './components/FunderTable'

    export default {
        data(){
            return {
                headings: [],
                poa: null,
                funders: [],
                project: null,
                activities: [],
                is_loading: true,

                columns: [
                    {
                        title: 'Rubro',
                        key: 'name',
                        tree: true,
                    },
                    {
                        title: 'Solicitado',
                        key: 'requested',
                        slot: 'requested',
                        width: 120,
                    },
                    {
                        title: 'Disponible',
                        slot: 'available',
                        key: 'available',
                        width: 120,
                    },
                    {
                        title: 'Variación',
                        slot: 'deficit',
                        key: 'deficit',
                        width: 120,
                    },
                    {
                        title: 'Ejercido',
                        slot: 'spent',
                        key: 'spent',
                        width: 120,
                    },
                    {
                        title: 'Por ejercer',
                        slot: 'to_spent',
                        key: 'to_spent',
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            GeneralData(){
                let hs = [].concat(this.headings)

                window._.each(hs, (h) => {
                    let hds = []
                    h.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
                    h.requested = this.getRequestedOfHeading(h.id)
                    h.available = this.getAvailableOfHeading(h.id)
                    h.deficit = h.available - h.requested
                    h.spent = this.getSpentOfHeading(h.id)
                    h.to_spent = h.available - h.spent

                    window._.each(h.subheadings, (hd) => {
                        hd.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
                        hd.requested = this.getRequestedOfSubheading(h.id, hd.id)
                        hd.available = this.getAvailableOfSubheading(hd.id) || 0
                        hd.deficit = hd.available - hd.requested
                        hd.spent = this.getSpentOfSubheading(h.id, hd.id)
                        hd.to_spent = hd.available - hd.spent

                        hds.push(hd)
                    })

                    h.children = hds
                })

                return hs
            },
            InitialBudget(){
                let total = 0

                window._.each(this.funders, (f) => {
                    window._.each(f.budget, (b) => {
                        total += parseFloat(b.final_total_price)
                    })
                })

                return total
            },
            TotalBudget(){
                let total = 0

                window._.each(this.activities, (activity) => {
                     let ps = parseFloat(activity.budget_total)
                    if( ps ) total += ps
                })

                return total
            },
            DeficitBudget(){
                return this.InitialBudget - this.TotalBudget
            }
        },
        methods: {
            getHeadings(){
                window.axios.post(this.$root.API_URL + `/budget/headings/get`)
                .then(response => {
                    if(response.data.success){
                        this.headings = response.data.headings
                        // this.is_loading = false
                    }
                })
            },
            getRequestedOfHeading(heading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                window._.each(sb.budgets, budget => {
                                    let ps = parseFloat(budget.final_gross_price || 0)
                                    if( ps ) total += ps
                                })
                            })
                        }
                    })
                })

                return total
            },
            getRequestedOfSubheading(heading_id, subheading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                if( sb.subheading.id == subheading_id ){
                                    window._.each(sb.budgets, budget => {
                                        let ps = parseFloat(budget.final_gross_price || 0)
                                        if( ps ) total += ps
                                    })
                                }
                            })
                        }
                    })
                })
                
                return total
            },
            getAvailableOfHeading(heading_id){
                let total = 0

                window._.each(this.funders, (funder) => {
                    window._.each(funder.budget, (budget) => {
                        if(budget.heading.id == heading_id){
                            let ps = parseFloat(budget.final_total_price || 0)
                            if( ps ) total += ps
                        }
                    })
                })

                return total
            },
            getAvailableOfSubheading(subheading_id){
                let total = 0

                window._.each(this.funders, (funder) => {
                    window._.each(funder.budget, (budget) => {
                        if(budget.subheading && budget.subheading.id == subheading_id){
                            let ps = parseFloat(budget.final_total_price || 0)
                            if( ps ) total += ps
                        }
                    })
                })

                return total
            },
            getSpentOfHeading(heading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                window._.each(sb.budgets, budget => {
                                    window._.each(budget.transfers, (transfer) => {
                                        let ps = parseFloat(transfer.amount)
                                        if( ps ){
                                            if( bd.heading.name == 'Honorarios' ){
                                                let nps = ps / 0.95333333334  * 1.16
                                                total += nps
                                            }
                                            else  total += ps
                                        }
                                    })
                                })
                            })
                        }
                    })
                })
                
                return total
            },
            getSpentOfSubheading(heading_id, subheading_id){
                let total = 0

                window._.each(this.activities, (activity) => {
                    window._.each(activity.budget_details, bd => {
                        if( bd.heading.id == heading_id ){
                            window._.each(bd.subheadings, sb => {
                                if( sb.subheading.id == subheading_id ){
                                    window._.each(sb.budgets, budget => {
                                        window._.each(budget.transfers, (transfer) => {
                                            if(transfer.authorized_by != null){
                                                let ps = parseFloat(transfer.amount)
                                                if( ps ) {
                                                    if( bd.heading.name == 'Honorarios' ){
                                                        let nps = ps / 0.95333333334  * 1.16
                                                        total += nps
                                                    }
                                                    else  total += ps
                                                }
                                            }
                                        })
                                    })
                                }
                            })
                        }
                    })
                })

                return total
            },
            getPOA(area_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${area_slug}/get-area`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.area = response.data.area
                        this.poa = response.data.poa
                        this.getFunders()
                        this.getActivities()
                        // this.getResults()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.area.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getFunders(){
                window.axios.post(this.$root.API_URL + `/budget/get-funders/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.funders = response.data.funders
                    }
                })
            },
            getActivities(){
                window.axios.post(this.$root.API_URL + `/activities/get-budget/${this.poa.id}`, { load_budget: true })
                .then(response => {
                    if(response.data.success){
                        window._.each(response.data.activities, act => {
                            this.activities.push({
                                ...act,
                                is_budget_loaded: false,
                                is_budget_loading: true,
                            })
                        })
                        this.is_loading = false
                    }
                })
            },
            currencyFormatForSummary(value){
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                return formatter.format(value)
            },
            handleSummary ({ columns, data }) {
                const sums = {};
                columns.forEach((column, index) => {
                    const key = column.key;
                    if (index === 0) {
                        sums[key] = {
                            key,
                            value: 'Total'
                        };
                        return;
                    }

                    const values = data.map(item => Number(item[key]));
                    // console.log(values)
                    if (!values.every(value => isNaN(value))) {
                        const v = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[key] = {
                            key,
                            value: this.currencyFormatForSummary(v),
                        };
                    } else {
                        sums[key] = {
                            key,
                            value: 'N/A'
                        };
                    }
                });

                return sums;
            }
        },
        mounted(){
            if( this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.$itsDirector() || this.$itsSubdirector() || this.$hasRole('Oficial del área directiva') ){
                this.getHeadings()

                let poa_year = this.$route.params.year
                let area_slug = this.$route.params.name

                this.getPOA(area_slug, poa_year)
            }
            else this.$router.push('/')

           /* Collapse drawer menu */
            this.$collpaseDrawer('poas-content')
        },
        components: {
            POATabs,
            BudgetTabs,
            FunderTable,
        }
    }
</script>

<style lang="scss">
    .ivu-table-summary .ivu-table-cell{
        text-align: right !important;
        font-weight: bold;
    }
</style>