<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nueva actividad de impacto" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="activity" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input type="textarea" v-model="activity.name" placeholder="" :rows="5"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                activity: {
                    name: "",
                },
                roles: [],
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/bitacora/impact-activity/create', { ...this.activity })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('imp-activities--add-activity', response.data.activity)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            clear(){
                this.activity =  {
                    name: "",
                }
                this.$refs.planForm.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('imp-activities--create-popup', () => {
                this.show = true
            })
        }
    }
</script>