<template>
    <div>
        <template v-if="is_loading">
            <LoadingContainer/>
        </template>
        <template v-else>
            <template v-if="results.length == 0">
                <EmptyState icon="md-list-box" title="" :content="`${program.name} (${poa.year}) no tiene resultados creados. <br> Haz clic en el botón de abajo para agregar el primer resultado de este POA.`" :show_cta="CanCreateResult" cta_title="Agregar resultado" :cta="() => { addResult() }" />
            </template>
            <template v-else>
                <Result v-for="result in results" :key="'rest_l_'+result.id" :result="result" :canModify="CanCreateResult" :program="program" :statuses="status" />
                <br>
                <Row v-if="CanCreateResult">
                    <i-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 24, offset: 0 }" :md="{ span: 6, offset: 18 }" :lg="{ span: 6, offset: 18 }" style="margin-bottom: 24px">
                        <Button v-if="results.length < MaxLength" @click="addResult" long ghost type="primary">Agregar resultado</Button>
                    </i-col>
                </Row>
            </template>
        </template>

        <CreateResult v-if="CanCreateResult"/>
        <UpdateResult v-if="CanCreateResult"/>
        <CreateIndicator v-if="CanCreateResult"/>
        <UpdateIndicator v-if="CanCreateResult"/>
    </div>
</template>

<script>
    import Result from './../../../../General/Result/Item'
    import CreateResult from './components/Create'
    import UpdateResult from './components/Update'
    import { mapGetters } from 'vuex'
    import CreateIndicator from './../Results/components/Indicators/Create'
    import UpdateIndicator from './../Results/components/Indicators/Update'
    import projectMixin from './../../../../../mixins/project'

    export default {
        mixins: [ projectMixin ],
        props:{
            poa: { required: true, type: [Object, Array] },
            program: { required: true, type: [Object, Array] },
            results: { required: true, type: [Object, Array] },
            members: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                is_loading: false,
                max_length: 10,
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status: 'status_poa',
                program_member: 'program',
            }),
            MaxLength(){
                if( this.user.role_name == 'Super Admin' ) return 99
                else return this.max_length
            },
            CanCreateResult(){
                if( this.user.role_name == 'Super Admin' ) return true
                else return this.isAuthUserCoordOfThisProject()
            }
        },
        methods: {
            addResult(){
                window.EventBus.$emit('program-poa--create-result')
            },
        },
        components: {
            Result,
            CreateResult,
            UpdateResult,
            CreateIndicator,
            UpdateIndicator,
        }
    }
</script>