<template>
    <div>
        <br>
        <template v-if="loading">
            <FeedbackView :evaluation="evaluation" :autoevaluation="autoevaluation"/>
        </template>
        <template v-else>
            <template v-if="evaluation.type == 'feedback'">

            </template>
            <template v-else>
                <!-- <Row :gutter="16">
                    <i-col v-if="ShowResultsChart" span="12">
                        <h2 style="text-align: center;">Resultados</h2>
                        <SemiCircle :data="buildCompetencesChartData(results_status)" />
                        <div style="display: flex; align-items: center; justify-content: center;" >
                            <div :style="{ 'background-color': getResultsAvgStatusColor() }" class="indicator--tag"></div>
                            <p style="font-weight: bold; font-size: 0.9em">{{ getResultsAvgStatusName() }}</p>
                        </div>
                    </i-col>
                    <i-col span="12">
                        <h2 style="text-align: center;">Competencias</h2>
                        <SemiCircle :data="buildCompetencesChartData(competences_status)" />
                        <div style="display: flex; align-items: center; justify-content: center;" >
                            <div :style="{ 'background-color': getCompetenceAvgStatusColor() }" class="indicator--tag"></div>
                            <p style="font-weight: bold; font-size: 0.9em">{{ getCompetenceAvgStatusName() }}</p>
                        </div>
                    </i-col>
                </Row> -->
                <br><br>
                <h2>Fortalezas/Reconocimiento</h2>
                <Row :gutter="50">
                    <i-col span="12">
                        <p v-html="autoevaluation.strengths"></p>
                    </i-col>
                    <i-col span="12">
                        <template v-if="CanEvaluationBeUpdated">
                            <Input @on-blur="updateField('strengths_feedback')" v-model="evaluation.strengths_feedback" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                        </template>
                        <template v-else>
                            <div style="padding: 10px 0">
                                <p v-html="evaluation.strengths_feedback"></p>
                            </div>
                        </template>
                    </i-col>
                </Row>
                <br><br>
                <h2>Áreas de oportunidad</h2>
                <Row :gutter="50">
                    <i-col span="12">
                        <p v-html="autoevaluation.opportunities"></p>
                    </i-col>
                    <i-col span="12">
                        <template v-if="CanEvaluationBeUpdated">
                            <Input @on-blur="updateField('opportunities_feedback')" v-model="evaluation.opportunities_feedback" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                        </template>
                        <template v-else>
                            <div style="padding: 10px 0">
                                <p v-html="evaluation.opportunities_feedback"></p>
                            </div>
                        </template>
                    </i-col>
                </Row>
                <br><br>
                <h2>Compromisos y plan de acción</h2>
                <Row :gutter="50">
                    <i-col span="12">
                        <p v-html="autoevaluation.compromises"></p>
                    </i-col>
                    <i-col span="12">
                        <template v-if="CanEvaluationBeUpdated">
                            <Input @on-blur="updateField('compromises_feedback')" v-model="evaluation.compromises_feedback" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                        </template>
                        <template v-else>
                            <div style="padding: 10px 0">
                                <p v-html="evaluation.compromises_feedback"></p>
                            </div>
                        </template>
                    </i-col>
                </Row>
            </template>
        </template>
    </div>
</template>

<script>
    import chartMix from './../../../../../mixins/chart'
    import FeedbackView from './Feedback/EvResult'
    // import SemiCircle from './../../../../Charts/CustomSemiCircle'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [chartMix],
        props: {
            evaluation: { required: true, type: [Object, Array] },
            autoevaluation: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                loading: true,
                results_status: null,
                competences_status: null,
                avg: null,

            }
        },
        computed: {
            ...mapGetters([
                'status'
            ]),
            Statuses(){
                return this.status
            },
            ShowResultsChart(){
               let a = 0
               window._.each(this.results_status, status => {
                   a += status
               })
               return a >= 1
            },
            CanEvaluationBeUpdated(){
                let s = [ 'finished', 'completed' ];
                return ! s.includes(this.evaluation.status)
            },
        },
        methods: {
            getEvaluationResults(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-evaluation-results/${this.evaluation.evaluated.id}/${this.evaluation.evaluation.id}`)
                .then(response => {
                    if(response.data.success){
                        this.avg = response.data.avg
                        this.results_status = response.data.results_status
                        this.competences_status = response.data.competences_status
                        this.loading = false
                        // window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        // this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            },
            buildCompetencesChartData(competences){
                return this.getCompetencesChartData(competences)
            },
            getCompetenceAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.competences.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return '#ececec'
            },
            getResultsAvgStatusName(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].name
                else return 'NA'
            },
            getResultsAvgStatusColor(){
                let si = window._.findIndex(this.Statuses, s => s.score == this.avg.results.rounded)
                if( si != -1 ) return this.Statuses[si].hex_color
                else return '#ececec'
            },
            updateField(field){
                window.axios.post(this.$root.API_URL + `/evaluation/update-field/${this.evaluation.id}`, { field: field, comment: this.evaluation[field] })
                .then(response => {
                    if(response.data.success){
                        window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            }
        },
        mounted(){
            // this.getEvaluationResults()
        },
        components: {
            FeedbackView,
        //     SemiCircle,
        }
    }
</script>