<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo Usuario" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="userForm" :model="user" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input v-model="user.name" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Correo electrónico" prop="email">
                        <Input type="email" v-model="user.email" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Contraseña" prop="password">
                        <Input v-model="user.password" placeholder=""/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Puesto / Rol" prop="role">
                        <Select v-model="user.role">
                            <Option v-for="role in roles" :key="`role_${role.id}`" :value="role.id">{{ role.name }}</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                user: {
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                },
                loading: false,
                roles: [],
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                    email: [
                        { required: true, message: 'Correo electrónico es requerido', trigger: 'change' },
                        { type: 'email', message: 'Correo electrónico no válido', trigger: 'change' }
                    ],
                    password: [
                        { required: true, message: 'Contraseña es requerida', trigger: 'change' },                    
                    ],
                    role: [
                        { required: true, type: 'integer', message: 'Rol / Puesto es requerido', trigger: 'change' },                    
                    ],
                },
            }
        },
        methods: {
            getRoles(){
                window.axios.post(this.$root.API_URL + '/users/get-roles')
                .then(response => {
                    if(response.data.success){
                        this.roles = response.data.roles
                    }
                })
            },
            save(){
                this.$refs.userForm.validate(valid => {
                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/users/create', { ...this.user })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('users-gird--update-users', response.data.users)
                                this.show = false
                                this.loading = false
                                this.reset()
                                this.$Message.success('Usuario creado exitosamente');
                            }
                            else {
                                this.$Message.error(response.data.message);
                            }
                        })
                    }
                })
            },
            reset(){
                this.user = {
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                }
                this.$refs.userForm.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('users-grid--create', () => {
                this.getRoles()
                this.show = true
            })
        }
    }
</script>