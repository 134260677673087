<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo proyecto" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="project" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="24">
                    <FormItem label="Nombre" prop="name">
                        <Input :autosize="{minRows: 5,maxRows: 8}" v-model="project.name" placeholder="Nombre"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <p style="margin-bottom: 10px"><strong>Selecciona las personas involucradas:</strong></p>
                    <div v-for="program in programs" :key="`program_member_list_${program.id}`" style="margin-bottom: 15px; padding-left: 5px">
                        <Checkbox v-model="program.checked_all" @on-change="handleCheckAll(program)"> <strong style="font-size: 1.1em; margin-left: 5px">{{ program.name }}</strong> </Checkbox>
                        <div style="padding-top: 20px; padding-left: 25px">
                            <Checkbox style="width: 100%; display: flex" 
                                v-for="member in program.members" :key="`program_member_${program.id}_id_${member.id}`" 
                                v-model="member.checked"> 
                                <div style="margin-bottom: 15px; margin-left: 10px; margin-top: -5px">
                                    <p style="font-weight: 600">{{ member.name }}</p>
                                    <p style="font-size: 0.9em; color: grey">{{ member.role }}</p>
                                </div>
                            </Checkbox>
                        </div>
                    </div>
                </i-col>
                <i-col span="24">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save">Agregar</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                project: {
                    name: "",
                    areas: [],
                },
                users: [],
                programs: [],
                loading: false,
                ruleInline: {
                    name: [
                        { required: true, message: 'Nombre es requerido', trigger: 'change' }
                    ],
                    areas: [
                        { required: true, type: 'array', message: 'Selecciona al menos una área', trigger: 'change' }
                    ]
                },
            }
        },
        // computed: {
        //     Programs(){
        //         let prgs = this.programs

        //         window._.each(prgs, p => {
        //             p.checked_all = false
        //             window._.each(p.members, m => {
        //                 m.checked = false
        //             })
        //         })

        //         return prgs.sort( (a,b) => {
        //             return a.name.localeCompare(b.name)
        //         })
        //     }
        // },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/projects/create', { ...this.project, members: this.getCheckedUsersIDs() })
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit('drawer--add-project', response.data.project)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                        })
                    }
                })
            },
            getPrograms(){
                window.axios.post(this.$root.API_URL + '/programs/get-all-members')
                .then(response => {
                    if(response.data.success){
                        window._.each( response.data.programs, program => {
                            this.programs.push({
                                ...program,
                                checked_all: false,
                            })
                        })
                        // this.programs = response.data.programs
                    }
                })
            },
            handleCheckAll(program){
                let pi = window._.findIndex(this.programs, p => { return p.id == program.id })
                if( pi != -1 ){
                    if( program.checked_all == true ) window._.each(this.programs[pi].members, member => { member.checked = true })
                    else window._.each(this.programs[pi].members, member => { member.checked = false })
                }
            },
            getCheckedUsersIDs(){
                let ids = [];
                window._.each(this.programs, (program) => {
                    window._.each(program.members, member => {
                        if( member.checked === true ) ids.push(member.id)
                    })
                })
                return ids;
            },
            clear(){
                this.project = {
                    name: "",
                    areas: [],
                }
            }
        },
        mounted(){
            window.EventBus.$on('drawer--create-project', () => {
                if ( this.programs.length == 0 ) this.getPrograms()
                this.show = true
            })
        }
    }
</script>