import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#843cf6',
                error: '#d91e18',
                success: '#019875',
                white: '#fff',
            }
        }
    }
}

export default new Vuetify(opts)