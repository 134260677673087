<template>
    <div :id="`q_${c_id}`" class="work-env-report-item">
        <Row justify="center" align="middle">
            <i-col span="24">
                <h3 style="margin-bottom: 10px">{{ question }}</h3>
                <Button class="display-none-print" size="small" type="primary" @click="download">Descargar</Button>
            </i-col>
            <i-col span="24">
                <PieChart :data="buildChartObject(answers['answers'])" />
            </i-col>
            <i-col v-if=" answers['others'].length > 0 " span="24">
                <div class="work-env--inner">
                    <p class="work-env--other-title">Otras respuestas:</p>
                    <ul class="work-env--other-list">
                        <li v-for="(other_answer, i) in answers['others']" :key="`q3_o_${i}`">
                            {{ other_answer }}
                        </li>
                    </ul>
                </div>
            </i-col>
        </Row>
    </div>
</template>

<script>
    import download from '@/mixins/download'
    import PieChart from "@/components/Charts/PieChart"

    export default {
        mixins: [ download ],
        props: {
            question: { required: true, type: String, },
            answers: { required: true, type: [Array, Object] },
        },
        data(){
            return {
                c_id: (Math.random() + 1).toString(36).substring(7),
            }
        },
        methods:{
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
            download(){
                this.downloadAsImage(document.getElementById(`q_${this.c_id}`), `respuesta-${this.c_id}.png`)
            }
        },
        components: {
            PieChart,
        }
    }
</script>