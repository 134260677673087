<template>
    <div>
        <!-- Q1 -->
        <WorkEnvReportItemHalfPie question="1. ¿Conoces la misión, visión y cadena de cambio propuestas desde el LSB?"
                                  :answers="answers_data['q1']" />
        <!-- Q2 -->
        <WorkEnvReportItemHalfPie question="2. ¿Te identificas con la misión, visión y cadena de cambio del ILSB?"
                                  :answers="answers_data['q2']" />

        <!-- Q3 -->
        <WorkEnvReportItemPie question="3. ¿Qué es lo que más te gusta del ILSB? (puedes seleccionar tantas como quieras)"
                              :answers="answers_data['q3']" />

        <!-- Q4 -->
        <WorkEnvReportItemPie question="4. ¿Qué es lo que menos te gusta del ILSB? (puedes seleccionar tantas como quieras)" :answers="answers_data['q4']" />

        <!-- Q5 -->
        <div class="work-env-report-item">
            <Row justify="center" align="middle">
                <i-col span="12">
                    <h3>5. ¿Por los próximos dos años, ¿Te ves trabajando en el ILSB?</h3>
                </i-col>
                <i-col span="12">
                    <SemiCircle :data="buildChartObject(answers_data['q5']['answers'])" :legend="{ position: 'right' }" />
                </i-col>
                <i-col v-if=" Object.values( answers_data['q5']['negative_reasons'] ).length > 0 " span="24">
                    <div class="work-env--inner">
                        <h3>¿Cuáles podrían ser las razones de esta decisión?</h3>
                        <PieChart :data="buildChartObject(answers_data['q5']['negative_reasons'])" />
                        <br><br>
                        <template>
                            <p class="work-env--other-title">Otras respuestas:</p>
                            <ul class="work-env--other-list">
                                <li v-for="(other_answer, i) in answers_data['q5']['others']" :key="`q4_o_${i}`">
                                    {{ other_answer }}
                                </li>
                            </ul>
                        </template>
                    </div>
                </i-col>
            </Row>
        </div>

        <!-- Q6 -->
        <WorkEnvReportItemHalfPie question="6. ¿Consideras que el ambiente de trabajo refleja los principios institucionales que predica el ILSB?"
                                  :answers="answers_data['q6']" has_yes_no_answers />
    </div>
</template>

<script>
    //import download from '@/mixins/download'
    import PieChart from "@/components/Charts/PieChart"
    import SemiCircle from "@/components/Charts/SemiCircle"
    import WorkEnvReportItemPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-Pie"
    import WorkEnvReportItemHalfPie from "@/components/WorkEnvSurvey/Details/components/WorkEnvReportItem-HalfPie"

    export default {
        props: {
            answers_data: { required: true, type: [Object, Array] },
        },
        methods: {
            buildChartObject(answer_data){
                let object = []
                window._.each(answer_data, data => {
                    object.push({
                        name: data['name'],
                        value: data['count'],
                        hex_color: this.getHexColor(data['name']),
                    })
                })
                return object
            },
            getHexColor(value){
                if( value === "Si" ) return "#16a085"
                else if ( value === "No" ) return "#f1828d"
                else return "#000"
            },
        },
        components: {
            PieChart,
            SemiCircle,
            WorkEnvReportItemPie,
            WorkEnvReportItemHalfPie,
        }
    }
</script>