<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/area/${area.slug}`">{{ area.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ area.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="budget" :poa_link="`/area/${$route.params.name}/${$route.params.year}`" />

            <br>
            <Row style="text-align: center">
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Disponible</strong></p>
                    <h2 style="font-weight: normal">{{ InitialBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Presupuesto</strong></p>
                    <h2 style="font-weight: normal">{{ TotalBudget | currency }}</h2>
                </Col>
                <Col :xs="12" :sm="12" :md="8" :lg="8">
                    <p><strong>Variación</strong></p>
                    <h2 style="font-weight: normal">{{ DeficitBudget | currency }}</h2>
                </Col>
            </Row>
            <br>
            <br>
            <BudgetTabs tab="requests" :poa_link="`/area/${$route.params.name}/${$route.params.year}`" />
            <br>
            <template v-if="Activities.length == 0">
                <EmptyState icon="ios-archive" title="" :content="`No hay autorizaciones.`" />
            </template>
            <template v-else>
                <Collapse simple class="header-flex budget" v-model="collapse">
                    <Panel v-for="activity in Activities" :key="'budget_list_authorized_'+activity.id" :name="activity.name">
                        <div class="budget-header--collapse-panel">
                            <div style="flex-grow: 1">
                                <p style="font-size: 0.9em; margin: 5px 0">
                                    <strong>Resultado: </strong> {{ activity.result.name }}
                                </p>
                                <h3 style="flex-grow: 1; display: flex; align-items: center;">{{ activity.name }}</h3>
                            </div>
                            <div  @click.stop="() => { showActivityComments(activity) }" class="action budget-header--action-currency">
                                <Badge :count="activity.comments_count">
                                    <Tooltip content="Comentarios" placement="left">
                                        <Icon size="25" type="md-chatbubbles" />
                                    </Tooltip>
                                </Badge>
                            </div>
                        </div>

                        <template #content>
                            <Collapse simple class="header-flex">
                                <div style="padding-left: 25px" v-for="h_detail in activity.budget_details" :key="'budget_list_authorized_heading_'+h_detail.heading.id" :name="h_detail.heading.name">
                                    <Panel v-if="h_detail.subheadings.length > 0">
                                        <div class="budget-header--collapse-panel">
                                            <h4 style="flex-grow: 1; display: flex; align-items: center; margin-left: 15px">{{ h_detail.heading.name }}</h4>
                                            <div class="action budget-header--action-currency">
                                                <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                    <p>Presupuesto</p>
                                                    <h4>{{ h_detail.heading.total | currency }}</h4>
                                                </div>
                                                <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                    <p>Ejercido</p>
                                                    <h4>{{ ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].spent | currency }}</h4>
                                                </div>
                                                <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                    <p>Por ejercer</p>
                                                    <h4 :class="{ 'red-text': ( h_detail.heading.total - ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].spent ) < 0 }">{{ ( h_detail.heading.total - ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].spent ) | currency }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <template #content>
                                            <Collapse simple class="header-flex">
                                                <Panel v-for="d_subheading in h_detail.subheadings" :key="'budget_list_authorized_subheading_'+d_subheading.subheading.id" :name="d_subheading.subheading.name">
                                                    <div class="budget-header--collapse-panel">
                                                        <h4 style="flex-grow: 1; display: flex; align-items: center;">{{ d_subheading.subheading.name }}</h4>
                                                        <div class="action budget-header--action-currency">
                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                <p>Presupuesto</p>
                                                                <h4>{{ ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].subheadings[`id_${d_subheading.subheading.id}`].total | currency }}</h4>
                                                            </div>
                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                <p>Ejercido</p>
                                                                <h4>{{ ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].subheadings[`id_${d_subheading.subheading.id}`].spent | currency }}</h4>
                                                            </div>
                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                <p>Por ejercer</p>
                                                                <h4 :class="{ 'red-text': ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].subheadings[`id_${d_subheading.subheading.id}`].available < 0 }" >{{ ActivitiesSpent[`id_${activity.id}`].headings[`id_${h_detail.heading.id}`].subheadings[`id_${d_subheading.subheading.id}`].available | currency }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template #content>
                                                         <Collapse simple class="header-flex">
                                                                <Panel v-for="budget in d_subheading.budgets" :key="'budget_list_authorized_subheading_budget_'+budget.id+'_budget_'+budget.id" :name="'transfer_'+budget.id">
                                                                    <div class="budget-header--collapse-panel">
                                                                        <h4 style="flex-grow: 1; display: flex; align-items: center;">{{ budget.funder.name }}</h4>
                                                                        <div class="action budget-header--action-currency">
                                                                            <div class="budget-header--action-currency--item">
                                                                                <Button v-if="CanCreateRequest" @click.stop="newBudgetTransfer(activity, budget)" size="small" type="primary">Nueva solicitud</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <template #content>
                                                                        <template v-if="budget.transfers.length == 0">
                                                                            <p style="text-align: center; padding: 15px 0">No hay solicitudes creadas</p>
                                                                        </template>
                                                                        <template v-else>
                                                                            <Collapse simple class="header-flex">
                                                                                <Panel v-for="transfer in budget.transfers" :key="'budget_list_authorized_budget_b_'+budget.id+'_transfer_'+transfer.id" :name="'transfer_'+transfer.id">
                                                                                    <div class="budget-header--collapse-panel">
                                                                                        <div style="flex-grow: 1; display: flex; justify-content: center; flex-direction: column">
                                                                                            <p><small>{{ transfer.type }}</small></p>
                                                                                            <h4>{{ transfer.in_favor_of }}</h4>
                                                                                        </div>
                                                                                        <div class="action budget-header--action-currency">
                                                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                                                <Tooltip v-if="CanAuthorizeRequest" :content="transfer.authorized_at == null ? 'Autorizar' : 'Autorizado'" placement="left">
                                                                                                    <Button @click="toggleTransferAuth(activity, budget, transfer)" shape="circle" :type="authBudgetButtonType(transfer)" icon="ios-checkmark-circle"/>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            <div class="budget-header--action-currency--item">
                                                                                                <Button @click.stop="pdfViewer(transfer)" ghost type="primary">Ver solicitud</Button>
                                                                                            </div>
                                                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                                                <p>Importe</p>
                                                                                                <h4>{{ transfer.amount | currency }}</h4>
                                                                                            </div>
                                                                                            <div @click.stop="() => {}" class="budget-header--action-currency--item">
                                                                                                <Dropdown v-if="CanModifyOrDeleteRequest" trigger="click" placement="left-end">
                                                                                                    <a href="javascript:void(0)">
                                                                                                        <Icon color="grey" size="16" type="md-more"></Icon>
                                                                                                    </a>
                                                                                                    <DropdownMenu slot="list">
                                                                                                        <DropdownItem @click.native="updateBudgetTransfer(activity, budget, transfer)">Editar</DropdownItem>
                                                                                                        <DropdownItem @click.native="deleteBudgetTransfer(activity, budget, transfer)">Eliminar</DropdownItem>
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <template #content>
                                                                                        <div style="margin: 0 30px">
                                                                                            <Row style="margin-bottom: 15px">
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Fecha solicitud</small></p>
                                                                                                    <p><strong>{{ transfer.date | moment('DD/MM/YYYY') }}</strong></p>
                                                                                                </i-col>
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Solicitado por</small></p>
                                                                                                    <p><strong>{{ transfer.created_by.name }}</strong></p>
                                                                                                    <p><strong><small>{{ transfer.created_at | moment('DD/MM/YYYY HH:mm') }}</small></strong></p>
                                                                                                </i-col>
                                                                                                <i-col v-if="transfer.authorized_by" :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Autorizado por</small></p>
                                                                                                    <p><strong>{{ transfer.authorized_by.name }}</strong></p>
                                                                                                    <p><strong><small>{{ transfer.authorized_at | moment('DD/MM/YYYY HH:mm') }}</small></strong></p>
                                                                                                </i-col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Banco</small></p>
                                                                                                    <p><strong>{{ transfer.bank_name }}</strong></p>
                                                                                                </i-col>
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Títular de la cuenta</small></p>
                                                                                                    <p><strong>{{ transfer.bank_holder }}</strong></p>
                                                                                                </i-col>
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>Cuenta</small></p>
                                                                                                    <p><strong>{{ transfer.bank_account }}</strong></p>
                                                                                                </i-col>
                                                                                                <i-col :xs="24" :sm="24" :md="6" :lg="6" >
                                                                                                    <p><small>CLABE</small></p>
                                                                                                    <p><strong>{{ transfer.bank_clabe }}</strong></p>
                                                                                                </i-col>
                                                                                                <i-col v-if="transfer.international != null" :xs="24" :sm="24" :md="24" :lg="24" >
                                                                                                    <p>&nbsp;</p>
                                                                                                    <p><small>Datos para transferencia internacional</small></p>
                                                                                                    <p style="white-space: break-spaces;"><strong>{{ transfer.international }}</strong></p>
                                                                                                </i-col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </template>
                                                                                </Panel>
                                                                            </Collapse>
                                                                        </template>
                                                                    </template>
                                                                </Panel>
                                                         </Collapse>
                                                    </template>
                                                </Panel>
                                            </Collapse>
                                        </template>
                                    </Panel>
                                </div>
                            </Collapse>

                            <!-- Total row -->
                            <div style="padding: 10px 0px; background: #ececec" class="budget-header--collapse-panel">
                                <h4 style="flex-grow: 1; display: flex; align-items: center; margin-left: 15px"></h4>
                                <div style="width: 360px; justify-content: center;" class="action budget-header--action-currency">
                                    <div style="width: 120px" class="budget-header--action-currency--item">
                                        <p>Presupuesto</p>
                                        <h4>{{ GetRowBudgetTotal(activity.budget_details) | currency }}</h4>
                                    </div>
                                    <div style="width: 120px" class="budget-header--action-currency--item">
                                        <p>Ejercido</p>
                                        <h4>{{ GetRowSpentTotal(activity) | currency }}</h4>
                                    </div>
                                    <div style="width: 120px" class="budget-header--action-currency--item">
                                        <p>Por ejercer</p>
                                        <h4 :class="{ 'red-text': ( GetRowBudgetTotal(activity.budget_details) - GetRowSpentTotal(activity)  ) < 0 }">{{ ( GetRowBudgetTotal(activity.budget_details) - GetRowSpentTotal(activity) ) | currency }}</h4>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Panel>
                </Collapse>
            </template>

            <CreateTransfer/>
            <UpdateTransfer/>
            <PdfFormatTransfer/>
            <CommentsPopup />
        </template>
    </div>
</template>

<script>
    import POATabs from './../../../Tabs'
    import BudgetTabs from './../Tabs'
    import projectmixin from './../../../../../../../mixins/project'
    import { mapGetters } from 'vuex'
    import CommentsPopup from './components/CommentsPopup'
    import CreateTransfer from './components/CreateTransfer'
    import UpdateTransfer from './components/UpdateTransfer'
    import PdfFormatTransfer from './components/PdfViewer'

    export default {
        mixins: [ projectmixin ],
        data(){
            return {
                poa: [],
                collapse: '',
                budgets: [],
                project: [],
                funders: [],
                is_loading: true,
                activities: [],
                total_budget: 0,
                members: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                program_member: 'program',
            }),
            CanCreateRequest(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserMembersOfThisProject()
            },
            CanModifyOrDeleteRequest(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.isAuthUserCoordOfThisProject()
            },
            CanAuthorizeRequest(){
                return this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.$can('poa.budget.authorize')
            },
            Activities(){
                return window._.filter(this.activities, a => a.has_budget_authorized)
            },
            InitialBudget(){
                let total = 0

                window._.each(this.funders, (f) => {
                    window._.each(f.budget, (b) => {
                        total += parseFloat(b.final_total_price)
                    })
                })

                return total
            },
            TotalBudget(){
                return this.total_budget
            },
            DeficitBudget(){
                return this.InitialBudget - this.TotalBudget
            },
            ActivitiesSpent(){
                let a = {}
                window._.each(this.activities, activity => {
                    a[`id_${activity.id}`] = {
                        headings: {},
                    }
                    window._.each(activity.budget_details, bd => {
                        let heading_total = 0
                        a[`id_${activity.id}`].headings[`id_${bd.heading.id}`] = {
                            spent: 0,
                            subheadings: {},
                        }
                        window._.each(bd.subheadings, sb => {
                            let subheading_total = 0
                            a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`] = {
                                total: 0,
                                spent: 0,
                                available: 0,
                            }
                            let budget_total = 0
                            window._.each(sb.budgets, budget => {
                                budget_total += parseFloat(budget.final_gross_price)
                                window._.each(budget.transfers, transfer => {
                                    if( transfer.authorized_at != null ){
                                        subheading_total += parseFloat(transfer.amount)
                                        heading_total += parseFloat(transfer.amount)
                                    }
                                })
                            })
                            
                            // SET Subheading total
                            a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`].total = budget_total
                            // Si es honorarios: calcular el bruto
                            if( bd.heading.name == 'Honorarios' ){
                                let subheading_total_gross = subheading_total / 0.95333333334  * 1.16
                                a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`].spent = subheading_total_gross
                                a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`].available = budget_total - subheading_total_gross
                            }
                            // Sino, es el total que ya se había calculado en el proceso
                            else {
                                a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`].spent = subheading_total
                                a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].subheadings[`id_${sb.subheading.id}`].available = budget_total - subheading_total
                            }
                        })
                        // SET Heading total
                        // Si es honorarios: calcular el bruto
                        if( bd.heading.name == 'Honorarios' ){
                            a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].spent = heading_total / 0.95333333334  * 1.16
                        }
                        // Sino, es el total que ya se había calculado en el proceso
                        else {
                            a[`id_${activity.id}`].headings[`id_${bd.heading.id}`].spent = heading_total
                        }
                    })
                })
                return a
            },
        },
        methods: {
            GetRowBudgetTotal(budget_details){
                let total = 0
                window._.each(budget_details, det => {
                    total += parseFloat(det.heading.total)
                })
                return total
            },
            GetRowSpentTotal(activity){
                let total = 0
                if ( activity ){
                    window._.each( this.ActivitiesSpent[`id_${activity.id}`].headings, heading => {
                        total += parseFloat(heading.spent)
                    } )
                }
                return total
            },
            showActivityComments(activity){
                window.EventBus.$emit('poa-budget--activity-comments', activity)
            },
            authBudgetButtonType(transfer){
                return transfer.authorized_by != null ? 'success' : 'text'
            },
            toggleTransferAuth(activity, budget,transfer){
                if( transfer.authorized_by == null ){
                    this.$Modal.confirm({
                        title: `Autorizar solicitud`,
                        content: `<p>¿Deseas autorizar esta solicitud?</p>`,
                        okText: `Autorizar`,
                        cancelText: 'Cancelar',
                        onOk: () => {
                            window.axios.post(this.$root.API_URL + `/budget/authorize-transfer/${transfer.id}`)
                            .then(response => {
                                if(response.data.success){
                                    window.EventBus.$emit('poa-budget-requests--update-transfer', activity, budget, response.data.transfer) 
                                    this.$Message.success('Presupuesto actualizado correctamente');
                                }
                            })
                        },
                    });
                }
            },
            getAuthorizedHeadings(headings){
                return window._.filter(headings, h => h.has_authorized == true )
            },
            getAuthorizedSubheadings(subheadings){
                return window._.filter(subheadings, s => s.budget.authorized == true )
            },
            getTotalOfHeading(heading){
                let total = 0

                window._.each(heading.subheadings, (sub) => {
                    let ps = parseFloat(sub.budget.final_gross_price)
                    if( ps ) total += ps
                })

                return total
            },
            getTotalSpentOfHeading(heading){
                let total = 0

                window._.each(heading.subheadings, (sub) => {
                    if(sub.budget.authorized){
                        let ps = parseFloat(sub.budget.spent)
                        if( ps ) total += ps
                    }
                })

                return total
            },
            getTotalAvailableOfHeading(heading){
                let h_total = 0
                let s_total = 0

                window._.each(heading.subheadings, (sub) => {
                    let ps = parseFloat(sub.budget.final_gross_price)
                    if( ps ) h_total += ps
                })


                window._.each(heading.subheadings, (sub) => {
                    if(sub.budget.authorized){
                        let ps = parseFloat(sub.budget.spent)
                        if( ps ) s_total += ps
                    }
                })

                return h_total - s_total
            },
            newBudgetTransfer(activity, budget){
                window.EventBus.$emit('poa-budget--create-transfer-poup', activity, budget)
            },
            updateBudgetTransfer(activity, budget, transfer){
                if( transfer.authorized_at == null || transfer.authorized_at == '' ) window.EventBus.$emit('poa-budget--update-transfer-poup', activity, budget, transfer)
                else this.$Message.error('Esta transferencia no se puede editar');
            },
            deleteBudgetTransfer(activity, budget, transfer){
                if( transfer.authorized_at == null || transfer.authorized_at == '' ){
                    this.$Modal.confirm({
                        title: `Eliminar transferencia`,
                        okText: `Aceptar`,
                        cancelText: 'Cancelar',
                        onOk: () => {
                            window.axios.post(this.$root.API_URL + `/budget/delete-transfer/${transfer.id}`)
                            .then(response => {
                                if(response.data.success){
                                    window.EventBus.$emit('poa-budget-requests--delete-transfer', activity, budget, transfer)
                                    this.$Message.success('Transferencia eliminada')
                                }
                            })
                        },
                    })
                }
                else this.$Message.error('Esta transferencia no se puede eliminar');
            },
            pdfViewer(transfer){
                window.EventBus.$emit('poa-budget--view-transfer-format-poup-project', transfer)
                // window.axios.post(this.$root.API_URL + `/budget/transfer-format/${transfer.id}`, {}, { headers: {responseType: 'blob'} })
                // .then(response => {
                //     console.log(response)
                // })
            },
            getPOA(area_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${area_slug}/get-area`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.area = response.data.area
                        this.poa = response.data.poa
                        this.members = response.data.members
                        this.getFunders()
                        this.getActivities()
                        // this.getResults()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.area.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            getFunders(){
                window.axios.post(this.$root.API_URL + `/budget/get-funders/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.funders = response.data.funders
                    }
                })
            },
            getActivities(){
                window.axios.post(this.$root.API_URL + `/budget/get-authorized-budgets/${this.poa.id}`)
                .then(response => {
                    if(response.data.success){
                        this.activities = response.data.activities
                        this.total_budget = response.data.activities_budget_total
                        this.is_loading = false
                    }
                })
            },
        },
        mounted(){
            if( this.$itsSuperAdmin() || this.$itsAdminTeammate() || this.$itsDirector() || this.$itsSubdirector() || this.$hasRole('Oficial del área directiva') ){
                let poa_year = this.$route.params.year
                let area_slug = this.$route.params.name

                this.getPOA(area_slug, poa_year)
            }
            else this.$router.push('/')

           /* Collapse drawer menu */
            this.$collpaseDrawer('poas-content')

            window.EventBus.$on('poa-budget-request--update-activity', (activity) => {
                let ai = window._.findIndex(this.activities, (a) => {
                    return a.id == activity.id
                })

                if(ai != -1){
                    this.$set(this.activities, ai, activity)
                }

            })

            window.EventBus.$on('poa-budget-requests--add-transfer', (activity, budget, transfer) => {
                let ai = window._.findIndex(this.activities, a => a.id == activity.id)
                if( ai != -1 ){
                    let hi = window._.findIndex(this.activities[ai].budget_details, bd => bd.heading.id == budget.heading.id)
                    if( hi != -1 ){
                        let si = window._.findIndex(this.activities[ai].budget_details[hi].subheadings, sb => sb.subheading.id == budget.subheading.id)
                        if( si != -1 ){
                            let bi = window._.findIndex(this.activities[ai].budget_details[hi].subheadings[si].budgets, b => b.id == budget.id)
                            if( bi != -1 ){
                                this.activities[ai].budget_details[hi].subheadings[si].budgets[bi].transfers.push(transfer)
                            }
                        }
                    }
                }
            })

            window.EventBus.$on('poa-budget-requests--update-transfer', (activity, budget, transfer) => {
                let ai = window._.findIndex(this.activities, a => a.id == activity.id)
                if( ai != -1 ){
                    let hi = window._.findIndex(this.activities[ai].budget_details, bd => bd.heading.id == budget.heading.id)
                    if( hi != -1 ){
                        let si = window._.findIndex(this.activities[ai].budget_details[hi].subheadings, sb => sb.subheading.id == budget.subheading.id)
                        if( si != -1 ){
                            let bi = window._.findIndex(this.activities[ai].budget_details[hi].subheadings[si].budgets, b => b.id == budget.id)
                            if( bi != -1 ){
                                let ti = window._.findIndex(this.activities[ai].budget_details[hi].subheadings[si].budgets[bi].transfers, t => t.id == transfer.id)
                                if( ti != -1 ){
                                    this.$set(this.activities[ai].budget_details[hi].subheadings[si].budgets[bi].transfers, ti, transfer)
                                }
                            }
                        }
                    }
                }
            })

            window.EventBus.$on('poa-budget-requests--delete-transfer', (activity, budget, transfer) => {
                let ai = window._.findIndex(this.activities, a => a.id == activity.id)
                if( ai != -1 ){
                    let hi = window._.findIndex(this.activities[ai].budget_details, bd => bd.heading.id == budget.heading.id)
                    if( hi != -1 ){
                        let si = window._.findIndex(this.activities[ai].budget_details[hi].subheadings, sb => sb.subheading.id == budget.subheading.id)
                        if( si != -1 ){
                            let bi = window._.findIndex(this.activities[ai].budget_details[hi].subheadings[si].budgets, b => b.id == budget.id)
                            if( bi != -1 ){
                                let ti = window._.findIndex(this.activities[ai].budget_details[hi].subheadings[si].budgets[bi].transfers, t => t.id == transfer.id)
                                if( ti != -1 ){
                                    this.activities[ai].budget_details[hi].subheadings[si].budgets[bi].transfers.splice(ti, 1)
                                }
                            }
                        }
                    }
                }
            })
        },
        components: {
            POATabs,
            BudgetTabs,
            CommentsPopup,
            CreateTransfer,
            UpdateTransfer,
            PdfFormatTransfer,
        }
    }
</script>