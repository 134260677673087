<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan institucional</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}`">{{ program.name }}</BreadcrumbItem>
                <BreadcrumbItem>{{ poa.year }}</BreadcrumbItem>
            </Breadcrumb>

            <Row>
                <i-col span="24">
                    <h2 class="program--title">{{ program.name }} ( {{ poa.year }} )</h2>
                </i-col>
            </Row>
            <br>
            
            <POATabs tab="bitacora" :poa_link="`/programa/${$route.params.name}/${$route.params.year}`" />
            <br>

            <BitacoraTabs tab="recap" :poa_link="`/programa/${$route.params.name}/${$route.params.year}`" />
            <br>

            <template v-if="imp_activities.length == 0">
                <EmptyState icon="md-list-box" title="" :content="`${program.name} (${poa.year}) no tiene actividades de impacto agregadas.`" />
            </template>
            <template v-else>

                <div v-for="(impactActivity, i) in ImpactActivities" :key="`imp_act_${impactActivity.id}`">
                    <template v-if="i > 0">
                        <br><br><br><br>
                    </template>
                    <Row>
                        <i-col span="8">
                            <h3>Actividades de impacto ({{ impactActivity.name }})</h3>
                            <br>
                            <Row>
                                <i-col span="12">
                                    <p>Costo total</p>
                                    <h3>{{ TotalSpent(impactActivity.id) | currency }}</h3>
                                </i-col>
                                <i-col span="12">
                                    <p>Beneficiadas</p>
                                    <h3>{{ TotalOfPeople(impactActivity.id) }}</h3>
                                </i-col>
                            </Row>
                        </i-col>
                        <i-col span="12">
                            <Table class="profiles" row-key="uid" :columns="columns_profiles" :data="ActivityProfiles(impactActivity.id)" show-summary :summary-method="handleSummaryForProfiles" border>
                            </Table>
                        </i-col>
                    </Row>
                    <br>
                    <br>

                    <Table class="bitacora" row-key="uid" :columns="columns" :data="GeneralData(impactActivity.id)" :update-show-children="true" border>

                        <template slot-scope="{ row }" slot="state_name">
                            <span :class="{ 'state_name_bold': row.type == 'state', 'padding-city': row.type == 'city', 'padding-profile': row.type == 'profile', 'profile-text': row.type == 'profile'  }">{{ row.state_name | capitalize }}</span>
                        </template>

                        <template slot-scope="{ row }" slot="asist_total">
                            <span>{{ parseInt(row.asist_m) + parseInt(row.asist_h) + parseInt(row.asist_o) }}</span>
                        </template>

                        <template slot-scope="{ row }" slot="e_total">
                            <span>{{ parseInt(row.e_indig) + parseInt(row.e_afro) + parseInt(row.e_otro) }}</span>
                        </template>

                    </Table>
                </div>
            </template>

        </template>
    </div>
</template>

<script>
    import POATabs from './../Tabs'
    import BitacoraTabs from './Tabs'
     import estadosMex from './../../../../../services/estados.json'
    import municipiosMex from './../../../../../services/municipios.json'

    export default {
        data(){
            return {
                collapse: '',
                profiles: [],
                columns_profiles: [
                    {
                        title: 'Perfil de asistente',
                        key: 'name',
                        align: 'center',
                    },
                    {
                        title: 'Total',
                        key: 'total',
                        align: 'center',
                    }
                ],
                columns: [
                    {
                        title: ' ',
                        key: 'state_name',
                        slot: 'state_name',
                        tree: true,
                    },
                    {
                        title: 'Número de asistentes / Personas beneficiadas',
                        align: 'center',
                        children: [
                            {
                                title: 'Mujeres',
                                key: 'asist_m',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Hombres',
                                key: 'asist_h',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Otres',
                                key: 'asist_o',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Total',
                                key: 'asist_total',
                                slot: 'asist_total',
                                width: 62,
                                align: 'center',
                            },
                        ]
                    },
                    {
                        title: 'Pertenencia étnica',
                        align: 'center',
                        children: [
                            {
                                title: 'Indígenas',
                                key: 'e_indig',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Afros',
                                key: 'e_afro',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Otro',
                                key: 'e_otro',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: 'Total',
                                key: 'e_total',
                                slot: 'e_total',
                                width: 62,
                                align: 'center',
                            },
                        ]
                    },
                    {
                        title: 'Rangos de edad',
                        align: 'center',
                        children: [
                            {
                                title: '< 18',
                                key: 'a_m_18',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: '18 - 29',
                                key: 'a_18_29',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: '30 - 49',
                                key: 'a_30_49',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: '50 - 65',
                                key: 'a_50_65',
                                width: 62,
                                align: 'center',
                            },
                            {
                                title: '> 65',
                                key: 'a_m_65',
                                width: 62,
                                align: 'center',
                            },
                        ]
                    },
                ],
                estados: [],
                municipios: [],
                is_loading: true,
                imp_activities: [],
            }
        },
        computed: {
            BudgetTotal(){
                let total = 0
                window._.each(this.imp_activities, activity => {
                    window._.each(activity.activities, a => {
                        total += parseFloat(a.budget_total)
                    })
                })
                return total
            },
            ImpactActivities(){
                let a = []
                let data = window._.groupBy(this.imp_activities, 'impact_activity_id')
                window._.each(data, d => {
                    a.push({
                        ...d[0].impact_activity,
                    })
                })
                return a 
            },
        },
        methods: {
            GeneralData(impactActivityId){
                let a = []
                let data = this.groupData(impactActivityId)

                window._.each(data, d=> {
                    let item = {}
                    item.type = 'state'
                    item.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
                    item.state_name = d[0].state
                    item.children = []
                    item.a_18_29 = 0
                    item.a_30_49 = 0
                    item.a_50_65 = 0
                    item.a_m_18 = 0
                    item.a_m_65 = 0
                    item.asist_h = 0
                    item.asist_m = 0
                    item.asist_o = 0
                    item.asist_total = 0
                    item.e_indig = 0
                    item.e_afro = 0
                    item.e_otro = 0

                    //each city 
                    window._.each(d.cities, city => {
                        let itm = {}
                        itm.type = 'city'
                        itm.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
                        itm.state_name = city[0].city
                        itm.children = []
                        itm.a_18_29 = 0
                        itm.a_30_49 = 0
                        itm.a_50_65 = 0 
                        itm.a_m_18 = 0
                        itm.a_m_65 = 0
                        itm.asist_h = 0
                        itm.asist_m = 0
                        itm.asist_o = 0 
                        itm.e_indig = 0
                        itm.e_afro = 0
                        itm.e_otro = 0

                        window._.each(city, c => {
                            let im = {}
                            im.uid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
                            im.type = 'profile'
                            im.state_name = c.profiles.map(p => p.name).join(', ')
                            im.a_18_29 = 0
                            im.a_30_49 = 0
                            im.a_50_65 = 0 
                            im.a_m_18 = 0
                            im.a_m_65 = 0
                            im.asist_h = 0
                            im.asist_m = 0
                            im.asist_o = 0 
                            im.e_indig = 0
                            im.e_afro = 0
                            im.e_otro = 0
                            

                            im.a_18_29 = item.a_18_29 + parseInt(c.a_18_29)
                            im.a_30_49 = item.a_30_49 + parseInt(c.a_30_49)
                            im.a_50_65 = item.a_50_65 + parseInt(c.a_50_65)
                            im.a_m_18 = item.a_m_18 + parseInt(c.a_m_18)
                            im.a_m_65 = item.a_m_65 + parseInt(c.a_m_65)
                            im.asist_h = item.asist_h + parseInt(c.asist_h)
                            im.asist_m = item.asist_m + parseInt(c.asist_m)
                            im.asist_o = item.asist_o + parseInt(c.asist_o)
                            im.e_indig = item.e_indig + parseInt(c.e_indig)
                            im.e_afro = item.e_afro + parseInt(c.e_afro)
                            im.e_otro = item.e_otro + parseInt(c.e_otro)
                            itm.children.push(im)

                        })

                        // each city -> count 
                        window._.each(city, c => {
                            itm.a_18_29 = itm.a_18_29 + parseInt(c.a_18_29)
                            itm.a_30_49 = itm.a_30_49 + parseInt(c.a_30_49)
                            itm.a_50_65 = itm.a_50_65 + parseInt(c.a_50_65)
                            itm.a_m_18 = itm.a_m_18 + parseInt(c.a_m_18)
                            itm.a_m_65 = itm.a_m_65 + parseInt(c.a_m_65)
                            itm.asist_h = itm.asist_h + parseInt(c.asist_h)
                            itm.asist_m = itm.asist_m + parseInt(c.asist_m)
                            itm.asist_o = itm.asist_o + parseInt(c.asist_o)
                            itm.e_indig = itm.e_indig + parseInt(c.e_indig)
                            itm.e_afro = itm.e_afro + parseInt(c.e_afro)
                            itm.e_otro = itm.e_otro + parseInt(c.e_otro)
                        })
                        item.children.push(itm)
                    })

                    // each state -> count
                    window._.each(d, dd => {
                        item.a_18_29 = item.a_18_29 + parseInt(dd.a_18_29)
                        item.a_30_49 = item.a_30_49 + parseInt(dd.a_30_49)
                        item.a_50_65 = item.a_50_65 + parseInt(dd.a_50_65)
                        item.a_m_18 = item.a_m_18 + parseInt(dd.a_m_18)
                        item.a_m_65 = item.a_m_65 + parseInt(dd.a_m_65)
                        item.asist_h = item.asist_h + parseInt(dd.asist_h)
                        item.asist_m = item.asist_m + parseInt(dd.asist_m)
                        item.asist_o = item.asist_o + parseInt(dd.asist_o)
                        item.e_indig = item.e_indig + parseInt(dd.e_indig)
                        item.e_afro = item.e_afro + parseInt(dd.e_afro)
                        item.e_otro = item.e_otro + parseInt(dd.e_otro)
                    })
                    a.push(item)
                })

                return a
            },
            ActivityProfiles(id){
                let a = []
                
                window._.each(this.imp_activities, imp_act => {
                    if( imp_act.impact_activity.id == id ){
                        window._.each(imp_act.profiles, profile => {

                            let ai = window._.findIndex(a, (aa) => {
                                return aa.name == profile.profiles[0].name;
                            })

                            if ( ai == -1 ){
                                let itm_total = parseInt( profile.asist_h ) + parseInt( profile.asist_m ) + parseInt( profile.asist_o )
                                let item = {
                                    uid: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
                                    name: profile.profiles[0].name,
                                    total: itm_total,
                                }

                                a.push(item)
                            }
                            else {
                                let itm_total = parseInt( profile.asist_h ) + parseInt( profile.asist_m ) + parseInt( profile.asist_o )
                                a[ai].total += itm_total
                            }

                        })
                    }
                })

                return a
            },
            handleSummaryForProfiles({ data }){
                let total = 0

                window._.each(data, d => {
                    total += d.total
                })

                return {
                    name: {
                        key: 'name',
                        value: 'Total',
                    },
                    total: {
                        key: 'total',
                        value: total,
                    }
                }
            },
            TotalOfPeople(ImpactActivityId){
                let total = 0
                let data = window._.filter(this.imp_activities, { 'impact_activity_id': ImpactActivityId })
                window._.each(data, d => {
                    window._.each(d.profiles, profile => {
                        total += parseInt(profile.asist_h)
                        total += parseInt(profile.asist_m)
                        total += parseInt(profile.asist_o)
                    })
                })
                return total
            },
            TotalSpent(ImpactActivityId){
                let total = 0
                let activities_counted_ids = []
                let data = window._.filter(this.imp_activities, { 'impact_activity_id': ImpactActivityId })
                window._.each(data, d => {
                    window._.each(d.activities, activity => {
                        if( ! activities_counted_ids.includes( activity.id ) ){
                            total += parseFloat(activity.budget_total)
                            activities_counted_ids.push( activity.id )
                        }
                        /*window._.each(activity.budget_details, bd => {
                            window._.each(bd.subheadings, sb => {
                                window._.each(sb.budgets, budget => {
                                    window._.each(budget.transfers, (transfer) => {
                                        let ps = parseFloat(transfer.amount)
                                        console.log(ps)
                                        if( ps ){
                                            if( bd.heading.name == 'Honorarios' ){
                                                let nps = ps / 0.95333333334  * 1.16
                                                total += nps
                                            }
                                            else  total += ps
                                        }
                                    })
                                })
                            })
                        })*/
                    })
                })
                return total
            },
            getPOA(program_slug, poa_year){
                window.axios.post(this.$root.API_URL + `/poas/${program_slug}/get`, { year: poa_year })
                .then(response => {
                    if(response.data.success){
                        this.program = response.data.program
                        this.poa = response.data.poa
                        // this.fetchData()
                        this.fetchImpactActivities()

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.program.name)

                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            fetchImpactActivities(){
                window.axios.post(this.$root.API_URL + `/bitacora/poa-impact-activity/get/${this.poa.id}`,)
                .then(response => {
                    if( response.data.success ){
                        this.imp_activities = response.data.activities
                        this.fetchActivitiesBudget()
                    }
                })
            },
            fetchActivitiesBudget(){
                let self = this
                window._.each(this.imp_activities, imp_act => {
                    window._.each(imp_act.activities, activity => {
                        this.fetchBudgetInfo(activity.id).then(data => activity.budget_details = data)
                    })
                })
                setTimeout(() => {
                    self.is_loading = false
                }, 1000)
            },
            fetchBudgetInfo(activity_id){
                return window.axios.post(this.$root.API_URL + `/activities/budget/${activity_id}`)
                .then(response => {
                    if(response.data.success){
                        return response.data.budget_details
                    }
                })
            },
            // fetchData(){
            //     window.axios.post(this.$root.API_URL + `/bitacora/impact-activity/get-data/${this.poa.id}`)
            //     .then(response => {
            //         if(response.data.success){
            //             // this.profiles = response.data.profiles
            //             window._.each(response.data.profiles, profile => {
            //                 let  p = {
            //                     ...profile,
            //                     a_18_29: 0,
            //                     a_30_49: 0,
            //                     a_50_65: 0,
            //                     a_m_18: 0,
            //                     a_m_65: 0,
            //                     asist_h: 0,
            //                     asist_m: 0,
            //                     asist_o: 0,
            //                     cities: [],
            //                     states: [],
            //                 }
            //                 this.profiles.push(p)
            //             })
            //             this.data_fetched = true
            //         }
            //     })
            // },
            groupData(impactActivityId){
                let data = []
                // let f_data = []

                window._.each(this.imp_activities, imp_activity => {
                    if( imp_activity.impact_activity.id == impactActivityId ){
                        window._.each(imp_activity.profiles, profile_data => { data.push(profile_data) })
                    }
                })

                data = window._.groupBy(data, 'state')

                window._.each(data, d => {
                    d.cities = window._.groupBy(d, 'city')
                })

                return data
            },
        },
        mounted(){
            this.estados = estadosMex 
            this.municipios = municipiosMex
            let poa_year = this.$route.params.year
            let program_slug = this.$route.params.name

            this.getPOA(program_slug, poa_year)

        },
        components: {
            POATabs,
            BitacoraTabs,
        }
    }
</script>

<style lang="scss" scope>
    table.info-table{
        width: 100%;
        border: 1px solid rgba(#ececec, 1);
        border-radius: 5px;
        border-collapse: collapse;
        font-size: 0.8em;
        color: #515a6e;
        margin-top: 5px;
        table-layout: fixed;
        & th, td {
            border: 1px solid rgba(#ececec, 1);
            text-align: center;
            padding: 5px;
        }
        & tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }
        & tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }
    }

    .bitacora.ivu-table-wrapper .ivu-table-cell-tree{
        display: none !important;
    }

    .bitacora.ivu-table-wrapper tr[draggable="false"].ivu-table-row{
        display: table-row !important;
    }

    .state_name_bold{
        font-weight: bold;
        font-size: 1.1em;
    }

    .padding-city{
        padding-left: 15px;
        font-weight: bold;
    }

    .padding-profile{
        padding-left: 30px;
    }
    
    .profile-text{
        font-size: 0.9em;
    }

    .ivu-table-cell-tree-level{
        display: none !important;
    }

    td, th {
        position: relative;
    }

    .bitacora.ivu-table-wrapper {
        th .ivu-table-cell{
            font-size: 0.8em;
            word-break: keep-all;
            padding: 0;
        }
    }

    .ivu-table-summary .ivu-table-cell{
        text-align: center !important;
    }

</style>