<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">

            <template v-if="evaluation != null">
                <h1 class="section--subtitle">Evaluación de mi equipo {{ evaluation.name || '' }}</h1>
                <br><br>
                <!-- <h2 style="margin-bottom: -20px; margin-left: 10px;">Tus evaluaciones activas:</h2> -->
                <div class="evaluations-list">
                    <!-- <div @click="$router.push('/evaluacion/autoevaluacion')" class="evaluation-item">
                        <div class="evaluation-item--avatar">
                            <Avatar style="background-color: #843cf6" icon="ios-person" />
                        </div>
                        <div class="evaluation-item--info">
                            <p class="evaluation-item--info--name">{{ user.name }}</p>
                            <p class="evaluation-item--info--role">Autoevaluación</p>
                        </div>
                    </div> -->

                    <div @click="goToEvaluation(user)" v-for="user in Users" :key="'ev_itm_'+user.id" class="evaluation-item" :class="{ 'inactive': user.available == false }">
                        <div class="evaluation-item--avatar">
                            <Avatar :src="user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                        </div>
                        <div class="evaluation-item--info">
                            <div v-if="user.type == 'random'" class="evaluation-item--info--icon">
                                <Tooltip placement="right" content="Evaluación aleatoria">
                                    <Icon size="20" color="grey" type="md-information-circle" />
                                </Tooltip>
                            </div>
                            <p class="evaluation-item--info--name">{{ user.name }}</p>
                            <p class="evaluation-item--info--role">
                                {{ user.role_name }}
                                <span v-if="user.program"> en {{ user.program.program.name }}</span>
                            </p>
                        </div>
                    </div>

                    <template v-if="user.role_name == 'Coordinación'">
                        <div v-for="ev_program in programs" :key="'ev_prg_itm_'+ev_program.id" @click="$router.push(`/evaluacion/area/${ev_program.id}`)" class="evaluation-item">
                            <div class="evaluation-item--avatar">
                                <Avatar style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-square" />
                            </div>
                            <div class="evaluation-item--info">
                                <p class="evaluation-item--info--name">{{ ev_program.evaluated.name }}</p>
                                <p class="evaluation-item--info--role">
                                    Área {{ ev_program.evaluated.category == 'prog' ? 'programática' : 'transversal'  }}
                                </p>
                            </div>
                        </div>
                    </template>

                </div>
            </template>
            <template v-else>
                <h1 class="section--subtitle">Evaluación de mi equipo</h1>
                <br><br>
                 <div style="display: flex; flex-direction: column; align-items: center;">
                    <Icon size="70" type="md-contact" />
                    <br>
                    <h2>Por el momento no hay evaluación activa</h2>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                users: [],
                secrets: [],
                programs: [],
                evaluation: null,
                loading: true,
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ]),
            Users(){
                let a = []

                window._.each(this.users, u => {
                    u.type = 'normal'
                    a.push(u)
                })
                
                window._.each(this.secrets, s => {
                    s.type = 'random'
                    a.push(s)
                })

                return a 
            }
        },
        methods: {
            getToEvaluate(){
                window.axios.post(this.$root.API_URL + '/evaluation/get-to-evaluate')
                .then(response => {
                    if(response.data.success){
                        this.users = response.data.users
                        this.secrets = response.data.secrets
                        this.evaluation = response.data.evaluation
                        this.programs = response.data.programs
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            goToEvaluation(user){
                if( user.available ) this.$router.push(`/evaluacion/${user.id}`)
            }
        },
        mounted(){
            this.$collpaseDrawer('evs-content')
            this.$deleteSubitemsActives()
            this.$markSubitemAsActive('Evaluaciones')

            this.getToEvaluate()
        }
    }
</script>