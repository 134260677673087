<template>
    <div>
        <LoadingContainer v-if="loading"/>
        <div v-else class="container top">
            <Breadcrumb>
                <BreadcrumbItem to="/evaluaciones">Evaluaciones</BreadcrumbItem>
                <BreadcrumbItem :to="`/evaluaciones/${$route.params.year}`">{{ $route.params.year }}</BreadcrumbItem>
                <BreadcrumbItem>{{ program.name }}</BreadcrumbItem>
            </Breadcrumb>

            <Row type="flex" align="middle">
                <i-col span="20">
                    <h1 class="section--subtitle">Evaluaciones {{ $route.params.year }}</h1>
                </i-col>
            </Row>

            <br><br>
            <h2>Evaluaciones de {{ program.name }}</h2>
            <p>Programas involucrados en la evaluación:</p>
            <div style="padding-top: 10px" class="evaluations-list">
                <div @click="goToProgramsEvaluation(ev_program)" v-for="ev_program in Evaluators" :key="'ev_prg_itm_'+ev_program.id" class="evaluation-item">
                    <div class="evaluation-item--avatar">
                        <Avatar style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-square" />
                    </div>
                    <div class="evaluation-item--info">
                        <p class="evaluation-item--info--name">{{ ev_program.name }}</p>
                        <p class="evaluation-item--info--role">
                            Área {{ ev_program.category == 'prog' ? 'programática' : 'transversal'  }}
                        </p>
                    </div>
                </div>
            </div>

            <br><br>
            <h3>Resultados de la evaluación</h3>
            <br>
            <Row :gutter="16">
                <i-col span="24">
                    <h2 style="text-align: center;">Competencias</h2>
                    <SemiCircle :data="buildCompetencesChartData(evaluation_data.competences_status)" />
                    <h4 style="text-align: center">Promedio: {{ evaluation_data.avg.competences.original }} / {{ evaluation_data.avg.competences.rounded }}</h4>
                    <div style="display: flex; align-items: center; justify-content: center;" >
                        <div :style="{ 'background-color': getCompetenceAvgStatusColor() }" class="indicator--tag"></div>
                        <p style="font-weight: bold; font-size: 0.9em">{{ getCompetencesAvgStatusName() }}</p>
                    </div>
                </i-col>
            </Row>

            <br><br>
            <Collapse class="invisible">
                <Panel v-for="(evaluation, i) in evaluations" :key="`prg_evaluator_${i}`" :name="`${i}`">
                    <div style="display: flex; flex-direction: column; align-items: flex-start;" >
                        <p v-if="evaluation.status == 'init'" style="padding: 3px 5px; background: #F13030; color: white; font-size: 0.7em; border-radius: 5px; margin-bottom: 5px;">{{ evaluation.status == 'init' ? 'No finalizada' : '' }}</p>
                        <h3>{{ evaluation.evaluator.name }}</h3>
                    </div>
                    <template #content>
                        <div class="evaluation--box" v-for="ev in evaluation.competences" :key="'autv_cmp_id_'+ev.id">
                            <Row :gutter="16">
                                <i-col span="24">
                                    <p class="competence-name">{{ ev.special_competence.name }}</p>
                                </i-col>
                                <i-col span="24">
                                    <div class="evaluation--indicator">
                                        <div class="evaluation--indicator--item">
                                            <div :style="{ 'background-color': ev.status.hex_color }" class="indicator--tag"></div>
                                            <p>{{ ev.status.name }}</p>
                                        </div>
                                    </div>
                                    <div style="padding: 10px 0">
                                        <p>{{ ev.comment }}</p>
                                    </div>
                                </i-col>
                            </Row>
                        </div>

                        <div class="evaluation--box">
                            <Row :gutter="16">
                                <i-col span="24">
                                    <p class="competence-name">Recomendaciones y/o comentarios para mejorar el desempeño del área</p>
                                </i-col>
                                <i-col span="24">
                                    <div style="padding: 10px 0">
                                        <p style="white-space: break-spaces;" v-html="evaluation.comments"></p>
                                    </div>
                                </i-col>
                            </Row>
                        </div>
                        <h2></h2>
                    </template>
                </Panel>
            </Collapse>

        </div>
    </div>
</template>

<script>
    import chartMix from './../../../mixins/chart'
    import SemiCircle from './../../Charts/CustomSemiCircle'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [chartMix],
        data(){
            return {
                loading: true,
                program: null,
                evaluations: [],
                evaluation_data: null,
            }
        },
        computed: {
            ...mapGetters([
                'status_ev_trans',
            ]),
            Evaluators(){
                let a = []
                window._.each(this.evaluations, e => a.push(e.evaluator))
                return a 
            },
        },
        methods: {
            goToProgramsEvaluation(program){
                this.$router.push({ name: 'admin-program-evs', params: { year: this.$route.params.year, id: program.id } })
                window.location.reload()
            },
            buildCompetencesChartData(competences){
                return this.getCompetencesChartData(competences)
            },
            getCompetenceAvgStatusColor(){
                let si = window._.findIndex(this.status_ev_trans, s => s.score == this.evaluation_data.avg.competences.rounded)
                if( si != -1 ) return this.status_ev_trans[si].hex_color
                else return '#ececec'
            },
            getCompetencesAvgStatusName(){
                let si = window._.findIndex(this.status_ev_trans, s => s.score == this.evaluation_data.avg.competences.rounded)
                if( si != -1 ) return this.status_ev_trans[si].name
                else return 'NA'
            },
            getEvaluations(){
                window.axios.post(this.$root.API_URL + `/evaluation/get-all-evaluations-area/${this.$route.params.id}`, { year: this.$route.params.year })
                .then(response => {
                    if(response.data.success){
                       this.program = response.data.program
                       this.evaluations = response.data.evaluations
                       this.evaluation_data = response.data.data
                       this.loading = false
                    }
                    else {
                        this.message = response.data.type
                        this.loading = false
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            }
        },
        mounted(){
            this.getEvaluations()
        },
        components: {
            SemiCircle,
        }
    }
</script>