<template>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: 25px 0 40px 0; border-bottom: 1px solid #ececec;
    padding-bottom: 9px;">
        <router-link class="poa-tab-link" :to="`${report_link}`">
            Usuarios
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat1`">
            Misión y visión
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat2`">
            Espacio laboral libre de discriminación
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat3`">
            Acoso y Hostigamiento Sexual
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat4`">
            Acoso y Hostigamiento Laboral
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat5`">
            Conciliación Vida Laboral, Familiar e Institucional
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat6`">
            Liderazgo y gestión de equipo
        </router-link>

        <router-link class="poa-tab-link" :to="`${report_link}/tab/cat7`">
            Resolución de Conflictos al Interior del ILSB
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        tab: { required: true, type: String, default: 'users' },
        report_link: { required: true, type: String, }
    },
    methods:{
        switchTab(name){
            console.log(`${this.report_link}/tab/${name}`)
            this.$router.push({path: `${this.report_link}/tab/${name} `}).then(r => {
                console.log(r)
            })
        },
    },
}
</script>

<style lang="scss">
.poa-tab-link{
    height: 100%;
    padding: 8px 16px;
    margin-right: 16px;
    border-bottom: 2px solid transparent;
    color: #515a6e;
    font-size: 0.9em;
    margin-bottom: 15px;
    &:hover{
        color: #843cf6;
    }
    &.router-link-active{
        color: #843cf6;
        border-color: #843cf6;
    }
}
</style>