<template>
    <div>

        <h2 class="section--subtitle">Conciliación Vida Laboral, Familiar e Institucional </h2>
        <br>

        <Form style="width: 100%" ref="cat5Form" :model="answers" :rules="ruleInline">

            <!-- Q24 -->
            <FormItem
                label="24. ¿En tu área de trabajo todxs tienen las mismas facilidades para atender problemas familiares no previstos? " prop="q24">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q24">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q25 -->
            <FormItem label="25. ¿Tu horario de trabajo te permite conciliar la vida familiar/personal con la laboral?" prop="q25">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q25">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q26 -->
            <FormItem label="26. ¿En tu área de trabajo se otorgan permisos para atender situaciones familiares como cuidado de hijas e hijos, personas enfermas o personas adultas mayores?" prop="q26">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q26">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

            <!-- Q27 -->
            <FormItem label="27. ¿En tu área de trabajo encuentras facilidades para acceder a ajustes razonables a tu puesto de trabajo para conciliar tu vida personal y profesional?" prop="q27">
                <div class="env-survey-field-answers">
                    <RadioGroup v-model="answers.q27">
                        <Radio label="Si" border></Radio>
                        <Radio label="No" border></Radio>
                    </RadioGroup>
                </div>
            </FormItem>

        </Form>

        <!--  -->
        <div class="row">
            <div style="height: 50px; width: 100%;"></div>
            <div class="col" style="display: flex; justify-content: center">
                <Button :loading="server_loading" style="width: 50%" type="primary" size="large" @click="goToNext">Siguiente</Button>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            form: { required: true, type: [Object, Array] },
            server_loading: { required: true, type: Boolean, default: false },
        },
        data(){
            return {
                answers: {
                    q24: "",
                    q25: "",
                    q26: "",
                    q27: "",
                    //q28: "",
                    //q29: "",
                },
                ruleInline: {
                    q24: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q25: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q26: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    q27: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    //q28: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                    //q29: [ { required: true, message: "Esta pregunta es requerida", trigger: 'change', } ],
                },
            }
        },
        computed: {

        },
        methods: {
            goToNext(){
                this.$refs.cat5Form.validate(valid => {
                    console.log(valid)
                    if( ! valid ) this.goToFirstError()
                    else {
                        let answers = [
                            {
                                code: 'q24',
                                answer_type: 'multiple',
                                answer: this.answers.q24,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q25',
                                answer_type: 'multiple',
                                answer: this.answers.q25,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q26',
                                answer_type: 'multiple',
                                answer: this.answers.q26,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q27',
                                answer_type: 'multiple',
                                answer: this.answers.q27,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            /*{
                                code: 'q28',
                                answer_type: 'multiple',
                                answer: this.answers.q28,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },
                            {
                                code: 'q29',
                                answer_type: 'multiple',
                                answer: this.answers.q29,
                                answers: null,
                                other_option: null,
                                reason_option: null,
                            },*/
                        ]
                        this.$emit('on-register-answers', { answers: answers, 'last_question_code': 'q29' })
                    }
                })
            },
            goToFirstError(){
                setTimeout(() => {
                    let first_error =
                        window.$('.ivu-form-item.ivu-form-item-required.ivu-form-item-error')[0]
                    if( first_error ) {
                        window.$('html, body').animate({
                            scrollTop: (window.$(first_error).offset().top - 120)
                        }, 1000);
                    }
                }, 200)
            }
        }
    }
</script>