<template>
    <div class="special-cmpt">
        <div class="cont">
            <p>{{ competence.name }}</p>
        </div>
        <div class="actions">
            <Dropdown slot="extra" trigger="click" placement="left">
                <a href="javascript:void(0)">
                    <Icon color="grey" size="16" type="md-more"></Icon>
                </a>
                <DropdownMenu v-if="CanUpdateCompetence || CanHideCompetence" slot="list">
                    <DropdownItem v-if="CanUpdateCompetence" @click.native="updateCompetence(competence)">Editar</DropdownItem>
                    <DropdownItem v-if="CanHideCompetence" @click.native="hideCompetence(competence)">Ocultar</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            competence: { required: true, type: [Object, Array] }
        },
        computed: {
            CanUpdateCompetence(){
                return this.$can('competences.update')
            },
            CanHideCompetence(){
                return this.$can('competences.hide')
            }
        },
        methods: {
            updateCompetence(competence){
                window.EventBus.$emit('competences--update-special-popup', competence)
            },
            hideCompetence(competence){
                this.$Modal.confirm({
                    title: 'Eliminar Competencia',
                    content: `<p>¿Deseas eliminar esta competencia?</p><p>Al hacer clic en <strong>Eliminar</strong> también se borarrá toda la información relacionada a esta competencia.</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + `/evaluation/competences/hide-special/${competence.id}`)
                        .then(response => {
                            if(response.data.success){
                                window.EventBus.$emit(`competences--delete-item-special-competence`, competence)
                                this.$Message.success('Competencia eliminada correctamente');
                            }
                        })
                    },
                });
            },
        }
    }
</script>