export default {
    computed: {
        AllMembersIDs() {
            let ids = []
            window._.each(this.members, m => ids.push(m.id))
            return ids
        },
        CoordsIDs() {
            let ids = []
            window._.each(this.members, (m) => {
                if( m.role_name == 'Coordinación' ) ids.push(m.id) 
            })
            return ids
        }
    },
    methods: {
        isAuthUserCoordOfThisProject() {
            return this.CoordsIDs.includes( this.user.id ) || this.itsAuthUserDirMemberOfThisProject() || this.itsAuthUserSubdirMemberOfThisProject() 
        },
        isAuthUserMembersOfThisProject() {
            return this.AllMembersIDs.includes( this.user.id )
        },
        itsAuthUserDirMemberOfThisProject() {
            if ( this.$itsDirector() && this.AllMembersIDs.includes( this.user.id ) ) return true
            else return false
        },
        itsAuthUserSubdirMemberOfThisProject() {
            if ( this.$itsSubdirector() && this.AllMembersIDs.includes( this.user.id ) ) return true
            else return false
        }
    },
}