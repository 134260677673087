<template>
    <div>
        <!-- Evaluation scales -->
        <EvaluationScales :items="status" name="ev-results" />
        <br><br>
        <Row v-if="HasFeedback">
            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <div style="display: flex; align-items: center; flex-direction: column; padding: 15px">
                    <br>
                    <h3 style="text-align: center;">Tu autoevaluación</h3>
                </div>
            </i-col>
            <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <div style="display: flex; align-items: center; flex-direction: column; padding: 15px">
                    <h3 style="text-align: center;">Tu retroalimentación por:</h3>
                    <template v-if="!multipleFeedback">
                        <div style="display: flex; align-items: center; margin-top: 15px">
                            <Avatar :src="feedback.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                            <div style="margin-left: 15px">
                                <h4>{{ feedback.user.name }}</h4>
                                <p style="font-size: 0.9em; color: grey;">{{ feedback.user.role_name }}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <br>
                        <div style="display: flex">
                            <div v-for="user_f in feedback" :key="`feedback_usr_${user_f.user.id}`" style="display: flex; align-items: center; margin: 0 15px;">
                                <Avatar :src="user_f.user.profile_pic" style="background: linear-gradient(280deg, #f16823, #843cf6)" icon="md-person" />
                                <div style="margin-left: 15px">
                                    <h4>{{ user_f.user.name }}</h4>
                                    <p style="font-size: 0.9em; color: grey;">{{ user_f.user.role_name }}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </i-col>
        </Row>
        <div class="evaluation--box" v-for="evaluation in Activities" :key="'autv_cmp_id_'+evaluation.id">
            <Row :gutter="50">
                <i-col :xs="24" :sm="24" :md="TitleRowLength" :lg="TitleRowLength">
                    <p class="competence-name">{{ evaluation.classable.name }}</p>
                    <template v-if="hasActivities(evaluation.classable)">
                        <br>
                        <ActivitiesCollapse :activities="getResultsActivities(evaluation.classable)"/>
                    </template>
                </i-col>
                <i-col :xs="24" :sm="24" :md="12" :lg="12">
                    <div class="evaluation--indicator">
                        <template v-if="CanEvaluationBeUpdated">
                            <Dropdown placement="bottom-start" trigger="click">
                                <div class="evaluation--indicator--item">
                                    <div :style="{ 'background-color': getIndicatorBackground(evaluation) }" class="indicator--tag"></div>
                                    <p>{{ getIndicatorName(evaluation) }}</p>
                                </div>
                                <DropdownMenu slot="list">
                                    <DropdownItem @click.native="updateEvIndicator(evaluation, status)" v-for="status in Statuses" :key="'res_ev_ind_upt_succ_'+status.id" >
                                        <div class="indicator--short">
                                            <div :style="{ 'background-color': status.hex_color }" class="indicator--tag"></div>
                                            {{ status.name }}
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                        <template v-else>
                            <div class="evaluation--indicator--item">
                                <div :style="{ 'background-color': getIndicatorBackground(evaluation) }" class="indicator--tag"></div>
                                <p>{{ getIndicatorName(evaluation) }}</p>
                            </div>
                        </template>
                    </div>
                    <template v-if="CanEvaluationBeUpdated">
                        <Input @on-blur="updateEvComment(evaluation)" v-model="evaluation.comment" style="margin-top: 15px" type="textarea" :rows="4" placeholder="Escribe aquí tus comentarios" />
                    </template>
                    <template v-else>
                        <div style="padding: 10px 0">
                            <p>{{ evaluation.comment }}</p>
                        </div>
                    </template>
                </i-col>
                <i-col v-if="HasFeedback" :xs="24" :sm="24" :md="12" :lg="12">
                    <template v-if="!multipleFeedback">
                        <div class="evaluation--indicator">
                            <div class="evaluation--indicator--item">
                                <div :style="{ 'background-color': getFeedbackIndicatorBackground(evaluation) }" class="indicator--tag"></div>
                                <p>{{ getFeedbackIndicatorName(evaluation) }}</p>
                            </div>
                        </div>
                        <div style="padding: 10px 0">
                            <p v-html="getFeedbackComment(evaluation)"></p>
                        </div>
                    </template>
                    <template v-else>
                        <div v-for="user_f in feedback" :key="`feedback_usr_ev_${user_f.user.id}`">
                            <h4>Evaluación de {{ user_f.user.name }}:</h4>
                            <br>
                            <div class="evaluation--indicator">
                                <div class="evaluation--indicator--item">
                                    <div :style="{ 'background-color': getMultipleFeedbackIndicatorBackground(user_f, evaluation) }" class="indicator--tag"></div>
                                    <p>{{ getMultilpleFeedbackIndicatorName(user_f, evaluation) }}</p>
                                </div>
                            </div>
                            <div style="padding: 10px 0">
                                <p v-html="getMultipleFeedbackComment(user_f, evaluation)"></p>
                            </div>
                            <br><br>
                        </div>
                    </template>
                </i-col>
            </Row>
        </div>

        <template v-if="Activities.length == 0">
            <div style="display: flex; aligin-items: center; justify-content: center; flex-direction: column; text-align: center;">
                <div style="height: 55px"></div>
                <h2>No hay resultados para evaluar.</h2>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import EvaluationScales from './../../../../EvaluationScales/Legends'
    import ActivitiesCollapse from './ActivitiesCollapse'

    export default {
        props: {
            feedback: { required: true, },
            evaluation: { required: true, type: [Object, Array] },
            activities: { required: false, type: [Object, Array] },
            multipleFeedback: { required: true, type: Boolean, default: false },
        },
        computed:{
            ...mapGetters({
                'status': 'status_des',
            }),
            Statuses(){
                return this.status
            },
            HasFeedback(){
                if( Array.isArray(this.feedback) ) return this.feedback.length > 0
                return this.feedback != null
            },
            TitleRowLength(){
                return this.HasFeedback ? 24 : 12
            },
            Activities(){
                return window._.filter(this.evaluation.evaluations, e => e.classable_type == 'result')
            },
            CanEvaluationBeUpdated(){
                let s = [ 'auto-finished', 'boss-finished', 'accepted' ];
                return ! s.includes(this.evaluation.status)
            },
        },
        methods: {
            getResultsActivities(result){
                return window._.filter(this.activities, a => a.result.id == result.id)
            },
            hasActivities(result){
                let activities = this.getResultsActivities(result)
                return activities.length > 0
            },
            getIndicatorBackground(evaluation){
                return evaluation.status ? evaluation.status.hex_color : '#ececec'
            },
            getIndicatorName(evaluation){
                return evaluation.status ? evaluation.status.name : 'Pendiente de evaluar'
            },
            updateEvIndicator(evaluation, status){
                window.axios.post(this.$root.API_URL + `/evaluation/update-status/${evaluation.id}`, { field: 'statuses_id', status_id: status.id })
                .then(response => {
                    if(response.data.success){
                        window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                        this.$Message.success('Desempeño actualizado correctamente');
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                })
            },
            updateEvComment(evaluation){
                if( evaluation.comment != null ){
                    window.axios.post(this.$root.API_URL + `/evaluation/update-comment/${evaluation.id}`, { field: 'comment', comment: evaluation.comment })
                    .then(response => {
                        if(response.data.success){
                            window.EventBus.$emit('autoevaluation--update-evaluation', response.data.evaluation)
                            this.$Message.success('Desempeño actualizado correctamente');
                        }
                    })
                    .catch(() => {
                        this.$Message.error('Hubo un problema, no pudimos actualizar el desempeño');
                    })
                }
            },
            getFeedbackIndicatorBackground(evaluation){
                let evfi = window._.findIndex(this.feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = this.feedback.evaluations[evfi]
                    return ev.status ? ev.status.hex_color : '#ececec'
                }
                return '#ececec'
            },
            getMultipleFeedbackIndicatorBackground(feedback, evaluation){
                let evfi = window._.findIndex(feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = feedback.evaluations[evfi]
                    return ev.status ? ev.status.hex_color : '#ececec'
                }
                return '#ececec'
            },
            getMultilpleFeedbackIndicatorName(feedback, evaluation){
                let evfi = window._.findIndex(feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = feedback.evaluations[evfi]
                    return ev.status ? ev.status.name : 'NA'
                }
                return 'NA'
            },
            getMultipleFeedbackComment(feedback, evaluation){
                let evfi = window._.findIndex(feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = feedback.evaluations[evfi]
                    return ev.comment
                }
                return ''
            },
            getFeedbackIndicatorName(evaluation){
                let evfi = window._.findIndex(this.feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = this.feedback.evaluations[evfi]
                    return ev.status ? ev.status.name : 'NA'
                }
                return 'NA'
            },
            getFeedbackComment(evaluation){
                let evfi = window._.findIndex(this.feedback.evaluations, e => e.classable.id == evaluation.classable.id)
                if( evfi != -1 ){
                    let ev = this.feedback.evaluations[evfi]
                    return ev.comment
                }
                return ''
            },
        },
        components: {
            EvaluationScales,
            ActivitiesCollapse,
        }
    }
</script>